import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Receipt } from 'tabler-icons-react';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import { Context as AuthContext } from '../../providers/AuthProvider';
import { Context as RegistrationAdminDashboardContext } from '../../providers/RegistrationAdminDashboardProvider';

import AppCard from './AppCard';
import AppStack from './AppStack';

const ResourceChangeAssociationView = ({
  resourceIdentifier,
  IconComponent,
  fkRegAssociation,
  backPath
}) => {
  const { state: authState } = useContext(AuthContext);
  const { changeAssociation } = useContext(RegistrationAdminDashboardContext);

  const regAssociation =
    authState.regAssociationAdmins.value.find(
      (a) => a.regAssociation.pkRegAssociation === fkRegAssociation
    )?.regAssociation || null;
  const Icon = IconComponent || Receipt;

  return (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 600,
        paddingTop: 16,
        gap: 16,
        alignItems: 'center'
      }}
    >
      <AppCard
        radius="md"
        shadow="lg"
        style={{ padding: 20, maxWidth: 400, marginTop: 40 }}
        withBorder
      >
        <AppStack style={{ flex: 1, gap: 8 }}>
          <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
            <Icon color="#000" size={150} />
          </AppFlexbox>
          <AppStack style={{ gap: 20 }}>
            {regAssociation ? (
              <>
                <AppStack style={{ gap: 8, textAlign: 'center' }}>
                  <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                    Switch association
                  </AppText>
                  <AppText style={{ fontSize: 16, color: '#666' }}>
                    This {resourceIdentifier} belongs to{' '}
                    <b>{regAssociation.association.name}</b>. Please switch to
                    the correct associaion to view.
                  </AppText>
                </AppStack>

                <Button
                  color="dark"
                  onClick={() => {
                    changeAssociation({
                      pkRegAssociation: regAssociation.pkRegAssociation,
                      regAssociation
                    });
                  }}
                  variant="outline"
                >
                  Switch association
                </Button>

                <Divider label="OR" labelPosition="center" />
              </>
            ) : (
              <AppStack style={{ gap: 8, textAlign: 'center' }}>
                <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                  {resourceIdentifier} not found
                </AppText>
                <AppText style={{ fontSize: 16, color: '#666' }}>
                  The {resourceIdentifier} you are looking for does not exist or
                  you do not have permission to view it.
                </AppText>
              </AppStack>
            )}
            <Button
              color="dark"
              component={Link}
              to={backPath}
              variant="filled"
            >
              Back to {resourceIdentifier}
            </Button>
          </AppStack>
        </AppStack>
      </AppCard>
    </AppStack>
  );
};

ResourceChangeAssociationView.propTypes = {
  IconComponent: PropTypes.any,
  backPath: PropTypes.string,
  fkRegAssociation: PropTypes.number,
  resourceIdentifier: PropTypes.string
};

export default ResourceChangeAssociationView;
