import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppText from '../../../../components/common/AppText';

const SidebarNavButton = ({
  IconComponent,
  link,
  label,
  color,
  isSelected,
  innerSelected,
  target,
  rightSection
}) => (
  <AppFlexbox
    component={Link}
    style={{
      padding:
        isSelected || innerSelected ? `3px 5px 3px 10px` : `3px 5px 3px 10px`,
      borderRadius: 8,
      color: '#000',
      textDecoration: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 10,
      backgroundImage: isSelected
        ? 'linear-gradient(270deg, rgba(240, 180, 0,0.2) 0%, rgba(255,255,255,1) 100%)'
        : 'none',
      borderRight: isSelected
        ? 'solid 3px rgba(240, 180, 0)'
        : innerSelected
        ? 'solid 3px #CDCDCD'
        : 'none',
      borderTopRightRadius: isSelected || innerSelected ? 0 : 8,
      borderBottomRightRadius: isSelected || innerSelected ? 0 : 8,
      margin: '0px 10px',
      marginRight: isSelected || innerSelected ? 0 : 10
    }}
    target={target}
    to={link}
  >
    <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
      <AppFlexbox style={{ width: 24, height: 24, justifyContent: 'center' }}>
        {IconComponent && <IconComponent size={24} />}
      </AppFlexbox>
      <AppText
        style={{
          fontSize: 18,
          fontWeight: 600,
          color: isSelected ? '#000' : color ?? 'grey'
        }}
      >
        {label}
      </AppText>
    </AppFlexbox>
    {rightSection}
  </AppFlexbox>
);

SidebarNavButton.propTypes = {
  IconComponent: PropTypes.any,
  color: PropTypes.string,
  innerSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.string,
  rightSection: PropTypes.any,
  target: PropTypes.string
};

export default SidebarNavButton;
