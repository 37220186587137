import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  Radio,
  Textarea,
  TextInput,
  Tooltip
} from '@mantine/core';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { useMediaQueries } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppRadioGroup from '../../common/AppRadioGroup';
import ImageUploadButton from '../../common/ImageUploadButton';

const AssignDivisionAccordionListItem = ({
  currencyAbbr,
  division,
  formState,
  onAssignChange,
  onValueChange,
  onPreviewInfoModal
}) => {
  const { mqIndex, isLargeMobileOrSmaller } = useMediaQueries();
  const [accordionState, setAccordionState] = useState(null);
  const accordionValue = division.value.toString();

  return (
    <Accordion
      chevronSize={0}
      onChange={(value) => {
        if (formState?.isActive) {
          setAccordionState(value);
        }
      }}
      radius={0}
      styles={{
        control: { height: rs(['unset', 'unset', 60], mqIndex), padding: 16 },
        content: { padding: 0 },
        root: { padding: 0 },
        contentInner: { padding: 0 }
      }}
      value={accordionState}
      variant="contained"
    >
      <Accordion.Item value={accordionValue}>
        <Accordion.Control>
          <AppFlexbox
            key={division.value}
            style={{
              gap: rs([10, 0], mqIndex),
              justifyContent: 'space-between',
              flexDirection: rs(['column', 'row'], mqIndex),
              alignItems: 'center'
            }}
          >
            <Tooltip
              label="Assign Division"
              style={{ alignSelf: 'stretch', margin: 'auto 0px' }}
              withArrow
              withinPortal
            >
              <Checkbox
                checked={formState?.isActive ?? false}
                label={division.label}
                onChange={(e) => {
                  setAccordionState(
                    e.currentTarget.checked ? accordionValue : null
                  );
                  onAssignChange(e);
                }}
                styles={{label: { fontWeight: formState?.isActive ? 600 : 400 }}}
              />
            </Tooltip>
            {formState?.isActive && (
              <Button
                radius="xl"
                rightSection={
                  accordionState ? (
                    <ChevronUp color="dodgerblue" />
                  ) : (
                    <ChevronDown color="dodgerblue" />
                  )
                }
                variant="outline"
              >
                {accordionState ? 'Show Less' : 'Show More'}
              </Button>
            )}
          </AppFlexbox>
        </Accordion.Control>
        <Accordion.Panel>
          <Divider color="#dee2e6" />
          <AppStack style={{ padding: 16 }}>
            {formState?.isActive && (
              <AppStack style={{ gap: 20 }}>
                <AppFlexbox>
                  <NumberFormat
                    allowNegative={false}
                    customInput={TextInput}
                    decimalScale={2}
                    fixedDecimalScale
                    label="Price"
                    onValueChange={(e) =>
                      onValueChange({
                        ...formState,
                        price: e.value
                      })
                    }
                    placeholder="$0.00"
                    prefix="$"
                    required
                    rightSection={
                      currencyAbbr && (
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: '#999',
                            paddingRight: 5
                          }}
                        >
                          {currencyAbbr}
                        </AppText>
                      )
                    }
                    style={{ flex: 1, maxWidth: rs(['unset', 300], mqIndex) }}
                    thousandSeparator
                    value={formState.price}
                  />
                </AppFlexbox>
                <AppRadioGroup
                  label="Platform Fee"
                  onChange={(value) =>
                    onValueChange({
                      ...formState,
                      customerPaysServiceFees: value === 'ADDITIONAL-FEE'
                    })
                  }
                  size="sm"
                  spacing="lg"
                  value={
                    formState.customerPaysServiceFees
                      ? 'ADDITIONAL-FEE'
                      : 'NORMAL-FEE'
                  }
                >
                  <Radio
                    label="Platform fee is taken from principle amount"
                    value="NORMAL-FEE"
                  />
                  <Radio
                    label="Platform fee is paid as an additional fee by the customer"
                    value="ADDITIONAL-FEE"
                  />
                </AppRadioGroup>

                <Divider />

                <TextInput
                  label="Title Override"
                  onChange={(e) =>
                    onValueChange({
                      ...formState,
                      titleOverride: e.currentTarget.value.substring(0, 255)
                    })
                  }
                  style={{ flex: 1, maxWidth: rs(['unset', 300], mqIndex) }}
                  value={formState.titleOverride ?? ''}
                />
                {division?.hasOrderEmails && (
                  <Checkbox
                    checked={formState.orderEmailEnabled}
                    label="Include order email"
                    onChange={(e) => {
                      onValueChange({
                        ...formState,
                        orderEmailEnabled: e.currentTarget.checked
                      });
                    }}
                    styles={{ label: { fontWeight: 500 } }}
                  />
                )}
                <AppStack style={{ gap: 10 }}>
                  <AppText style={{ fontSize: 14 }} weight={500}>
                    Days of play
                  </AppText>
                  <AppFlexbox style={{ flexWrap: 'wrap' }}>
                    {[
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday'
                    ].map((day) => (
                      <Checkbox
                        key={day}
                        checked={formState.daysOfPlay.includes(day)}
                        label={day}
                        onChange={(e) => {
                          const newDaysOfPlayState = formState.daysOfPlay.filter(
                            (d) => d !== day
                          );
                          onValueChange({
                            ...formState,
                            daysOfPlay: e.currentTarget.checked
                              ? [...newDaysOfPlayState, day]
                              : newDaysOfPlayState
                          });
                        }}
                        style={{ width: rs(['45%', 'unset'], mqIndex) }}
                      />
                    ))}
                  </AppFlexbox>
                </AppStack>

                <Divider />

                <AppStack style={{ gap: 15 }}>
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Additional Info
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      Provide more details about the division. This information
                      will be displayed when users click "View More" on a
                      registration.
                    </AppText>
                  </AppStack>

                  <AppFlexbox
                    style={{
                      gap: 10,
                      justifyContent: 'space-between',
                      flexDirection: isLargeMobileOrSmaller
                        ? 'column-reverse'
                        : 'row'
                    }}
                  >
                    <AppRadioGroup
                      onChange={(value) => {
                        onValueChange({
                          ...formState,
                          isAdditionalInfoHyperlink: value === '2'
                        });
                      }}
                      size="sm"
                      value={
                        formState.isAdditionalInfoHyperlink === true ? '2' : '1'
                      }
                    >
                      <Radio label="Text" value="1" />
                      <Radio label="Link" value="2" />
                    </AppRadioGroup>

                    <AppFlexbox
                      style={{
                        gap: 10,
                        flexDirection: isLargeMobileOrSmaller
                          ? 'row-reverse'
                          : 'row'
                      }}
                    />

                    {formState.additionalInfoImage && (
                      <Button
                        color="red"
                        onClick={() => {
                          onValueChange({
                            ...formState,
                            additionalInfoImage: null
                          });
                        }}
                        size="xs"
                        variant="outline"
                      >
                        Remove Image
                      </Button>
                    )}

                    <ImageUploadButton
                      color="blue"
                      identifier={`division-${division.pkRegAssociationDivision}-additional-info`}
                      label={
                        formState.additionalInfoImage
                          ? 'Change Image'
                          : 'Add Image'
                      }
                      onUploadComplete={(url) => {
                        onValueChange({
                          ...formState,
                          additionalInfoImage: url
                        });
                      }}
                      size="xs"
                      variant="outline"
                    />
                  </AppFlexbox>

                  {formState.isAdditionalInfoHyperlink ? (
                    <TextInput
                      onChange={(e) => {
                        onValueChange({
                          ...formState,
                          additionalInfo: e.currentTarget.value.substring(
                            0,
                            2000
                          )
                        });
                      }}
                      placeholder="https://website.com/additional-info"
                      value={formState.additionalInfo ?? ''}
                    />
                  ) : (
                    <Textarea
                      autosize
                      maxRows={10}
                      minRows={4}
                      onChange={(e) => {
                        onValueChange({
                          ...formState,
                          additionalInfo: e.currentTarget.value.substring(
                            0,
                            2000
                          )
                        });
                      }}
                      value={formState.additionalInfo ?? ''}
                    />
                  )}

                  <AppStack
                    style={{
                      gap: 20,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AppText
                      style={{
                        fontSize: 14,
                        color: '#666',
                        fontStyle: 'italic',
                        textAlign: 'center'
                      }}
                    >
                      Images will be placed above the additional info text.
                    </AppText>

                    <Button
                      color="blue"
                      onClick={onPreviewInfoModal}
                      size="sm"
                      variant="filled"
                    >
                      Preview
                    </Button>
                  </AppStack>
                </AppStack>
              </AppStack>
            )}
          </AppStack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

AssignDivisionAccordionListItem.propTypes = {
  currencyAbbr: PropTypes.string,
  division: PropTypes.object,
  formState: PropTypes.object,
  onAssignChange: PropTypes.func,
  onPreviewInfoModal: PropTypes.func,
  onValueChange: PropTypes.func
};

export default AssignDivisionAccordionListItem;
