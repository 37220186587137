import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueries } from '../../../helpers/hooks';
import CustomLabelTextInput from '../../common/CustomLabelInput';
import ConfirmModal from '../../common/ConfirmModal';

const SecondaryContactModal = ({
  isOpen,
  onClose,
  regPersonContact,
  withEmailSettings,
  withNote,
  onConfirm,
  onRemove,
  confirmRemoval,
  isLoading,
  isDeleting
}) => {
  const { mqIndex, getResponsiveStyle: rs } = useMediaQueries();
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    note: '',
    receiveAllEmails: false,
    confirmRemoval: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        firstName: regPersonContact?.firstName || '',
        lastName: regPersonContact?.lastName || '',
        email: regPersonContact?.email || '',
        phone: regPersonContact?.phone || '',
        note: regPersonContact?.note || '',
        receiveAllEmails: regPersonContact?.receiveAllEmails || false,
        confirmRemoval: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: 'Cancel',
        onCancel: onClose,
        onSubmit: () => {
          onConfirm({ ...regPersonContact, ...formState });
        },
        isLoading: isLoading && !isDeleting,
        submitTitle: regPersonContact ? 'Save contact' : 'Add contact'
      }}
      onClose={onClose}
      opened={isOpen}
      size={800}
      style={{ marginTop: 0, gap: rs([20, 40], mqIndex), flex: 1 }}
      title={
        <AppTitle order={2}>
          {regPersonContact
            ? 'Edit Secondary Contact'
            : 'Add Secondary Contact'}
        </AppTitle>
      }
    >
      <AppStack style={{ gap: rs([20, 40], mqIndex) }}>
        <AppFlexbox
          style={{
            gap: 0,
            alignItems: 'start',
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          <AppText
            style={{
              display: rs(['none', 'block'], mqIndex),
              paddingTop: rs([0, 10], mqIndex),
              flex: 1,
              marginBottom: rs([10, 0], mqIndex)
            }}
            weight={700}
          >
            Name
          </AppText>
          <AppStack style={{ flex: 4, width: rs(['100%', 'inital'], mqIndex) }}>
            <AppFlexbox
              style={{
                gap: rs([15, 20], mqIndex),
                flexDirection: rs(['column', 'row'], mqIndex)
              }}
            >
              <CustomLabelTextInput
                disabled={isLoading}
                label="First Name"
                name="firstName"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    firstName: e.currentTarget.value
                  })
                }
                required
                value={formState.firstName}
              />

              <CustomLabelTextInput
                disabled={isLoading}
                label="Last Name"
                name="lastName"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    lastName: e.currentTarget.value
                  })
                }
                required
                value={formState.lastName}
              />
            </AppFlexbox>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox
          style={{
            gap: 0,
            alignItems: 'start',
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          <AppText
            style={{
              display: rs(['none', 'block'], mqIndex),
              paddingTop: rs([0, 10], mqIndex),
              flex: 1,
              marginBottom: rs([10, 0], mqIndex)
            }}
            weight={700}
          >
            Email
          </AppText>
          <AppStack style={{ flex: 4, width: rs(['100%', 'inital'], mqIndex) }}>
            <CustomLabelTextInput
              disabled={isLoading}
              label="Email"
              name="email"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: e.currentTarget.value
                })
              }
              required
              type="email"
              value={formState.email}
            />
          </AppStack>
        </AppFlexbox>

        <AppFlexbox
          style={{
            gap: 0,
            alignItems: 'start',
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          <AppText
            style={{
              display: rs(['none', 'block'], mqIndex),
              paddingTop: rs([0, 10], mqIndex),
              flex: 1,
              marginBottom: rs([10, 0], mqIndex)
            }}
            weight={700}
          >
            Phone Number
          </AppText>
          <AppStack style={{ flex: 4, width: rs(['100%', 'inital'], mqIndex) }}>
            <AppFlexbox style={{ gap: 20 }}>
              <CustomLabelTextInput
                disabled={isLoading}
                format="+1 (###) ###-####"
                label="Phone Number"
                mask="_"
                name="phoneNumber"
                onChange={(values) => {
                  setFormState({
                    ...formState,
                    phone: values.value
                  });
                }}
                type="number"
                value={formState.phone}
              />

              <AppStack
                style={{ flex: 1, display: rs(['none', 'flex'], mqIndex) }}
              />
            </AppFlexbox>
          </AppStack>
        </AppFlexbox>

        {withNote && (
          <AppFlexbox
            style={{
              gap: 0,
              alignItems: 'start',
              flexDirection: rs(['column', 'row'], mqIndex)
            }}
          >
            <AppText
              style={{
                display: rs(['none', 'block'], mqIndex),
                paddingTop: rs([0, 10], mqIndex),
                flex: 1,
                marginBottom: rs([10, 0], mqIndex)
              }}
              weight={700}
            >
              Note
            </AppText>
            <AppStack
              style={{ flex: 4, width: rs(['100%', 'inital'], mqIndex) }}
            >
              <CustomLabelTextInput
                disabled={isLoading}
                label="Note"
                name="note"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    note: e.currentTarget.value
                  })
                }
                type="text-area"
                value={formState.note}
              />
            </AppStack>
          </AppFlexbox>
        )}

        {withEmailSettings && (
          <AppFlexbox
            style={{
              gap: 0,
              alignItems: 'start',
              flexDirection: rs(['column', 'row'], mqIndex)
            }}
          >
            <AppText
              style={{
                display: rs(['none', 'block'], mqIndex),
                paddingTop: rs([0, 10], mqIndex),
                flex: 1,
                marginBottom: rs([10, 0], mqIndex)
              }}
              weight={700}
            >
              Email Settings
            </AppText>
            <AppStack
              style={{
                flex: 4,
                width: rs(['100%', 'inital'], mqIndex),
                marginTop: 12
              }}
            >
              <Checkbox
                checked={formState?.receiveAllEmails || false}
                disabled={isLoading}
                label="Include in all emails"
                onChange={() => {
                  setFormState({
                    ...formState,
                    receiveAllEmails: !formState?.receiveAllEmails
                  });
                }}
              />
            </AppStack>
          </AppFlexbox>
        )}

        {regPersonContact && onRemove && (
          <AppFlexbox style={{ justifyContent: 'center' }}>
            <Button
              color="red"
              disabled={isLoading}
              loading={isDeleting}
              onClick={() => {
                if (confirmRemoval) {
                  setFormState({
                    ...formState,
                    confirmRemoval: true
                  });
                }
                else {
                  onRemove(regPersonContact);
                }
              }}
              radius="xs"
              size="md"
              style={{ flex: 1, maxWidth: rs(['unset', 250], mqIndex) }}
              variant="outline"
            >
              Remove contact
            </Button>
          </AppFlexbox>
        )}
      </AppStack>

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, remove contact"
        isLoading={formState.loading}
        isOpen={formState.confirmRemoval}
        message={
          <AppStack style={{ gap: 10 }}>
            <AppText style={{ fontSize: 16 }}>
              Are you sure you want to remove{' '}
              <b>
                {regPersonContact?.firstName} {regPersonContact?.lastName}
              </b>{' '}
              as a contact?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action cannot be undone.
            </AppText>
          </AppStack>
        }
        onCancel={() =>
          setFormState({
            ...formState,
            confirmRemoval: false
          })
        }
        onConfirm={onRemove}
        title="Remove contact?"
        topPadding={0}
      />
    </ResponsiveModal>
  );
};

SecondaryContactModal.propTypes = {
  confirmRemoval: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onRemove: PropTypes.func,
  regPersonContact: PropTypes.object,
  withEmailSettings: PropTypes.bool,
  withNote: PropTypes.bool
};

export default SecondaryContactModal;
