import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Button, Menu, Tabs } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import AppFlexbox from './AppFlexbox';
import AppStack from './AppStack';
import AppText from './AppText';
import SupportInfoMenu from './SupportInfoMenu';
import { useMediaQueryIndex } from '../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../helpers/styles';
import MenuContent from './MenuContent';

const AdminView = ({
  children,
  headerTitle,
  headerLink,
  breadcrumbs,
  isViewLoading,
  tabs,
  onAction,
  actionControl,
  isActionControlDisabled,
  helpLinks,
  indexHelpLink
}) => {
  const mqIndex = useMediaQueryIndex();
  const { pathname } = useLocation();
  const selectedTab = tabs?.find((tab) => pathname.startsWith(tab.to));
  const actionButtoncontrol = actionControl || selectedTab?.control;

  return (
    <AppStack style={{ flex: 1, gap: 0, height: '100%' }}>
      <AppFlexbox
        style={{
          minHeight: 70,
          gap: 10,
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: rs(['column', 'column', 'row'], mqIndex),
          padding: rs([10, 10, '0px 10px'], mqIndex)
        }}
      >
        <AppFlexbox
          style={{
            minHeight: 32,
            flex: 1,
            alignSelf: 'stretch',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 10
          }}
        >
          <AppFlexbox
            style={{ alignItems: 'center', gap: 10, fontWeight: 700 }}
          >
            <AppText
              component={headerLink ? Link : null}
              style={{ fontSize: 16, flexWrap: 'nowrap', overflow: 'visible' }}
              to={headerLink}
            >
              {headerTitle}
            </AppText>
            {selectedTab && (
              <>
                <AppText style={{ fontSize: 16, overflow: 'visible' }}>
                  /
                </AppText>
                <AppText
                  component={Link}
                  style={{ fontSize: 16 }}
                  to={selectedTab.to}
                >
                  {selectedTab.title}
                </AppText>
              </>
            )}
            {breadcrumbs &&
              breadcrumbs.map((b) => (
                <React.Fragment key={b.to}>
                  <AppText style={{ fontSize: 16, overflow: 'visible' }}>
                    /
                  </AppText>
                  <AppText component={Link} style={{ fontSize: 16 }} to={b.to}>
                    {b.title}
                  </AppText>
                </React.Fragment>
              ))}
          </AppFlexbox>
          {(helpLinks || selectedTab?.helpLinks) && (
            <AppStack>
              <SupportInfoMenu
                indexLink={indexHelpLink || selectedTab?.indexHelpLink}
                links={helpLinks || selectedTab.helpLinks}
              />
            </AppStack>
          )}
        </AppFlexbox>
        <AppFlexbox
          style={{
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: tabs ? 'space-between' : 'flex-end'
          }}
        >
          {tabs && (
            <AppFlexbox hiddenFrom="sm">
              <MenuContent
                control={
                  <Button
                    rightSection={<ChevronDown />}
                    style={{
                      paddingLeft: 2,
                      paddingRight: 2
                    }}
                    variant="subtle"
                  >
                    {selectedTab?.title}
                  </Button>
                }
              >
                {tabs.map((tab) => (
                  <Menu.Item key={tab.value} component={Link} to={tab.to}>
                    {tab.title}
                  </Menu.Item>
                ))}
              </MenuContent>
            </AppFlexbox>
          )}

          {actionButtoncontrol && (
            <Button
              component={actionButtoncontrol.to ? Link : null}
              disabled={isViewLoading || isActionControlDisabled}
              leftSection={actionButtoncontrol.icon}
              onClick={() => {
                if (onAction) {
                  onAction(actionButtoncontrol.action);
                }
              }}
              style={{ flex: tabs ? 'unset' : 1 }}
              to={actionButtoncontrol.to}
            >
              {actionButtoncontrol.label}
            </Button>
          )}
        </AppFlexbox>
      </AppFlexbox>
      <AppStack
        style={{
          flex: 1,
          gap: 0,
          height: 'calc(100vh - 150px)',
          overflow: 'auto'
        }}
      >
        {tabs && (
          <AppFlexbox visibleFrom="sm">
            <Tabs
              defaultValue={tabs[0].value}
              style={{ flex: 1 }}
              value={selectedTab?.value}
              variant="outline"
            >
              <Tabs.List>
                {tabs.map((tab) => {
                  const isSelected = selectedTab?.value === tab.value;
                  return (
                    <Tabs.Tab
                      key={tab.value}
                      component={Link}
                      onClick={(e) => {
                        if (isSelected) {
                          e.preventDefault();
                        }
                      }}
                      style={{ ...(isSelected ? { background: '#FFF' } : {}) }}
                      to={tab.to}
                      value={tab.value}
                    >
                      {tab.title}
                    </Tabs.Tab>
                  );
                })}
              </Tabs.List>
            </Tabs>
          </AppFlexbox>
        )}
        {children}
      </AppStack>
    </AppStack>
  );
};

AdminView.propTypes = {
  actionControl: PropTypes.object,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
  headerLink: PropTypes.string,
  headerTitle: PropTypes.string,
  helpLinks: PropTypes.array,
  indexHelpLink: PropTypes.object,
  isActionControlDisabled: PropTypes.bool,
  isViewLoading: PropTypes.bool,
  onAction: PropTypes.func,
  tabs: PropTypes.array
};

export default AdminView;
