import React, { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { Context as AuthContext } from '../providers/AuthProvider';
import CouponsView from './CouponsView';
import FinancialsView from './FinancialsView';
import FormsView from './FormsView';
import EvaluationsView from './EvaluationsView';
import ReportsView from './ReportsView';
import AppStack from '../components/common/AppStack';
import AppFlexbox from '../components/common/AppFlexbox';
import AdminSidebar from '../components/content/navigation/AdminSidebar';
import { useDashboardCookies, useMediaQueryIndex } from '../helpers/hooks';
import { getResponsiveStyle as rs } from '../helpers/styles';
import { useRegAssociationAdminAccess } from '../helpers/adminHelper';
import RegAssociationAnalyticsView from '../components/content/analytics/RegAssociationAnalyticsView';
import RegistrationAdminSideBar from '../experimental/components/content/navigation/RegistrationAdminSideBar';
import FinancialViews from '../experimental/views/FinancialViews';
import TaskListTemplateViews from '../experimental/components/content/taskList/TaskListTemplateViews';
import LeagueView from './LeagueView';
import RegistrationViews from '../experimental/components/content/registrations/RegistrationViews';
import SettingsViews from '../components/content/settings/SettingsViews';
import BulkEditRegistrationsView from '../experimental/components/content/registrations/BulkEditRegistrationsView';

const hiddenNavRoutes = [
  /\/admin\/forms\/(\d*)\/(.*)/, // form edit
  /\/admin\/reports\/(\d*)\/(.*)/, // report view
  /\/admin\/reports\/staff-report/, // report view
  /\/admin\/evaluations\/templates\/(\d*)/, // report view
  /\/admin\/evaluations\/sessions\/(\d*)/, // report view
  /\/admin\/league\/teams\/builder/ // team builder
];

const RegistrationAdminViews = () => {
  const mqIndex = useMediaQueryIndex();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: authState } = useContext(AuthContext);
  const { hasAccess } = useRegAssociationAdminAccess();
  const showAdminSidebar = !hiddenNavRoutes.some((r) => pathname.match(r));
  const { isClassicView } = useDashboardCookies();

  useEffect(() => {
    if (!authState.userData.isRegistrationAdmin || !hasAccess) {
      navigate('/');
    }
  }, [hasAccess]);

  return isClassicView ? (
    <AppFlexbox
      style={{
        width: '100%',
        flex: 1,
        gap: 0,
        backgroundColor: showAdminSidebar ? '#f7f7f7' : 'unset',
        maxHeight: showAdminSidebar ? 'calc(100vh - 90px)' : 'unset'
      }}
    >
      {showAdminSidebar && <AdminSidebar />}
      <AppStack
        style={{
          flex: 1,
          maxWidth: showAdminSidebar
            ? rs(['100%', '100%', 'calc(100vw - 250px)'], mqIndex)
            : '100%'
        }}
      >
        <Routes>
          <Route element={<CouponsView />} path="coupons/*" />
          <Route
            element={<BulkEditRegistrationsView />}
            path="financials/registrations/bulk-edit/*"
          />
          <Route
            element={<RegistrationViews />}
            path="financials/registrations/:pkRegFormSubmission/*"
          />
          <Route element={<FinancialsView />} path="financials/*" />
          <Route element={<FormsView />} path="forms/*" />
          <Route element={<TaskListTemplateViews />} path="task-templates/*" />
          <Route element={<LeagueView />} path="league/*" />
          <Route element={<EvaluationsView />} path="evaluations/*" />
          <Route element={<RegAssociationAnalyticsView />} path="analytics/*" />
          <Route element={<ReportsView />} path="reports/*" />
          <Route element={<SettingsViews />} path="settings/*" />
          <Route element={<Navigate replace to="/admin/league" />} path="*" />
        </Routes>
      </AppStack>
    </AppFlexbox>
  ) : (
    <AppFlexbox
      style={{
        width: '100%',
        flex: 1,
        gap: 0,
        backgroundColor: showAdminSidebar ? '#f7f7f7' : 'unset'
      }}
    >
      {showAdminSidebar && <RegistrationAdminSideBar />}
      <AppStack
        style={{
          flex: 1,
          maxWidth: showAdminSidebar
            ? rs(['100%', '100%', 'calc(100vw - 250px)'], mqIndex)
            : '100%'
        }}
      >
        <Routes>
          <Route element={<CouponsView />} path="coupons/*" />
          <Route element={<FinancialViews />} path="financials/*" />
          <Route element={<FormsView />} path="forms/*" />
          <Route element={<TaskListTemplateViews />} path="task-templates/*" />
          <Route element={<LeagueView />} path="league/*" />
          <Route element={<EvaluationsView />} path="evaluations/*" />
          <Route element={<RegAssociationAnalyticsView />} path="analytics/*" />
          <Route element={<ReportsView />} path="reports/*" />
          <Route element={<SettingsViews />} path="settings/*" />
          <Route element={<Navigate replace to="/admin/league" />} path="*" />
        </Routes>
      </AppStack>
    </AppFlexbox>
  );
};

export default RegistrationAdminViews;
