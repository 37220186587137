import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowLeft,
  Columns,
  DeviceFloppy,
  Plus,
  Template,
  Trash
} from 'tabler-icons-react';
import {
  Accordion,
  Button,
  Divider,
  Skeleton,
  TextInput,
  Tooltip
} from '@mantine/core';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import EvaluationTemplateQuestion from './EvaluationTemplateQuestion';
import { REG_SCOUT_REPORT_QUESTION_TYPE } from '../../../config/constants';
import { usePrompt } from '../../../helpers/prompt';
import EvaluationTemplateQuestionOptionsModal from './EvaluationTemplateQuestionOptionsModal';
import EvaluationTemplateSnippetModal from './EvaluationTemplateSnippetModal';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import TabsContent from '../../common/TabsContent';
import EvaluationTemplateViewDragDropList from './EvaluationTemplateViewDragDropList';
import EvaluationTemplateQuestionRestrictionModal from './EvaluationTemplateQuestionRestrictionModal';
import CreateEvaluationTemplateQuestionModal from './CreateEvaluationTemplateQuestionModal';
import { useEvaluationTemplateState } from '../../../helpers/evaluationHelper';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ConfirmModal from '../../common/ConfirmModal';

const QUESTION_VIEW_TABS_ENUM = {
  questions: 'questions',
  attributes: 'attributes',
  permissions: 'permissions'
};

const VIEW_LAYOUT_TABS_ENUM = {
  builder: 'builder',
  linear: 'linear'
};

const QUESTION_VIEW_TABS = [
  {
    label: 'Questions',
    viewTitle: 'Question',
    value: QUESTION_VIEW_TABS_ENUM.questions,
    fkRegScoutReportQuestionType: REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
  },
  {
    label: 'Attributes',
    viewTitle: 'Attribute',
    value: QUESTION_VIEW_TABS_ENUM.attributes,
    fkRegScoutReportQuestionType: REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
  }
];

const VIEW_LAYOUT_TABS = [
  {
    tooltip: 'Builder View',
    value: VIEW_LAYOUT_TABS_ENUM.builder,
    icon: <Template />
  },
  {
    tooltip: 'Linear View',
    value: VIEW_LAYOUT_TABS_ENUM.linear,
    icon: <Columns />
  }
];

const EvaluationTemplateView = () => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const isTabletView = mqIndex <= 1;
  const isMediumView = mqIndex <= 2;
  const navigate = useNavigate();
  const { pkRegScoutReport } = useParams();
  const [viewState, setViewState] = useState({
    activeTab: QUESTION_VIEW_TABS[0].value,
    activeLayout: VIEW_LAYOUT_TABS[0].value,
    triggerFormSubmit: false,
    triggerItem: null
  });
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    isLoading,
    isSaving,
    hasUnsavedChanges,
    templateName,
    regScoutReportQuestions,
    attributeQuestions,
    evaluationQuestions,
    selectedQuestionKey,
    regScoutReportEvaluationSessions,
    onUpdateTemplateSettings,
    onAddReportQuestion,
    onRemoveReportQuestion,
    onCopyQuestion,
    onChangeReportQuestion,
    onSelectReportQuestion,
    onUpdateListOrder,
    onRemoveRestriction,
    onDiscardChanges,
    onSaveChanges
  } = useEvaluationTemplateState(pkRegScoutReport);

  const activeViewTab = QUESTION_VIEW_TABS.find(
    (t) => t.value === viewState.activeTab
  );

  const selectedQuestions =
    activeViewTab.fkRegScoutReportQuestionType ===
    REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
      ? attributeQuestions
      : evaluationQuestions;

  usePrompt(
    'Are you sure you want to leave without saving?',
    hasUnsavedChanges
  );

  useEffect(() => {
    if (viewState.triggerFormSubmit && viewState.triggerItem) {
      document
        .getElementById(`question-card-${viewState.triggerItem.key}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      document.getElementById(`evaluation-template-form-submit`)?.click();
      setViewState({
        ...viewState,
        triggerFormSubmit: false,
        triggerItem: null
      });
    }
  }, [viewState.triggerFormSubmit]);

  useEffect(() => {
    if (modalState.isOpen) {
      switch (modalState.action) {
        case 'COPY-QUESTION':
          onCopyQuestion(modalState.item);
          onCloseModal();
          break;
        case 'DELETE-QUESTION':
          onRemoveReportQuestion(modalState.item);
          onCloseModal();
          break;
        default:
          break;
      }
    }
  }, [modalState.isOpen]);

  const onAddBuilderViewQuestion = () => {
    onAddReportQuestion(activeViewTab.fkRegScoutReportQuestionType);
  };

  const onSaveTemplate = (overrideExistingSessions = false) => {
    if (
      regScoutReportEvaluationSessions.length > 0 &&
      !overrideExistingSessions
    ) {
      onOpenModal('CONFIRM-OVERRIDE', null, null);
    }
    else {
      onSaveChanges(
        () => {
          triggerNotification('Changes Saved!', 'Success', 'green');
        },
        (error, item) => {
          triggerNotification(error);

          const tabView = QUESTION_VIEW_TABS.find(
            (t) =>
              t.fkRegScoutReportQuestionType ===
              item?.fkRegScoutReportQuestionType
          );
          setViewState((c) => ({
            ...c,
            activeTab: tabView?.value || c.activeTab,
            activeLayout: VIEW_LAYOUT_TABS_ENUM.builder,
            triggerFormSubmit: true,
            triggerItem: item
          }));
        }
      );
    }
  };

  return (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        flex: 1,
        gap: 5,
        maxHeight: 'calc(100vh - 100px)',
        overflow: 'hidden'
      }}
    >
      <AppCard
        style={{
          padding: 0,
          minHeight: 80,
          height: isTabletView ? 'unset' : 80
        }}
      >
        <AppFlexbox
          style={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: isMobileView ? 'stretch' : 'center',
            padding: isTabletView ? 10 : '10px 20px',
            flexDirection: isMobileView ? 'column' : 'row'
          }}
        >
          {isLoading ? (
            <>
              <AppFlexbox style={{ gap: 15, alignItems: 'center' }}>
                <Skeleton circle height={55} width={55} />
                <Skeleton height={26} width={200} />
              </AppFlexbox>

              <AppFlexbox style={{ gap: 15, alignItems: 'center' }}>
                <Skeleton height={34} width={150} />
              </AppFlexbox>
            </>
          ) : (
            <>
              <AppFlexbox
                style={{ gap: 15, alignItems: 'center', overflow: 'hidden' }}
              >
                <ActionableIcon
                  color="dark"
                  onClick={() => navigate('/admin/evaluations/templates')}
                  radius={100}
                  size={50}
                  variant="filled"
                >
                  <ArrowLeft size={35} />
                </ActionableIcon>
                <AppText
                  style={{
                    fontWeight: 700,
                    fontSize: 18,
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    lineClamp: '2',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {templateName}
                </AppText>
              </AppFlexbox>

              <AppFlexbox
                style={{
                  gap: 15,
                  alignItems: isMobileView ? 'stretch' : 'center'
                }}
              >
                {hasUnsavedChanges && (
                  <Button
                    color="red"
                    disabled={isSaving}
                    leftSection={<Trash />}
                    onClick={onDiscardChanges}
                    type="button"
                    visibleFrom="md"
                  >
                    Discard Changes
                  </Button>
                )}
                <Button
                  id="evaluation-template-form-submit"
                  style={{ display: 'none' }}
                  type="submit"
                />
                <Button
                  disabled={!hasUnsavedChanges || isLoading}
                  leftSection={<DeviceFloppy />}
                  loading={isSaving}
                  onClick={() => onSaveTemplate(false)}
                  style={{ flex: 1 }}
                >
                  Save Changes
                </Button>
              </AppFlexbox>
            </>
          )}
        </AppFlexbox>
      </AppCard>

      <AppFlexbox
        style={{
          flex: 1,
          gap: 10,
          overflow: 'hidden'
        }}
      >
        {!isTabletView &&
        viewState.activeLayout === VIEW_LAYOUT_TABS_ENUM.builder ? (
          <>
            <AppCard
              style={{
                flex: 1,
                minWidth: 300,
                maxWidth: 600,
                overflow: 'auto'
              }}
              visibleFrom="sm"
            >
              {isLoading ? (
                <AppStack style={{ gap: 20 }}>
                  <AppStack style={{ gap: 5 }}>
                    <Skeleton height={21} width={110} />
                    <Skeleton height={36} width="100%" />
                  </AppStack>

                  <Divider />

                  <TabsContent
                    activeTab={viewState.activeTab}
                    color="blue"
                    isLoading
                    onTabChange={(activeTab) =>
                      setViewState({
                        ...viewState,
                        activeTab
                      })
                    }
                    tabs={QUESTION_VIEW_TABS}
                    variant="outline"
                  />

                  <AppStack style={{ gap: 5 }}>
                    <AppFlexbox
                      style={{
                        padding: 10,
                        border: 'solid 1px lightgrey',
                        height: 55,
                        alignItems: 'center'
                      }}
                    >
                      <Skeleton height={18} width="50%" />
                    </AppFlexbox>
                    <AppFlexbox
                      style={{
                        padding: 10,
                        border: 'solid 1px lightgrey',
                        height: 55,
                        alignItems: 'center'
                      }}
                    >
                      <Skeleton height={18} width="50%" />
                    </AppFlexbox>
                    <AppFlexbox
                      style={{
                        padding: 10,
                        border: 'solid 1px lightgrey',
                        height: 55,
                        alignItems: 'center'
                      }}
                    >
                      <Skeleton height={18} width="40%" />
                    </AppFlexbox>
                  </AppStack>
                </AppStack>
              ) : (
                <AppStack style={{ gap: 20 }}>
                  <AppStack>
                    <TextInput
                      label="Template Name"
                      onChange={(e) =>
                        onUpdateTemplateSettings({name: e.currentTarget.value.substring(0, 255)})
                      }
                      placeholder="Tryout Evaluation Template"
                      required
                      value={templateName}
                    />
                  </AppStack>

                  <Divider />

                  <TabsContent
                    activeTab={viewState.activeTab}
                    color="blue"
                    onTabChange={(activeTab) =>
                      setViewState({
                        ...viewState,
                        activeTab
                      })
                    }
                    styles={{ list: { flexWrap: 'nowrap' } }}
                    tabActionButton={
                      <>
                        <Button
                          hiddenFrom="lg"
                          leftSection={<Plus />}
                          onClick={onAddBuilderViewQuestion}
                          size="compact-sm"
                          style={{ marginLeft: 'auto', alignSelf: 'center' }}
                        >
                          Add
                        </Button>
                        <Button
                          leftSection={<Plus />}
                          onClick={onAddBuilderViewQuestion}
                          size="compact-sm"
                          style={{ marginLeft: 'auto', alignSelf: 'center' }}
                          visibleFrom="lg"
                        >
                          Add {activeViewTab.viewTitle}
                        </Button>
                      </>
                    }
                    tabs={QUESTION_VIEW_TABS}
                    variant="outline"
                  />

                  <EvaluationTemplateViewDragDropList
                    items={selectedQuestions.map((q) => ({
                      ...q,
                      key: q.key,
                      isSelected: selectedQuestionKey === q.key,
                      value: q.value
                    }))}
                    itemTypeLabel={activeViewTab.viewTitle}
                    onAdd={onAddBuilderViewQuestion}
                    onCopy={onCopyQuestion}
                    onRemove={onRemoveReportQuestion}
                    onSelect={onSelectReportQuestion}
                    onUpdateListOrder={(newList) =>
                      onUpdateListOrder(
                        newList,
                        activeViewTab.fkRegScoutReportQuestionType
                      )
                    }
                  />
                </AppStack>
              )}
            </AppCard>
            <AppStack
              style={{
                flex: 3,
                gap: 5,
                padding: 10,
                paddingTop: 0,
                paddingLeft: 0,
                overflow: 'auto'
              }}
            >
              <AppFlexbox
                style={{
                  justifyContent: 'space-between',
                  gap: isMediumView ? 5 : 20
                }}
              >
                {isLoading ? (
                  <>
                    <AppFlexbox style={{ gap: isMediumView ? 5 : 20 }}>
                      <Button.Group>
                        {QUESTION_VIEW_TABS.map((t) => (
                          <Skeleton key={t.value} height={34} width={100} />
                        ))}
                      </Button.Group>
                      <Divider color="darkgrey" orientation="vertical" />
                      <Skeleton height={34} width={150} />
                    </AppFlexbox>

                    <Button.Group>
                      {VIEW_LAYOUT_TABS.map((t) => (
                        <Skeleton key={t.value} height={34} width={60} />
                      ))}
                    </Button.Group>
                  </>
                ) : (
                  <>
                    <AppFlexbox style={{ gap: isMediumView ? 5 : 20 }}>
                      <Button.Group>
                        {QUESTION_VIEW_TABS.map((t) => {
                          const isSelected = viewState.activeTab === t.value;
                          return (
                            <Button
                              key={t.value}
                              color="dark"
                              onClick={() =>
                                setViewState({
                                  ...viewState,
                                  activeTab: t.value
                                })
                              }
                              variant={isSelected ? 'filled' : 'light'}
                            >
                              {t.label}
                            </Button>
                          );
                        })}
                      </Button.Group>
                      <Divider color="darkgrey" orientation="vertical" />
                      <Button
                        leftSection={<Plus />}
                        onClick={onAddBuilderViewQuestion}
                      >
                        Add {activeViewTab.viewTitle}
                      </Button>
                    </AppFlexbox>

                    <Button.Group>
                      {VIEW_LAYOUT_TABS.map((t) => {
                        const isSelected = viewState.activeLayout === t.value;
                        return (
                          <Tooltip key={t.value} label={t.tooltip}>
                            <Button
                              color="dark"
                              onClick={() =>
                                setViewState({
                                  ...viewState,
                                  activeLayout: t.value
                                })
                              }
                              variant={isSelected ? 'filled' : 'light'}
                            >
                              {t.icon}
                            </Button>
                          </Tooltip>
                        );
                      })}
                    </Button.Group>
                  </>
                )}
              </AppFlexbox>

              {isLoading ? (
                <AppStack style={{ gap: 10 }}>
                  <EvaluationTemplateQuestion
                    isEditMode
                    isLoading
                    isSmallScreen={isMobileView}
                  />
                  <EvaluationTemplateQuestion
                    isEditMode
                    isLoading
                    isSmallScreen={isMobileView}
                  />
                  <EvaluationTemplateQuestion
                    isEditMode
                    isLoading
                    isSmallScreen={isMobileView}
                  />

                  <AppFlexbox
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      marginTop: 10
                    }}
                  >
                    <Skeleton height={34} width={150} />
                  </AppFlexbox>
                </AppStack>
              ) : selectedQuestions.length === 0 ? (
                <AppCard>
                  <AppStack
                    style={{
                      border: 'solid 1px lightgrey',
                      padding: '30px 10px',
                      justifyContent: 'center',
                      placeItems: 'center',
                      gap: 5
                    }}
                  >
                    <AppText weight={500}>
                      Setup your first {activeViewTab.viewTitle.toLowerCase()}!
                    </AppText>
                    <Button
                      leftSection={<Plus />}
                      onClick={onAddBuilderViewQuestion}
                    >
                      Add {activeViewTab.viewTitle}
                    </Button>
                  </AppStack>
                </AppCard>
              ) : (
                <AppStack style={{ gap: 10 }}>
                  {selectedQuestions
                    .sort((a, b) => a.sort - b.sort)
                    .map((q) => {
                      const isSelected = selectedQuestionKey === q.key;

                      return (
                        <EvaluationTemplateQuestion
                          key={q.key}
                          id={`question-card-${q.key}`}
                          isEditMode
                          isSelected={isSelected}
                          isSmallScreen={isMobileView}
                          onChange={onChangeReportQuestion}
                          onCopy={onCopyQuestion}
                          onModalAction={(action, item) =>
                            onOpenModal(action, q, item)
                          }
                          onRemove={onRemoveReportQuestion}
                          onRemoveRestriction={onRemoveRestriction}
                          question={q}
                        />
                      );
                    })}
                  <AppFlexbox
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      marginTop: 10
                    }}
                  >
                    <Button
                      leftSection={<Plus />}
                      onClick={onAddBuilderViewQuestion}
                      style={{ maxWidth: 350 }}
                    >
                      Add {activeViewTab.viewTitle}
                    </Button>
                  </AppFlexbox>
                </AppStack>
              )}
            </AppStack>
          </>
        ) : (
          <AppFlexbox
            style={{
              flex: 1,
              justifyContent: 'center',
              padding: 5,
              paddingBottom: 40,
              overflow: 'auto'
            }}
          >
            <AppStack
              style={{
                gap: 10,
                width: '100%',
                maxWidth: 1100
              }}
            >
              {isLoading ? (
                <>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'end'
                    }}
                  >
                    <AppStack style={{ gap: 5, flex: 1 }}>
                      <Skeleton height={14} width={150} />
                      <Skeleton
                        height={36}
                        width={isTabletView ? '100%' : 400}
                      />
                    </AppStack>

                    {!isTabletView && <Skeleton height={36} width={125} />}
                  </AppFlexbox>

                  <Skeleton height={48} width="100%" />
                  <Skeleton height={48} width="100%" />
                </>
              ) : (
                <>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'end'
                    }}
                  >
                    <TextInput
                      label="Template Name"
                      onChange={(e) =>
                        onUpdateTemplateSettings({name: e.currentTarget.value.substring(0, 255)})
                      }
                      placeholder="Tryout Evaluation Template"
                      required
                      style={{
                        flex: 1,
                        maxWidth: isTabletView ? 'unset' : 400
                      }}
                      value={templateName}
                    />

                    {!isTabletView && (
                      <Button.Group>
                        {VIEW_LAYOUT_TABS.map((t) => {
                          const isSelected = viewState.activeLayout === t.value;
                          return (
                            <Tooltip key={t.value} label={t.tooltip}>
                              <Button
                                color="dark"
                                onClick={() =>
                                  setViewState({
                                    ...viewState,
                                    activeLayout: t.value
                                  })
                                }
                                variant={isSelected ? 'filled' : 'light'}
                              >
                                {t.icon}
                              </Button>
                            </Tooltip>
                          );
                        })}
                      </Button.Group>
                    )}
                  </AppFlexbox>

                  <Accordion
                    chevronPosition="left"
                    multiple
                    style={{ border: 'solid 1px lightgrey' }}
                    styles={{
                      control: { padding: 10 },
                      label: { padding: 0 },
                      content: {
                        padding: 0,
                        borderTop: 'solid 1px lightgrey'
                      }
                    }}
                    variant="separated"
                  >
                    {QUESTION_VIEW_TABS.map((t) => {
                      const questions = regScoutReportQuestions.filter(
                        (q) =>
                          q.fkRegScoutReportQuestionType ===
                          t.fkRegScoutReportQuestionType
                      );
                      return (
                        <Accordion.Item
                          key={t.value}
                          style={{ backgroundColor: '#FFF' }}
                          value={t.value}
                        >
                          <Accordion.Control>
                            <AppFlexbox>
                              <AppText style={{ fontSize: 18 }} weight={500}>
                                {t.label} ({questions.length})
                              </AppText>
                            </AppFlexbox>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <AppStack style={{ gap: 0 }}>
                              <AppFlexbox
                                style={{
                                  justifyContent: 'space-between',
                                  padding: 10
                                }}
                              >
                                <AppText style={{ fontSize: 18 }} weight={500}>
                                  {t.label}
                                </AppText>
                                <Button
                                  leftSection={<Plus />}
                                  onClick={() =>
                                    onOpenModal(
                                      'QUESTION-EDIT',
                                      null,
                                      t.fkRegScoutReportQuestionType
                                    )
                                  }
                                  size="compact-sm"
                                >
                                  Add {t.viewTitle}
                                </Button>
                              </AppFlexbox>
                              <EvaluationTemplateViewDragDropList
                                items={questions.map((q) => ({
                                  ...q,
                                  key: q.key,
                                  isSelected: true,
                                  value: q.value,
                                  onAction: (action) => {
                                    onOpenModal(
                                      action,
                                      q,
                                      q.fkRegScoutReportQuestionType
                                    );
                                  },
                                  actions: [
                                    {
                                      label: 'Edit',
                                      value: 'QUESTION-EDIT'
                                    },
                                    {
                                      label: 'Copy',
                                      value: 'COPY-QUESTION'
                                    },
                                    {
                                      label: 'Delete',
                                      value: 'DELETE-QUESTION'
                                    }
                                  ]
                                }))}
                                itemTypeLabel={t.viewTitle}
                                listGap={0}
                                noBorder
                                onAdd={() =>
                                  onOpenModal(
                                    'QUESTION-EDIT',
                                    null,
                                    t.fkRegScoutReportQuestionType
                                  )
                                }
                                onSelect={onSelectReportQuestion}
                                onUpdateListOrder={(newList) =>
                                  onUpdateListOrder(
                                    newList,
                                    t.fkRegScoutReportQuestionType
                                  )
                                }
                              />
                            </AppStack>
                          </Accordion.Panel>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </>
              )}
            </AppStack>
          </AppFlexbox>
        )}
      </AppFlexbox>

      <CreateEvaluationTemplateQuestionModal
        fkRegScoutReportQuestionType={
          modalState.action === 'QUESTION-EDIT' ? modalState.secondItem : null
        }
        isOpen={modalState.isOpen && modalState.action === 'QUESTION-EDIT'}
        isSmallScreen={isMobileView}
        onClose={onCloseModal}
        onConfirm={(question) => {
          if (modalState.item) {
            onChangeReportQuestion({ ...modalState.item, ...question });
          }
          else {
            onCopyQuestion({ ...question });
          }
          onCloseModal();
        }}
        regScoutReportQuestion={modalState.item}
        regScoutReportQuestions={regScoutReportQuestions}
      />

      <EvaluationTemplateQuestionOptionsModal
        fkRegFormControlType={modalState.item?.fkRegFormControlType}
        fkRegScoutReportQuestionType={
          modalState.item?.fkRegScoutReportQuestionType
        }
        isOpen={modalState.isOpen && modalState.action === 'QUESTION-OPTIONS'}
        onClose={onCloseModal}
        onConfirm={(options) => {
          onChangeReportQuestion({
            ...modalState.item,
            regScoutReportQuestionOptions: options,
            regScoutReportQuestionRestrictions: modalState.item.regScoutReportQuestionRestrictions.filter(
              (r) =>
                r.questionConditionRefNumber !== modalState.item.key ||
                options.some((p) => p.value === r.conditionValue)
            )
          });
          onCloseModal();
        }}
        options={modalState.item?.regScoutReportQuestionOptions || []}
      />

      <EvaluationTemplateSnippetModal
        isOpen={modalState.isOpen && modalState.action === 'QUESTION-SNIPPETS'}
        onClose={onCloseModal}
        onConfirm={(snippets) => {
          onChangeReportQuestion({
            ...modalState.item,
            regScoutReportQuestionSnippets: snippets
          });
          onCloseModal();
        }}
        regScoutReportQuestions={regScoutReportQuestions}
        selectedSnippets={modalState.item?.regScoutReportQuestionSnippets || []}
      />

      <EvaluationTemplateQuestionRestrictionModal
        isOpen={
          modalState.isOpen && modalState.action === 'QUESTION-RESTRICTIONS'
        }
        onClose={onCloseModal}
        onConfirm={(restriction, restrictedQuestions) => {
          onChangeReportQuestion({
            ...modalState.item,
            regScoutReportQuestionRestrictions: [
              ...modalState.item.regScoutReportQuestionRestrictions.filter(
                (r) =>
                  !(
                    r.condition === restriction.condition &&
                    r.conditionValue === restriction.conditionValue
                  ) &&
                  !restrictedQuestions.some(
                    (s) =>
                      s.questionRestrictedRefNumber ===
                        r.questionRestrictedRefNumber &&
                      s.conditionValue === r.conditionValue
                  )
              ),
              ...restrictedQuestions
            ]
          });
          onCloseModal();
        }}
        regScoutReportQuestion={modalState.item}
        regScoutReportQuestionRestriction={
          modalState.action === 'QUESTION-RESTRICTIONS'
            ? modalState.secondItem
            : null
        }
        regScoutReportQuestions={evaluationQuestions}
      />

      <ConfirmModal
        confirmActionColor="red"
        isLoading={isSaving}
        isOpen={modalState.isOpen && modalState.action === 'CONFIRM-OVERRIDE'}
        message="You have existing sessions that will be overridden by this template. Are you sure you want to proceed?"
        onCancel={onCloseModal}
        onConfirm={() => {
          onSaveTemplate(true);
          onCloseModal();
        }}
        title="Override Existing Sessions"
      />
    </AppStack>
  );
};

EvaluationTemplateView.propTypes = {};

export default EvaluationTemplateView;
