import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Button, Divider } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import SecondaryContactModal from '../../../../components/content/participants/SecondaryContactModal';
import ResponsiveModal from '../../../../components/common/ResponsiveModal';
import { triggerNotification } from '../../../../helpers/notificationHelper';
import AppStack from '../../../../components/common/AppStack';
import AppText from '../../../../components/common/AppText';
import AppCard from '../../../../components/common/AppCard';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import classes from '../../../../styles/nestedStyles.module.css';

const PlayerSecondaryContactsModal = ({
  isOpen,
  onClose,
  createNew,
  regFormSubmission
}) => {
  const { updateRegFormSubmission } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    showCreate: false,
    selectedContact: null,
    isDeleting: false,
    isLoading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        showCreate: false,
        selectedContact: null,
        isDeleting: false,
        isLoading: false
      });
    }
  }, [isOpen]);

  const onUpdateContact = (updatedContact, deleteSelected = false) => {
    setFormState({ ...formState, isLoading: true, isDeleting: deleteSelected });

    updateRegFormSubmission(
      regFormSubmission.regAssociation.pkRegAssociation,
      {
        pkRegFormSubmission: regFormSubmission.pkRegFormSubmission,
        regFormSubmissionPersonContacts: [
          {
            ...formState.selectedContact,
            ...updatedContact,
            deleted: deleteSelected
          }
        ]
      },
      () => {
        triggerNotification(
          deleteSelected
            ? 'Contact deleted'
            : formState.selectedContact
            ? 'Contact updated'
            : 'Contact added',
          'Success',
          'green'
        );

        if (createNew) {
          onClose();
        }
        setFormState({
          ...formState,
          showCreate: false,
          selectedContact: null,
          isLoading: false,
          isDeleting: false
        });
      },
      (error) => {
        triggerNotification(error);
        setFormState({
          ...formState,
          isLoading: false,
          isDeleting: false
        });
      }
    );
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={800}
      title="Manage Secondary Contacts"
    >
      {regFormSubmission && (
        <AppStack style={{ gap: 16 }}>
          <AppText
            style={{
              fontSize: 14,
              color: '#666',
              fontStyle: 'italic',
              textAlign: 'center'
            }}
          >
            Select a contact to edit or delete, or click the "Add Contact"
            button to create a new contact.
          </AppText>

          <AppCard radius={8} shadow="none" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              {regFormSubmission.regFormSubmissionPersonContacts.length ===
              0 ? (
                <AppText
                  style={{ fontSize: 14, color: '#666', textAlign: 'center' }}
                >
                  No secondary contacts available.
                </AppText>
              ) : (
                regFormSubmission.regFormSubmissionPersonContacts
                  .sort(
                    (a, b) =>
                      b.receiveAllEmails - a.receiveAllEmails ||
                      a.firstName.localeCompare(b.firstName) ||
                      a.lastName.localeCompare(b.lastName)
                  )
                  .map((c, index) => (
                    <React.Fragment key={c.pkRegFormSubmissionPersonContact}>
                      {index > 0 && <Divider />}

                      <AppFlexbox
                        className={classes['hover-action-card']}
                        onClick={() =>
                          setFormState({
                            ...formState,
                            selectedContact: c
                          })
                        }
                        style={{
                          gap: 0,
                          padding: '8px 16px',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          fontWeight: 'normal'
                        }}
                      >
                        <AppFlexbox
                          onClick={() => {}}
                          style={{ gap: 16, alignItems: 'center' }}
                        >
                          <AppStack
                            style={{
                              gap: 0,
                              overflow: 'hidden',
                              maxWidth: '50vw'
                            }}
                          >
                            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                              {c.firstName} {c.lastName}
                            </AppText>
                            <AppText style={{ fontSize: 14, color: '#666' }}>
                              {c.email}
                            </AppText>
                            {c.phone && (
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                <NumberFormat
                                  displayType="text"
                                  format="+1 (###) ###-####"
                                  mask="_"
                                  prefix=", "
                                  value={c.phone}
                                />
                              </AppText>
                            )}
                            {c.note && (
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                {c.note}
                              </AppText>
                            )}

                            {c.receiveAllEmails && (
                              <AppText
                                style={{
                                  fontSize: 14,
                                  color: '#666',
                                  fontStyle: 'italic'
                                }}
                              >
                                Receives all emails
                              </AppText>
                            )}
                          </AppStack>
                        </AppFlexbox>
                      </AppFlexbox>
                    </React.Fragment>
                  ))
              )}
            </AppStack>
          </AppCard>

          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 16
            }}
          >
            <Button
              color="blue"
              leftSection={<Plus />}
              onClick={() => {
                setFormState({
                  ...formState,
                  showCreate: true,
                  selectedContact: null
                });
              }}
              variant="outline"
            >
              Add Contact
            </Button>
          </AppFlexbox>
        </AppStack>
      )}

      <SecondaryContactModal
        confirmRemoval
        isDeleting={formState.isDeleting}
        isLoading={formState.isLoading}
        isOpen={
          !!formState.selectedContact || formState.showCreate || createNew
        }
        onClose={() => {
          if (createNew) {
            onClose();
          }
          else {
            setFormState({
              ...formState,
              showCreate: false,
              selectedContact: null
            });
          }
        }}
        onConfirm={(contact) => onUpdateContact(contact)}
        onRemove={() => onUpdateContact({}, true)}
        regPersonContact={formState.selectedContact}
        withEmailSettings
        withNote
      />
    </ResponsiveModal>
  );
};

PlayerSecondaryContactsModal.propTypes = {
  createNew: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regFormSubmission: PropTypes.object
};

export default PlayerSecondaryContactsModal;
