import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Textarea, TextInput } from '@mantine/core';
import PageCard from './PageCard';
import HistoryTimeline from './HistoryTimeline';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import { useRegResource } from '../../helpers/hooks';
import { triggerNotification } from '../../helpers/notificationHelper';

const NoteTimelineDisplay = ({
  hideNoteCreate,
  customTimelineData,
  resourceId,
  fkRegResourceType
}) => {
  const { regResourceLogs, onUpdateRegResource } = useRegResource({
    resourceId,
    fkRegResourceType
  });
  const [noteState, setNoteState] = useState({ message: '', loading: false });

  const noteHistory =
    regResourceLogs?.map((note) => ({
      ...note,
      key: note.pkRegResourceLog,
      title: note.title,
      message: note.message,
      subTitle: '',
      fkRegResourceLogType: note.fkRegResourceLogType?.toString(),
      date: note.createdAt,
      createdBy: note.user?.name || null,
      content: <AppText style={{ fontSize: 14 }}>{note.message}</AppText>
    })) || [];

  const onNoteChange = (
    { pkRegResourceLog, title, message, deleted },
    onSuccessCallback,
    onErrorCallback
  ) => {
    onUpdateRegResource(
      { regResourceLogs: [{ pkRegResourceLog, title, message, deleted }] },
      (data) => {
        if (!pkRegResourceLog) {
          setNoteState({ ...noteState, message: '', title: '' });
        }

        triggerNotification(
          deleted
            ? 'Note deleted!'
            : pkRegResourceLog
            ? 'Note saved!'
            : 'Note created!',
          'Success',
          'green'
        );

        if (onSuccessCallback) {
          onSuccessCallback(data);
        }
      },
      (error) => {
        setNoteState({ ...noteState, loading: false });
        triggerNotification(error);

        if (onErrorCallback) {
          onSuccessCallback(error);
        }
      }
    );
  };

  return (
    <AppStack style={{ gap: 32 }}>
      {!hideNoteCreate && (
        <PageCard
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setNoteState({ ...noteState, loading: true });
            onNoteChange({
              title: noteState.title,
              message: noteState.message
            });
          }}
        >
          <AppStack style={{ gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>Notes</AppText>
              <AppFlexbox>
                <Button
                  color="blue"
                  disabled={!noteState.message || !noteState.title}
                  loading={noteState.loading}
                  size="compact-sm"
                  type="submit"
                  variant="filled"
                >
                  Save note
                </Button>
              </AppFlexbox>
            </AppFlexbox>
            <TextInput
              disabled={noteState.loading}
              onChange={(e) => {
                setNoteState({
                  ...noteState,
                  title: e.currentTarget.value
                });
              }}
              placeholder="Title"
              required
              value={noteState.title}
            />
            <Textarea
              autosize
              disabled={noteState.loading}
              minRows={3}
              onChange={(e) => {
                setNoteState({
                  ...noteState,
                  message: e.currentTarget.value
                });
              }}
              placeholder="Add a note..."
              required
              value={noteState.message}
            />
          </AppStack>
        </PageCard>
      )}

      <HistoryTimeline
        data={[...(customTimelineData || []), ...noteHistory].sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )}
        onDeleteNote={(note, onSuccessCallback, onErrorCallback) => {
          onNoteChange(
            {
              pkRegResourceLog: note.key,
              deleted: true
            },
            onSuccessCallback,
            onErrorCallback
          );
        }}
        onSaveNote={(note, onSuccessCallback, onErrorCallback) => {
          onNoteChange(
            {
              pkRegResourceLog: note.key,
              title: note.title,
              message: note.message
            },
            onSuccessCallback,
            onErrorCallback
          );
        }}
      />
    </AppStack>
  );
};

NoteTimelineDisplay.propTypes = {
  customTimelineData: PropTypes.array,
  fkRegResourceType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideNoteCreate: PropTypes.bool,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default NoteTimelineDisplay;
