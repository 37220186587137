import React, { useContext, useEffect, useRef, useState } from 'react';
import { File } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useModalState, usePaginationFilter } from '../../../../helpers/hooks';
import TableView from '../../../../components/common/TableView';
import ColoredAvatar from '../../../../components/common/ColoredAvatar';
import { formatUtcDate } from '../../../../helpers/format';
import { triggerNotification } from '../../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../../providers/RegistrationAdminDashboardProvider';
import { REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM } from '../../../../config/constants';
import AdminView from '../../../../components/common/AdminView';
import { FINANCIAL_VIEW_TABS } from '../navigation/links';
import AppCard from '../../../../components/common/AppCard';
import { getLeagueSelectOptions } from '../../../../helpers/leagueHooks';

const VIEW_ACTIONS = [
  {
    label: 'View',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.view,
    isVisable: () => true
  },
  {
    label: 'Order Summary',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.orderSummary,
    isVisable: () => true
  },
  {
    label: 'Email Contact',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.emailUser,
    isVisable: () => true
  },
  {
    label: 'Change Email',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeOverrideEmail,
    isVisable: () => true
  },
  {
    label: 'Change Division',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeDivision,
    isVisable: (s) => s.status !== 'REFUNDED'
  },
  {
    label: 'Invoice User',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.invoice,
    isVisable: (s) => s.status !== 'REFUNDED'
  },
  {
    label: 'Refund',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.refund,
    isVisable: (s) => s.status === 'ACCEPTED'
  },
  {
    label: 'Reinstate',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.reinstate,
    isVisable: (s) => s.status === 'DENIED'
  },
  {
    label: 'Deny',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.deny,
    isVisable: (s) =>
      s.status === 'ACCEPTED' ||
      s.status === 'PENDING' ||
      s.status === 'PENDING_PAYMENT'
  },
  {
    label: 'Record Payment',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
    isVisable: (s) =>
      s.status === 'ACCEPTED' ||
      s.status === 'PENDING' ||
      s.status === 'PENDING_PAYMENT'
  },
  {
    label: 'View Payments',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
    isVisable: (s) =>
      s.status !== 'ACCEPTED' &&
      s.status !== 'PENDING' &&
      s.status !== 'PENDING_PAYMENT'
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Season',
    value: 'season',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Form',
    value: 'form',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Date Purchased',
    value: 'date',
    sortable: true
  }
];

const RegistrationTable = () => {
  const navigate = useNavigate();
  const fetchedPkRegAssociation = useRef(null);
  const { search } = useLocation();
  const {
    state,
    fetchPaginatedRegFormSubmissions,
    fetchAdminRegAssociationLeagueOptions
  } = useContext(RegistrationAdminContext);
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: regFormSubmissions,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: '',
      sort: 'date_desc'
    },
    (newFilter) => {
      fetchPaginatedRegFormSubmissions(
        dashboardState.regAssociation.value?.pkRegAssociation,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedRegFormSubmissions
  );
  const {
    seasonOptions,
    divisionOptions,
    formOptions,
    unfilteredDivisionOptions
  } = getLeagueSelectOptions(state.regAssociation.value);

  const regAssociation = dashboardState.regAssociation.value;

  const loading =
    fetchedPkRegAssociation.current !== regAssociation?.pkRegAssociation ||
    dataLoading;

  useEffect(() => {
    if (
      regAssociation &&
      fetchedPkRegAssociation.current !== regAssociation.pkRegAssociation
    ) {
      fetchPaginatedRegFormSubmissions(
        regAssociation.pkRegAssociation,
        {
          search: new URLSearchParams(search).get('search') || '',
          sort: 'date_desc'
        },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      fetchAdminRegAssociationLeagueOptions(regAssociation.pkRegAssociation, {includeDeleted: true});

      fetchedPkRegAssociation.current = regAssociation.pkRegAssociation;
    }
  }, [regAssociation]);

  return (
    <AdminView headerTitle="Form Submissions" tabs={FINANCIAL_VIEW_TABS}>
      <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
        <TableView
          columns={TABLE_COLUMNS}
          disabled={!loading && totalNoFilterCount === 0}
          disabledContent={{
            title: 'No registrations found.',
            description:
              'Registrations will appear here once players have registered.',
            icon: <File color="#000" size={125} />
          }}
          emptyMessage="No registrations found."
          enableInputsWhenLoading
          filters={[
            {
              key: 1,
              label: 'Season',
              type: 'select',
              value: filter.season || null,
              placeholder: 'Filter by season',
              searchable: true,
              clearable: true,
              data: seasonOptions,
              onChange: (value) => {
                if (value !== filter.season) {
                  const divisionOption = value
                    ? unfilteredDivisionOptions
                        .filter(
                          (d) =>
                            d.fkRegAssociationSeason.toString() === value &&
                            d.pkRegAssociationDivision.toString() ===
                              filter.division
                        )[0]
                        ?.pkRegAssociationDivision.toString()
                    : filter.division;

                  onFilterChange(
                    {
                      ...filter,
                      season: value,
                      division: divisionOption ?? null
                    },
                    true
                  );
                }
              }
            },
            {
              key: 2,
              label: 'Division',
              type: 'select',
              value: filter.division || null,
              placeholder: 'Filter by division',
              searchable: true,
              clearable: true,
              data: divisionOptions.filter(
                (d) =>
                  !filter.season || d.fkRegAssociationSeason === filter.season
              ),
              onChange: (value) =>
                onFilterChange(
                  {
                    ...filter,
                    division: value
                  },
                  true
                )
            },
            {
              key: 3,
              label: 'Form',
              type: 'select',
              value: filter.form || null,
              placeholder: 'Filter by form',
              searchable: true,
              clearable: true,
              data: formOptions,
              onChange: (value) =>
                onFilterChange(
                  {
                    ...filter,
                    form: value
                  },
                  true
                )
            },
            {
              key: 4,
              label: 'Status',
              type: 'select',
              value: filter.status || null,
              placeholder: 'Filter by status',
              searchable: true,
              clearable: true,
              data: [
                { value: '2', label: 'Accepted' },
                { value: '3', label: 'Denied' },
                { value: '5', label: 'Pending' },
                { value: '4', label: 'Refunded' }
              ],
              onChange: (value) =>
                onFilterChange({
                  ...filter,
                  status: value
                })
            },
            {
              key: 5,
              label: 'Search',
              placeholder: 'Filter by registrant',
              value: filter.search,
              onChange: (value) => onFilterChange({ ...filter, search: value })
            }
          ]}
          isDescendingSort={isDescendingSort}
          isLoading={loading}
          noSort
          onAction={(action, item) => {
            const submission = regFormSubmissions.find(
              (s) => s.pkRegFormSubmission === item.key
            );
            if (action === REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.view) {
              navigate(`/admin/financials/registrations/${item.key}`);
            }
            else if (
              action === REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.emailUser
            ) {
              window.open(
                `mailto:${submission.overrideEmail ?? submission.user.email}`
              );
            }
            else {
              onOpenModal(action, submission);
            }
          }}
          onChangeSortBy={onSortChange}
          onPageChange={onPageChange}
          onRefresh={onRefresh}
          onSelect={setSelectedRows}
          pageIndex={pageIndex}
          rows={regFormSubmissions.map((s) => {
            const statusInfo =
              s.status === 'ACCEPTED'
                ? {
                    color: 'green',
                    label: 'ACCEPTED'
                  }
                : s.status === 'DENIED'
                ? {
                    color: 'red',
                    label: 'DENIED'
                  }
                : s.status === 'REFUNDED'
                ? {
                    color: 'gray',
                    label: 'REFUNDED'
                  }
                : {
                    color: 'blue',
                    label: 'PENDING'
                  };

            return {
              key: s.pkRegFormSubmission,
              actions: VIEW_ACTIONS.filter((a) => a.isVisable(s)),
              columns: [
                {
                  key: 1,
                  label: `${s.regPerson.firstName} ${s.regPerson.lastName}`,
                  weight: 500,
                  subLabel: s.overrideEmail ?? s.user.email,
                  component: Link,
                  to: `/admin/financials/registrations/${s.pkRegFormSubmission}`,
                  onSubLabelClick: () =>
                    window.open(`mailto:${s.overrideEmail ?? s.user.email}`),
                  icon: (
                    <ColoredAvatar
                      name={`${s.regPerson.firstName} ${s.regPerson.lastName}`}
                      uniqueId={s.user.email}
                    />
                  )
                },
                {
                  key: 2,
                  label:
                    s.regAssociationDivisionOverride?.regAssociationSeason
                      ?.name ||
                    s.regAssociationDivisionForm.regAssociationDivision
                      .regAssociationSeason.name,
                  showOnMobile: true
                },
                {
                  key: 3,
                  label:
                    s.regAssociationDivisionOverride?.name ||
                    s.regAssociationDivisionForm.regAssociationDivision.name,
                  showOnMobile: true
                },
                {
                  key: 4,
                  label: s.regAssociationDivisionForm.regForm.name,
                  showOnMobile: true
                },
                {
                  key: 5,
                  label: statusInfo.label,
                  color: statusInfo.color,
                  showOnMobile: true
                },
                {
                  key: 6,
                  label: dayjs(formatUtcDate(s.submittedAt)).format(
                    'MMM D, YYYY'
                  ),
                  sortValue: new Date(s.submittedAt),
                  sortValueType: 'date',
                  showOnMobile: true
                }
              ]
            };
          })}
          selectedRows={selectedRows}
          selectInputOnly
          sortBy={filter.sort || TABLE_COLUMNS[0].value}
          tableTitle="Registrations"
          totalCount={totalCount}
          totalPages={totalPages}
        />
      </AppCard>
    </AdminView>
  );
};

export default RegistrationTable;
