import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mantine/core';
import ResponsiveModal from '../../../../components/common/ResponsiveModal';
import AppStack from '../../../../components/common/AppStack';
import AppText from '../../../../components/common/AppText';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import PaginationList from '../../../../components/common/PaginationList';
import { useRegResource } from '../../../../helpers/hooks';
import { triggerNotification } from '../../../../helpers/notificationHelper';

const RemoveTasksModal = ({
  isOpen,
  onClose,
  resourceId,
  fkRegResourceType
}) => {
  const {
    regResourceTaskTemplateAnswers,
    regResourceTaskTemplates,
    onUpdateRegResource
  } = useRegResource({
    resourceId,
    fkRegResourceType
  });
  const [formState, setFormState] = useState({
    regResourceTaskTemplateAnswers: [],
    loading: false
  });

  const taskTemplates =
    regResourceTaskTemplates.sort((a, b) =>
      a.regAssociationTaskTemplate.name.localeCompare(
        b.regAssociationTaskTemplate.name
      )
    ) || [];
  const taskAnswersWithoutTemplates = regResourceTaskTemplateAnswers.filter(
    (a) =>
      !a.regAssociationTaskTemplateQuestion ||
      !taskTemplates.find((t) =>
        t.regAssociationTaskTemplate.regAssociationTaskTemplateQuestions.some(
          (q) =>
            q.pkRegAssociationTaskTemplateQuestion ===
            a.regAssociationTaskTemplateQuestion
              .pkRegAssociationTaskTemplateQuestion
        )
      )
  );
  const allUncategorisedSelected = taskAnswersWithoutTemplates.every(
    (a) =>
      formState.regResourceTaskTemplateAnswers.find(
        (b) =>
          b.pkRegResourceTaskTemplateAnswer ===
          a.pkRegResourceTaskTemplateAnswer
      )?.hidden
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        regResourceTaskTemplateAnswers: regResourceTaskTemplateAnswers.map(
          (a) => ({
            pkRegResourceTaskTemplateAnswer: a.pkRegResourceTaskTemplateAnswer,
            fkRegAssociationTaskTemplateQuestion:
              a.regAssociationTaskTemplateQuestion
                ?.pkRegAssociationTaskTemplateQuestion,
            hidden: a.hidden
          })
        ),
        loading: false
      });
    }
  }, [isOpen]);

  const onTaskAnswerChange = (taskAnswers) => {
    setFormState({
      ...formState,
      hasChanges: true,
      regResourceTaskTemplateAnswers: [
        ...formState.regResourceTaskTemplateAnswers.filter(
          (a) =>
            !taskAnswers.find((t) =>
              t.pkRegResourceTaskTemplateAnswer
                ? a.pkRegResourceTaskTemplateAnswer ===
                  t.pkRegResourceTaskTemplateAnswer
                : a.fkRegAssociationTaskTemplateQuestion ===
                  t.fkRegAssociationTaskTemplateQuestion
            )
        ),
        ...taskAnswers.map((t) => ({ ...t, hasChanges: true }))
      ]
    });
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        isLoading: formState.loading,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          onUpdateRegResource(
            {
              regResourceTaskTemplateAnswers: formState.regResourceTaskTemplateAnswers
                .filter((a) => a.hasChanges)
                .map((a) => ({
                  pkRegResourceTaskTemplateAnswer:
                    a.pkRegResourceTaskTemplateAnswer,
                  fkRegAssociationTaskTemplateQuestion:
                    a.fkRegAssociationTaskTemplateQuestion,
                  hidden: a.hidden
                }))
            },
            () => {
              onClose();
              triggerNotification('Tasks updated!', 'Success', 'green');
            },
            (error) => {
              setFormState({
                ...formState,
                false: true
              });
              triggerNotification(error);
            }
          );
        },
        submitTitle: 'Save changes',
        padding: 0
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title="Change task visibility"
    >
      <AppStack style={{ gap: 16 }}>
        <AppText
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: '#666',
            fontStyle: 'italic',
            textAlign: 'center'
          }}
        >
          Select the tasks you want to hide from this view.
        </AppText>
        <AppStack style={{ gap: 32 }}>
          {taskTemplates.map((t) => {
            const allSelected = t.regAssociationTaskTemplate.regAssociationTaskTemplateQuestions.every(
              (q) =>
                formState.regResourceTaskTemplateAnswers.find(
                  (a) =>
                    a.fkRegAssociationTaskTemplateQuestion ===
                    q.pkRegAssociationTaskTemplateQuestion
                )?.hidden || false
            );

            return (
              <AppStack key={t.pkRegResourceTaskTemplate} style={{ gap: 8 }}>
                <AppFlexbox style={{ justifyContent: 'space-between' }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    {t.regAssociationTaskTemplate.name}
                  </AppText>

                  <Checkbox
                    checked={allSelected}
                    disabled={formState.loading}
                    label="Select all"
                    onChange={() => {
                      onTaskAnswerChange(
                        t.regAssociationTaskTemplate.regAssociationTaskTemplateQuestions.map(
                          (q) => {
                            const answer = formState.regResourceTaskTemplateAnswers?.find(
                              (f) =>
                                f.fkRegAssociationTaskTemplateQuestion ===
                                q.pkRegAssociationTaskTemplateQuestion
                            );

                            return {
                              ...answer,
                              fkRegAssociationTaskTemplateQuestion:
                                q.pkRegAssociationTaskTemplateQuestion,
                              hidden: !allSelected
                            };
                          }
                        )
                      );
                    }}
                    styles={{ label: { fontWeight: 500 } }}
                  />
                </AppFlexbox>

                <PaginationList
                  items={[
                    ...t.regAssociationTaskTemplate.regAssociationTaskTemplateQuestions.map(
                      (q) => {
                        const answer = formState.regResourceTaskTemplateAnswers?.find(
                          (f) =>
                            f.fkRegAssociationTaskTemplateQuestion ===
                            q.pkRegAssociationTaskTemplateQuestion
                        );

                        return (
                          <AppFlexbox
                            key={q.pkRegAssociationTaskTemplateQuestion}
                            onClick={() => {
                              if (!formState.loading) {
                                onTaskAnswerChange([
                                  {
                                    ...answer,
                                    fkRegAssociationTaskTemplateQuestion:
                                      q.pkRegAssociationTaskTemplateQuestion,
                                    hidden: !answer?.hidden
                                  }
                                ]);
                              }
                            }}
                            style={{
                              padding: 8,
                              alignItems: 'center',
                              cursor: formState.loading
                                ? 'not-allowed'
                                : 'pointer'
                            }}
                          >
                            <Checkbox
                              checked={answer?.hidden || false}
                              disabled={formState.loading}
                              onChange={() => {}}
                            />
                            <AppText style={{ fontSize: 14 }}>
                              {q.value}
                            </AppText>
                          </AppFlexbox>
                        );
                      }
                    )
                  ]}
                  itemsPerPage={99999}
                />
              </AppStack>
            );
          })}

          {taskAnswersWithoutTemplates.length > 0 && (
            <AppStack style={{ gap: 8 }}>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Uncategorised
                </AppText>

                <Checkbox
                  checked={allUncategorisedSelected}
                  disabled={formState.loading}
                  label="Select all"
                  onChange={() => {
                    onTaskAnswerChange(
                      taskAnswersWithoutTemplates.map((a) => ({
                        ...a,
                        hidden: !allUncategorisedSelected
                      }))
                    );
                  }}
                  styles={{ label: { fontWeight: 500 } }}
                />
              </AppFlexbox>

              <PaginationList
                items={[
                  ...taskAnswersWithoutTemplates.map((a) => {
                    const answer = formState.regResourceTaskTemplateAnswers?.find(
                      (f) =>
                        f.pkRegResourceTaskTemplateAnswer ===
                        a.pkRegResourceTaskTemplateAnswer
                    );

                    return (
                      <AppFlexbox
                        key={a.pkRegResourceTaskTemplateAnswer}
                        onClick={() => {
                          if (!formState.loading) {
                            onTaskAnswerChange([
                              {
                                ...answer,
                                hidden: !answer?.hidden || false
                              }
                            ]);
                          }
                        }}
                        style={{
                          padding: 8,
                          alignItems: 'center',
                          cursor: formState.loading ? 'not-allowed' : 'pointer'
                        }}
                      >
                        <Checkbox
                          checked={answer?.hidden || false}
                          disabled={formState.loading}
                          onChange={() => {}}
                        />
                        <AppText style={{ fontSize: 14 }}>
                          {a.regAssociationTaskTemplateQuestion?.value ||
                            a.customValue}
                        </AppText>
                      </AppFlexbox>
                    );
                  })
                ]}
                itemsPerPage={99999}
              />
            </AppStack>
          )}
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

RemoveTasksModal.propTypes = {
  fkRegResourceType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default RemoveTasksModal;
