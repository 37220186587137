import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import RegistrationTable from '../components/content/registrations/RegistrationTable';
import RegistrationViews from '../components/content/registrations/RegistrationViews';

const FinancialViews = () => (
  <Routes>
    <Route element={<RegistrationTable />} path="/registrations" />
    <Route
      element={<RegistrationViews />}
      path="/registrations/:pkRegFormSubmission/*"
    />
    <Route element={<></>} path="/:waitlist/*" />
    <Route element={<></>} path="/:invites/*" />
    <Route element={<></>} path="/:merchandise/*" />
    <Route element={<></>} path="/:payee/*" />
    <Route
      element={<Navigate replace to="/admin/financials/registrations" />}
      path="*"
    />
  </Routes>
);

export default FinancialViews;
