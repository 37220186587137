import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from '@mantine/core';
import { CircleCheck, CircleX } from 'tabler-icons-react';
import PageCard from '../../../../components/common/PageCard';
import AppStack from '../../../../components/common/AppStack';
import { useMediaQueries, useModalState } from '../../../../helpers/hooks';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppText from '../../../../components/common/AppText';
import RegistrationExternalConnectionVerifyModal from './RegistrationExternalConnectionVerifyModal';
import EXTERNAL_CONNECTION_FORM_SECTION_CONFIG from '../../../../components/content/Forms/externalConnectionFormSectionConfig';

const RegistrationExternalConnectionInfoCard = ({ regFormSubmission }) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueries();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const { regAssociation, regFormSubmissionExternalIds } =
    regFormSubmission || {};

  const externalConnections = [
    ...regFormSubmissionExternalIds.map((e) => ({
      pkRegFormSubmissionExternalId: e.pkRegFormSubmissionExternalId,
      fkRegAssociationExternalConnection:
        e.regAssociationExternalConnection.pkRegAssociationExternalConnection,
      fkRegExternalConnectionType:
        e.regAssociationExternalConnection.fkRegExternalConnectionType,
      value: e.value,
      verifiedAt: e.verifiedAt,
      userVerified: e.userVerified
    })),
    ...(regAssociation.regAssociationExternalConnections
      ?.filter((c) =>
        regFormSubmissionExternalIds.every(
          (s) =>
            s.regAssociationExternalConnection.fkRegExternalConnectionType !==
            c.fkRegExternalConnectionType
        )
      )
      .map((e) => ({
        fkRegAssociationExternalConnection:
          e.pkRegAssociationExternalConnection,
        fkRegExternalConnectionType: e.fkRegExternalConnectionType,
        value: null,
        verifiedAt: null,
        userVerified: null
      })) || [])
  ];

  return regFormSubmission && externalConnections.length > 0 ? (
    <PageCard style={{ padding: 0 }}>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        {externalConnections.map((c) => {
          const config =
            EXTERNAL_CONNECTION_FORM_SECTION_CONFIG[
              c.fkRegExternalConnectionType.toString()
            ];

          return config ? (
            <AppStack
              key={c.fkRegExternalConnectionType}
              style={{ flex: 1, padding: 16, gap: 24 }}
            >
              <AppStack style={{ flex: 1, gap: 8 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    {config.title}
                  </AppText>
                  <Anchor
                    onClick={() => {
                      onOpenModal('verify', c);
                    }}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                  >
                    {!c.value
                      ? 'Add ID'
                      : c.verifiedAt
                      ? 'Update ID'
                      : 'Verify ID'}
                  </Anchor>
                </AppFlexbox>
                <AppStack style={{ gap: 5 }}>
                  {!c.value ? (
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 14, color: '#C40000' }}>
                        Not setup
                      </AppText>
                    </AppStack>
                  ) : (
                    <AppStack style={{ gap: 5 }}>
                      <AppText style={{ fontSize: 14 }}>{c.value}</AppText>

                      {c.verifiedAt ? (
                        <AppFlexbox style={{ gap: 5 }}>
                          <AppStack>
                            <CircleCheck
                              color="#067D62"
                              size={18}
                              style={{ marginTop: 1 }}
                            />
                          </AppStack>
                          <AppText
                            style={{
                              fontSize: 13,
                              color: '#067D62',
                              fontWeight: 500
                            }}
                          >
                            Verified by {c.userVerified?.name}
                          </AppText>
                        </AppFlexbox>
                      ) : (
                        <AppFlexbox style={{ gap: 5 }}>
                          <AppStack>
                            <CircleX
                              color="#C40000"
                              size={18}
                              style={{ marginTop: 1 }}
                            />
                          </AppStack>
                          <AppText style={{ fontSize: 13, color: '#C40000' }}>
                            Not Verified
                          </AppText>
                        </AppFlexbox>
                      )}
                    </AppStack>
                  )}
                </AppStack>
              </AppStack>
            </AppStack>
          ) : null;
        })}
      </AppStack>

      <RegistrationExternalConnectionVerifyModal
        externalConnection={modalState.item}
        isOpen={modalState.isOpen && modalState.action === 'verify'}
        onClose={onCloseModal}
        regFormSubmission={regFormSubmission}
      />
    </PageCard>
  ) : null;
};

RegistrationExternalConnectionInfoCard.propTypes = {
  regFormSubmission: PropTypes.object
};

export default RegistrationExternalConnectionInfoCard;
