import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import { InfoCircle } from 'tabler-icons-react';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import BulkEditColumnHeader from './BulkEditFieldHeader';
import BulkEditHeaderNavbar from './BulkEditHeaderNavbar';
import { usePrompt } from '../../../helpers/prompt';
import ConfirmModal from '../../common/ConfirmModal';
import { useModalState } from '../../../helpers/hooks';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import BulkEditSelectedModal from './BulkEditSelectedModal';
import TableViewDisabledContent from '../../common/TableViewDisabledContent';
import BulkEditDataRow from './BulkEditDataRow';

const BulkEditView = ({
  fields,
  data,
  optionsState,
  title,
  emptyContent,
  onlySubrows,
  loading,
  saving,
  onCancel,
  onSave
}) => {
  const hasInitialized = useRef(false);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [formState, setFormState] = useState({
    data: [],
    dataFields: [],
    hasUnsavedChanges: false
  });
  const isTableLoading =
    loading || (data.length > 0 && !hasInitialized.current);
  const defaultDisplayFields = fields.filter((f) => f.displayed);
  const displayedFields = formState.dataFields.filter((f) => f.displayed);

  const allRowsSelected = formState.data.every(
    (r) => r.selected && r.subrows.every((sr) => sr.selected)
  );
  const selectedCount = formState.data.reduce(
    (r, c) =>
      r +
      (c.selected ? 1 : 0) +
      (c.subrows?.filter((sr) => sr.selected).length || 0),
    0
  );

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    formState.hasUnsavedChanges
  );

  useEffect(() => {
    if (!saving && data.length > 0 && !formState.error) {
      setFormState({
        data: [
          ...data.map((d) => ({
            ...d,
            selected:
              formState.data?.find((r) => r.key === d.key)?.selected ?? false,
            hasUnsavedChanges: false,
            subrows:
              d.subrows?.map((s) => ({
                ...s,
                selected:
                  formState.data
                    ?.find((r) => r.key === d.key)
                    ?.subrows?.find((sr) => sr.key === s.key)?.selected ??
                  false,
                hasUnsavedChanges: false
              })) ?? []
          }))
        ],
        dataFields: !formState.dataFields.length
          ? [...fields]
          : formState.dataFields,
        fieldSearch: '',
        hasUnsavedChanges: false
      });
      hasInitialized.current = true;
    }
  }, [data, saving]);

  const onChangeRow = (rowKey, columnKey, value, adjustSubrows = false) => {
    setFormState((currentState) => ({
      data: currentState.data.map((r) => {
        if (r.key === rowKey) {
          const columnIndex = r.columns.findIndex((c) => c.key === columnKey);
          return {
            ...r,
            hasUnsavedChanges: true,
            columns: r.columns.map((c) => {
              if (c.key === columnKey) {
                return {
                  ...c,
                  value
                };
              }
              return c;
            }),
            subrows:
              adjustSubrows && r.subrows
                ? r.subrows.map((sr) => ({
                    ...sr,
                    hasUnsavedChanges: true,
                    columns: sr.columns.map((sc, index) => {
                      if (index === columnIndex) {
                        return {
                          ...sc,
                          value
                        };
                      }
                      return sc;
                    })
                  }))
                : r.subrows
          };
        }
        return r;
      }),
      dataFields: currentState.dataFields,
      fieldSearch: '',
      hasUnsavedChanges: true
    }));
  };

  const onChangeSubrow = (rowKey, subrowKey, columnKey, value) => {
    setFormState((currentState) => ({
      data: currentState.data.map((r) => {
        if (r.key === rowKey) {
          return {
            ...r,
            subrows: r.subrows.map((sr) => {
              if (sr.key === subrowKey) {
                return {
                  ...sr,
                  hasUnsavedChanges: true,
                  columns: sr.columns.map((c) => {
                    if (c.key === columnKey) {
                      return {
                        ...c,
                        value
                      };
                    }
                    return c;
                  })
                };
              }
              return sr;
            })
          };
        }
        return r;
      }),
      dataFields: currentState.dataFields,
      fieldSearch: '',
      hasUnsavedChanges: true
    }));
  };

  const onSelectAllRows = () => {
    setFormState((currentState) => ({
      data: currentState.data.map((r) => ({
        ...r,
        selected: !allRowsSelected,
        subrows: r.subrows.map((sr) => ({
          ...sr,
          selected: !allRowsSelected
        }))
      })),
      dataFields: currentState.dataFields,
      fieldSearch: '',
      hasUnsavedChanges: currentState.hasUnsavedChanges
    }));
  };

  const onSelectRow = (rowKey, selected = true) => {
    setFormState((currentState) => ({
      data: currentState.data.map((r) => {
        if (r.key === rowKey) {
          return {
            ...r,
            selected,
            subrows: r.subrows.map((sr) => ({
              ...sr,
              selected
            }))
          };
        }
        return r;
      }),
      dataFields: currentState.dataFields,
      fieldSearch: '',
      hasUnsavedChanges: currentState.hasUnsavedChanges
    }));
  };

  const onSelectSubrow = (rowKey, subrowKey) => {
    setFormState((currentState) => ({
      data: currentState.data.map((r) => {
        if (r.key === rowKey) {
          return {
            ...r,
            subrows: r.subrows.map((sr) => {
              if (sr.key === subrowKey) {
                return {
                  ...sr,
                  selected: !sr.selected
                };
              }
              return sr;
            })
          };
        }
        return r;
      }),
      dataFields: currentState.dataFields,
      fieldSearch: '',
      hasUnsavedChanges: currentState.hasUnsavedChanges
    }));
  };

  const onChangeSelectedRows = (changedInputs) => {
    setFormState((currentState) => ({
      data: currentState.data.map((r) => {
        if (r.selected) {
          return {
            ...r,
            hasUnsavedChanges: true,
            columns: r.columns.map((c, index) => {
              const disabled = fields[index]?.getDisabledMessage
                ? fields[index].getDisabledMessage(r.columns)
                : null;

              const changedInput = changedInputs[index];
              return {
                ...c,
                value:
                  changedInput.updateValue && !c.disabled && !disabled
                    ? changedInput.value
                    : c.value
              };
            }),
            subrows: r.subrows.map((sr) => {
              if (sr.selected) {
                return {
                  ...sr,
                  hasUnsavedChanges: true,
                  columns: sr.columns.map((c, index) => {
                    const changedInput = changedInputs[index];
                    return {
                      ...c,
                      value:
                        changedInput.updateValue && !c.disabled
                          ? changedInput.value
                          : c.value
                    };
                  })
                };
              }
              return sr;
            })
          };
        }
        return r;
      }),
      dataFields: currentState.dataFields,
      fieldSearch: '',
      hasUnsavedChanges: true
    }));

    onCloseModal();
  };

  return (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();

        const errorMessages = [
          ...new Set([
            ...formState.data
              .map((row) =>
                row.columns
                  .map((c, index) =>
                    formState.dataFields[index].getErrorMessage
                      ? formState.dataFields[index].getErrorMessage(row.columns)
                      : ''
                  )
                  .filter((m) => m)
              )
              .flat(),
            ...formState.data
              .map((row) =>
                row.subrows
                  .map((sr) =>
                    sr.columns.map((c, index) =>
                      formState.dataFields[index].getErrorMessage
                        ? formState.dataFields[index].getErrorMessage(
                            row.columns,
                            sr.columns
                          )
                        : ''
                    )
                  )
                  .flat()
                  .filter((m) => m)
              )
              .flat()
          ])
        ];
        if (errorMessages.length > 0) {
          onOpenModal(VIEW_ACTIONS_ENUM.ERROR, errorMessages);
        }
        else {
          setFormState({
            ...formState,
            error: null
          });
          onSave(
            formState.data.map((d) =>
              d.columns.reduce(
                (r, c, index) => ({
                  ...r,
                  [fields[index].dataField ?? fields[index].value]: c.value
                }),
                {
                  ...d,
                  subData: d.subrows?.map((s) =>
                    s.columns.reduce(
                      (r, c, index) => ({
                        ...r,
                        [fields[index].dataField ??
                        fields[index].value]: c.value
                      }),
                      { ...s }
                    )
                  )
                }
              )
            ),
            () => {},
            () => {
              setFormState({
                ...formState,
                error:
                  'An error occurred while saving the data. Please try again.'
              });
            }
          );
        }
      }}
      style={{
        flex: 1,
        position: 'absolute',
        gap: 0,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#F0F0F0',
        zIndex: 100
      }}
    >
      <BulkEditHeaderNavbar
        dataFields={formState.dataFields}
        hasUnsavedChanges={formState.hasUnsavedChanges}
        loading={isTableLoading}
        onExitEdit={onCancel}
        onOpenSelectedModal={() => onOpenModal(VIEW_ACTIONS_ENUM.EDIT)}
        onReset={() => onOpenModal(VIEW_ACTIONS_ENUM.DISCARD)}
        onSelectFields={(newFields) => {
          setFormState((c) => ({
            ...c,
            dataFields: c.dataFields.map((d) => ({
              ...d,
              displayed:
                !!newFields.find((f) => f.value === d.value) || d.staticDisplay
            }))
          }));
        }}
        saving={saving}
        selectedCount={selectedCount}
        title={title}
      />

      <AppCard
        radius="sm"
        shadow="xs"
        style={{
          height: '100%',
          padding: 0,
          backgroundColor: 'rgb(230, 230, 230)'
        }}
      >
        <AppStack
          style={{
            height: '100%',
            maxHeight: '100%',
            width: '100%',
            textWrap: 'nowrap',
            flex: 1,
            overflow: 'auto',
            position: 'relative'
          }}
        >
          <AppFlexbox
            style={{
              ...(!isTableLoading && formState.data.length === 0
                ? { width: '100%' }
                : {
                    gridTemplateColumns: `repeat(${
                      isTableLoading
                        ? defaultDisplayFields.length
                        : displayedFields.length
                    }, max-content)`,
                    width: 'max-content',
                    display: 'grid'
                  }),
              gridColumnGap: 0,
              gridRowGap: 0,
              position: 'relative'
            }}
          >
            {isTableLoading ? (
              <>
                {defaultDisplayFields.map((f, index) => (
                  <BulkEditColumnHeader
                    key={f.value}
                    firstPosition={index === 0}
                    loading={isTableLoading}
                  />
                ))}

                {Array.from(Array(10)).map((x, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={i}>
                    {defaultDisplayFields.map((f, index) => (
                      <AppFlexbox
                        key={f.value}
                        style={{
                          height: 37,
                          maxWidth: index === 0 ? 350 : 250,
                          width: index === 0 ? 350 : 250,
                          boxSizing: 'border-box',
                          border: '1px solid #dee2e6',
                          borderLeft: 'none',
                          borderTop: 'none',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor: '#FFF',
                          overflow: 'hidden',
                          position: 'sticky',
                          left: 0,
                          zIndex: 999,
                          padding: 8
                        }}
                      >
                        <Skeleton height={18} width="50%" />
                      </AppFlexbox>
                    ))}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>
                {formState.data.length > 0 &&
                  displayedFields.map((f) => (
                    <BulkEditColumnHeader
                      key={f.value}
                      firstPosition={f.firstPosition}
                      label={f.label}
                      onSelect={onSelectAllRows}
                      selected={allRowsSelected}
                      subLabel={f.subLabel}
                    />
                  ))}

                {formState.data.length === 0
                  ? emptyContent && (
                      <AppFlexbox
                        style={{
                          flex: 1,
                          maxWidth: '100%',
                          backgroundColor: '#FFF',
                          justifyContent: 'center'
                        }}
                      >
                        <TableViewDisabledContent
                          disabledContent={emptyContent}
                        />
                      </AppFlexbox>
                    )
                  : formState.data.map((r, index) => (
                      <BulkEditDataRow
                        key={r.key}
                        dataFields={formState.dataFields}
                        displayedFields={displayedFields}
                        onlySubrows={onlySubrows}
                        onRowChange={onChangeRow}
                        onSelectRow={onSelectRow}
                        onSelectSubrow={onSelectSubrow}
                        onSubrowChange={onChangeSubrow}
                        optionsState={optionsState}
                        row={r}
                        rowIndex={index}
                      />
                    ))}
              </>
            )}
          </AppFlexbox>
        </AppStack>
      </AppCard>

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, discard changes"
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DISCARD
        }
        message="Are you sure you want to discard all unsaved changes?"
        onCancel={onCloseModal}
        onConfirm={() => {
          onCloseModal();
          setFormState((c) => ({
            ...c,
            dataFields: c.dataFields,
            data: [...data],
            hasUnsavedChanges: false
          }));
        }}
        title="Discard changes?"
      />

      <ConfirmModal
        formSectionProps={{ isSubmitHidden: true, cancelTitle: 'Close' }}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.ERROR
        }
        message={
          <AppStack style={{ gap: 5, flex: 1 }}>
            {modalState.item?.map((i) => (
              <AppFlexbox key={i} style={{ gap: 8, alignItems: 'top' }}>
                <InfoCircle color="#c40000" size={24} />
                <AppText style={{ whiteSpace: 'normal' }}>{i}</AppText>
              </AppFlexbox>
            ))}
          </AppStack>
        }
        modalProps={{
          styles: {
            header: { backgroundColor: '#c40000', color: '#FFF' },
            close: { color: '#FFF' }
          }
        }}
        onCancel={onCloseModal}
        title="Please correct the following errors"
      />

      <BulkEditSelectedModal
        data={formState.data}
        dataFields={formState.dataFields}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EDIT
        }
        onClose={onCloseModal}
        onConfirm={onChangeSelectedRows}
        optionsState={optionsState}
        selectedCount={selectedCount}
      />
    </AppStack>
  );
};

BulkEditView.propTypes = {
  data: PropTypes.array,
  emptyContent: PropTypes.object,
  fields: PropTypes.array,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onlySubrows: PropTypes.bool,
  optionsState: PropTypes.object,
  saving: PropTypes.bool,
  title: PropTypes.string
};

export default BulkEditView;
