import React, { useEffect, useState } from 'react';
import { Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { fixHtmlHrefUrls } from '../../helpers/format';

const AppTextEditorContent = ({ content }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] })
    ],
    content
  });
  const [contentState, setContentState] = useState(null);

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content);
      const sanitizedHtml = DOMPurify.sanitize(editor.getHTML());
      sanitizedHtml.replace('href');

      setContentState(fixHtmlHrefUrls(sanitizedHtml));
    }
  }, [content, editor]);

  return contentState ? (
    <div dangerouslySetInnerHTML={{ __html: contentState }} />
  ) : null;
};

AppTextEditorContent.propTypes = { content: PropTypes.object };

export default AppTextEditorContent;
