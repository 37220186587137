import {
  Cash,
  Checklist,
  FileText,
  ReportAnalytics,
  Shirt,
  Ticket,
  Tournament,
  Users,
  World
} from 'tabler-icons-react';
import { REG_PERMISSION_ENUM } from '../../../../config/constants';
import SUPPORT_LINKS from '../../../../components/content/navigation/supportLinks';

const REGISTRATION_ADMIN_NAVIGATION_LINKS = {
  main: [],
  sections: [
    {
      title: 'My League',
      links: [
        {
          to: '/admin/league/seasons',
          title: 'Seasons',
          icon: Tournament,
          permissions: [REG_PERMISSION_ENUM.MY_LEAGUE],
          isSelected: (pathname) =>
            pathname.startsWith('/admin/league/seasons'),
          links: [
            {
              to: '/admin/league/divisions',
              title: 'Divisions',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/league/divisions')
            },
            {
              to: '/admin/league/teams',
              title: 'Teams',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/league/teams')
            }
          ]
        },
        {
          to: '/admin/league/players',
          title: 'Players',
          icon: Users,
          permissions: [REG_PERMISSION_ENUM.MY_LEAGUE],
          isSelected: (pathname) =>
            pathname.startsWith('/admin/league/players'),
          links: [
            {
              to: '/admin/league/bench-staff',
              title: 'Bench Staff',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/league/bench-staff')
            },
            {
              to: '/admin/league/guardians',
              title: 'Guardians',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/league/guardians')
            }
          ]
        },
        {
          to: '/admin/evaluations/sessions',
          title: 'Scouting & Evaluation',
          evaluatorAccess: true,
          icon: Checklist,
          permissions: [REG_PERMISSION_ENUM.EVALUATIONS],
          isSelected: (pathname) => pathname.startsWith('/admin/evaluations')
        }
      ]
    },
    {
      title: 'Financials',
      links: [
        {
          to: '/admin/financials/registrations',
          title: 'Registrations',
          icon: Cash,
          permissions: [REG_PERMISSION_ENUM.FINANCIALS],
          isSelected: (pathname) =>
            pathname.startsWith('/admin/financials/registrations'),
          links: [
            {
              to: '/admin/financials/waitlist',
              title: 'Waitlist',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/financials/waitlist')
            },
            {
              to: '/admin/financials/invites',
              title: 'Invites',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/financials/invites')
            }
          ]
        },
        {
          to: '/admin/financials/merchandise',
          title: 'Merchandise',
          icon: Shirt,
          permissions: [REG_PERMISSION_ENUM.FINANCIALS],
          isSelected: (pathname) =>
            pathname.startsWith('/admin/financials/merchandise')
        },
        {
          to: '/admin/analytics',
          title: 'Analytics',
          icon: ReportAnalytics,
          isSelected: (pathname) => pathname.startsWith('/admin/analytics'),
          links: [
            {
              to: '/admin/reports',
              title: 'Reports',
              isSelected: (pathname) => pathname.startsWith('/admin/reports')
            }
          ]
        }
      ]
    },
    {
      title: 'Store Front',
      links: [
        {
          to: '/admin/forms',
          title: 'Forms',
          icon: FileText,
          permissions: [REG_PERMISSION_ENUM.FORMS],
          isSelected: (pathname) => pathname.startsWith('/admin/forms'),
          links: [
            {
              to: '/admin/task-templates',
              title: 'Task Templates',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/task-templates')
            }
          ]
        },
        {
          to: '/admin/coupons/standard',
          title: 'Coupons',
          icon: Ticket,
          permissions: [REG_PERMISSION_ENUM.COUPONS],
          isSelected: () => false,
          links: [
            {
              to: '/admin/coupons/standard',
              title: 'Standard Coupons',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/coupons/standard')
            },
            {
              to: '/admin/coupons/group',
              title: 'Group Coupons',
              isSelected: (pathname) =>
                pathname.startsWith('/admin/coupons/group')
            }
          ]
        },
        {
          to: '/admin/settings',
          title: 'Branding',
          icon: World,
          permissions: [REG_PERMISSION_ENUM.BRANDING],
          isSelected: (pathname) => pathname.includes('/admin/settings')
        }
      ]
    }
  ]
};

const FINANCIAL_VIEW_TABS = [
  {
    to: '/admin/financials/registrations',
    title: 'Registrations',
    value: 'registrations',
    index: 0,
    indexHelpLink: SUPPORT_LINKS.financialLinks.index,
    helpLinks: [
      SUPPORT_LINKS.financialLinks.reconciliation,
      SUPPORT_LINKS.financialLinks.payouts,
      SUPPORT_LINKS.financialLinks.registrations
    ]
  },
  {
    to: '/admin/financials/waitlist',
    title: 'Waitlist',
    value: 'waitlist',
    index: 1
  },
  {
    to: '/admin/financials/invites',
    title: 'Invites',
    value: 'invites',
    index: 2,
    indexHelpLink: SUPPORT_LINKS.myLeagueLinks.index,
    helpLinks: [
      SUPPORT_LINKS.myLeagueLinks.waitlists,
      SUPPORT_LINKS.myLeagueLinks.invites,
      SUPPORT_LINKS.myLeagueLinks.emailUsers
    ]
  },
  {
    to: '/admin/financials/merchandise',
    title: 'Merchandise',
    value: 'merchandise',
    index: 3
  },
  {
    to: '/admin/financials/payee',
    title: 'Payee',
    value: 'payee',
    index: 4,
    indexHelpLink: SUPPORT_LINKS.financialLinks.index,
    helpLinks: [SUPPORT_LINKS.financialLinks.paymentProviders]
  }
];

const FORM_VIEW_TABS = [
  {
    to: '/admin/forms',
    title: 'Forms',
    value: 'forms',
    index: 0,
    indexHelpLink: SUPPORT_LINKS.formLinks.index,
    helpLinks: [
      SUPPORT_LINKS.formLinks.createForm,
      SUPPORT_LINKS.formLinks.formBuilder,
      SUPPORT_LINKS.formLinks.editForm,
      SUPPORT_LINKS.formLinks.copyForm
    ]
  },
  {
    to: '/admin/task-templates',
    title: 'Task Templates',
    value: 'task-templates',
    index: 1
  }
];

export {
  REGISTRATION_ADMIN_NAVIGATION_LINKS,
  FINANCIAL_VIEW_TABS,
  FORM_VIEW_TABS
};
