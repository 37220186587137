import React, { useContext } from 'react';
import { Button, Divider } from '@mantine/core';
import { CreditCard, ExternalLink } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import {
  PAYMENT_PROVIDERS,
  REG_EXTERNAL_CONNECTION_TYPE_ENUM
} from '../../../config/constants';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppImage from '../../common/AppImage';
import { useBackPath, useModalState } from '../../../helpers/hooks';
import PageView from '../../common/PageView';
import PageCard from '../../common/PageCard';
import FinancialPayeeOnboardingModal from '../financials/FinancialPayeeOnboardingModal';
import ExternalConnectionSetupModal from './ExternalConnectionSetupModal';

const CONNECTION_SECTIONS = [
  {
    value: 'payment_provider',
    label: 'Payment Provider',
    description:
      'Choose a payment provider for your association. You can change this selection at any time. If you have any questions, feel free to reach out to our support team.',
    hideActionsWhenDisabled: true,
    actionText: 'Connect',
    action: 'onboard',
    connections: [
      {
        value: PAYMENT_PROVIDERS.stripe.value.toString(),
        label: 'Stripe',
        icon: CreditCard,
        getIsConnected: (regAssociation) =>
          regAssociation?.regAssociationPaymentProvider?.fkPaymentProvider ===
          PAYMENT_PROVIDERS.stripe.value,
        getIsDisabled: (regAssociation) =>
          !!regAssociation?.regAssociationPaymentProvider &&
          regAssociation?.regAssociationPaymentProvider?.fkPaymentProvider !==
            PAYMENT_PROVIDERS.stripe.value,
        getConnectedDescription: (regAssociation) => (
          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 14, color: '#067D62' }}>
              {regAssociation?.regAssociationPaymentProvider?.accountId}
            </AppText>
          </AppStack>
        )
      },
      {
        value: PAYMENT_PROVIDERS.sportspay.value.toString(),
        label: 'SportsPay',
        icon: CreditCard,
        getIsConnected: (regAssociation) =>
          regAssociation?.regAssociationPaymentProvider?.fkPaymentProvider ===
          PAYMENT_PROVIDERS.sportspay.value,
        getIsDisabled: (regAssociation) =>
          !!regAssociation?.regAssociationPaymentProvider &&
          regAssociation?.regAssociationPaymentProvider?.fkPaymentProvider !==
            PAYMENT_PROVIDERS.sportspay.value
      }
    ]
  },
  {
    value: 'sports_management',
    label: 'Sports Management',
    action: 'external-connection',
    description:
      'Integrate your association with a sports management platform to collect, verify, and manage your data efficiently for the selected connection.',
    actionText: 'Setup',
    connections: [
      {
        value: REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA,
        label: 'Hockey Canada',
        icon: ExternalLink,
        canDisconnect: true,
        getIsConnected: (regAssociation) =>
          regAssociation?.regAssociationExternalConnections?.find(
            (c) =>
              c.fkRegExternalConnectionType.toString() ===
                REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA && !c.deleted
          )
      }
    ]
  }
];

const ExternalConnectionsView = () => {
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const { state } = useContext(RegistrationAdminContext);
  const { backPath } = useBackPath();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const regAssociation = state.regAssociations.value.find(
    (a) =>
      a.pkRegAssociation ===
      dashboardState.regAssociation.value?.pkRegAssociation
  );

  return (
    <PageView backPath={backPath} title="External connections" width={900}>
      <PageCard>
        <AppStack style={{ gap: 32 }}>
          {CONNECTION_SECTIONS.map((s) => (
            <AppStack key={s.value}>
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                  {s.label}
                </AppText>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  {s.description}
                </AppText>
              </AppStack>

              <AppStack style={{ gap: 0 }}>
                {s.connections.map((c) => {
                  const isConnected = c.getIsConnected
                    ? c.getIsConnected(regAssociation)
                    : false;
                  const isDisabled = c.getIsDisabled
                    ? c.getIsDisabled(regAssociation)
                    : false;

                  return (
                    <React.Fragment key={c.value}>
                      <Divider />
                      <AppFlexbox
                        style={{
                          gap: 5,
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '16px 0px'
                        }}
                      >
                        <AppFlexbox style={{ gap: 12, alignItems: 'center' }}>
                          <AppStack
                            style={{
                              width: 40,
                              height: 40,
                              overflow: 'hidden',
                              borderRadius: 8,
                              backgroundColor: '#f5f5f5',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            {c.icon ? (
                              <c.icon size={30} />
                            ) : (
                              <AppImage
                                src={c.image}
                                style={{ width: 30, height: 30 }}
                              />
                            )}
                          </AppStack>

                          <AppStack style={{ gap: 0 }}>
                            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                              {c.label}
                            </AppText>

                            {!!c.description && (
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                {c.description}
                              </AppText>
                            )}
                            {isConnected &&
                              !!c.getConnectedDescription &&
                              c.getConnectedDescription(regAssociation)}
                          </AppStack>
                        </AppFlexbox>
                        {regAssociation && (
                          <AppFlexbox style={{ gap: 8 }}>
                            {isConnected ? (
                              <>
                                {c.canDisconnect && (
                                  <Button
                                    color="dark"
                                    disabled={isDisabled}
                                    onClick={() => {
                                      onOpenModal('delete', c.value);
                                    }}
                                    size="compact-sm"
                                    variant="outline"
                                  >
                                    Disconnect
                                  </Button>
                                )}
                                <Button
                                  color="blue"
                                  disabled={isDisabled}
                                  onClick={() => {
                                    onOpenModal(c.action || s.action, c.value);
                                  }}
                                  size="compact-sm"
                                  variant="filled"
                                >
                                  Update
                                </Button>
                              </>
                            ) : (
                              (!isDisabled || !s.hideActionsWhenDisabled) && (
                                <Button
                                  color="dark"
                                  disabled={isDisabled}
                                  onClick={() => {
                                    onOpenModal(c.action || s.action, c.value);
                                  }}
                                  size="compact-sm"
                                  variant="outline"
                                >
                                  {c.actionText || s.actionText || 'Connect'}
                                </Button>
                              )
                            )}
                          </AppFlexbox>
                        )}
                      </AppFlexbox>
                    </React.Fragment>
                  );
                })}
              </AppStack>
            </AppStack>
          ))}
        </AppStack>
      </PageCard>
      <FinancialPayeeOnboardingModal
        isOpen={modalState.isOpen && modalState.action === 'onboard'}
        onClose={onCloseModal}
        regAssociation={regAssociation}
      />
      <ExternalConnectionSetupModal
        fkRegExternalConnectionType={
          modalState.action === 'external-connection' ||
          modalState.action === 'delete'
            ? modalState.item
            : ''
        }
        isOpen={
          modalState.isOpen &&
          (modalState.action === 'external-connection' ||
            modalState.action === 'delete')
        }
        onClose={onCloseModal}
        showDisconnect={modalState.action === 'delete'}
      />
    </PageView>
  );
};

export default ExternalConnectionsView;
