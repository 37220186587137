import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Select, TextInput } from '@mantine/core';
import { ArrowsMove, Edit, Plus } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import {
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../../../config/constants';
import TabsContent from '../../common/TabsContent';
import EvaluationTemplateQuestionInput from './EvaluationTemplateQuestionInput';
import EvaluationTemplateQuestionRestrictions from './EvaluationTemplateQuestionRestrictions';
import EvaluationTemplateQuestionRestrictionModal from './EvaluationTemplateQuestionRestrictionModal';
import EvaluationTemplateSnippetModal from './EvaluationTemplateSnippetModal';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import DragDropList from '../../common/DragDropList';
import EvaluationTemplateQuestionOptionsModal from './EvaluationTemplateQuestionOptionsModal';
import { useMediaQueryIndex } from '../../../helpers/hooks';

const MODAL_VIEW_TABS_ENUM = {
  INPUT: '1',
  RESTRICTIONS: '2',
  SNIPPETS: '3'
};

const MODAL_VIEW_TABS = [
  {
    label: 'Input',
    value: MODAL_VIEW_TABS_ENUM.INPUT
  },
  {
    label: 'Restrictions',
    value: MODAL_VIEW_TABS_ENUM.RESTRICTIONS
  },
  {
    label: 'Snippets',
    value: MODAL_VIEW_TABS_ENUM.SNIPPETS,
    metricOnly: true
  }
];

const CreateEvaluationTemplateQuestionModal = ({
  isOpen,
  onClose,
  fkRegScoutReportQuestionType,
  regScoutReportQuestion,
  regScoutReportQuestions,
  onConfirm,
  isSmallScreen
}) => {
  const mqIndex = useMediaQueryIndex();
  const isMobileView = mqIndex === 0;
  const hasInitialized = useRef(false);
  const [formState, setFormState] = useState({
    key: '',
    value: '',
    fkRegScoutReportQuestionType,
    fkRegFormControlType:
      fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
        ? REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
        : REG_FORM_CONTROL_TYPE_ENUM.SLIDER,
    regScoutReportQuestionOptions: [],
    regScoutReportQuestionSnippets: [],
    regScoutReportQuestionRestrictions: [],
    activeTab: MODAL_VIEW_TABS[0].value,
    innerModal: '',
    selectedRegScoutReportQuestionRestriction: null
  });

  const isAttribute =
    fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

  useEffect(() => {
    if (isOpen) {
      if (regScoutReportQuestion) {
        setFormState({
          key: regScoutReportQuestion.key,
          value: regScoutReportQuestion.value,
          fkRegScoutReportQuestionType:
            regScoutReportQuestion.fkRegScoutReportQuestionType,
          fkRegFormControlType: regScoutReportQuestion.fkRegFormControlType,
          regScoutReportQuestionOptions:
            regScoutReportQuestion.regScoutReportQuestionOptions,
          regScoutReportQuestionSnippets:
            regScoutReportQuestion.regScoutReportQuestionSnippets,
          regScoutReportQuestionRestrictions:
            regScoutReportQuestion.regScoutReportQuestionRestrictions,
          activeTab: MODAL_VIEW_TABS[0].value,
          innerModal: '',
          selectedRegScoutReportQuestionRestriction: null
        });
      }
      else {
        setFormState({
          key: new Date().getTime(),
          value: '',
          fkRegScoutReportQuestionType,
          fkRegFormControlType:
            fkRegScoutReportQuestionType ===
            REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
              ? REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
              : REG_FORM_CONTROL_TYPE_ENUM.SLIDER,
          regScoutReportQuestionOptions: [],
          regScoutReportQuestionSnippets: [],
          regScoutReportQuestionRestrictions: [],
          activeTab: MODAL_VIEW_TABS[0].value,
          innerModal: '',
          selectedRegScoutReportQuestionRestriction: null
        });
      }
      hasInitialized.current = true;
    }
    else {
      setFormState({
        ...formState,
        activeTab: MODAL_VIEW_TABS[0].value,
        innerModal: '',
        selectedRegScoutReportQuestionRestriction: null
      });
      hasInitialized.current = false;
    }
  }, [isOpen]);

  const onRemoveRestriction = (r) => {
    setFormState({
      ...formState,
      regScoutReportQuestionRestrictions: formState.regScoutReportQuestionRestrictions.filter(
        (f) =>
          !(
            f.conditionValue === r.conditionValue && f.condition === r.condition
          )
      )
    });
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={800}
      title={
        <AppTitle order={2}>
          {regScoutReportQuestion ? 'Update' : 'Add'}{' '}
          {isAttribute ? 'Attribute' : 'Question'}
        </AppTitle>
      }
      transitionProps={{
        duration: 0,
        transition: 'none'
      }}
    >
      <FormSection
        isLoading={formState.isLoading}
        onCancel={onClose}
        onSubmit={(e) => {
          e.stopPropagation();
          onConfirm({
            value: formState.value,
            fkRegScoutReportQuestionType:
              formState.fkRegScoutReportQuestionType,
            fkRegFormControlType: formState.fkRegFormControlType,
            regScoutReportQuestionOptions:
              formState.regScoutReportQuestionOptions,
            regScoutReportQuestionSnippets:
              formState.regScoutReportQuestionSnippets,
            regScoutReportQuestionRestrictions:
              formState.regScoutReportQuestionRestrictions
          });
        }}
        submitTitle={regScoutReportQuestion ? 'Update' : 'Add'}
      >
        <AppStack style={{ minHeight: 300 }}>
          {hasInitialized.current && (
            <>
              <TextInput
                label={isAttribute ? 'Attribute' : 'Question'}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    value: e.target.value
                  })
                }
                placeholder={
                  isAttribute
                    ? 'Height, Weight, ect.'
                    : 'Shooting, Passing, Dribbling, etc.'
                }
                required
                value={formState.value}
              />

              <TabsContent
                activeTab={formState.activeTab}
                color="blue"
                onTabChange={(value) =>
                  setFormState({
                    ...formState,
                    activeTab: value
                  })
                }
                tabs={MODAL_VIEW_TABS.filter(
                  (f) => !f.metricOnly || !isAttribute
                )}
                variant="outline"
              />

              {formState.activeTab === MODAL_VIEW_TABS_ENUM.SNIPPETS ? (
                <AppStack style={{ flex: 1, gap: 5 }}>
                  <AppFlexbox style={{ justifyContent: 'space-between' }}>
                    <AppText style={{ fontSize: 14 }} weight={500}>
                      Comment Snippets
                    </AppText>

                    <Button
                      color="blue"
                      leftSection={<Plus size={20} />}
                      onClick={() =>
                        setFormState({
                          ...formState,
                          innerModal: 'QUESTION-SNIPPETS',
                          selectedRegScoutReportQuestionRestriction: null
                        })
                      }
                      size="compact-xs"
                      variant="outline"
                    >
                      Setup Snippets
                    </Button>
                  </AppFlexbox>

                  {formState.regScoutReportQuestionSnippets.length === 0 ? (
                    <AppStack
                      style={{
                        flex: 1,
                        gap: 10,
                        padding: 30,
                        border: 'solid 1px lightgrey',
                        textAlign: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <AppText>No questions snippets</AppText>
                    </AppStack>
                  ) : (
                    <AppStack style={{ gap: 5 }}>
                      <DragDropList
                        items={formState.regScoutReportQuestionSnippets.map(
                          (s) => ({
                            key: s.value,
                            sort: s.sort,
                            value: s.value
                          })
                        )}
                        onUpdate={(newListOrder) =>
                          setFormState({
                            ...formState,
                            regScoutReportQuestionSnippets: newListOrder
                          })
                        }
                        renderOptions={({ item, index }) => (
                          <AppFlexbox
                            style={{
                              padding: 10,
                              border: 'solid 1px lightgrey',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              cursor: 'pointer',
                              height: 55
                            }}
                          >
                            <AppFlexbox
                              style={{
                                gap: 5,
                                alignItems: 'center'
                              }}
                            >
                              <ArrowsMove style={{ cursor: 'pointer' }} />
                              <AppText>
                                {index + 1}. {item.value}
                              </AppText>
                            </AppFlexbox>
                          </AppFlexbox>
                        )}
                      />
                    </AppStack>
                  )}
                </AppStack>
              ) : formState.activeTab === MODAL_VIEW_TABS_ENUM.RESTRICTIONS ? (
                <AppStack style={{ flex: 1, gap: 5 }}>
                  <EvaluationTemplateQuestionRestrictions
                    onAddRestriction={() =>
                      setFormState({
                        ...formState,
                        innerModal: 'QUESTION-RESTRICTIONS',
                        selectedRegScoutReportQuestionRestriction: null
                      })
                    }
                    onEditRestriction={(r) =>
                      setFormState({
                        ...formState,
                        innerModal: 'QUESTION-RESTRICTIONS',
                        selectedRegScoutReportQuestionRestriction: r
                      })
                    }
                    onRemoveRestriction={(r) => onRemoveRestriction(r)}
                    regScoutReportQuestion={formState}
                  />
                </AppStack>
              ) : (
                <AppStack>
                  <AppStack
                    style={{ gap: 5, maxWidth: isMobileView ? 'unset' : 300 }}
                  >
                    <AppFlexbox style={{ justifyContent: 'space-between' }}>
                      <AppText style={{ fontSize: 14 }} weight={500}>
                        Input Type
                      </AppText>
                      {formState.fkRegFormControlType !==
                        REG_FORM_CONTROL_TYPE_ENUM.SLIDER &&
                        formState.fkRegFormControlType !==
                          REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT && (
                          <Button
                            color="blue"
                            leftSection={<Edit size={20} />}
                            onClick={() =>
                              setFormState({
                                ...formState,
                                innerModal: 'QUESTION-OPTIONS',
                                selectedRegScoutReportQuestionRestriction: null
                              })
                            }
                            size="compact-xs"
                            variant="outline"
                          >
                            Edit Options
                          </Button>
                        )}
                    </AppFlexbox>
                    <Select
                      data={
                        fkRegScoutReportQuestionType ===
                        REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
                          ? [
                              {
                                label: 'Slider',
                                value: REG_FORM_CONTROL_TYPE_ENUM.SLIDER.toString()
                              },
                              {
                                label: 'Dropdown',
                                value: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN.toString()
                              },
                              {
                                label: 'Buttons',
                                value: REG_FORM_CONTROL_TYPE_ENUM.BUTTON_GROUP.toString()
                              }
                            ]
                          : [
                              {
                                label: 'Dropdown',
                                value: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN.toString()
                              },
                              {
                                label: 'Text',
                                value: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT.toString()
                              }
                            ]
                      }
                      onChange={(value) =>
                        setFormState({
                          ...formState,
                          fkRegFormControlType: Number(value)
                        })
                      }
                      value={formState.fkRegFormControlType.toString()}
                    />
                  </AppStack>

                  <Divider label="PREVIEW" labelPosition="center" />

                  <EvaluationTemplateQuestionInput
                    isEditMode
                    isSmallScreen={isSmallScreen}
                    question={formState}
                  />
                </AppStack>
              )}
            </>
          )}
        </AppStack>
      </FormSection>

      <EvaluationTemplateQuestionRestrictionModal
        isOpen={formState.innerModal === 'QUESTION-RESTRICTIONS'}
        onClose={() => setFormState({ ...formState, innerModal: '' })}
        onConfirm={(restriction, restrictedQuestions) => {
          setFormState({
            ...formState,
            regScoutReportQuestionRestrictions: [
              ...formState.regScoutReportQuestionRestrictions.filter(
                (r) =>
                  !(
                    r.condition === restriction.condition &&
                    r.conditionValue === restriction.conditionValue
                  ) &&
                  !restrictedQuestions.some(
                    (s) =>
                      s.questionRestrictedRefNumber ===
                        r.questionRestrictedRefNumber &&
                      s.conditionValue === r.conditionValue
                  )
              ),
              ...restrictedQuestions
            ],
            innerModal: ''
          });
        }}
        regScoutReportQuestion={formState}
        regScoutReportQuestionRestriction={
          formState.selectedRegScoutReportQuestionRestriction
        }
        regScoutReportQuestions={regScoutReportQuestions}
      />

      <EvaluationTemplateSnippetModal
        isOpen={formState.innerModal === 'QUESTION-SNIPPETS'}
        onClose={() => setFormState({ ...formState, innerModal: '' })}
        onConfirm={(snippets) =>
          setFormState({
            ...formState,
            regScoutReportQuestionSnippets: snippets,
            innerModal: ''
          })
        }
        regScoutReportQuestions={regScoutReportQuestions}
        selectedSnippets={formState.regScoutReportQuestionSnippets}
      />

      <EvaluationTemplateQuestionOptionsModal
        fkRegFormControlType={formState.fkRegFormControlType}
        fkRegScoutReportQuestionType={formState.fkRegScoutReportQuestionType}
        isOpen={formState.innerModal === 'QUESTION-OPTIONS'}
        onClose={() => {
          setFormState({
            ...formState,
            innerModal: ''
          });
        }}
        onConfirm={(options) => {
          setFormState({
            ...formState,
            regScoutReportQuestionOptions: options,
            regScoutReportQuestionRestrictions: formState.regScoutReportQuestionRestrictions.filter(
              (r) =>
                r.questionConditionRefNumber !== formState.key ||
                options.some((p) => p.value === r.conditionValue)
            ),
            innerModal: ''
          });
        }}
        options={formState.regScoutReportQuestionOptions}
      />
    </ResponsiveModal>
  );
};

CreateEvaluationTemplateQuestionModal.propTypes = {
  fkRegScoutReportQuestionType: PropTypes.number,
  isOpen: PropTypes.bool,
  isSmallScreen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  regScoutReportQuestion: PropTypes.object,
  regScoutReportQuestions: PropTypes.array
};

export default CreateEvaluationTemplateQuestionModal;
