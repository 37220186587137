import React from 'react';
import { Accordion, Checkbox, Divider, Grid } from '@mantine/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';

const RegPersonContactFormControls = ({
  regPerson,
  formState,
  setFormState,
  disabled
}) => {
  const onContactChange = (contactFormState, regPersonContact) => {
    if (!disabled) {
      setFormState({
        ...formState,
        regFormSubmissionPersonContacts: [
          ...formState.regFormSubmissionPersonContacts.filter(
            (f) => f.fkRegPersonContact !== regPersonContact.pkRegPersonContact
          ),
          {
            ...contactFormState,
            fkRegPersonContact: regPersonContact.pkRegPersonContact
          }
        ]
      });
    }
  };

  return (
    regPerson &&
    regPerson.regPersonContacts?.length > 0 && (
      <Grid.Col
        role="gridcell"
        span={12}
        style={{
          padding: 8,
          display: 'flex',
          justifyContent: 'start',
          placeItems: 'center',
          minHeight: 80,
          height: 'unset',
          cursor: disabled ? 'not-allowed' : 'default'
        }}
      >
        <AppStack style={{ gap: 24 }}>
          <AppStack style={{ gap: 8 }}>
            <Divider style={{ margin: '8px 0px' }} />
            <AppText style={{ fontSize: 14, fontWeight: 500, color: '#000' }}>
              Secondary contacts
            </AppText>
            <Checkbox
              checked={formState.includeRegPersonContacts}
              description="Please consider including secondary contacts for this association. Review this information carefully before proceeding, as any changes would require you to contact the association directly."
              disabled={disabled}
              label="Include secondary contacts"
              onChange={() => {
                if (
                  !formState.includeRegPersonContacts &&
                  formState.regFormSubmissionPersonContacts.length === 0
                ) {
                  setFormState({
                    ...formState,
                    includeRegPersonContacts: !formState.includeRegPersonContacts,
                    regFormSubmissionPersonContacts: regPerson.regPersonContacts?.map(
                      (c) => ({
                        fkRegPersonContact: c.pkRegPersonContact,
                        receiveAllEmails: true,
                        deleted: false,
                        expandAccordion: true
                      })
                    )
                  }) || [];
                }
                else {
                  setFormState({
                    ...formState,
                    includeRegPersonContacts: !formState.includeRegPersonContacts
                  });
                }
              }}
            />
          </AppStack>

          {formState.includeRegPersonContacts && (
            <AppCard radius={8} shadow={0} style={{ padding: 0 }} withBorder>
              <AppStack style={{ gap: 0 }}>
                {regPerson.regPersonContacts
                  ?.sort((a, b) =>
                    `${a.firstName}${a.lastName}`.localeCompare(
                      `${b.firstName}${b.lastName}`
                    )
                  )
                  .map((c, index) => {
                    const contactFormState = formState.regFormSubmissionPersonContacts.find(
                      (s) => s.fkRegPersonContact === c.pkRegPersonContact
                    );
                    const isSelected =
                      contactFormState && !contactFormState.deleted;

                    return (
                      <React.Fragment key={c.pkRegPersonContact}>
                        {index > 0 && <Divider />}

                        <Accordion
                          onChange={() => {
                            onContactChange(
                              {
                                ...contactFormState,
                                expandAccordion: !contactFormState?.expandAccordion
                              },
                              c
                            );
                          }}
                          styles={{
                            control: { padding: 0 },
                            label: { padding: 0 },
                            chevron: {
                              marginRight: 16,
                              display: !contactFormState?.deleted
                                ? 'flex'
                                : 'none'
                            },
                            item: { border: 'none' }
                          }}
                          value={
                            !contactFormState?.deleted &&
                            contactFormState?.expandAccordion
                              ? '0'
                              : ''
                          }
                        >
                          <Accordion.Item value="0">
                            <Accordion.Control>
                              <AppFlexbox
                                style={{
                                  gap: 0,
                                  padding: '8px 16px',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  cursor: disabled ? 'not-allowed' : 'pointer'
                                }}
                              >
                                <AppFlexbox
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onContactChange(
                                      {
                                        ...contactFormState,
                                        deleted: isSelected,
                                        expandAccordion: !isSelected
                                      },
                                      c
                                    );
                                  }}
                                  style={{ gap: 16, alignItems: 'center' }}
                                >
                                  <Checkbox
                                    checked={isSelected || false}
                                    onChange={() => {}}
                                    style={{ pointerEvents: 'none' }}
                                  />

                                  <AppStack
                                    style={{
                                      gap: 0,
                                      overflow: 'hidden',
                                      maxWidth: '50vw'
                                    }}
                                  >
                                    <AppText
                                      style={{ fontSize: 14, fontWeight: 500 }}
                                    >
                                      {c.firstName} {c.lastName}
                                    </AppText>
                                    <AppText
                                      style={{ fontSize: 14, color: '#666' }}
                                    >
                                      {c.email}
                                    </AppText>
                                    {c.phone && (
                                      <AppText
                                        style={{ fontSize: 14, color: '#666' }}
                                      >
                                        <NumberFormat
                                          displayType="text"
                                          format="+1 (###) ###-####"
                                          mask="_"
                                          prefix=", "
                                          value={c.phone}
                                        />
                                      </AppText>
                                    )}
                                  </AppStack>
                                </AppFlexbox>
                              </AppFlexbox>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <AppStack
                                style={{ marginLeft: 36, paddingTop: 8 }}
                              >
                                <Checkbox
                                  checked={
                                    contactFormState?.receiveAllEmails || false
                                  }
                                  label="Include in all emails"
                                  onChange={() => {
                                    onContactChange(
                                      {
                                        ...contactFormState,
                                        receiveAllEmails: !contactFormState?.receiveAllEmails
                                      },
                                      c
                                    );
                                  }}
                                />
                              </AppStack>
                            </Accordion.Panel>
                          </Accordion.Item>
                        </Accordion>
                      </React.Fragment>
                    );
                  })}
              </AppStack>
            </AppCard>
          )}
        </AppStack>
      </Grid.Col>
    )
  );
};

RegPersonContactFormControls.propTypes = {
  disabled: PropTypes.bool,
  formState: PropTypes.object,
  regPerson: PropTypes.object,
  setFormState: PropTypes.func
};

export default RegPersonContactFormControls;
