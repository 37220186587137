import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Radio,
  Select,
  Switch,
  Textarea,
  TextInput
} from '@mantine/core';
import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { AlertCircle, Calendar, Pencil, Plus, X } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import { formatUtcDate } from '../../../helpers/format';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import TabsContent from '../../common/TabsContent';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import FormControlSelector from './FormControlSelector';
import FormControlPreview from './FormControlPreview';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import ActionableIcon from '../../common/ActionableIcon';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';
import AppTextEditor from '../../common/AppTextEditor';
import { tryJsonParse } from '../../../helpers/storageHelper';

const inputOptions = [
  {
    title: 'Text',
    type: 'TEXT',
    component: AppText,
    constraints: {
      textAlign: 'start',
      fontSize: '16px'
    }
  },
  {
    title: 'Text Input',
    type: 'TEXT_INPUT',
    component: TextInput,
    constraints: { allowNumbers: 'true', allowLetters: 'true' }
  },
  {
    title: 'Drop down',
    type: 'DROP_DOWN',
    component: Select,
    constraints: {}
  },
  {
    title: 'Date',
    type: 'DATE',
    component: DatePickerInput,
    constraints: {}
  },
  {
    title: 'Radio',
    type: 'RADIO_GROUP',
    component: Radio.Group,
    constraints: {}
  },
  {
    title: 'Checkbox',
    type: 'CHECKBOX',
    component: Checkbox,
    constraints: {}
  },
  {
    title: 'File Download',
    type: 'FILE_DOWNLOAD',
    component: null,
    constraints: {}
  },
  {
    title: 'File Upload',
    type: 'FILE_UPLOAD',
    component: null,
    constraints: {}
  },
  {
    title: 'Signature',
    type: 'SIGNATURE',
    component: null,
    constraints: {}
  }
];

const FORM_CONTROL_TABS = [
  {
    label: 'General',
    value: 'GENERAL'
  },
  {
    label: 'Constraints',
    value: 'CONSTRAINTS'
  },
  {
    label: 'Products',
    value: 'PRODUCTS'
  }
];

const FormControlModal = ({
  pkRegFormSection,
  isOpen,
  onClose,
  control,
  onAdd,
  onUpdate,
  onRemove,
  onDocumentUpload,
  lowestCostDivisionForm,
  lowestPosibleRegistrationCost
}) => {
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const { state } = useContext(RegistrationAdminContext);
  const mqIndex = useMediaQueryIndex();
  const fileUploadRef = useRef(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formState, setFormState] = useState({
    title: '',
    content: '',
    tooltip: '',
    type: '',
    options: [],
    constraints: {},
    allowDiscountProducts: false,
    documents: [],
    uploading: false,
    product: null,
    inputComponent: null,
    showLegacy: true,
    error: ''
  });
  const [settingsTab, setSettingsTab] = useState(FORM_CONTROL_TABS[0].value);

  const defaultTabs = [FORM_CONTROL_TABS[0]];
  const constraintTabs =
    formState.type !== 'FILE_DOWNLOAD' ? [FORM_CONTROL_TABS[1]] : [];
  const productTabs =
    formState.type === 'DROP_DOWN' ||
    formState.type === 'RADIO_GROUP' ||
    formState.type === 'CHECKBOX'
      ? [FORM_CONTROL_TABS[2]]
      : [];
  const adminRegAssociation = state.regAssociations.value.find(
    (a) =>
      a.pkRegAssociation.toString() ===
      dashboardState.regAssociation.value?.pkRegAssociation.toString()
  );
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      adminRegAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;
  const newTextControl = formState.type === 'TEXT' && !formState.showLegacy;

  useEffect(() => {
    if (isOpen) {
      if (control) {
        setFormState({
          title: control.title,
          content: control.content,
          tooltip: control.tooltip,
          type: control.type,
          constraints: control.constraints.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item.value }),
            {}
          ),
          allowDiscountProducts:
            control.product?.price < 0 ||
            control.options.some((p) => p.product?.price < 0),
          options: control.options,
          product: control.product,
          documents: control.documents.map((d) => ({
            fileName: d.fileName,
            url: d.url
          })),
          inputComponent: inputOptions.find((i) => i.type === control.type)
            .component,
          showLegacy: !control.content,
          error: ''
        });
      }
      else {
        setFormState({
          title: '',
          tooltip: '',
          type: '',
          options: [],
          constraints: {},
          allowDiscountProducts: false,
          product: null,
          documents: [],
          uploading: false,
          inputComponent: null,
          showLegacy: false,
          error: ''
        });
      }
      setSettingsTab(FORM_CONTROL_TABS[0].value);
    }
    else {
      setShowDeleteConfirmation(false);
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={showDeleteConfirmation ? 600 : 1000}
      title={
        <AppTitle
          style={{
            fontSize: rs([25, 34], mqIndex),
            textAlign: rs(['start', 'center'], mqIndex)
          }}
        >
          {formState.inputComponent
            ? showDeleteConfirmation
              ? 'Delete Control'
              : 'Edit Control'
            : 'Create New Control'}
        </AppTitle>
      }
    >
      {!formState.type ? (
        <FormControlSelector
          inputOptions={inputOptions}
          onSelect={(option) =>
            setFormState({
              ...formState,
              type: option.type,
              constraints: option.constraints,
              options:
                option.type === 'RADIO_GROUP'
                  ? [
                      {
                        pkRegFormControlOption: 'temp-option-1',
                        option: 'Option 1',
                        positionOrder: 0
                      },
                      {
                        pkRegFormControlOption: 'temp-option-2',
                        option: 'Option 2',
                        positionOrder: 1
                      }
                    ]
                  : [],
              inputComponent: option.component,
              error: ''
            })
          }
        />
      ) : !showDeleteConfirmation ? (
        <FormSection
          onCancel={onClose}
          onSubmit={() => {
            const controlContent = newTextControl
              ? formState.content
              : formState.title;
            if (!controlContent) {
              setSettingsTab(FORM_CONTROL_TABS[0].value);
              setFormState({
                ...formState,
                error: `${
                  formState.type === 'TEXT' ? 'Message' : 'Label'
                } is Required`
              });
            }
            else if (
              formState.type === 'FILE_DOWNLOAD' &&
              !formState.documents[0]
            ) {
              setSettingsTab(FORM_CONTROL_TABS[0].value);
              setFormState({
                ...formState,
                error: 'File upload required'
              });
            }
            else if (
              formState.constraints.isProduct === 'true' &&
              formState.options.findIndex(
                (f) =>
                  f.option &&
                  (!f.product?.name ||
                    (!f.product?.price && f.product?.price !== 0))
              ) !== -1
            ) {
              setSettingsTab(FORM_CONTROL_TABS[2].value);
              setFormState({
                ...formState,
                error: 'Product name and price are required.'
              });
            }
            else {
              let constraints = [];
              for (const [key, value] of Object.entries(
                formState.constraints
              )) {
                if (value) {
                  constraints = [
                    ...constraints,
                    {
                      name: key,
                      value: value.toString()
                    }
                  ];
                }
              }
              const controlData = {
                ...formState,
                title: newTextControl ? '' : formState.title,
                content: newTextControl ? formState.content : '',
                options: formState.options.filter((f) => f.option),
                constraints
              };
              if (control) {
                onUpdate(control.pkRegFormControl, controlData);
              }
              else {
                onAdd(pkRegFormSection, controlData);
              }
              onClose();
            }
          }}
          submitTitle="Save Changes"
        >
          {formState.type === 'TEXT' ? (
            <Divider />
          ) : (
            <TabsContent
              activeTab={settingsTab}
              color="blue"
              onTabChange={(tabIndex) => {
                setFormState({
                  ...formState,
                  error: ''
                });
                setSettingsTab(tabIndex);
              }}
              tabs={[...defaultTabs, ...constraintTabs, ...productTabs]}
            />
          )}

          <AppFlexbox
            style={{
              alignItems: 'start',
              gap: 40,
              flexDirection: rs(['column', 'column', 'row'], mqIndex)
            }}
          >
            {settingsTab === FORM_CONTROL_TABS[0].value ? (
              <AppStack style={{ flex: 1, gap: 20, alignSelf: 'stretch' }}>
                {formState.type === 'TEXT' ? (
                  <>
                    <AppFlexbox>
                      <Button
                        color="dark"
                        onClick={() => {
                          setFormState({
                            ...formState,
                            showLegacy: !formState.showLegacy
                          });
                        }}
                        variant="outline"
                      >
                        {formState.showLegacy
                          ? 'Switch to new rich text'
                          : 'Switch to legacy text'}
                      </Button>
                    </AppFlexbox>

                    {formState.showLegacy ? (
                      <>
                        <Textarea
                          autosize
                          label="Message"
                          minRows={6}
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              title: e.currentTarget.value.substring(0, 1000),
                              error: ''
                            })
                          }
                          required
                          value={formState.title}
                        />
                        <Select
                          data={[
                            { value: '10px', label: '10' },
                            { value: '12px', label: '12' },
                            { value: '14px', label: '14' },
                            { value: '16px', label: '16' },
                            { value: '18px', label: '18' },
                            { value: '20px', label: '20' },
                            { value: '22px', label: '22' },
                            { value: '24px', label: '24' }
                          ]}
                          label="Font Size"
                          onChange={(value) =>
                            setFormState({
                              ...formState,
                              constraints: {
                                ...formState.constraints,
                                fontSize: value
                              },
                              error: ''
                            })
                          }
                          value={formState.constraints.fontSize}
                        />
                        <Select
                          data={[
                            { value: 'start', label: 'Left' },
                            { value: 'center', label: 'Center' },
                            { value: 'end', label: 'Right' }
                          ]}
                          label="Align Text"
                          onChange={(value) =>
                            setFormState({
                              ...formState,
                              constraints: {
                                ...formState.constraints,
                                textAlign: value
                              },
                              error: ''
                            })
                          }
                          value={formState.constraints.textAlign}
                        />
                        <AppFlexbox
                          style={{
                            gap: 20,
                            flexDirection: rs(['column', 'row'], mqIndex),
                            marginTop: 5
                          }}
                        >
                          <Checkbox
                            checked={formState.constraints.bold === 'true'}
                            color="blue"
                            label="Bold"
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                constraints: {
                                  ...formState.constraints,
                                  bold: e.currentTarget.checked.toString()
                                },
                                error: ''
                              })
                            }
                          />
                          <Checkbox
                            checked={formState.constraints.italicize === 'true'}
                            color="blue"
                            label="Italicize"
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                constraints: {
                                  ...formState.constraints,
                                  italicize: e.currentTarget.checked.toString()
                                },
                                error: ''
                              })
                            }
                          />
                          <Checkbox
                            checked={formState.constraints.underline === 'true'}
                            color="blue"
                            label="Underline"
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                constraints: {
                                  ...formState.constraints,
                                  underline: e.currentTarget.checked.toString()
                                },
                                error: ''
                              })
                            }
                          />
                        </AppFlexbox>
                      </>
                    ) : (
                      <AppTextEditor
                        limitReachedMessage="You've reached the 1,000-character limit. To continue writing, please create a new text control and place it below."
                        onChange={(v) => {
                          setFormState({
                            ...formState,
                            content: JSON.stringify(v),
                            error: ''
                          });
                        }}
                        value={tryJsonParse(formState.content)}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <TextInput
                      label="Label"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          title: e.currentTarget.value.substring(0, 75),
                          error: ''
                        })
                      }
                      required
                      value={formState.title}
                    />
                    <Textarea
                      label="Tooltip"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          tooltip:
                            e.currentTarget.value.length > 255
                              ? e.currentTarget.value.substring(0, 255)
                              : e.currentTarget.value,
                          error: ''
                        })
                      }
                      value={formState.tooltip}
                    />
                    {formState.type === 'FILE_DOWNLOAD' && (
                      <AppFlexbox style={{ flex: 1, gap: 0 }}>
                        <TextInput
                          disabled
                          style={{ flex: 1 }}
                          styles={{
                            input: {
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0
                            }
                          }}
                          value={
                            formState.documents[0]?.fileName ??
                            'No file uploaded'
                          }
                        />
                        <Button
                          color="dark"
                          loading={formState.uploading}
                          onClick={() => fileUploadRef.current.click()}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                          }}
                        >
                          Upload File
                        </Button>
                        <input
                          ref={fileUploadRef}
                          data-testid="fileInput"
                          hidden
                          onChange={(e) => {
                            const fileSize =
                              e.target.files[0].size / 1024 / 1024;
                            if (fileSize > 50) {
                              setFormState({
                                ...formState,
                                error: 'File exceeds maximum size of 50MB'
                              });
                            }
                            else {
                              setFormState({
                                ...formState,
                                uploading: true,
                                error: ''
                              });
                              uploadFileToStorage(
                                `${e.target.files[0].name.split('.')[0]}-`,
                                e.target.files[0],
                                (url, fileName) => {
                                  onDocumentUpload(formState.documents[0]);
                                  setFormState({
                                    ...formState,
                                    documents: [{ fileName, url }],
                                    uploading: false,
                                    error: ''
                                  });
                                },
                                () => {
                                  setFormState({
                                    ...formState,
                                    uploading: false,
                                    error: 'Oops something went wrong.'
                                  });
                                }
                              );
                            }
                          }}
                          type="file"
                        />
                      </AppFlexbox>
                    )}
                    {(formState.type === 'DROP_DOWN' ||
                      formState.type === 'RADIO_GROUP') && (
                      <AppStack style={{ gap: 10 }}>
                        <AppFlexbox style={{ justifyContent: 'space-between' }}>
                          <AppText style={{ fontSize: 14 }} weight={500}>
                            Options
                          </AppText>
                          {(formState.type !== 'RADIO_GROUP' ||
                            formState.options.length < 6) && (
                            <ActionableIcon
                              color="green"
                              onClick={() => {
                                setFormState({
                                  ...formState,
                                  options: [
                                    ...formState.options,
                                    {
                                      pkRegFormControlOption: `temp-option-${new Date().getTime()}`,
                                      option: '',
                                      positionOrder: formState.options.length
                                    }
                                  ]
                                });
                              }}
                              variant="subtle"
                            >
                              <Plus />
                            </ActionableIcon>
                          )}
                        </AppFlexbox>
                        <AppStack
                          style={{
                            gap: 0,
                            maxHeight: 220,
                            overflow: 'auto',
                            border: '1px solid #ced4da'
                          }}
                        >
                          {formState.options.length > 0 ? (
                            formState.options
                              .sort((a, b) => a.positionOrder - b.positionOrder)
                              .map((option) => (
                                <TextInput
                                  key={option.pkRegFormControlOption}
                                  icon={<Pencil />}
                                  onChange={(e) => {
                                    const optionsCopy = [...formState.options];

                                    const changeOption = optionsCopy.find(
                                      (p) =>
                                        p.pkRegFormControlOption ===
                                        option.pkRegFormControlOption
                                    );
                                    changeOption.option = e.currentTarget.value;
                                    setFormState({
                                      ...formState,
                                      options: optionsCopy
                                    });
                                  }}
                                  placeholder="Click to edit option"
                                  radius={0}
                                  rightSection={
                                    <ActionableIcon
                                      color="red"
                                      onClick={() => {
                                        const optionsCopy = [
                                          ...formState.options
                                        ].filter(
                                          (f) =>
                                            f.pkRegFormControlOption !==
                                            option.pkRegFormControlOption
                                        );
                                        for (const optionCopy of optionsCopy) {
                                          if (
                                            optionCopy.positionOrder >
                                            option.positionOrder
                                          ) {
                                            optionCopy.positionOrder -= 1;
                                          }
                                        }
                                        setFormState({
                                          ...formState,
                                          options: formState.options.filter(
                                            (f) =>
                                              f.pkRegFormControlOption !==
                                              option.pkRegFormControlOption
                                          )
                                        });
                                      }}
                                      variant="subtle"
                                    >
                                      <X />
                                    </ActionableIcon>
                                  }
                                  style={{ borderTop: 'none' }}
                                  value={option.option}
                                />
                              ))
                          ) : (
                            <AppText
                              style={{
                                padding: '30px 10px',
                                textAlign: 'center'
                              }}
                            >
                              No options created.
                            </AppText>
                          )}
                        </AppStack>
                      </AppStack>
                    )}
                  </>
                )}
              </AppStack>
            ) : settingsTab === FORM_CONTROL_TABS[1].value ? (
              <AppStack style={{ flex: 1, gap: 20, alignSelf: 'stretch' }}>
                <Checkbox
                  checked={formState.constraints.required === 'true'}
                  color="blue"
                  label="Required"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      constraints: {
                        ...formState.constraints,
                        required: e.currentTarget.checked.toString()
                      },
                      error: ''
                    })
                  }
                />
                {formState.type === 'TEXT_INPUT' && (
                  <>
                    <Checkbox
                      checked={formState.constraints.allowLetters === 'true'}
                      color="blue"
                      label="Allow Letters"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          constraints: {
                            ...formState.constraints,
                            allowLetters: e.currentTarget.checked.toString()
                          },
                          error: ''
                        })
                      }
                    />
                    <Checkbox
                      checked={formState.constraints.allowNumbers === 'true'}
                      color="blue"
                      label="Allow Numbers"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          constraints: {
                            ...formState.constraints,
                            allowNumbers: e.currentTarget.checked.toString()
                          },
                          error: ''
                        })
                      }
                    />
                  </>
                )}
                {formState.type === 'DATE' && (
                  <AppFlexbox
                    style={{
                      flex: 1,
                      flexDirection: rs(['column', 'row'], mqIndex),
                      flexWrap: 'nowrap'
                    }}
                  >
                    <DatePickerInput
                      clearable
                      label="Min Date"
                      leftSection={<Calendar color="#000" size={16} />}
                      onChange={(value) =>
                        setFormState({
                          ...formState,
                          constraints: {
                            ...formState.constraints,
                            minDate: value,
                            maxDate:
                              value > formState.constraints.maxDate
                                ? value
                                : formState.constraints.maxDate
                          },
                          error: ''
                        })
                      }
                      style={{ flex: 1, alignSelf: 'stretch' }}
                      value={
                        formState.constraints.minDate &&
                        new Date(formatUtcDate(formState.constraints.minDate))
                      }
                    />
                    <DatePickerInput
                      clearable
                      label="Max Date"
                      leftSection={<Calendar color="#000" size={16} />}
                      onChange={(value) =>
                        setFormState({
                          ...formState,
                          constraints: {
                            ...formState.constraints,
                            maxDate: value,
                            minDate:
                              value < formState.constraints.minDate
                                ? value
                                : formState.constraints.minDate
                          },
                          error: ''
                        })
                      }
                      style={{ flex: 1, alignSelf: 'stretch' }}
                      value={
                        formState.constraints.maxDate &&
                        new Date(formatUtcDate(formState.constraints.maxDate))
                      }
                    />
                  </AppFlexbox>
                )}
              </AppStack>
            ) : settingsTab === FORM_CONTROL_TABS[2].value ? (
              <AppStack style={{ flex: 1, gap: 20, alignSelf: 'stretch' }}>
                <Checkbox
                  checked={formState.constraints.isProduct === 'true'}
                  color="blue"
                  label="This is a Product"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      constraints: {
                        ...formState.constraints,
                        isProduct: e.currentTarget.checked.toString()
                      },
                      error: ''
                    })
                  }
                />

                {formState.constraints.isProduct === 'true' && (
                  <AppStack>
                    <Checkbox
                      checked={formState.allowDiscountProducts}
                      color="blue"
                      label="Allow Discount Products"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          allowDiscountProducts: e.currentTarget.checked,
                          error: ''
                        })
                      }
                    />
                    <AppStack style={{ gap: 0, border: '1px solid #ced4da' }}>
                      <AppFlexbox style={{ gap: 0 }}>
                        <Box style={{ flex: 3 }}>
                          <AppText style={{ margin: 10 }}>Option</AppText>
                        </Box>
                        <Box
                          style={{
                            flex: 3,
                            borderLeft: '1px solid #ced4da'
                          }}
                        >
                          <AppText style={{ margin: 10 }}>Product Name</AppText>
                        </Box>
                        <Box
                          style={{
                            flex: 2,
                            borderLeft: '1px solid #ced4da'
                          }}
                        >
                          <AppText style={{ margin: 10 }}>Price</AppText>
                        </Box>
                      </AppFlexbox>
                      {formState.type === 'CHECKBOX' ? (
                        <AppFlexbox
                          style={{
                            gap: 0,
                            borderTop: '1px solid #ced4da',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            style={{
                              flex: 3,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <AppText
                              style={{
                                margin: '0px 10px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {formState.title}
                            </AppText>
                          </Box>
                          <Box
                            style={{
                              flex: 3,
                              borderLeft: '1px solid #ced4da'
                            }}
                          >
                            <TextInput
                              icon={<Pencil />}
                              onChange={(e) =>
                                setFormState({
                                  ...formState,
                                  product: {
                                    ...formState.product,
                                    name: e.currentTarget.value
                                  },
                                  error: ''
                                })
                              }
                              placeholder="Example Name"
                              radius={0}
                              required
                              styles={{ input: { border: 'none' } }}
                              value={formState.product?.name ?? ''}
                            />
                          </Box>
                          <Box
                            style={{
                              flex: 2,
                              borderLeft: '1px solid #ced4da'
                            }}
                          >
                            <NumberFormat
                              allowNegative={formState.allowDiscountProducts}
                              customInput={TextInput}
                              decimalScale={2}
                              fixedDecimalScale
                              icon={<Pencil />}
                              onValueChange={(e) => {
                                setFormState({
                                  ...formState,
                                  product: {
                                    ...formState.product,
                                    price: e.floatValue
                                  },
                                  error: ''
                                });
                              }}
                              placeholder="$0.00"
                              prefix="$"
                              required
                              rightSection={
                                currency && (
                                  <AppText
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 500,
                                      color: '#999',
                                      paddingRight: 5
                                    }}
                                  >
                                    {currency}
                                  </AppText>
                                )
                              }
                              styles={{ input: { border: 'none' } }}
                              thousandSeparator
                              value={formState.product?.price ?? ''}
                            />
                          </Box>
                        </AppFlexbox>
                      ) : (
                        formState.options
                          .sort((a, b) => a.positionOrder - b.positionOrder)
                          .map((option) => (
                            <AppFlexbox
                              key={option.pkRegFormControlOption}
                              style={{
                                gap: 0,
                                borderTop: '1px solid #ced4da',
                                alignItems: 'center'
                              }}
                            >
                              <Box
                                style={{
                                  flex: 3,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                <AppText
                                  style={{
                                    margin: '0px 10px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {option.option}
                                </AppText>
                              </Box>
                              <Box
                                style={{
                                  flex: 3,
                                  borderLeft: '1px solid #ced4da'
                                }}
                              >
                                <TextInput
                                  icon={<Pencil />}
                                  onChange={(e) => {
                                    const updatedOption = {
                                      ...option,
                                      product: {
                                        ...option.product,
                                        name: e.currentTarget.value
                                      }
                                    };
                                    setFormState({
                                      ...formState,
                                      options: [
                                        ...formState.options.filter(
                                          (f) =>
                                            f.pkRegFormControlOption !==
                                            option.pkRegFormControlOption
                                        ),
                                        updatedOption
                                      ],
                                      error: ''
                                    });
                                  }}
                                  placeholder="Example Name"
                                  radius={0}
                                  required
                                  styles={{ input: { border: 'none' } }}
                                  value={option.product?.name ?? ''}
                                />
                              </Box>
                              <Box
                                style={{
                                  flex: 2,
                                  borderLeft: '1px solid #ced4da'
                                }}
                              >
                                <NumberFormat
                                  allowNegative={
                                    formState.allowDiscountProducts
                                  }
                                  customInput={TextInput}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  icon={<Pencil />}
                                  onValueChange={(e) => {
                                    const updatedOption = {
                                      ...option,
                                      product: {
                                        ...option.product,
                                        price: e.floatValue
                                      }
                                    };
                                    setFormState({
                                      ...formState,
                                      options: [
                                        ...formState.options.filter(
                                          (f) =>
                                            f.pkRegFormControlOption !==
                                            option.pkRegFormControlOption
                                        ),
                                        updatedOption
                                      ],
                                      error: ''
                                    });
                                  }}
                                  placeholder="$0.00"
                                  prefix="$"
                                  required
                                  rightSection={
                                    currency && (
                                      <AppText
                                        style={{
                                          fontSize: 14,
                                          fontWeight: 500,
                                          color: '#999',
                                          paddingRight: 5
                                        }}
                                      >
                                        {currency}
                                      </AppText>
                                    )
                                  }
                                  styles={{ input: { border: 'none' } }}
                                  thousandSeparator
                                  value={option.product?.price ?? ''}
                                />
                              </Box>
                            </AppFlexbox>
                          ))
                      )}
                    </AppStack>
                  </AppStack>
                )}
              </AppStack>
            ) : null}

            {!newTextControl && (
              <>
                <AppStack
                  style={{
                    alignSelf: 'stretch',
                    boxSizing: 'border-box',
                    borderLeft: rs(
                      ['none', 'none', '1px solid #ced4da'],
                      mqIndex
                    ),
                    borderTop: rs(
                      ['1px solid #ced4da', '1px solid #ced4da', 'none'],
                      mqIndex
                    )
                  }}
                />

                <FormControlPreview formState={formState} />
              </>
            )}
          </AppFlexbox>

          {formState.allowDiscountProducts && lowestCostDivisionForm && (
            <AppStack style={{ placeItems: 'center' }}>
              <Alert
                color="orange"
                icon={<AlertCircle size={24} />}
                radius="xs"
                style={{ maxWidth: 550, width: '100%' }}
                variant="outline"
              >
                <AppStack>
                  <AppText>
                    This control allows discount products that reduce the total
                    cost of the registration.
                  </AppText>
                  <AppText weight={500}>
                    The lowest total cost is ${lowestPosibleRegistrationCost}{' '}
                    for the division '
                    {lowestCostDivisionForm.regAssociationDivision.name}'
                  </AppText>
                </AppStack>
              </Alert>
            </AppStack>
          )}

          {formState.title && formState.title.length >= 1000 && (
            <Alert
              color="#eca70a"
              icon={<AlertCircle color="#eca70a" />}
              variant="outline"
            >
              <AppText style={{ fontSize: 14 }}>
                You've reached the 1,000-character limit. To continue writing,
                please create a new text control and place it below.
              </AppText>
            </Alert>
          )}

          {formState.error && (
            <AppStack style={{ placeItems: 'center' }}>
              <Alert
                color="red"
                icon={<AlertCircle size={16} />}
                radius="xs"
                style={{ maxWidth: 425, width: '100%' }}
                variant="filled"
              >
                {formState.error}
              </Alert>
            </AppStack>
          )}

          {settingsTab === FORM_CONTROL_TABS[0].value && control && (
            <>
              <Divider />
              <Button
                color="red"
                disabled={formState.uploading}
                onClick={() => setShowDeleteConfirmation(true)}
                style={{ width: '100%', alignSelf: 'center', maxWidth: 250 }}
              >
                Delete Control
              </Button>
            </>
          )}
        </FormSection>
      ) : (
        <FormSection
          onCancel={() => setShowDeleteConfirmation(false)}
          onSubmit={() => {
            onRemove(control.pkRegFormControl);
            onClose();
          }}
          submitColor="red"
          submitTitle=" Delete Control"
        >
          <AppText style={{ textAlign: 'center' }}>
            Are you sure you want to delete {control.title}?
          </AppText>
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

FormControlModal.propTypes = {
  control: PropTypes.object,
  isOpen: PropTypes.bool,
  lowestCostDivisionForm: PropTypes.object,
  lowestPosibleRegistrationCost: PropTypes.number,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  onDocumentUpload: PropTypes.func,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  pkRegFormSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FormControlModal;
