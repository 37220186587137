import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Menu } from '@mantine/core';
import { Dots, Pencil } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import AppCard from '../../common/AppCard';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import { buildQueryString, formatUtcDate } from '../../../helpers/format';
import RegistrationListActionModals from './RegistrationListActionModals';
import {
  REG_EXTERNAL_CONNECTION_TYPE_ENUM,
  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM
} from '../../../config/constants';
import ColoredAvatar from '../../common/ColoredAvatar';
import { getLeagueSelectOptions } from '../../../helpers/leagueHooks';
import TableView from '../../common/TableView';
import MenuContent from '../../common/MenuContent';
import AppStack from '../../common/AppStack';
import TableSelectConfirmModal from '../../common/TableSelectConfirmModal';

const VIEW_ACTIONS = [
  {
    label: 'Detailed view',
    value: 'details',
    isVisable: () => true
  },
  {
    label: 'View form',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.view,
    isVisable: () => true
  },
  {
    label: 'Order Summary',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.orderSummary,
    isVisable: () => true
  },
  {
    label: 'Email Contact',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.emailUser,
    isVisable: () => true
  },
  {
    label: 'Change Email',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeOverrideEmail,
    isVisable: () => true
  },
  {
    label: 'Change Division',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeDivision,
    isVisable: (s) => s.status !== 'REFUNDED'
  },
  {
    label: 'Invoice User',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.invoice,
    isVisable: (s) => s.status !== 'REFUNDED'
  },
  {
    label: 'Refund',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.refund,
    isVisable: (s) => s.status === 'ACCEPTED'
  },
  {
    label: 'Reinstate',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.reinstate,
    isVisable: (s) => s.status === 'DENIED'
  },
  {
    label: 'Deny',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.deny,
    isVisable: (s) =>
      s.status === 'ACCEPTED' ||
      s.status === 'PENDING' ||
      s.status === 'PENDING_PAYMENT'
  },
  {
    label: 'Record Payment',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
    isVisable: (s) =>
      s.status === 'ACCEPTED' ||
      s.status === 'PENDING' ||
      s.status === 'PENDING_PAYMENT'
  },
  {
    label: 'View Payments',
    value: REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
    isVisable: (s) =>
      s.status !== 'ACCEPTED' &&
      s.status !== 'PENDING' &&
      s.status !== 'PENDING_PAYMENT'
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Season',
    value: 'season',
    sortable: true
  },
  {
    label: 'Division',
    value: 'division',
    sortable: true
  },
  {
    label: 'Form',
    value: 'form',
    sortable: true
  },
  {
    label: 'Status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Hockey Canada ID',
    value: 'hockey_canada_id',
    sortable: false,
    getIsVisable: ({ hasHockeyCanadaIntegration }) => hasHockeyCanadaIntegration
  },
  {
    label: 'Date Purchased',
    value: 'date',
    sortable: true
  }
];

const FinancialRegistrations = ({ isLoading, regAssociation }) => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const searchRef = useRef('');
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [selectedRowsState, setSelectedRowsState] = useState({
    selectedRows: [],
    showConfirm: false,
    confirmAction: null
  });
  const { state, fetchPaginatedRegFormSubmissions } = useContext(
    RegistrationAdminContext
  );
  const {
    seasonOptions,
    divisionOptions,
    formOptions,
    unfilteredDivisionOptions
  } = getLeagueSelectOptions(state.regAssociation.value);
  const { customFilterData } = useSortByFilter(
    TABLE_COLUMNS.filter((c) => c.sortable)
  );
  const {
    pageIndex,
    totalCount,
    data: regFormSubmissions,
    filter,
    totalNoFilterCount
  } = state.paginatedRegFormSubmissions;
  const currentFilter = filter || {};
  const isDescendingSort = currentFilter.orderBy === 'desc';

  const loading =
    !hasFetched.current ||
    isLoading ||
    state.paginatedRegFormSubmissions.loading;

  const selectedData = regFormSubmissions.filter((s) =>
    selectedRowsState.selectedRows.includes(s.pkRegFormSubmission)
  );

  const hasHockeyCanadaIntegration =
    regAssociation?.regAssociationExternalConnections?.some(
      (c) =>
        c.fkRegExternalConnectionType.toString() ===
        REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA
    ) || false;

  useEffect(() => {
    if (regAssociation) {
      fetchPaginatedRegFormSubmissions(regAssociation.pkRegAssociation, {
        orderBy: currentFilter.orderBy,
        sortBy: TABLE_COLUMNS[0].value
      });
      hasFetched.current = true;
    }
  }, [regAssociation]);

  const onFetchMore = () => {
    fetchPaginatedRegFormSubmissions(regAssociation.pkRegAssociation, {
      ...currentFilter,
      page: pageIndex + 1,
      appendResults: true,
      skipLoading: true
    });
  };

  const onFilter = (newFilter) => {
    setSelectedRowsState({ selectedRows: [], showConfirm: false });
    fetchPaginatedRegFormSubmissions(
      regAssociation.pkRegAssociation,
      newFilter
    );
  };

  return (
    <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
      <TableView
        columns={TABLE_COLUMNS.filter(
          (f) =>
            !f.getIsVisable || f.getIsVisable({ hasHockeyCanadaIntegration })
        )}
        emptyMessage="No Registrations Available"
        filters={[
          {
            key: 2,
            label: 'Season',
            type: 'select',
            value: currentFilter.season || null,
            placeholder: 'Filter by season',
            searchable: true,
            clearable: true,
            data: seasonOptions,
            onChange: (value) => {
              if (value !== currentFilter.season) {
                const divisionOption = value
                  ? unfilteredDivisionOptions
                      .filter(
                        (d) =>
                          d.fkRegAssociationSeason.toString() === value &&
                          d.pkRegAssociationDivision.toString() ===
                            currentFilter.division
                      )[0]
                      ?.pkRegAssociationDivision.toString()
                  : currentFilter.division;

                onFilter({
                  ...currentFilter,
                  season: value,
                  division: divisionOption ?? null
                });
              }
            }
          },
          {
            key: 3,
            label: 'Division',
            type: 'select',
            value: currentFilter.division || null,
            placeholder: 'Filter by division',
            searchable: true,
            clearable: true,
            data: divisionOptions.filter(
              (d) =>
                !currentFilter.season ||
                d.fkRegAssociationSeason === currentFilter.season
            ),
            onChange: (value) =>
              onFilter({
                ...currentFilter,
                division: value
              })
          },
          {
            key: 4,
            label: 'Form',
            type: 'select',
            value: currentFilter.form || null,
            placeholder: 'Filter by form',
            searchable: true,
            clearable: true,
            data: formOptions,
            onChange: (value) =>
              onFilter({
                ...currentFilter,
                form: value
              })
          },
          {
            key: 5,
            label: 'Status',
            type: 'select',
            value: currentFilter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: '2', label: 'Accepted' },
              { value: '3', label: 'Denied' },
              { value: '5', label: 'Pending' },
              { value: '4', label: 'Refunded' }
            ],
            onChange: (value) =>
              onFilter({
                ...currentFilter,
                status: value
              })
          },
          ...(hasHockeyCanadaIntegration
            ? [
                {
                  key: 6,
                  label: 'Hockey Canada ID Status',
                  type: 'select',
                  value: currentFilter.hockeyCanadaIdStatus || null,
                  placeholder: 'Filter by ID status',
                  searchable: true,
                  clearable: true,
                  data: [
                    { value: 'verified', label: 'Verified' },
                    { value: 'not_verified', label: 'Not Verified' },
                    { value: 'missing', label: 'Missing' },
                    { value: 'excluded', label: 'Excluded' }
                  ],
                  onChange: (value) =>
                    onFilter({
                      ...currentFilter,
                      hockeyCanadaIdStatus: value
                    })
                }
              ]
            : []),
          {
            key: 7,
            label: 'Registrant',
            placeholder: 'Filter by registrant',
            value: currentFilter.search || '',
            untrackedValue: true,
            onBlur: () =>
              searchRef.current === currentFilter.search
                ? onFilter({
                    ...currentFilter,
                    search: ''
                  })
                : onFilter({
                    ...currentFilter,
                    search: searchRef.current
                  }),
            onChange: (value, clearInput) => {
              if (clearInput) {
                onFilter({
                  ...currentFilter,
                  search: ''
                });
              }
              else {
                searchRef.current = value;
              }
            }
          }
        ]}
        filtersLoading={state.regAssociation.loading}
        isDescendingSort={isDescendingSort}
        isLoading={loading}
        lastUpdated={state.paginatedRegFormSubmissions.lastUpdated}
        noSort
        onAction={(action, item) => {
          if (action === 'details') {
            navigate(`/admin/financials/registrations/${item.key}`);
          }
          else {
            const submission = regFormSubmissions.find(
              (s) => s.pkRegFormSubmission === item.key
            );
            if (action === REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.emailUser) {
              window.open(
                `mailto:${submission.overrideEmail ?? submission.user.email}`
              );
            }
            else {
              onOpenModal(action, submission);
            }
          }
        }}
        onChangeSortBy={(sortBy, isDescending) => {
          if (
            sortBy !== currentFilter.sortBy ||
            isDescending !== isDescendingSort
          ) {
            fetchPaginatedRegFormSubmissions(regAssociation.pkRegAssociation, {
              ...currentFilter,
              sortBy,
              orderBy: isDescending ? 'desc' : 'asc'
            });
          }
        }}
        onFetchMore={onFetchMore}
        onRefresh={() =>
          fetchPaginatedRegFormSubmissions(
            regAssociation.pkRegAssociation,
            currentFilter
          )
        }
        onSelect={(selectedRows) => {
          setSelectedRowsState({ selectedRows, showConfirm: false });
        }}
        rows={
          regFormSubmissions
            ?.filter(
              (f) =>
                f.regAssociationDivisionForm.regForm.fkRegAssociation ===
                regAssociation?.pkRegAssociation
            )
            .map((s) => {
              const statusInfo =
                s.status === 'ACCEPTED'
                  ? {
                      color: 'green',
                      label: 'ACCEPTED'
                    }
                  : s.status === 'DENIED'
                  ? {
                      color: '#c40000',
                      label: 'DENIED'
                    }
                  : s.status === 'REFUNDED'
                  ? {
                      color: 'gray',
                      label: 'REFUNDED'
                    }
                  : {
                      color: 'blue',
                      label: 'PENDING'
                    };

              const hockeyCanadaIdInfo = hasHockeyCanadaIntegration
                ? {
                    hockeyCanadaId: s.regFormSubmissionExternalIds.find(
                      (id) =>
                        id.regAssociationExternalConnection.fkRegExternalConnectionType.toString() ===
                        REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA
                    ),
                    isExcluded:
                      s.regAssociationDivisionForm.regForm.regFormExternalConnections?.some(
                        (c) =>
                          c.regAssociationExternalConnection.fkRegExternalConnectionType.toString() ===
                            REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA &&
                          c.isExcluded
                      ) || false
                  }
                : null;

              return {
                key: s.pkRegFormSubmission,
                actions: VIEW_ACTIONS.filter((a) => a.isVisable(s)),
                columns: [
                  {
                    key: 1,
                    label: `${s.regPerson.firstName} ${s.regPerson.lastName}`,
                    weight: 500,
                    subLabel: s.overrideEmail ?? s.user.email,
                    onClick: () =>
                      onOpenModal(
                        REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
                        s
                      ),
                    onSubLabelClick: () =>
                      window.open(`mailto:${s.overrideEmail ?? s.user.email}`),
                    icon: (
                      <ColoredAvatar
                        name={`${s.regPerson.firstName} ${s.regPerson.lastName}`}
                        uniqueId={s.user.email}
                      />
                    )
                  },
                  {
                    key: 2,
                    label:
                      s.regAssociationDivisionOverride?.regAssociationSeason
                        ?.name ||
                      s.regAssociationDivisionForm.regAssociationDivision
                        .regAssociationSeason.name,
                    showOnMobile: true
                  },
                  {
                    key: 3,
                    label:
                      s.regAssociationDivisionOverride?.name ||
                      s.regAssociationDivisionForm.regAssociationDivision.name,
                    showOnMobile: true
                  },
                  {
                    key: 4,
                    label: s.regAssociationDivisionForm.regForm.name,
                    showOnMobile: true
                  },
                  {
                    key: 5,
                    label: statusInfo.label,
                    color: statusInfo.color,
                    showOnMobile: true
                  },
                  ...(hasHockeyCanadaIntegration
                    ? [
                        {
                          key: 6,
                          label: hockeyCanadaIdInfo.hockeyCanadaId
                            ? hockeyCanadaIdInfo.hockeyCanadaId.value
                            : hockeyCanadaIdInfo.isExcluded
                            ? 'EXCLUDED'
                            : '',
                          color: hockeyCanadaIdInfo.hockeyCanadaId?.verifiedAt
                            ? 'green'
                            : hockeyCanadaIdInfo.isExcluded
                            ? 'gray'
                            : '#c40000',
                          showOnMobile: true
                        }
                      ]
                    : []),
                  {
                    key: 8,
                    label: dayjs(formatUtcDate(s.submittedAt)).format(
                      'MMM D, YYYY'
                    ),
                    sortValue: new Date(s.submittedAt),
                    sortValueType: 'date',
                    showOnMobile: true
                  }
                ]
              };
            }) || []
        }
        selectActionSection={
          <MenuContent
            control={
              <ActionIcon color="#ced4da" size="lg" variant="outline">
                <Dots color="#000" />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <AppStack style={{ padding: 2, gap: 1 }}>
              <Menu.Item
                leftSection={<Pencil size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  if (selectedData.length === totalNoFilterCount) {
                    navigate(
                      `/admin/financials/registrations/bulk-edit?ids=${selectedData
                        .map((f) => f.pkRegFormSubmission)
                        .join(',')}`
                    );
                  }
                  else {
                    setSelectedRowsState({
                      ...selectedRowsState,
                      showConfirm: true,
                      confirmAction: 'bulk_edit'
                    });
                  }
                }}
                visibleFrom="xsm"
              >
                Bulk edit
              </Menu.Item>
            </AppStack>
          </MenuContent>
        }
        selectedRows={selectedRowsState.selectedRows}
        sortBy={currentFilter.sortBy || TABLE_COLUMNS[0].value}
        sortFilter={customFilterData}
        tableTitle="Registrations"
        totalCount={totalCount}
      />
      <RegistrationListActionModals
        modalState={modalState}
        onCloseModal={onCloseModal}
      />

      <TableSelectConfirmModal
        filteredCount={totalCount}
        ids={selectedData.map((r) => r.pkRegFormSubmission)}
        isOpen={selectedRowsState.showConfirm}
        itemType="Registration"
        itemTypePlural="Registrations"
        onCancel={() => {
          setSelectedRowsState({ ...selectedRowsState, showConfirm: false });
        }}
        onConfirm={(ids, filtered) => {
          if (selectedRowsState.confirmAction === 'bulk_edit') {
            navigate(
              `/admin/financials/registrations/bulk-edit${
                ids ? `?ids=${ids.join(',')}` : ''
              }${
                filtered
                  ? buildQueryString(filter, [
                      'season',
                      'division',
                      'form',
                      'status',
                      'hockeyCanadaIdStatus',
                      'search'
                    ])
                  : ''
              }`
            );
          }
          setSelectedRowsState({ ...selectedRowsState, showConfirm: false });
        }}
        totalCount={totalNoFilterCount}
      />
    </AppCard>
  );
};

FinancialRegistrations.propTypes = {
  isLoading: PropTypes.bool,
  regAssociation: PropTypes.object
};

export default FinancialRegistrations;
