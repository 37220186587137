import React, { useContext, useEffect, useRef, useState } from 'react';
import { List, Plus, Template } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import TableView from '../../../../components/common/TableView';
import {
  formatUtcDate,
  singularPluralFormat
} from '../../../../helpers/format';
import { triggerNotification } from '../../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../../providers/RegistrationAdminDashboardProvider';
import AdminView from '../../../../components/common/AdminView';
import { FORM_VIEW_TABS } from '../navigation/links';
import AppCard from '../../../../components/common/AppCard';

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Created at',
    value: 'date',
    sortable: true
  }
];

const TaskListTemplateTable = () => {
  const navigate = useNavigate();
  const fetchedPkRegAssociation = useRef(null);
  const { state, fetchRegAssociationTaskTemplates } = useContext(
    RegistrationAdminContext
  );
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const [filterState, setFilterState] = useState({ search: '' });
  const regAssociation = dashboardState.regAssociation.value;

  const loading =
    fetchedPkRegAssociation.current !== regAssociation?.pkRegAssociation ||
    state.regAssociationTaskTemplates.loading;

  const taskListData = state.regAssociationTaskTemplates.value;

  useEffect(() => {
    if (
      regAssociation &&
      fetchedPkRegAssociation.current !== regAssociation.pkRegAssociation
    ) {
      // eslint-disable-next-line no-use-before-define
      onFetchTaskTemplates();
      fetchedPkRegAssociation.current = regAssociation.pkRegAssociation;
    }
  }, [regAssociation]);

  const onFetchTaskTemplates = () => {
    fetchRegAssociationTaskTemplates(
      regAssociation.pkRegAssociation,
      {},
      null,
      (error) => {
        triggerNotification(error);
      }
    );
  };

  return (
    <AdminView
      actionControl={{
        label: 'Create list',
        icon: <Plus />,
        to: '/admin/task-templates/new'
      }}
      headerTitle="Templates"
      tabs={FORM_VIEW_TABS}
    >
      <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
        <TableView
          columns={TABLE_COLUMNS}
          disabled={!loading && taskListData.length === 0}
          disabledContent={{
            title: 'Create your first task template',
            description:
              'Task templates are designed to organize and track tasks, allowing you to easily check them off as they are completed.',
            icon: <List color="#000" size={125} />,
            primaryButton: {
              label: 'Create list',
              icon: <Plus size={18} />,
              link: '/admin/task-templates/new'
            }
          }}
          emptyMessage="No lists found."
          enableInputsWhenLoading
          filters={[
            {
              key: 1,
              label: 'Search',
              placeholder: 'Search by title',
              value: filterState.search,
              onChange: (value) =>
                setFilterState({ ...filterState, search: value })
            }
          ]}
          isLoading={loading}
          noSort
          onAction={(action, item) => {}}
          onRefresh={onFetchTaskTemplates}
          rows={
            taskListData
              ?.filter(
                (f) => f.fkRegAssociation === regAssociation?.pkRegAssociation
              )
              .map((t) => ({
                key: t.pkRegAssociationTaskTemplate,
                actions: [],
                columns: [
                  {
                    key: 1,
                    label: t.name,
                    weight: 500,
                    subLabel: singularPluralFormat(
                      t.regAssociationTaskTemplateQuestions.length,
                      'question',
                      'questions'
                    ),
                    component: Link,
                    to: `/admin/task-templates/${t.pkRegAssociationTaskTemplate}`,
                    icon: <Template size={24} />
                  },
                  {
                    key: 2,
                    label: dayjs(formatUtcDate(t.createdAt)).format(
                      'MMM D, YYYY'
                    ),
                    showOnMobile: true
                  }
                ]
              })) || []
          }
          selectInputOnly
          tableTitle="Task templates"
        />
      </AppCard>
    </AdminView>
  );
};

export default TaskListTemplateTable;
