import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  TextInput,
  MultiSelect,
  Textarea,
  Radio,
  Divider,
  Checkbox,
  Select,
  Image
} from '@mantine/core';
import { Calendar, InfoCircle } from 'tabler-icons-react';
import { DatePickerInput } from '@mantine/dates';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormSection from '../../common/FormSection';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import AssignDivisionsModalSection from './AssignDivisionsModalSection';
import { persoanlInformationSectionData } from './managedFormSectionsData';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AssociationSelectInput from '../associations/AssociationSelectInput';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppRadioGroup from '../../common/AppRadioGroup';
import DeleteItemView from '../../common/DeleteItemView';
import ImageUploadButton from '../../common/ImageUploadButton';
import EXTERNAL_CONNECTION_FORM_SECTION_CONFIG from './externalConnectionFormSectionConfig';

const FINANCIAL_ASSISTANCE_TYPES = [
  {
    label: 'Jumpstart',
    value: 'JUMPSTART'
  }
];

const EditFormModalSection = ({
  associationDivisions,
  pkRegAssociation,
  regForm,
  showDelete,
  onShowDelete,
  copyForm,
  onClose
}) => {
  const mqIndex = useMediaQueryIndex();
  const navigate = useNavigate();
  const {
    state,
    createAdminRegForm,
    updateAdminRegForm,
    deleteAdminRegForm
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    pkRegForm: '',
    name: '',
    additionalInfoImage: '',
    startDate: new Date(),
    endDate: new Date(),
    fkRegAssociation: '',
    selectedDivisions: [],
    divisions: [],
    regFormExternalConnections: [],
    description: '',
    enabled: true,
    taxType: 'INCLUSIVE',
    taxRate: 0,
    financialAssistanceEnabled: false,
    financialAssistanceType: '',
    showDivisionAssign: false,
    loading: false,
    errors: {}
  });
  const selectedRegAssociation = state.regAssociations.value.find(
    (a) => a.pkRegAssociation.toString() === pkRegAssociation?.toString()
  );

  useEffect(() => {
    if (regForm) {
      const divisions = regForm.regAssociationDivisionForms
        .filter(
          (d) =>
            !!associationDivisions.find(
              (a) =>
                a.value ===
                d.regAssociationDivision.pkRegAssociationDivision.toString()
            )
        )
        .map((d) => ({
          pkRegAssociationDivision: d.regAssociationDivision.pkRegAssociationDivision.toString(),
          price: d.regProduct.price,
          orderEmailEnabled: d.orderEmailEnabled,
          customerPaysServiceFees: d.customerPaysServiceFees,
          isAdditionalInfoHyperlink: d.isAdditionalInfoHyperlink,
          additionalInfo: d.additionalInfo,
          additionalInfoImage: d.additionalInfoImage,
          titleOverride: d.titleOverride,
          daysOfPlay: d.daysOfPlay,
          isActive: true
        }));

      setFormState({
        pkRegForm: regForm.pkRegForm.toString(),
        name: copyForm ? `${regForm.name} - Copy` : regForm.name,
        additionalInfoImage: regForm.additionalInfoImage,
        startDate: new Date(formatUtcDate(regForm.startDate)),
        endDate: new Date(formatUtcDate(regForm.endDate)),
        fkRegAssociation:
          pkRegAssociation?.toString() || regForm.fkRegAssociation.toString(),
        selectedDivisions: divisions,
        divisions,
        regFormExternalConnections: regForm.regFormExternalConnections?.map(
          (c) =>
            ({
              fkRegForm: c.fkRegForm,
              fkRegExternalConnectionType:
                c.regAssociationExternalConnection.fkRegExternalConnectionType,
              isExcluded: c.isExcluded
            } || [])
        ),
        description: regForm.description,
        enabled: regForm.enabled,
        taxType:
          regForm.regAssociationDivisionForms[0]?.regProduct.taxRate > 0
            ? 'EXCLUSIVE'
            : 'INCLUSIVE',
        taxRate:
          regForm.regAssociationDivisionForms[0]?.regProduct.taxRate ?? 0,
        financialAssistanceEnabled: !!regForm.regFormFinancialAssistance,
        financialAssistanceType:
          regForm.regFormFinancialAssistance?.financialAssistanceType,
        showDivisionAssign: false,
        loading: false,
        errors: {}
      });
    }
    else {
      setFormState({
        pkRegForm: '',
        name: '',
        additionalInfoImage: '',
        startDate: new Date(),
        endDate: new Date(),
        fkRegAssociation: pkRegAssociation?.toString(),
        selectedDivisions: [],
        divisions: [],
        regFormExternalConnections: [],
        description: '',
        enabled: true,
        taxType: 'INCLUSIVE',
        taxRate: 0,
        financialAssistanceEnabled: false,
        financialAssistanceType: '',
        showDivisionAssign: false,
        loading: false,
        errors: {}
      });
    }
  }, [regForm]);

  const onError = (message) => {
    triggerNotification(message);
  };

  return showDelete ? (
    <DeleteItemView
      isLoading={formState.loading}
      itemLabel="Form"
      onCancel={onClose}
      onDelete={() => {
        setFormState({
          ...formState,
          errors: {},
          loading: true
        });
        deleteAdminRegForm(regForm.uuid, onClose, () => {
          setFormState({
            ...formState,
            loading: false
          });
          onError('Oops something went wrong.');
        });
      }}
      warnings={['Ability to register for this form']}
    />
  ) : formState.showDivisionAssign ? (
    <AssignDivisionsModalSection
      assignedDivisions={formState.selectedDivisions}
      divisions={associationDivisions}
      onCancel={() =>
        setFormState({
          ...formState,
          selectedDivisions: formState.divisions,
          showDivisionAssign: false
        })
      }
      onDivisionChange={(selectedDivisions) =>
        setFormState({
          ...formState,
          selectedDivisions
        })
      }
      onSubmit={() => {
        setFormState({
          ...formState,
          divisions: [...formState.selectedDivisions],
          showDivisionAssign: false
        });
      }}
      regAssociation={selectedRegAssociation}
      regFormState={formState}
    />
  ) : (
    <AppStack style={{ gap: 20 }}>
      <AppFlexbox
        style={{
          flex: 1,
          padding: 20,
          gap: 20,
          border: 'solid 1px lightgrey',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <InfoCircle color="#1971c2" size={40} />
        <AppText>
          Please enter the title and description of your registration form
        </AppText>
      </AppFlexbox>
      <FormSection
        cancelTitle="Cancel"
        isLoading={formState.loading}
        onCancel={onClose}
        onSubmit={() => {
          if (formState.divisions.length === 0) {
            const error = 'Please assign one or more divisions.';
            triggerNotification(error);
            setFormState({
              ...formState,
              errors: { divisions: { value: error } }
            });
          }
          else if (!formState.startDate) {
            const error = 'Invalid start date.';
            triggerNotification(error);
            setFormState({
              ...formState,
              errors: { startDate: { value: error } }
            });
          }
          else if (!formState.endDate) {
            const error = 'Invalid end date.';
            triggerNotification(error);
            setFormState({
              ...formState,
              errors: { endDate: { value: error } }
            });
          }
          else if (formState.startDate >= formState.endDate) {
            const error = 'Invalid date range.';
            triggerNotification(error);
            setFormState({
              ...formState,
              errors: { startDate: { value: error }, endDate: { value: error } }
            });
          }
          else {
            setFormState({
              ...formState,
              errors: {},
              loading: true
            });
            if (regForm && !copyForm) {
              updateAdminRegForm(
                regForm.uuid,
                {
                  ...formState,
                  taxRate:
                    formState.taxType === 'INCLUSIVE' ? 0 : formState.taxRate,
                  divisions: formState.divisions.filter((d) => d.isActive)
                },
                (results) => {
                  navigate(
                    `/admin/forms/${results.fkRegAssociation}/${results.uuid}`
                  );
                },
                (message) => {
                  onError(message);
                  setFormState({
                    ...formState,
                    loading: false
                  });
                }
              );
            }
            else {
              createAdminRegForm(
                {
                  ...formState,
                  pkRegFormCopy: copyForm ? regForm.pkRegForm : null,
                  sections: copyForm ? null : [persoanlInformationSectionData],
                  taxRate:
                    formState.taxType === 'INCLUSIVE' ? 0 : formState.taxRate,
                  divisions: formState.divisions.filter((d) => d.isActive)
                },
                (results) => {
                  navigate(
                    `/admin/forms/${results.fkRegAssociation}/${results.uuid}`
                  );
                },
                (message) => {
                  onError(message);
                  setFormState({
                    ...formState,
                    loading: false
                  });
                }
              );
            }
          }
        }}
        submitTitle="Continue"
      >
        <AppStack style={{ gap: 10 }}>
          <AppFlexbox
            style={{
              gap: rs([10, 40], mqIndex),
              flexDirection: rs(['column', 'row'], mqIndex),
              alignItems: 'stretch'
            }}
          >
            <TextInput
              disabled={formState.loading}
              label="Form Name"
              name="name"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.currentTarget.value,
                  errors: {}
                })
              }
              required
              style={{ flex: 1 }}
              value={formState.name}
            />
            <AssociationSelectInput
              disabled
              label="Association"
              onChange={() => {}}
              preventUrlUpdate
              required
              style={{ flex: 1 }}
              value={formState.fkRegAssociation ?? ''}
            />
          </AppFlexbox>
          <AppFlexbox
            style={{
              alignItems: 'start',
              gap: rs([10, 10, 40], mqIndex),
              flexDirection: rs(['column', 'column', 'row'], mqIndex)
            }}
          >
            <AppStack
              style={{
                gap: 10,
                alignSelf: 'stretch',
                marginTop: 4,
                flex: 1
              }}
            >
              <AppFlexbox
                style={{
                  flexDirection: rs(['column', 'row', 'column'], mqIndex),
                  gap: rs([10, 40, 10], mqIndex),
                  alignItems: 'stretch'
                }}
              >
                <DatePickerInput
                  clearable
                  disabled={formState.loading}
                  error={formState.errors.startDate?.value}
                  label="Start Date"
                  leftSection={<Calendar color="#000" size={16} />}
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      startDate: value,
                      endDate:
                        value && value > formState.endDate
                          ? value
                          : formState.endDate,
                      errors: {}
                    })
                  }
                  placeholder="MM/DD/YYYY"
                  required
                  style={{ flex: 1 }}
                  value={formState.startDate}
                  valueFormat="MM/DD/YYYY"
                />
                <DatePickerInput
                  clearable
                  disabled={formState.loading}
                  error={formState.errors.endDate?.value}
                  label="End Date"
                  leftSection={<Calendar color="#000" size={16} />}
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      endDate: value,
                      startDate:
                        value && value < formState.startDate
                          ? value
                          : formState.startDate,
                      errors: {}
                    })
                  }
                  placeholder="MM/DD/YYYY"
                  required
                  style={{ flex: 1 }}
                  value={formState.endDate}
                  valueFormat="MM/DD/YYYY"
                />
              </AppFlexbox>

              <AppFlexbox
                style={{
                  flexDirection: rs(['column', 'row', 'column'], mqIndex),
                  gap: rs([10, 40, 10], mqIndex),
                  alignItems: 'stretch'
                }}
              >
                <AppRadioGroup
                  disabled={formState.loading}
                  label="Tax Options"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      taxType: value,
                      errors: {}
                    })
                  }
                  size="sm"
                  value={formState.taxType}
                >
                  <Radio
                    disabled={formState.loading}
                    label="Price Inclusive or Tax-free"
                    value="INCLUSIVE"
                  />
                  <Radio
                    disabled={formState.loading}
                    label="Specify Tax Rate"
                    value="EXCLUSIVE"
                  />
                </AppRadioGroup>

                {formState.taxType !== 'INCLUSIVE' && (
                  <NumberFormat
                    allowNegative={false}
                    customInput={TextInput}
                    decimalScale={2}
                    disabled={formState.loading}
                    label="Tax Rate"
                    name="taxRate"
                    onValueChange={(values) => {
                      setFormState({
                        ...formState,
                        taxRate: values.value,
                        errors: {}
                      });
                    }}
                    style={{ flex: 1 }}
                    suffix="%"
                    thousandSeparator
                    value={formState.taxRate}
                  />
                )}
              </AppFlexbox>

              <AppStack style={{ gap: 10, alignItems: 'stretch' }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText
                    style={{ fontSize: 14, lineHeight: 1.55 }}
                    weight={500}
                  >
                    Financial Assistance
                  </AppText>
                  <Checkbox
                    checked={formState.financialAssistanceEnabled}
                    disabled={formState.loading}
                    label="Enable Financial Assistance"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        financialAssistanceEnabled: e.currentTarget.checked,
                        financialAssistanceType: !e.currentTarget.checked
                          ? ''
                          : FINANCIAL_ASSISTANCE_TYPES[0].value
                      })
                    }
                    style={{ flex: 1 }}
                  />
                </AppStack>

                {formState.financialAssistanceType && (
                  <Select
                    data={FINANCIAL_ASSISTANCE_TYPES}
                    disabled={formState.loading}
                    label="Assistance Provider"
                    onChange={(value) => {
                      if (
                        value &&
                        value !== formState.financialAssistanceType
                      ) {
                        setFormState({
                          ...formState,
                          financialAssistanceType: value
                        });
                      }
                    }}
                    required
                    style={{ flex: 1 }}
                    value={formState.financialAssistanceType}
                  />
                )}
              </AppStack>

              <Divider style={{ marginTop: 10 }} />

              <AppStack
                style={{ gap: 10, alignItems: 'stretch', marginTop: 10 }}
              >
                <AppStack style={{ gap: 15 }}>
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Additional Info Image
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      Set an additional information image as a base image for
                      all divisons. This can be overrrided when assigning
                      divisions.
                    </AppText>
                  </AppStack>

                  <AppFlexbox style={{ gap: 10 }}>
                    {formState.additionalInfoImage && (
                      <Button
                        color="red"
                        onClick={() => {
                          setFormState({
                            ...formState,
                            additionalInfoImage: null
                          });
                        }}
                        size="xs"
                        variant="outline"
                      >
                        Remove Image
                      </Button>
                    )}

                    <ImageUploadButton
                      color="blue"
                      identifier={`${
                        regForm
                          ? `regForm-${regForm?.pkRegForm}`
                          : `regAssociation-${selectedRegAssociation.pkRegAssociation}`
                      }-additional-info`}
                      label={
                        formState.additionalInfoImage
                          ? 'Change Image'
                          : 'Add Image'
                      }
                      onUploadComplete={(url) => {
                        setFormState({
                          ...formState,
                          additionalInfoImage: url
                        });
                      }}
                      size="xs"
                      variant="outline"
                    />
                  </AppFlexbox>

                  {formState.additionalInfoImage && (
                    <AppStack
                      style={{ width: '100%', height: '100%', maxWidth: 200 }}
                    >
                      <Image
                        fit="contain"
                        src={formState.additionalInfoImage}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </AppStack>
                  )}
                </AppStack>
              </AppStack>
            </AppStack>
            <AppStack
              style={{
                gap: 10,
                flex: 1,
                alignSelf: 'stretch',
                minWidth: 0,
                marginTop: rs([10, 0], mqIndex)
              }}
            >
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    marginBottom: 5
                  }}
                >
                  <AppFlexbox
                    style={{
                      display: rs(['none', 'flex'], mqIndex),
                      alignItems: 'end',
                      gap: 0,
                      marginTop: 5
                    }}
                  >
                    <AppText
                      style={{ fontSize: 14, lineHeight: 1.55 }}
                      weight={500}
                    >
                      Divisions
                    </AppText>
                    <AppText
                      style={{
                        fontSize: 14,
                        lineHeight: 1.55,
                        color: '#f03e3e',
                        paddingLeft: 5
                      }}
                      weight={500}
                    >
                      *
                    </AppText>
                  </AppFlexbox>

                  <Button
                    color="blue"
                    disabled={formState.loading}
                    onClick={() =>
                      setFormState({
                        ...formState,
                        errors: {},
                        showDivisionAssign: true
                      })
                    }
                    size="compact-sm"
                  >
                    Assign Divisions
                  </Button>
                </AppFlexbox>
                <MultiSelect
                  data={associationDivisions
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((d) => {
                      const assignedDivision = formState.divisions.find(
                        (f) => f.pkRegAssociationDivision === d.value
                      );
                      return {
                        label: `${d.label}${
                          assignedDivision
                            ? ` - ${currencyFormat(assignedDivision.price)}`
                            : ''
                        }`,
                        value: d.value
                      };
                    })}
                  disabled
                  error={formState.errors.divisions?.value}
                  onChange={(values) =>
                    setFormState({
                      ...formState,
                      divisions: values,
                      errors: {}
                    })
                  }
                  placeholder={
                    formState.divisions.length === 0
                      ? 'No Divisions Assigned'
                      : ''
                  }
                  required
                  value={formState.divisions
                    .filter((d) => d.isActive)
                    .map((d) => d.pkRegAssociationDivision)}
                />
              </AppStack>

              <Textarea
                autosize
                disabled={formState.loading}
                label="Description"
                minRows={6}
                name="description"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    description: e.currentTarget.value,
                    errors: {}
                  })
                }
                value={formState.description}
              />

              {selectedRegAssociation.regAssociationExternalConnections?.map(
                (c) => {
                  const config =
                    EXTERNAL_CONNECTION_FORM_SECTION_CONFIG[
                      c.fkRegExternalConnectionType.toString()
                    ];
                  const isExcluded = formState.regFormExternalConnections.find(
                    (r) =>
                      r.fkRegExternalConnectionType ===
                      c.fkRegExternalConnectionType
                  )?.isExcluded;

                  return config ? (
                    <AppStack
                      key={c.fkRegExternalConnectionType}
                      style={{ gap: 5 }}
                    >
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {config.title}
                      </AppText>
                      <Checkbox
                        checked={isExcluded || false}
                        disabled={formState.loading}
                        label="Exclude ID input"
                        onChange={() =>
                          setFormState({
                            ...formState,
                            regFormExternalConnections: [
                              ...formState.regFormExternalConnections.filter(
                                (r) =>
                                  r.fkRegExternalConnectionType !==
                                  c.fkRegExternalConnectionType
                              ),
                              {
                                fkRegExternalConnectionType:
                                  c.fkRegExternalConnectionType,
                                isExcluded: !isExcluded
                              }
                            ]
                          })
                        }
                      />
                    </AppStack>
                  ) : null;
                }
              )}
            </AppStack>
          </AppFlexbox>
        </AppStack>
        {regForm && !copyForm && !formState.showDivisionAssign && (
          <>
            <Divider />
            <Button
              color="red"
              disabled={formState.loading}
              onClick={onShowDelete}
              style={{
                width: '100%',
                maxWidth: 250,
                alignSelf: 'center'
              }}
            >
              Delete Form
            </Button>
          </>
        )}
      </FormSection>
    </AppStack>
  );
};

EditFormModalSection.propTypes = {
  associationDivisions: PropTypes.array,
  copyForm: PropTypes.bool,
  onClose: PropTypes.func,
  onShowDelete: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regForm: PropTypes.object,
  showDelete: PropTypes.bool
};
export default EditFormModalSection;
