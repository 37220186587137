import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Button,
  Checkbox,
  Divider,
  Menu,
  Skeleton
} from '@mantine/core';
import { CircleCheck, DotsVertical, EyeOff, Receipt } from 'tabler-icons-react';
import AppCard from '../../../../components/common/AppCard';
import AppStack from '../../../../components/common/AppStack';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppText from '../../../../components/common/AppText';
import PageCard from '../../../../components/common/PageCard';
import MenuContent from '../../../../components/common/MenuContent';
import AssignTasksModal from './AssignTasksModal';
import { useModalState, useRegResource } from '../../../../helpers/hooks';
import { triggerNotification } from '../../../../helpers/notificationHelper';
import RemoveTasksModal from './RemoveTasksModal';

const TaskListInfoCard = ({ resourceId, fkRegResourceType }) => {
  const {
    regResource,
    regResourceTaskTemplates,
    regResourceTaskTemplateAnswers,
    onUpdateRegResource
  } = useRegResource({
    resourceId,
    fkRegResourceType
  });
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [formState, setFormState] = useState({
    regResourceTaskTemplateAnswers: [],
    hasChanges: false
  });
  const taskTemplates =
    regResourceTaskTemplates
      .filter((t) =>
        t.regAssociationTaskTemplate.regAssociationTaskTemplateQuestions.some(
          (q) =>
            !regResourceTaskTemplateAnswers.find(
              (a) =>
                a.regAssociationTaskTemplateQuestion
                  ?.pkRegAssociationTaskTemplateQuestion ===
                q.pkRegAssociationTaskTemplateQuestion
            )?.hidden
        )
      )
      .sort((a, b) =>
        a.regAssociationTaskTemplate.name.localeCompare(
          b.regAssociationTaskTemplate.name
        )
      ) || [];
  const taskAnswersWithoutTemplates = regResourceTaskTemplateAnswers.filter(
    (a) =>
      !a.hidden &&
      (!a.regAssociationTaskTemplateQuestion ||
        !regResourceTaskTemplates.find((t) =>
          t.regAssociationTaskTemplate.regAssociationTaskTemplateQuestions.some(
            (q) =>
              q.pkRegAssociationTaskTemplateQuestion ===
              a.regAssociationTaskTemplateQuestion
                .pkRegAssociationTaskTemplateQuestion
          )
        ))
  );

  const hasTasksAssigned =
    taskTemplates.length > 0 || taskAnswersWithoutTemplates.length > 0;

  useEffect(() => {
    if (regResource) {
      // eslint-disable-next-line no-use-before-define
      onResetFormState();
    }
  }, [regResource]);

  const onResetFormState = () => {
    setFormState({
      regResourceTaskTemplateAnswers: regResourceTaskTemplateAnswers.map(
        (a) => ({
          ...a,
          showIcon: !!a.completedAt,
          fkRegAssociationTaskTemplateQuestion:
            a.regAssociationTaskTemplateQuestion
              ?.pkRegAssociationTaskTemplateQuestion,
          markCompleted: !!a.completedAt
        })
      ),
      hasChanges: false
    });
  };

  const onTaskAnswerChange = (taskAnswer) => {
    setFormState({
      ...formState,
      hasChanges: true,
      regResourceTaskTemplateAnswers: [
        ...formState.regResourceTaskTemplateAnswers.filter((a) =>
          taskAnswer.pkRegResourceTaskTemplateAnswer
            ? a.pkRegResourceTaskTemplateAnswer !==
              taskAnswer.pkRegResourceTaskTemplateAnswer
            : a.fkRegAssociationTaskTemplateQuestion !==
              taskAnswer.fkRegAssociationTaskTemplateQuestion
        ),
        {
          ...taskAnswer,
          hasChanges: true
        }
      ]
    });
  };

  const onSaveChanges = () => {
    setFormState({ ...formState, loading: true });
    onUpdateRegResource(
      {
        regResourceTaskTemplateAnswers: formState.regResourceTaskTemplateAnswers.filter(
          (a) =>
            (a.markCompleted || !!a.pkRegResourceTaskTemplateAnswer) &&
            a.hasChanges
        )
      },
      () => {
        setFormState({ ...formState, loading: false, hasChanges: false });
        triggerNotification('Tasks updated!', 'Success', 'green');
      },
      (error) => {
        setFormState({
          ...formState,
          false: true
        });
        triggerNotification(error);
      }
    );
  };

  return resourceId ? (
    <PageCard style={{ padding: 0 }}>
      <AssignTasksModal
        fkRegResourceType={fkRegResourceType}
        isOpen={modalState.isOpen && modalState.action === 'assign'}
        onClose={onCloseModal}
        resourceId={resourceId}
      />

      <RemoveTasksModal
        fkRegResourceType={fkRegResourceType}
        isOpen={modalState.isOpen && modalState.action === 'hide'}
        onClose={onCloseModal}
        resourceId={resourceId}
      />

      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 28
          }}
        >
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>Tasks</AppText>

          <AppFlexbox style={{ gap: 8 }}>
            {formState.hasChanges ? (
              <>
                <Button
                  color="dark"
                  disabled={formState.loading}
                  onClick={onResetFormState}
                  size="compact-sm"
                  type="button"
                  variant="outline"
                >
                  Reset
                </Button>

                <Button
                  color="blue"
                  loading={formState.loading}
                  onClick={onSaveChanges}
                  size="compact-sm"
                  type="button"
                  variant="filled"
                >
                  Save Changes
                </Button>
              </>
            ) : (
              <MenuContent
                control={
                  <ActionIcon color="dark" variant="subtle">
                    <DotsVertical />
                  </ActionIcon>
                }
              >
                <Menu.Item
                  leftSection={<Receipt size={18} />}
                  onClick={() => {
                    onOpenModal('assign');
                  }}
                >
                  Assign Tasks
                </Menu.Item>

                {hasTasksAssigned && (
                  <Menu.Item
                    leftSection={<EyeOff size={18} />}
                    onClick={() => {
                      onOpenModal('hide');
                    }}
                  >
                    Hide tasks
                  </Menu.Item>
                )}
              </MenuContent>
            )}
          </AppFlexbox>
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            {!hasTasksAssigned ? (
              <AppStack
                style={{
                  gap: 8,
                  padding: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}
              >
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  No tasks have been assigned to this registration.
                </AppText>
                <Button
                  color="dark"
                  onClick={() => {
                    onOpenModal('assign');
                  }}
                  size="compact-sm"
                  variant="outline"
                >
                  Assign Tasks
                </Button>
              </AppStack>
            ) : (
              <AppStack style={{ padding: 16 }}>
                {taskTemplates.map((t, index) => (
                  <React.Fragment key={t.pkRegResourceTaskTemplate}>
                    {index > 0 && <Divider />}
                    <AppStack style={{ gap: 12 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        {t.regAssociationTaskTemplate.name}
                      </AppText>
                      <AppStack style={{ gap: 8, paddingLeft: 16 }}>
                        {t.regAssociationTaskTemplate.regAssociationTaskTemplateQuestions
                          .filter(
                            (q) =>
                              !regResourceTaskTemplateAnswers.find(
                                (a) =>
                                  a.regAssociationTaskTemplateQuestion
                                    ?.pkRegAssociationTaskTemplateQuestion ===
                                  q.pkRegAssociationTaskTemplateQuestion
                              )?.hidden
                          )
                          .map((q) => {
                            const answer = formState.regResourceTaskTemplateAnswers.find(
                              (a) =>
                                a.fkRegAssociationTaskTemplateQuestion ===
                                q.pkRegAssociationTaskTemplateQuestion
                            );

                            return (
                              <AppFlexbox
                                key={q.pkRegAssociationTaskTemplateQuestion}
                                style={{
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <AppFlexbox
                                  onClick={() => {
                                    if (!formState.loading) {
                                      if (answer?.showIcon) {
                                        onTaskAnswerChange({
                                          ...answer,
                                          showIcon: false
                                        });
                                      }
                                      else {
                                        onTaskAnswerChange({
                                          ...answer,
                                          fkRegAssociationTaskTemplateQuestion:
                                            q.pkRegAssociationTaskTemplateQuestion,
                                          markCompleted: !answer?.markCompleted,
                                          customValue: '',
                                          note: '',
                                          hidden: false
                                        });
                                      }
                                    }
                                  }}
                                  style={{
                                    gap: 8,
                                    cursor: formState.loading
                                      ? 'not-allowed'
                                      : 'pointer'
                                  }}
                                >
                                  {answer?.showIcon ? (
                                    <>
                                      <AppStack>
                                        <CircleCheck
                                          color="#067D62"
                                          size={20}
                                          style={{ marginTop: 1 }}
                                        />
                                      </AppStack>
                                      <AppText
                                        style={{
                                          fontSize: 14,
                                          color: '#666',
                                          textDecoration: 'line-through',
                                          textDecorationColor:
                                            'rgba(102, 102, 102, 0.65)'
                                        }}
                                      >
                                        {q.value}
                                      </AppText>
                                    </>
                                  ) : (
                                    <>
                                      <Checkbox
                                        checked={answer?.markCompleted || false}
                                        disabled={formState.loading}
                                        onChange={() => {}}
                                        style={{ marginTop: 1 }}
                                      />
                                      <AppText style={{ fontSize: 14 }}>
                                        {q.value}
                                      </AppText>
                                    </>
                                  )}
                                </AppFlexbox>
                              </AppFlexbox>
                            );
                          })}
                      </AppStack>
                    </AppStack>
                  </React.Fragment>
                ))}
                {taskAnswersWithoutTemplates.length > 0 && (
                  <>
                    {taskTemplates.length > 0 && <Divider />}
                    <AppStack style={{ gap: 12 }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Uncategorised
                      </AppText>
                      <AppStack style={{ gap: 8, paddingLeft: 16 }}>
                        {taskAnswersWithoutTemplates.map((a) => {
                          const answer = formState.regResourceTaskTemplateAnswers.find(
                            (f) =>
                              f.pkRegResourceTaskTemplateAnswer ===
                              a.pkRegResourceTaskTemplateAnswer
                          );

                          return (
                            <AppFlexbox
                              key={a.pkRegResourceTaskTemplateAnswer}
                              style={{
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <AppFlexbox
                                onClick={() => {
                                  if (!formState.loading) {
                                    if (answer?.showIcon) {
                                      onTaskAnswerChange({
                                        ...answer,
                                        showIcon: false
                                      });
                                    }
                                    else {
                                      onTaskAnswerChange({
                                        ...answer,
                                        markCompleted: !answer?.markCompleted
                                      });
                                    }
                                  }
                                }}
                                style={{
                                  gap: 8,
                                  cursor: formState.loading
                                    ? 'not-allowed'
                                    : 'pointer'
                                }}
                              >
                                {answer?.showIcon ? (
                                  <>
                                    <AppStack>
                                      <CircleCheck
                                        color="#067D62"
                                        size={20}
                                        style={{ marginTop: 1 }}
                                      />
                                    </AppStack>
                                    <AppText
                                      style={{
                                        fontSize: 14,
                                        color: '#666',
                                        textDecoration: 'line-through',
                                        textDecorationColor:
                                          'rgba(102, 102, 102, 0.65)'
                                      }}
                                    >
                                      {a.regAssociationTaskTemplateQuestion
                                        ?.value || a.customValue}
                                    </AppText>
                                  </>
                                ) : (
                                  <>
                                    <Checkbox
                                      checked={answer?.markCompleted || false}
                                      disabled={formState.loading}
                                      onChange={() => {}}
                                      style={{ marginTop: 1 }}
                                    />
                                    <AppText style={{ fontSize: 14 }}>
                                      {a.regAssociationTaskTemplateQuestion
                                        ?.value || a.customValue}
                                    </AppText>
                                  </>
                                )}
                              </AppFlexbox>
                            </AppFlexbox>
                          );
                        })}
                      </AppStack>
                    </AppStack>
                  </>
                )}
              </AppStack>
            )}
          </AppStack>
        </AppCard>
      </AppStack>
    </PageCard>
  ) : (
    <PageCard style={{ padding: 0 }}>
      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Skeleton height={22} width="20%" />
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>

            <Divider />
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>
          </AppStack>
        </AppCard>
      </AppStack>
    </PageCard>
  );
};

TaskListInfoCard.propTypes = {
  fkRegResourceType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskList: PropTypes.object
};

export default TaskListInfoCard;
