import React from 'react';
import PropTypes from 'prop-types';
import { Textarea, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import NumberFormat from 'react-number-format';
import { Calendar } from 'tabler-icons-react';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import { useMediaQueryIndex } from '../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../helpers/styles';
import AppNativeSelect from './AppNativeSelect';

const CustomLabelnput = ({
  label,
  onChange,
  value,
  disabled,
  required,
  data,
  type,
  ...rest
}) => {
  const mqIndex = useMediaQueryIndex();

  return (
    <AppStack
      style={{
        gap: 0,
        flex: 1,
        width: rs(['100%', 'inital'], mqIndex)
      }}
    >
      {type === 'select' ? (
        <AppNativeSelect
          data={data}
          disabled={disabled}
          label={label}
          onChange={onChange}
          required={required}
          size="md"
          value={value}
          {...rest}
          style={{ flex: 1, ...rest.style }}
          styles={{
            ...rest.styles,
            label: { display: rs(['flex', 'none'], mqIndex) }
          }}
        />
      ) : type === 'date' ? (
        <DatePickerInput
          data={data}
          disabled={disabled}
          label={label}
          leftSection={<Calendar color="#000" size={16} />}
          onChange={onChange}
          placeholder="MM/DD/YYYY"
          required={required}
          size="md"
          value={value}
          valueFormat="MM/DD/YYYY"
          {...rest}
          style={{ flex: 1, ...rest.style }}
          styles={{
            ...rest.styles,
            label: { display: rs(['flex', 'none'], mqIndex) }
          }}
        />
      ) : type === 'number' ? (
        <NumberFormat
          customInput={TextInput}
          disabled={disabled}
          fixedDecimalScale
          label={label}
          onValueChange={onChange}
          required={required}
          size="md"
          thousandSeparator
          value={value}
          {...rest}
          style={{ flex: 1, ...rest.style }}
          styles={{
            ...rest.styles,
            label: {
              fontSize: 16,
              fontWeight: 500,
              display: rs(['flex', 'none'], mqIndex)
            }
          }}
        />
      ) : type === 'text-area' ? (
        <Textarea
          autosize
          disabled={disabled}
          label={label}
          minRows={3}
          onChange={onChange}
          required={required}
          size="md"
          value={value}
          {...rest}
          style={{ flex: 1, ...rest.style }}
          styles={{
            ...rest.styles,
            label: { display: rs(['flex', 'none'], mqIndex) }
          }}
        />
      ) : (
        <TextInput
          disabled={disabled}
          label={label}
          onChange={onChange}
          required={required}
          size="md"
          type={type}
          value={value}
          {...rest}
          style={{ flex: 1, ...rest.style }}
          styles={{
            ...rest.styles,
            label: { display: rs(['flex', 'none'], mqIndex) }
          }}
        />
      )}

      <AppFlexbox style={{ display: rs(['none', 'flex'], mqIndex), gap: 5 }}>
        <AppText weight={500}>{label}</AppText>
        {required && (
          <AppText style={{ color: '#f03e3e' }} weight={500}>
            *
          </AppText>
        )}
      </AppFlexbox>
    </AppStack>
  );
};

CustomLabelnput.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ])
};

export default CustomLabelnput;
