import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FinancialRegistrationTransactionModal from './FinancialRegistrationTransactionModal';
import ViewInvoicePaymentsModal from './ViewInvoicePaymentsModal';
import FinancialRegistrationTransactionList from './FinancialRegistrationTransactionList';
import RebaseInvoiceModal from './RebaseInvoiceModal';
import RegistrationInvoiceStatusChangeModal from './RegistrationInvoiceStatusChangeModal';
import RegistrationInvoiceResendModal from './RegistrationInvoiceResendModal';
import RegistrationInvoiceRefundModal from './RegistrationInvoiceRefundModal';

const FinancialRegistrationTransactions = ({
  regAssociation,
  regFormSubmissionBalance,
  isLoading,
  isAdminViewing,
  isPendingPayment,
  isDetailedView,
  regFormSubmission
}) => {
  const [rebaseInvoiceModalState, setRebaseInvoiceModalState] = useState({
    isOpen: false,
    payment: null
  });
  const [transactionModalState, setTransactionModalState] = useState({
    isOpen: false,
    refund: false,
    paymentRecord: null
  });
  const [invoiceModalState, setInvoiceModalState] = useState({
    isOpen: false,
    action: '',
    paymentRecord: null
  });
  const [invoicePaymentsModalState, setInvoicePaymentsModalState] = useState({
    isOpen: false,
    regFormSubmissionInvoice: null
  });
  const payments = regFormSubmissionBalance?.regFormSubmissionPayments ?? [];

  const refundableAmount = regFormSubmissionBalance
    ? regFormSubmissionBalance.balance.amountPaidInCents -
      regFormSubmissionBalance.balance.refundedAmountInCents
    : 0;
  const chargeId = regFormSubmissionBalance?.balance.chargeId;

  return (
    <>
      <FinancialRegistrationTransactionList
        chargeId={chargeId}
        fullViewPath={
          isDetailedView
            ? null
            : `/admin/financials/registrations/${regFormSubmission?.pkRegFormSubmission}`
        }
        isAdminViewing={isAdminViewing}
        isLoading={isLoading}
        isPendingPayment={isPendingPayment}
        isRefundable={refundableAmount > 0}
        onAction={(payment, action) => {
          switch (action) {
            case 'APPLY-PAYMENT':
              setTransactionModalState({
                isOpen: true,
                refund: false,
                paymentRecord: null
              });
              break;
            case 'INVOICE-PAYMENTS':
              setInvoicePaymentsModalState({
                ...invoicePaymentsModalState,
                regFormSubmissionInvoice: payment?.regFormSubmissionInvoice,
                isOpen: true
              });
              break;
            case 'REBASE':
              setRebaseInvoiceModalState({
                payment,
                isOpen: true
              });
              break;
            default:
              if (action.includes('INVOICE')) {
                setInvoiceModalState({
                  isOpen: true,
                  action,
                  paymentRecord: payment
                });
              }
              else {
                setTransactionModalState({
                  isOpen: true,
                  refund: true,
                  paymentRecord: payment
                });
              }
              break;
          }
        }}
        payments={payments.filter(
          (p) =>
            !p.regFormSubmissionInvoice ||
            p.fkRegFormSubmissionPaymentType === 6
        )}
        regFormSubmissionBalance={regFormSubmissionBalance}
      />
      <ViewInvoicePaymentsModal
        isOpen={invoicePaymentsModalState.isOpen}
        onClose={() =>
          setInvoicePaymentsModalState({
            ...invoicePaymentsModalState,
            isOpen: false
          })
        }
        regFormSubmissionBalance={regFormSubmissionBalance}
        regFormSubmissionInvoice={
          invoicePaymentsModalState.regFormSubmissionInvoice
        }
      />
      <FinancialRegistrationTransactionModal
        isOpen={transactionModalState.isOpen}
        isRefund={transactionModalState.refund}
        onClose={() =>
          setTransactionModalState({
            ...transactionModalState,
            isOpen: false
          })
        }
        regFormSubmissionBalance={regFormSubmissionBalance}
        regFormSubmissionPayment={transactionModalState.paymentRecord}
      />

      <RegistrationInvoiceStatusChangeModal
        isOpen={
          invoiceModalState.isOpen &&
          invoiceModalState.action === 'INVOICE-STATUS'
        }
        onClose={() =>
          setInvoiceModalState({
            ...invoiceModalState,
            isOpen: false
          })
        }
        regAssociation={regAssociation}
        regFormSubmissionBalance={regFormSubmissionBalance}
        regFormSubmissionInvoice={
          invoiceModalState.paymentRecord?.regFormSubmissionInvoice
        }
      />
      <RegistrationInvoiceRefundModal
        isOpen={
          invoiceModalState.isOpen &&
          invoiceModalState.action === 'INVOICE-CREDIT'
        }
        onClose={() =>
          setInvoiceModalState({
            ...invoiceModalState,
            isOpen: false
          })
        }
        regAssociation={regAssociation}
        regFormSubmission={regFormSubmission}
        regFormSubmissionBalance={regFormSubmissionBalance}
        regFormSubmissionInvoice={
          invoiceModalState.paymentRecord?.regFormSubmissionInvoice
        }
      />
      <RegistrationInvoiceResendModal
        isOpen={
          invoiceModalState.isOpen &&
          invoiceModalState.action === 'INVOICE-RESEND'
        }
        onClose={() =>
          setInvoiceModalState({
            ...invoiceModalState,
            isOpen: false
          })
        }
        regAssociation={regAssociation}
        regFormSubmissionBalance={regFormSubmissionBalance}
        regFormSubmissionInvoice={
          invoiceModalState.paymentRecord?.regFormSubmissionInvoice
        }
      />
      <RebaseInvoiceModal
        isOpen={rebaseInvoiceModalState.isOpen}
        onClose={() =>
          setRebaseInvoiceModalState({
            ...rebaseInvoiceModalState,
            isOpen: false
          })
        }
        regFormSubmissionPayment={rebaseInvoiceModalState.payment}
      />
    </>
  );
};

FinancialRegistrationTransactions.propTypes = {
  isAdminViewing: PropTypes.bool,
  isDetailedView: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPendingPayment: PropTypes.bool,
  regAssociation: PropTypes.object,
  regFormSubmission: PropTypes.object,
  regFormSubmissionBalance: PropTypes.object
};

export default FinancialRegistrationTransactions;
