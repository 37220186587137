import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Button,
  Divider,
  Select,
  TextInput,
  Tooltip,
  useMantineTheme
} from '@mantine/core';
import { AlertCircle, ChevronDown, ChevronUp } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppTitle from '../../common/AppTitle';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueries } from '../../../helpers/hooks';
import RegistrationSignupListItem from './RegistrationSignupListItem';

const RegistrationsList = ({
  regAssociationDivisionForms,
  title,
  titleTooltip,
  onSelectRegForm,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
  disabled
}) => {
  const { mqIndex, isLargeMobileOrSmaller } = useMediaQueries();
  const theme = useMantineTheme();
  const [filteredRegFormData, setFilteredRegFormData] = useState({
    search: '',
    divisionName: '',
    formName: '',
    showMobileExpand: false,
    displayCount: 5
  });

  const filterOptions = regAssociationDivisionForms?.reduce(
    (r, c) => {
      if (
        !r.divisionOptions.some(
          (s) => s.value === c.regAssociationDivision.name
        )
      ) {
        r.divisionOptions.push({
          value: c.regAssociationDivision.name,
          label: c.regAssociationDivision.name
        });
      }

      if (
        !r.regFormOptions.some(
          (f) => f.value === (c.titleOverride || c.regForm.name)
        )
      ) {
        r.regFormOptions.push({
          value: c.titleOverride || c.regForm.name,
          label: c.titleOverride || c.regForm.name
        });
      }
      return r;
    },
    {
      divisionOptions: [],
      regFormOptions: []
    }
  );

  const filteredForms = regAssociationDivisionForms.filter(
    (r) =>
      (!filteredRegFormData.search ||
        r.regForm.name
          .toLowerCase()
          .includes(filteredRegFormData.search.toLowerCase()) ||
        r.regAssociationDivision.name
          .toLowerCase()
          .includes(filteredRegFormData.search.toLowerCase()) ||
        r.titleOverride
          .toLowerCase()
          .includes(filteredRegFormData.search.toLowerCase())) &&
      (!filteredRegFormData.divisionName ||
        r.regAssociationDivision.name === filteredRegFormData.divisionName) &&
      (!filteredRegFormData.formName ||
        (r.titleOverride || r.regForm.name) === filteredRegFormData.formName)
  );

  useEffect(() => {
    setFilteredRegFormData({
      search: '',
      divisionName: '',
      formName: '',
      showMobileExpand: false,
      displayCount: 5
    });
  }, []);

  return (
    <AppStack style={{ flex: 1, margin: '15px 20px 25px 20px', gap: 20 }}>
      <AppFlexbox
        style={{
          margin: '0px 10px',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 10
        }}
      >
        <AppTitle>{title}</AppTitle>
        {titleTooltip && (
          <Tooltip
            disabled={!titleTooltip}
            events={{ hover: true, focus: false, touch: true }}
            label={titleTooltip}
            multiline
            position={mqIndex >= 2 ? 'right' : 'bottom'}
            withArrow
            withinPortal
          >
            <AppStack>
              <AlertCircle color="dodgerblue" size={35} />
            </AppStack>
          </Tooltip>
        )}
      </AppFlexbox>

      <AppFlexbox
        style={{
          margin: '0px 10px',
          gap: 8,
          flex: 1,
          flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
        }}
      >
        <AppFlexbox
          style={{
            flex: 1,
            gap: 12,
            alignItems: 'center'
          }}
        >
          <TextInput
            onChange={(e) =>
              setFilteredRegFormData({
                ...filteredRegFormData,
                search: e.target.value,
                displayCount: 5
              })
            }
            placeholder="Search..."
            size="md"
            style={{ flex: 1 }}
            value={filteredRegFormData.search || ''}
          />

          <AppStack hiddenFrom="sm">
            <ActionIcon
              color="dark"
              onClick={() => {
                setFilteredRegFormData({
                  ...filteredRegFormData,
                  showMobileExpand: !filteredRegFormData.showMobileExpand
                });
              }}
              size="xl"
              variant="subtle"
            >
              {filteredRegFormData.showMobileExpand ? (
                <ChevronUp color="#000" size={22} />
              ) : (
                <ChevronDown color="#000" size={22} />
              )}
            </ActionIcon>
          </AppStack>
        </AppFlexbox>

        {(!isLargeMobileOrSmaller || filteredRegFormData.showMobileExpand) && (
          <>
            <Select
              clearable
              data={filterOptions.divisionOptions}
              onChange={(v) =>
                setFilteredRegFormData({
                  ...filteredRegFormData,
                  divisionName: v,
                  displayCount: 5
                })
              }
              placeholder="Filter by division"
              searchable
              size="md"
              style={{ flex: 1 }}
              value={filteredRegFormData.divisionName || ''}
            />
            <Select
              clearable
              data={filterOptions.regFormOptions}
              onChange={(v) =>
                setFilteredRegFormData({
                  ...filteredRegFormData,
                  formName: v,
                  displayCount: 5
                })
              }
              placeholder="Filter by registration"
              searchable
              size="md"
              style={{ flex: 1 }}
              value={filteredRegFormData.formName || ''}
            />
          </>
        )}
      </AppFlexbox>

      <AppStack style={{ flex: 1, gap: 15, padding: '0px 10px' }}>
        {filteredForms.length === 0 ? (
          <AppText style={{ textAlign: 'center' }}>
            No Registration forms available
          </AppText>
        ) : (
          <>
            <Divider />
            {filteredForms
              .slice(0, filteredRegFormData.displayCount)
              .map((r) => (
                <React.Fragment key={r.pkRegAssociationDivisionForm}>
                  <RegistrationSignupListItem
                    isDisabled={disabled}
                    onSelectRegForm={onSelectRegForm}
                    primaryColor={primaryColor}
                    primaryTextColor={primaryTextColor}
                    regAssociationDivisionForm={r}
                    secondaryColor={secondaryColor}
                    secondaryTextColor={secondaryTextColor}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            {filteredForms.length > filteredRegFormData.displayCount && (
              <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
                <Button
                  color="dark"
                  onClick={() =>
                    setFilteredRegFormData({
                      ...filteredRegFormData,
                      displayCount: filteredRegFormData.displayCount + 5
                    })
                  }
                  style={{
                    backgroundColor: theme.black,
                    marginTop: 20,
                    paddingLeft: 40,
                    paddingRight: 40
                  }}
                >
                  See More
                </Button>
              </AppFlexbox>
            )}
          </>
        )}
      </AppStack>
    </AppStack>
  );
};

RegistrationsList.propTypes = {
  disabled: PropTypes.bool,
  onSelectRegForm: PropTypes.func,
  primaryColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  regAssociationDivisionForms: PropTypes.array,
  secondaryColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
  title: PropTypes.string,
  titleTooltip: PropTypes.string
};

export default RegistrationsList;
