import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Divider } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { BULK_EDIT_INPUTS } from './BulkEditInputs';
import AppFlexbox from '../../common/AppFlexbox';
import { singularPluralFormat } from '../../../helpers/format';
import { BULK_EDIT_FIELD_TYPE_ENUM } from '../../../config/constants';

const BulkEditSelectedModal = ({
  isOpen,
  onClose,
  selectedCount,
  dataFields,
  optionsState,
  onConfirm
}) => {
  const [formState, setFormState] = useState({ inputData: [] });
  const availableInputs = formState.inputData?.filter(
    (i) =>
      i.displayed &&
      i.dataField.type !== BULK_EDIT_FIELD_TYPE_ENUM.TEXT &&
      i.dataField.type !== BULK_EDIT_FIELD_TYPE_ENUM.TEXT_PRICE
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        inputData: dataFields.map((f) => ({
          key: f.value,
          dataField: f,
          value: f.defaultValue ?? '',
          displayed: f.displayed && !f.hidden && !f.noBulkEdit,
          removed: false
        }))
      });
    }
  }, [isOpen]);

  const onConfirmValues = () => {
    const changedInputData = formState.inputData.map((i) => ({
      dataField: i.dataField,
      value: i.removed ? i.dataField.defaultValue ?? '' : i.value,
      updateValue:
        i.displayed &&
        (i.removed || i.value !== (i.dataField.defaultValue ?? ''))
    }));

    onConfirm(changedInputData);
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: onConfirmValues,
        isSubmitHidden: availableInputs.length === 0,
        submitTitle: 'Confirm',
        padding: 0,
        footerProps: { style: { zIndex: 2000 } }
      }}
      isOpen={isOpen}
      onClose={onClose}
      sidePadding={20}
      size={500}
      title={`Change ${singularPluralFormat(
        selectedCount,
        'selected row',
        'selected rows'
      )} `}
    >
      {availableInputs.length === 0 ? (
        <AppStack style={{ gap: 0, position: 'relative' }}>
          <AppStack style={{ padding: 20, gap: 5 }}>
            <AppText
              style={{ fontSize: 14, color: '#666', textAlign: 'center' }}
            >
              No fields available for bulk edit. Please select a different set
              of rows or add columns to the table.
            </AppText>
          </AppStack>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 0, position: 'relative' }}>
          <AppStack style={{ gap: 5 }}>
            <AppText
              style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}
            >
              You are about to change{' '}
              {singularPluralFormat(selectedCount, 'row', 'rows')}.
            </AppText>
            <AppText
              style={{ fontSize: 14, color: '#666', textAlign: 'center' }}
            >
              Enter the new values below to change the current row values. Leave
              the input blank to keep the current row value.
            </AppText>

            <AppText
              style={{ fontSize: 13, color: '#666', textAlign: 'center' }}
            >
              <i>
                Remove the row values by clicking the 'remove' button. You can
                only do this for optional fields.
              </i>
            </AppText>
          </AppStack>

          <Divider />

          <AppStack style={{ padding: 20, gap: 16 }}>
            {availableInputs.map((i) => (
              <AppStack key={i.dataField.value} style={{ gap: 2 }}>
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between '
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    {i.dataField.label}
                  </AppText>

                  {!i.dataField.required && (
                    <Anchor
                      c={i.removed ? 'dodgerblue' : '#c40000'}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          inputData: formState.inputData.map((d) => {
                            if (d.dataField.value === i.dataField.value) {
                              return { ...d, removed: !d.removed };
                            }
                            return d;
                          })
                        });
                      }}
                      style={{ fontSize: 14 }}
                    >
                      {i.removed ? 'Undo' : 'Remove'}
                    </Anchor>
                  )}
                </AppFlexbox>
                {i.removed ? (
                  <AppFlexbox
                    style={{
                      minHeight: 36,
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: 'solid 1px #dee2e6'
                    }}
                  >
                    <AppText
                      style={{
                        fontSize: 14,
                        color: '#c40000',
                        fontWeight: 500
                      }}
                    >
                      The value will be removed from{' '}
                      {singularPluralFormat(selectedCount, 'row', 'rows')}.
                    </AppText>
                  </AppFlexbox>
                ) : (
                  <AppStack style={{ position: 'relative' }}>
                    {BULK_EDIT_INPUTS[i.dataField.type].render({
                      fieldData: {
                        ...i.dataField,
                        required: false
                      },
                      optionsState,
                      value: i.value,
                      onChange: (value) => {
                        setFormState({
                          ...formState,
                          inputData: formState.inputData.map((d) => {
                            if (d.dataField.value === i.dataField.value) {
                              return { ...d, value };
                            }
                            return d;
                          })
                        });
                      },
                      prefix: i.dataField.getPrefix
                        ? i.dataField.getPrefix(formState.inputData)
                        : '',
                      suffix: i.dataField.getSuffix
                        ? i.dataField.getSuffix(formState.inputData)
                        : '',
                      variant: 'default',
                      noLeftSection: true
                    })}
                  </AppStack>
                )}
              </AppStack>
            ))}
          </AppStack>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

BulkEditSelectedModal.propTypes = {
  dataFields: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  optionsState: PropTypes.object,
  selectedCount: PropTypes.number
};

export default BulkEditSelectedModal;
