import React from 'react';
import { PropTypes } from 'prop-types';
import { Checkbox, Grid, TextInput, Tooltip } from '@mantine/core';
import { Lock } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueries } from '../../../helpers/hooks';
import EXTERNAL_CONNECTION_FORM_SECTION_CONFIG from './externalConnectionFormSectionConfig';
import { REG_FORM_CONTROL_TYPE_ENUM } from '../../../config/constants';

const ExternalConnectionFormSection = ({
  regAssociationExternalConnection,
  regAssociation,
  formState,
  onChange,
  isEditorView,
  isDisabled
}) => {
  const { isTabletOrSmaller } = useMediaQueries();

  const config =
    EXTERNAL_CONNECTION_FORM_SECTION_CONFIG[
      regAssociationExternalConnection?.fkRegExternalConnectionType.toString()
    ];

  const connectionFormState =
    formState ||
    config?.controls
      .filter((c) => !!c.field)
      .reduce(
        (r, c) => ({
          ...r,
          [c.field]: c.defaultValue
        }),
        {}
      ) ||
    {};

  const onFormChange = (newValues) => {
    onChange({
      ...connectionFormState,
      ...newValues
    });
  };

  return (
    config && (
      <AppStack
        style={{
          gap: 5,
          margin: isTabletOrSmaller ? '0px 10px' : 0,
          cursor: isDisabled ? 'not-allowed' : 'normal'
        }}
      >
        <AppFlexbox style={{ display: 'inline-flex' }}>
          {isEditorView ? (
            <Tooltip
              color="blue"
              disabled={!isEditorView}
              label="This is a managed section and can not be modified"
              multiline
              width={250}
              withArrow
              withinPortal
            >
              <AppFlexbox style={{ gap: 5 }}>
                <AppText weight={700}>{config.title}</AppText>
                <Lock size={20} />
              </AppFlexbox>
            </Tooltip>
          ) : (
            <AppText weight={700}>{config.title}</AppText>
          )}
        </AppFlexbox>

        <Tooltip
          color="blue"
          disabled={!isEditorView}
          label="This is a managed section and can not be modified"
          multiline
          width={250}
          withArrow
          withinPortal
        >
          <AppStack
            role="region"
            style={{
              border: 'solid 1px lightgrey',
              position: 'relative'
            }}
          >
            <Grid
              styles={{
                root: { margin: 10, overflow: 'visible' },
                inner: {
                  margin: 0,
                  padding: 0,
                  width: 'unset',
                  position: 'relative'
                },
                col: { padding: 0 }
              }}
            >
              {config.controls
                .filter((c) => !c.isVisible || c.isVisible(connectionFormState))
                .map((control) => (
                  <Grid.Col
                    key={control.key}
                    role="gridcell"
                    span={
                      control.type === REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT
                        ? {
                            sm: 12,
                            md: 6,
                            lg: 4
                          }
                        : 12
                    }
                    style={{
                      padding: 8,
                      display: 'flex',
                      justifyContent: 'start',
                      placeItems: 'center',
                      minHeight: 80,
                      height: 'unset'
                    }}
                  >
                    {control.type === REG_FORM_CONTROL_TYPE_ENUM.TEXT ? (
                      <AppText
                        style={{
                          flex: 1,
                          fontSize: 16,
                          overflow: 'hidden',
                          minHeight: 80,
                          height: 'unset',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {control.getLabel
                          ? control.getLabel(regAssociation)
                          : control.label}
                      </AppText>
                    ) : control.type ===
                      REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT ? (
                      <TextInput
                        disabled={isDisabled}
                        label={
                          control.getLabel
                            ? control.getLabel(regAssociation)
                            : control.label
                        }
                        onChange={(e) =>
                          onFormChange({[control.field]: e.currentTarget.value})
                        }
                        required
                        style={{ flex: 1 }}
                        styles={{label: {
                            cursor: isDisabled ? 'not-allowed' : 'pointer'
                          }}}
                        value={connectionFormState[control.field] || ''}
                      />
                    ) : (
                      control.type === REG_FORM_CONTROL_TYPE_ENUM.CHECKBOX && (
                        <Checkbox
                          checked={connectionFormState[control.field] || false}
                          disabled={isDisabled}
                          label={
                            control.getLabel
                              ? control.getLabel(regAssociation)
                              : control.label
                          }
                          onChange={() =>
                            onFormChange({
                              [control.field]: !connectionFormState[
                                control.field
                              ]
                            })
                          }
                          spacing="lg"
                          style={{ flex: 1 }}
                          styles={{label: {
                              cursor: isDisabled ? 'not-allowed' : 'pointer'
                            }
                          }}
                        />
                      )
                    )}
                  </Grid.Col>
                ))}
            </Grid>
          </AppStack>
        </Tooltip>
      </AppStack>
    )
  );
};

ExternalConnectionFormSection.propTypes = {
  formState: PropTypes.object,
  isDisabled: PropTypes.bool,
  isEditorView: PropTypes.bool,
  onChange: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationExternalConnection: PropTypes.object
};

export default ExternalConnectionFormSection;
