import React from 'react';
import {
  REG_EXTERNAL_CONNECTION_TYPE_ENUM,
  REG_FORM_CONTROL_TYPE_ENUM
} from '../../../config/constants';

const EXTERNAL_CONNECTION_FORM_SECTION_CONFIG = {
  [REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA]: {
    title: 'Hockey Canada ID',
    dataField: 'hockeyCanadaId',
    verifiedDataField: 'hockeyCanadaIdVerified',
    reportable: true,
    controls: [
      {
        key: 'connection_message',
        type: REG_FORM_CONTROL_TYPE_ENUM.TEXT,
        label: 'Hockey Canada ID',
        getLabel: (regAssociation) => (
          <>
            Have you previously registered with <b>Hockey Canada</b> through{' '}
            <b>{regAssociation.association.name}</b>? If so, please enter your
            Hockey Canada ID below. If you have not registered yet, please check
            the box to indicate that you need to complete your registration.
          </>
        )
      },
      {
        key: 'connection_id',
        type: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT,
        label: 'Hockey Canada ID',
        field: 'value',
        defaultValue: '',
        isVisible: (formState) => !formState.skipConnection
      },
      {
        key: 'connection_skip',
        type: REG_FORM_CONTROL_TYPE_ENUM.CHECKBOX,
        field: 'skipConnection',
        defaultValue: false,
        getLabel: (regAssociation) => (
          <>
            I have not registed for Hockey Canada through{' '}
            {regAssociation.association.name} before.
          </>
        )
      }
    ]
  }
};

export default EXTERNAL_CONNECTION_FORM_SECTION_CONFIG;
