import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, TextInput } from '@mantine/core';
import { REG_FORM_CONTROL_TYPE_ENUM } from '../../../../config/constants';
import ResponsiveModal from '../../../../components/common/ResponsiveModal';
import AppStack from '../../../../components/common/AppStack';

const EditTemplateQuestionModal = ({
  isOpen,
  onClose,
  onConfirm,
  regAssociationTaskTemplateQuestion
}) => {
  const [formState, setFormState] = useState({
    value: '',
    fkRegFormControlType: REG_FORM_CONTROL_TYPE_ENUM.CHECKBOX.toString()
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        value: regAssociationTaskTemplateQuestion?.value || '',
        fkRegFormControlType:
          regAssociationTaskTemplateQuestion?.fkRegFormControlType.toString() ||
          REG_FORM_CONTROL_TYPE_ENUM.CHECKBOX.toString()
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => {
          onConfirm({
            ...regAssociationTaskTemplateQuestion,
            ...formState
          });
        },
        submitTitle: regAssociationTaskTemplateQuestion
          ? 'Save Task'
          : 'Add Task'
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title={regAssociationTaskTemplateQuestion ? 'Edit Task' : 'Add Task'}
    >
      <AppStack style={{ gap: 16 }}>
        <TextInput
          label="Task title"
          onChange={(e) =>
            setFormState({
              ...formState,
              value: e.currentTarget.value
            })
          }
          placeholder="Enter task title"
          required
          style={{ flex: 1 }}
          value={formState.value}
        />

        <Select
          clearable
          data={[
            {
              value: REG_FORM_CONTROL_TYPE_ENUM.CHECKBOX.toString(),
              label: 'Checkbox (default)'
            }
            // {
            //   value: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT.toString(),
            //   label: 'Text input'
            // },
            // {
            //   value: REG_FORM_CONTROL_TYPE_ENUM.FILE_UPLOAD.toString(),
            //   label: 'File upload'
            // }
          ]}
          description="Select a input type to allow custom inputs when completing the task."
          label="Input type"
          onChange={(v) =>
            setFormState({
              ...formState,
              fkRegFormControlType: v
            })
          }
          placeholder="Select input type"
          required
          searchable
          style={{ flex: 1 }}
          value={formState.fkRegFormControlType}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

EditTemplateQuestionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  regAssociationTaskTemplateQuestion: PropTypes.object
};

export default EditTemplateQuestionModal;
