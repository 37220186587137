import React from 'react';
import { Timeline } from '@mantine/core';
import PropTypes from 'prop-types';
import HistoryTimelineItemEditable from './HistoryTimelineItemEditable';

const HistoryTimeline = ({ data, onSaveNote, onDeleteNote }) => (
  <Timeline active={data.length} bulletSize={16} color="dark" lineWidth={2}>
    {data.map((item) => (
      // eslint-disable-next-line react/no-array-index-key
      <HistoryTimelineItemEditable
        key={item.key}
        {...item}
        onDeleteNote={(onSuccessCallback, onErrorCallback) =>
          onDeleteNote(item, onSuccessCallback, onErrorCallback)
        }
        onSaveNote={(d, onSuccessCallback, onErrorCallback) =>
          onSaveNote({ ...item, ...d }, onSuccessCallback, onErrorCallback)
        }
      />
    ))}
  </Timeline>
);

HistoryTimeline.propTypes = {
  data: PropTypes.array,

  onDeleteNote: PropTypes.func,
  onSaveNote: PropTypes.func
};

export default HistoryTimeline;
