import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Check, Selector, X } from 'tabler-icons-react';
import { ActionIcon, Menu, ScrollArea } from '@mantine/core';
import MenuContent from '../../common/MenuContent';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';

const CustomMenuSelectInput = ({
  data,
  value,
  clearable,
  searchable,
  onChange,
  controlProps,
  variant
}) => {
  const [menuState, setMenuState] = useState({
    isOpen: false,
    searchValue: ''
  });

  const selectedData = data.find((d) => d.value === value);

  return (
    <MenuContent
      closeOnClickOutside
      control={
        <AppFlexbox
          style={{
            flex: 1,
            height: 36,
            minHeight: 36,
            padding: '0px 12px 0px 8px',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: menuState.isOpen
              ? '1px solid #1e90ff'
              : variant === 'default'
              ? 'solid 1px #dee2e6'
              : 'none'
          }}
          {...controlProps}
        >
          <AppText style={{ fontSize: 14 }}>
            {data.find((d) => d.value === value)?.label ?? ''}
          </AppText>

          {selectedData && clearable ? (
            <ActionIcon
              color="#868e96"
              onClick={() => onChange(null)}
              variant="subtle"
            >
              <X size={15} />
            </ActionIcon>
          ) : (
            <Selector color="#868e96" size={15} />
          )}
        </AppFlexbox>
      }
      onChange={(isOpen) => {
        setMenuState({
          isOpen,
          searchValue: ''
        });
      }}
      opened={menuState.isOpen}
      position="bottom-start"
      shadow="xl"
      width={250}
    >
      {menuState.isOpen && (
        <ScrollArea.Autosize mah={225} mx="auto" scrollbarSize={5}>
          <AppStack style={{ gap: 0 }}>
            {data?.map((d) => (
              <Menu.Item
                key={d.value}
                onClick={() => onChange(d.value === value ? '' : d.value)}
              >
                <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                  {value === d.value && <Check color="#868e96" size={15} />}
                  <AppText style={{ fontSize: 14 }}>{d.label}</AppText>
                </AppFlexbox>
              </Menu.Item>
            ))}
          </AppStack>
        </ScrollArea.Autosize>
      )}
    </MenuContent>
  );
};

CustomMenuSelectInput.propTypes = {
  clearable: PropTypes.bool,
  controlProps: PropTypes.object,
  data: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchValue: PropTypes.any,
  searchable: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string
};

export default CustomMenuSelectInput;
