import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQueryIndex } from '../../helpers/hooks';
import AppCard from './AppCard';

const PageCard = ({ style, children, padding, ...props }) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();

  return (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: padding ?? 16, ...style }}
      withBorder
      {...props}
    >
      {children}
    </AppCard>
  );
};

PageCard.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.number,
  style: PropTypes.object
};

export default PageCard;
