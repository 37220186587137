import React, { useContext, useState } from 'react';
import {
  Button,
  Avatar,
  useMantineTheme,
  Menu,
  ScrollArea,
  Switch
} from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp, Help, Logout } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import { Context as RegistrationDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import { getInitialsFromName } from '../../../helpers/format';
import { links } from './links';
import AppFlexbox from '../../common/AppFlexbox';
import MenuContent from '../../common/MenuContent';
import AppText from '../../common/AppText';
import classes from '../../../styles/nestedStyles.module.css';
import SUPPORT_LINKS from './supportLinks';
import { LOCAL_API, REG_PERMISSION_ENUM } from '../../../config/constants';
import { getRegAssociationAdminPermissions } from '../../../helpers/adminHelper';
import AppStack from '../../common/AppStack';

const HeaderAccountNavigationMenu = ({ control, adminLinks, lightMode }) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { state, logout } = useContext(AuthContext);
  const { clearCart } = useContext(RegistrationContext);
  const [openState, setOpenState] = useState(false);
  const { state: dashboardState, changeClassicViewSettings } = useContext(
    RegistrationDashboardContext
  );
  const textColor = lightMode ? '#000' : '#FFF';
  const adminPermissions = getRegAssociationAdminPermissions(
    state.regAssociationAdmins.value
  );
  const adminDisplayLinks =
    adminLinks ||
    links.admin.filter(
      (link) =>
        adminPermissions.includes(REG_PERMISSION_ENUM.SUPER_ADMIN) ||
        link.permissions.some((p) => adminPermissions.includes(p))
    );

  const logoutUser = () => {
    logout();
    clearCart();
    setOpenState(false);
    navigate('/');
  };

  return (
    <MenuContent
      classNames={{
        item: lightMode
          ? classes['hover-button-light-mode']
          : classes['hover-button-dark-mode']
      }}
      control={
        control ? (
          <AppFlexbox
            onClick={() => setOpenState(true)}
            style={{ cursor: 'pointer' }}
          >
            {control}
          </AppFlexbox>
        ) : (
          <Button
            color={lightMode ? '#000' : '#FFF'}
            radius="xs"
            size="lg"
            style={{
              color: textColor,
              borderColor: lightMode ? theme.colors.gray[4] : theme.white
            }}
            variant="outline"
          >
            <Avatar
              radius="xl"
              src={state.userData.user.avatar}
              style={{ backgroundColor: theme.colors.yellow[9] }}
            >
              <AppText color={textColor}>
                {getInitialsFromName(state.userData.user.name)}
              </AppText>
            </Avatar>
            <AppText
              style={{ marginLeft: 10, marginRight: 5, fontSize: 16 }}
              weight={500}
            >
              My Account
            </AppText>
            {openState ? <ChevronUp /> : <ChevronDown />}
          </Button>
        )
      }
      onClose={() => setOpenState(false)}
      onOpen={() => setOpenState(true)}
      opened={openState}
      position="bottom-end"
      styles={{
        menu: {},
        dropdown: {
          padding: 0,
          backgroundColor: lightMode ? theme.white : theme.colors.dark[6],
          borderColor: lightMode ? theme.white : theme.colors.dark[6],
          maxHeight: 'calc(100vh - 90px)',
          overflowY: 'hidden'
        },
        item: {
          padding: '10px 16px',
          color: textColor
        }
      }}
      width={240}
    >
      <AppFlexbox
        style={{
          flex: 1,
          gap: 0,
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 90px)'
        }}
      >
        <AppFlexbox
          component={Link}
          onClick={() => setOpenState(false)}
          style={{
            flex: 1,
            padding: '10px 16px',
            paddingTop: 14,
            placeItems: 'center',
            textDecoration: 'none',
            color: '#000'
          }}
          to="settings"
        >
          <Avatar
            radius="xl"
            src={state.userData.user.avatar}
            style={{ backgroundColor: theme.colors.yellow[9] }}
          >
            <AppText color={textColor}>
              {getInitialsFromName(state.userData.user.name)}
            </AppText>
          </Avatar>
          <AppFlexbox
            style={{
              flex: 1,
              overflow: 'hidden',
              flexDirection: 'column',
              gap: 0
            }}
          >
            <AppText color={textColor} size="14px" style={{ lineHeight: 1.55 }}>
              {state.userData.user.name}
            </AppText>
            <AppText color={textColor} size="14px" style={{ lineHeight: 1.55 }}>
              {state.userData.user.email}
            </AppText>
          </AppFlexbox>
        </AppFlexbox>
        <Menu.Divider />

        <AppFlexbox
          style={{
            gap: 0,
            height: '100%',
            flex: 1,
            overflow: 'hidden'
          }}
        >
          <ScrollArea style={{ minWidth: 0, width: '100%' }} type="auto">
            {state.userData.isAdmin &&
              LOCAL_API &&
              state.userData.isRegistrationAdmin && (
                <>
                  <Menu.Item
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      changeClassicViewSettings({
                        isClassicView: !dashboardState.view.isClassicView
                      });
                    }}
                  >
                    <AppStack style={{ gap: 5 }}>
                      <Switch
                        checked={!dashboardState.view.isClassicView}
                        label="Experimental UI "
                        style={{ fontWeight: 'normal' }}
                      />
                      <AppText
                        style={{
                          fontSize: 13,
                          color: textColor,
                          fontWeight: 'normal'
                        }}
                      >
                        Try new features and improvements as they are developed.
                      </AppText>
                    </AppStack>
                  </Menu.Item>
                  <Menu.Divider />
                </>
              )}

            {links.profile.map((link) => (
              <Menu.Item
                key={link.title}
                component={Link}
                leftSection={<link.icon />}
                styles={{ root: { backgroundColor: 'blue' } }}
                to={link.to}
              >
                {link.title}
              </Menu.Item>
            ))}
            <Menu.Divider />
            {state.userData.isRegistrationAdmin &&
              adminDisplayLinks.length > 0 && (
                <>
                  {adminDisplayLinks.map((link) => (
                    <Menu.Item
                      key={link.title}
                      component={Link}
                      leftSection={<link.icon />}
                      to={link.to}
                    >
                      {link.title}
                    </Menu.Item>
                  ))}

                  <Menu.Divider />
                  <Menu.Item
                    component="a"
                    href={SUPPORT_LINKS.index.href}
                    leftSection={<Help />}
                    target="_blank"
                  >
                    {SUPPORT_LINKS.index.label}
                  </Menu.Item>
                  <Menu.Divider />
                </>
              )}
            <Menu.Item
              leftSection={<Logout style={{ transform: 'rotate(180deg)' }} />}
              onClick={logoutUser}
              style={{ paddingBottom: 14 }}
            >
              Sign out
            </Menu.Item>
          </ScrollArea>
        </AppFlexbox>
      </AppFlexbox>
    </MenuContent>
  );
};

HeaderAccountNavigationMenu.propTypes = {
  adminLinks: PropTypes.array,
  control: PropTypes.node,
  lightMode: PropTypes.bool
};

export default HeaderAccountNavigationMenu;
