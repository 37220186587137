import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Grid,
  Select,
  TextInput,
  Checkbox,
  Radio,
  Button,
  Box,
  Tooltip
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import {
  Calendar,
  FileDownload,
  FileUpload,
  Signature
} from 'tabler-icons-react';
import Draggable from 'react-draggable';
import NumberFormat from 'react-number-format';
import { useHover } from '@mantine/hooks';
import { EditorContent } from '@tiptap/react';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import { downloadUrlFile } from '../../../helpers/awsHelper';
import SignatureControlModal from './SignatureControlModal';
import FileControlModal from './FileControlModal';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import AppRadioGroup from '../../common/AppRadioGroup';
import AppTextEditorContent from '../../common/AppTextEditorContent';
import { tryJsonParse } from '../../../helpers/storageHelper';

const formControls = {
  TEXT_INPUT: TextInput,
  DROP_DOWN: Select,
  DATE: DatePickerInput,
  RADIO_GROUP: Radio.Group,
  CHECKBOX: Checkbox,
  TEXT: Text
};

const RegistrationFormControl = ({
  draggableSectionId,
  isMoveable,
  isDisabled,
  isViewable,
  control,
  label,
  submissionAnswer,
  onSelectControl,
  onMoveControl,
  onStopMovingControl,
  onResizeControl,
  onStopResizingControl,
  onInputChange,
  gridSize,
  isSelected,
  errors,
  isOverlapping
}) => {
  const { hovered, ref } = useHover();
  const mqIndex = useMediaQueryIndex();
  const componentRef = useRef(null);
  const [isBeingResized, setIsBeingResized] = useState(false);
  const [isBeingMoved, setIsBeingMoved] = useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const ControlComponent =
    formControls[control.type] ?? formControls.TEXT_INPUT;
  const selectedBorderColor =
    (isSelected || isBeingResized) && isOverlapping
      ? 'solid 1px red'
      : 'solid 1px lightgrey';
  const constraints = control.constraints.reduce(
    (obj, item) => Object.assign(obj, { [item.name]: item.value }),
    {}
  );

  return (
    <Tooltip
      disabled={isMoveable || !control.tooltip}
      label={control.tooltip}
      multiline
      style={{ maxWidth: 250 }}
      withArrow
      withinPortal
    >
      <Grid.Col
        ref={ref}
        role="gridcell"
        span={{
          sm: 12,
          md:
            control.type === 'TEXT' || control.type === 'RADIO_GROUP' ? 12 : 6,
          lg: control.positionSpan
        }}
        style={{ zIndex: isSelected ? 3 : 2 }}
      >
        <Draggable
          bounds={draggableSectionId}
          disabled={!isMoveable}
          grid={gridSize}
          onDrag={(e, data) => {
            if (!isBeingResized) {
              setIsBeingMoved(true);
              onMoveControl(e, data, componentRef.current);
              return true;
            }
            return false;
          }}
          onMouseDown={(e) => {
            isMoveable && !isDisabled && onSelectControl(e, control);
          }}
          onStop={(e, data) => {
            if (!isBeingResized) {
              onStopMovingControl(e, data, componentRef.current);
              setIsBeingMoved(false);
            }
            else {
              setIsBeingResized(false);
            }
          }}
          position={{ x: 0, y: 0 }}
        >
          <AppFlexbox
            style={{
              cursor: isMoveable
                ? 'pointer'
                : isDisabled
                ? 'not-allowed'
                : 'default',
              padding: 0,
              justifyContent: 'start',
              placeItems: 'center',
              minHeight: 80,
              height:
                control.type === 'TEXT' && !isBeingMoved
                  ? 'unset'
                  : rs(['unset', 'unset', 'unset', 'unset', 80], mqIndex),
              border: hovered
                ? isMoveable
                  ? selectedBorderColor
                  : null
                : isSelected && isMoveable
                ? selectedBorderColor
                : null
            }}
          >
            {control.type === 'TEXT' ? (
              control.content ? (
                <AppStack
                  ref={componentRef}
                  style={{
                    flex: 1,
                    margin: 8,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minHeight: 80,
                    maxHeight: isBeingMoved ? 80 : 'unset',
                    userSelect: isDisabled || isMoveable ? 'none' : 'text',
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  <AppTextEditorContent
                    content={tryJsonParse(control.content)}
                  />
                </AppStack>
              ) : (
                <AppText
                  ref={componentRef}
                  style={{
                    flex: 1,
                    margin: 8,
                    textAlign: constraints.textAlign,
                    fontStyle:
                      constraints.italicize === 'true' ? 'italic' : 'none',
                    textDecoration:
                      constraints.underline === 'true' ? 'underline' : 'none',
                    fontSize: constraints.fontSize,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minHeight: 80,
                    maxHeight: isBeingMoved ? 80 : 'unset',
                    userSelect: isDisabled || isMoveable ? 'none' : 'text',
                    whiteSpace: 'pre-wrap'
                  }}
                  weight={constraints.bold === 'true' ? 700 : 'normal'}
                >
                  {control.title}
                </AppText>
              )
            ) : control.type === 'DROP_DOWN' ? (
              <Select
                ref={componentRef}
                clearable={!isDisabled && !isMoveable}
                data={
                  control.options
                    ?.filter(
                      (c, i, a) =>
                        c.option &&
                        a.findIndex((c2) => c2.option === c.option) === i
                    )
                    .map((option) => ({
                      value: option.option,
                      label:
                        constraints.isProduct === 'true'
                          ? `${option.option} - ${
                              option.product?.price < 0
                                ? `(${currencyFormat(option.product?.price)})`
                                : currencyFormat(option.product?.price)
                            }`
                          : option.option
                    })) ?? []
                }
                disabled={isDisabled || isMoveable}
                label={label ?? control.title}
                onChange={(value) => {
                  onInputChange(control.pkRegFormControl, value);
                }}
                required={constraints.required === 'true'}
                searchable
                style={{
                  margin: 8,
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                styles={
                  isMoveable
                    ? {
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' }
                      }
                    : isDisabled
                    ? { label: { cursor: 'not-allowed' } }
                    : null
                }
                value={submissionAnswer?.answer ?? ''}
              />
            ) : control.type === 'DATE' ? (
              <DatePickerInput
                ref={componentRef}
                clearable
                disabled={isDisabled || isMoveable}
                error={errors ? errors[control.pkRegFormControl]?.value : null}
                label={label ?? control.title}
                leftSection={<Calendar color="#000" size={16} />}
                maxDate={
                  constraints.maxDate &&
                  new Date(formatUtcDate(constraints.maxDate))
                }
                minDate={
                  constraints.minDate &&
                  new Date(formatUtcDate(constraints.minDate))
                }
                onChange={(value) =>
                  onInputChange(control.pkRegFormControl, value?.toISOString())
                }
                required={constraints.required === 'true'}
                style={{
                  margin: 8,
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                styles={
                  isMoveable
                    ? {
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' }
                      }
                    : isDisabled
                    ? { label: { cursor: 'not-allowed' } }
                    : null
                }
                value={
                  submissionAnswer?.answer
                    ? new Date(submissionAnswer.answer.toUpperCase())
                    : null
                }
              />
            ) : control.type === 'RADIO_GROUP' ? (
              <AppRadioGroup
                ref={componentRef}
                disabled={isDisabled || isMoveable}
                error={errors ? errors[control.pkRegFormControl]?.value : null}
                label={label ?? control.title}
                onChange={() => {}}
                orientation="horizontal"
                required={constraints.required === 'true'}
                size="sm"
                style={{
                  margin: 8,
                  padding: 0,
                  gap: 8,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                styles={
                  isMoveable
                    ? {
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' }
                      }
                    : isDisabled
                    ? { label: { cursor: 'not-allowed' } }
                    : null
                }
                value={submissionAnswer?.answer ?? ''}
              >
                {control.options
                  .sort((c) => c.positionOrder)
                  .filter(
                    (c, i, a) =>
                      c.option &&
                      a.findIndex((c2) => c2.option === c.option) === i
                  )
                  .map((option) => (
                    <Radio
                      key={option.pkRegFormControlOption}
                      disabled={isDisabled || isMoveable}
                      label={
                        constraints.isProduct === 'true' ? (
                          <AppText size="sm">
                            {option.option} -{' '}
                            {option.product?.price < 0 ? (
                              <>
                                (
                                <NumberFormat
                                  decimalScale={2}
                                  displayType="text"
                                  fixedDecimalScale
                                  prefix="$"
                                  thousandSeparator
                                  value={option.product?.price}
                                />
                                )
                              </>
                            ) : (
                              <NumberFormat
                                decimalScale={2}
                                displayType="text"
                                fixedDecimalScale
                                prefix="$"
                                thousandSeparator
                                value={option.product?.price}
                              />
                            )}
                          </AppText>
                        ) : (
                          option.option
                        )
                      }
                      onClick={() => {
                        const isOptionSelected =
                          option.option === submissionAnswer?.answer;
                        onInputChange(
                          control.pkRegFormControl,
                          isOptionSelected ? null : option.option
                        );
                      }}
                      size="xs"
                      style={{ width: rs(['100%', 'unset'], mqIndex) }}
                      value={option.option}
                    />
                  ))}
              </AppRadioGroup>
            ) : control.type === 'CHECKBOX' ? (
              <Checkbox
                ref={componentRef}
                checked={submissionAnswer?.answer.toLowerCase() === 'true'}
                disabled={isDisabled || isMoveable}
                label={
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText>{control.title}</AppText>
                    {constraints.isProduct === 'true' && (
                      <AppText>
                        -{' '}
                        {control.product?.price < 0 ? (
                          <>
                            (
                            <NumberFormat
                              decimalScale={2}
                              displayType="text"
                              fixedDecimalScale
                              prefix="$"
                              thousandSeparator
                              value={control.product?.price}
                            />
                            )
                          </>
                        ) : (
                          <NumberFormat
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={control.product?.price}
                          />
                        )}
                      </AppText>
                    )}
                    {constraints.required === 'true' && (
                      <AppText style={{ color: '#f03e3e' }} weight={500}>
                        *
                      </AppText>
                    )}
                  </AppFlexbox>
                }
                onChange={(e) =>
                  onInputChange(
                    control.pkRegFormControl,
                    e.currentTarget.checked.toString()
                  )
                }
                required={constraints.required === 'true'}
                style={{
                  margin: 8,
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                styles={
                  isMoveable
                    ? {
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' },
                        body: { alignItems: 'center' }
                      }
                    : isDisabled
                    ? {
                        label: { cursor: 'not-allowed' },
                        body: { alignItems: 'center' }
                      }
                    : null
                }
              />
            ) : control.type === 'FILE_UPLOAD' ? (
              <AppStack
                style={{
                  margin: 8,
                  maxHeight: 80,
                  flex: 1,
                  gap: 4,
                  justifyContent: 'center',
                  overflow: 'hidden',
                  alignSelf: 'center'
                }}
              >
                <AppStack style={{ justifyContent: 'flex-end', flex: 1 }}>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14 }} weight={500}>
                      {control.title}
                    </AppText>
                    {constraints.required === 'true' && (
                      <AppText style={{ color: '#f03e3e' }} weight={500}>
                        *
                      </AppText>
                    )}
                  </AppFlexbox>
                </AppStack>
                <Button
                  color="dark"
                  disabled={
                    isMoveable ||
                    (isDisabled && !(isViewable && submissionAnswer?.answer))
                  }
                  leftSection={isDisabled ? <FileDownload /> : <FileUpload />}
                  onClick={() => {
                    if (isDisabled) {
                      downloadUrlFile(submissionAnswer.answer);
                    }
                    else {
                      setIsFileUploadModalOpen(true);
                    }
                  }}
                  style={{
                    flex: 1,
                    cursor:
                      !submissionAnswer?.answer && isViewable
                        ? 'not-allowed'
                        : 'pointer'
                  }}
                  styles={{ inner: { height: 36 } }}
                >
                  {isViewable && isDisabled
                    ? submissionAnswer?.answer
                      ? 'Download File'
                      : 'No File'
                    : submissionAnswer?.answer
                    ? 'Update File'
                    : 'Upload File'}
                </Button>
                <FileControlModal
                  file={submissionAnswer?.answer}
                  isOpen={isFileUploadModalOpen}
                  onClose={() => setIsFileUploadModalOpen(false)}
                  onConfirm={(file) => {
                    onInputChange(control.pkRegFormControl, file);
                    setIsFileUploadModalOpen(false);
                  }}
                />
              </AppStack>
            ) : control.type === 'FILE_DOWNLOAD' ? (
              <AppStack
                style={{
                  margin: 8,
                  flex: 1,
                  gap: 4,
                  overflow: 'hidden',
                  alignSelf: 'center'
                }}
              >
                <AppStack style={{ justifyContent: 'flex-end', flex: 1 }}>
                  <AppText
                    style={{
                      fontSize: 14,
                      whiteSpace: 'nowrap',
                      flex: 1
                    }}
                    weight={500}
                  >
                    {control.title}
                  </AppText>
                </AppStack>

                {control.documents[0] && !isMoveable ? (
                  <Button
                    color="dark"
                    disabled={isDisabled}
                    leftSection={<FileDownload />}
                    onClick={() =>
                      downloadUrlFile(
                        control.documents[0].url,
                        control.documents[0].fileName
                      )
                    }
                    style={{ flex: 1 }}
                    styles={{ inner: { height: 36 } }}
                  >
                    Download
                  </Button>
                ) : (
                  <Button
                    color="dark"
                    disabled
                    leftSection={<FileDownload />}
                    style={{
                      flex: 1,
                      cursor: isMoveable ? 'pointer' : 'not-allowed'
                    }}
                    styles={{ inner: { height: 36 } }}
                  >
                    Download
                  </Button>
                )}
              </AppStack>
            ) : control.type === 'SIGNATURE' ? (
              <AppStack
                style={{
                  margin: 8,
                  flex: 1,
                  gap: 4,
                  overflow: 'hidden',
                  alignSelf: 'center'
                }}
              >
                <AppStack style={{ justifyContent: 'flex-end', flex: 1 }}>
                  <AppFlexbox style={{ gap: 5 }}>
                    <AppText style={{ fontSize: 14 }} weight={500}>
                      {control.title}
                    </AppText>
                    {constraints.required === 'true' && (
                      <AppText style={{ color: '#f03e3e' }} weight={500}>
                        *
                      </AppText>
                    )}
                  </AppFlexbox>
                </AppStack>

                <Button
                  color="dark"
                  disabled={
                    isMoveable ||
                    (isDisabled && !(isViewable && submissionAnswer?.answer))
                  }
                  leftSection={<Signature />}
                  onClick={() => setIsSignatureModalOpen(true)}
                  style={{
                    flex: 1,
                    cursor:
                      isViewable && !submissionAnswer?.answer
                        ? 'not-allowed'
                        : 'pointer'
                  }}
                  styles={{ inner: { height: 36 } }}
                >
                  {isViewable && isDisabled
                    ? submissionAnswer?.answer
                      ? 'View Signature'
                      : 'No Signature'
                    : submissionAnswer?.answer
                    ? 'Update Signature'
                    : 'Enter Signature'}
                </Button>
                <SignatureControlModal
                  base64String={submissionAnswer?.answer}
                  isDisabled={isDisabled}
                  isOpen={isSignatureModalOpen}
                  onClose={() => setIsSignatureModalOpen(false)}
                  onConfirm={(base64String) => {
                    onInputChange(control.pkRegFormControl, base64String);
                    setIsSignatureModalOpen(false);
                  }}
                />
              </AppStack>
            ) : (
              <ControlComponent
                ref={componentRef}
                disabled={isDisabled || isMoveable}
                label={label ?? control.title}
                onChange={(e) => {
                  let textValue = e.currentTarget.value;
                  if (control.type === 'TEXT_INPUT') {
                    if (constraints.allowNumbers === 'false') {
                      textValue = textValue.replace(/[^a-z]/gi, '');
                    }
                    if (constraints.allowLetters === 'false') {
                      textValue = textValue.replace(/\D/g, '');
                    }
                  }
                  onInputChange(control.pkRegFormControl, textValue);
                }}
                required={constraints.required === 'true'}
                style={{
                  margin: 8,
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                styles={
                  isMoveable
                    ? {
                        input: { cursor: 'pointer !important' },
                        label: { cursor: 'pointer' }
                      }
                    : isDisabled
                    ? { label: { cursor: 'not-allowed' } }
                    : null
                }
                value={submissionAnswer?.answer ?? ''}
              />
            )}
            {isMoveable && (
              <>
                <Box
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    zIndex: 8
                  }}
                />
                {isSelected && control.type !== 'TEXT' && (
                  <>
                    <Box
                      style={{
                        position: 'absolute',
                        borderRadius: 20,
                        left: -8,
                        width: 15,
                        height: 15,
                        top: 'calc(50% - 8px)',
                        backgroundColor: 'dodgerblue',
                        boxSizing: 'border-box',
                        zIndex: 9
                      }}
                    />
                    <Box
                      style={{
                        position: 'absolute',
                        borderRadius: 20,
                        right: -8,
                        width: 15,
                        height: 15,
                        top: 'calc(50% - 8px)',
                        backgroundColor: 'dodgerblue',
                        boxSizing: 'border-box',
                        zIndex: 9
                      }}
                    />
                    <Draggable
                      axis="x"
                      disabled={!isMoveable}
                      grid={[25, 25]}
                      onDrag={(e, data) => {
                        onResizeControl(
                          control,
                          data,
                          'left',
                          componentRef.current
                        );
                      }}
                      onMouseDown={() => setIsBeingResized(true)}
                      onStop={(e, data) =>
                        onStopResizingControl(e, data, 'left')
                      }
                      position={{ x: 0, y: 0 }}
                      positionOffset={{ x: 8 }}
                    >
                      <Box
                        style={{
                          cursor: 'w-resize',
                          position: 'absolute',
                          left: -8,
                          width: 15,
                          top: '0',
                          height: '100%',
                          boxSizing: 'border-box',
                          zIndex: 10
                        }}
                      />
                    </Draggable>

                    <Draggable
                      axis="x"
                      disabled={!isMoveable}
                      grid={[25, 25]}
                      onDrag={(e, data) => {
                        onResizeControl(
                          control,
                          data,
                          'right',
                          componentRef.current
                        );
                      }}
                      onMouseDown={() => setIsBeingResized(true)}
                      onStop={(e, data) =>
                        onStopResizingControl(e, data, 'right')
                      }
                      position={{ x: 0, y: 0 }}
                      positionOffset={{ x: 8 }}
                    >
                      <Box
                        style={{
                          cursor: 'e-resize',
                          position: 'absolute',
                          right: -8,
                          width: 15,
                          height: 15,
                          top: 'calc(50% - 10px)',
                          boxSizing: 'border-box',
                          zIndex: 10
                        }}
                      />
                    </Draggable>
                  </>
                )}
              </>
            )}
          </AppFlexbox>
        </Draggable>
      </Grid.Col>
    </Tooltip>
  );
};

RegistrationFormControl.propTypes = {
  control: PropTypes.object,
  draggableSectionId: PropTypes.string,
  errors: PropTypes.object,
  gridSize: PropTypes.array,
  isDisabled: PropTypes.bool,
  isMoveable: PropTypes.bool,
  isOverlapping: PropTypes.bool,
  isSelected: PropTypes.bool,
  isViewable: PropTypes.bool,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  onMoveControl: PropTypes.func,
  onResizeControl: PropTypes.func,
  onSelectControl: PropTypes.func,
  onStopMovingControl: PropTypes.func,
  onStopResizingControl: PropTypes.func,
  showGrid: PropTypes.bool,
  submissionAnswer: PropTypes.object
};

export default RegistrationFormControl;
