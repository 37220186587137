import React from 'react';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import CharacterCount from '@tiptap/extension-character-count';
import PropTypes from 'prop-types';
import { Alert } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';

const AppTextEditor = ({
  characterLimit,
  limitReachedMessage,
  minHeight,
  value,
  onChange
}) => {
  const charLimit = characterLimit ?? 1000;
  const textEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      CharacterCount.configure({ limit: charLimit })
    ],
    content: value,
    onUpdate: ({ editor }) => onChange(editor.getJSON())
  });
  const editorCharacterCount =
    textEditor?.storage.characterCount.characters() || 0;

  return (
    <AppStack style={{ gap: 5 }}>
      <RichTextEditor
        editor={textEditor}
        styles={{
          controlIcon: {
            width: 20,
            height: 20
          },
          content: {
            minHeight: minHeight ?? 300,
            display: 'flex',
            flexDirection: 'column',
            cursor: 'text'
          },
          root: { minHeight: minHeight ?? 300 }
        }}
      >
        <RichTextEditor.Toolbar sticky stickyOffset={40}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>

      <AppFlexbox style={{ alignContent: 'center', justifyContent: 'end' }}>
        <AppText style={{ fontSize: 14, color: '#666' }}>
          {editorCharacterCount} / {charLimit} characters
        </AppText>
      </AppFlexbox>

      {!!limitReachedMessage && editorCharacterCount >= charLimit && (
        <Alert
          color="#eca70a"
          icon={<AlertCircle color="#eca70a" />}
          variant="outline"
        >
          <AppText style={{ fontSize: 14 }}>{limitReachedMessage}</AppText>
        </Alert>
      )}
    </AppStack>
  );
};

AppTextEditor.propTypes = {
  characterLimit: PropTypes.number,
  limitReachedMessage: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default AppTextEditor;
