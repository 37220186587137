import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider } from '@mantine/core';
import RecordPaymentPriceItem from './RecordPaymentPriceItem';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';

const RefundTransactionOverview = ({
  transactionTotal,
  serviceFee,
  amountRefunded,
  currency,
  isInvoicePayment
}) => (
  <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
    <AppText style={{ color: 'dodgerblue', fontSize: 18 }} weight={700}>
      Overview
    </AppText>
    <Divider size={2} />

    <RecordPaymentPriceItem
      currency={currency}
      label={isInvoicePayment ? 'Invoice Cost' : 'Registration Cost'}
      value={transactionTotal}
    />

    {serviceFee > 0 && (
      <RecordPaymentPriceItem
        color="#FA5252"
        currency={currency}
        label="Service Fee"
        value={serviceFee}
      />
    )}

    {amountRefunded > 0 && (
      <RecordPaymentPriceItem
        color="#FA5252"
        currency={currency}
        label="Amount Refunded"
        value={amountRefunded}
      />
    )}

    <RecordPaymentPriceItem
      currency={currency}
      isDividerHidden
      label="Refundable Balance"
      value={transactionTotal - serviceFee - amountRefunded}
    />
  </AppStack>
);

RefundTransactionOverview.propTypes = {
  amountRefunded: PropTypes.number,
  currency: PropTypes.string,
  isInvoicePayment: PropTypes.bool,
  serviceFee: PropTypes.number,
  transactionTotal: PropTypes.number
};
export default RefundTransactionOverview;
