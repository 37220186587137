import React, { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Checkbox, Divider, Radio, TextInput } from '@mantine/core';
import NumberFormat from 'react-number-format';
import { AlertCircle } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import RegistrationListItem from './RegistrationListItem';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { currencyFormat } from '../../../helpers/format';
import { calculatePassedOnFeeInCents } from '../../../helpers/paymentHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppRadioGroup from '../../common/AppRadioGroup';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';

const RegistrationInvoiceCreateModal = ({
  regAssociation,
  regFormSubmission,
  isOpen,
  onClose,
  invoiceRemainingBalance,
  onInvoice
}) => {
  const hasFetchedPayments = useRef(false);
  const {
    state,
    sendAdminRegistrationInvoice,
    fetchAdminRegFormSubmissionPayments
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    invoiceAmount: 0,
    description: '',
    isOfflineInvoice: false,
    invoiceStatus: '',
    isAdditionalCharge: true,
    customerPaysServiceFees: false,
    loading: false,
    showConfirm: false
  });
  const isLoading =
    !hasFetchedPayments.current || state.regFormSubmissionBalance.loading;
  const regFormSubmissionBalance = state.regFormSubmissionBalance.value;
  const remainingRegistrationBalance = regFormSubmissionBalance
    ? regFormSubmissionBalance.balance.balanceInCents / 100
    : 0;
  const registrationInvoiceBalance = regFormSubmissionBalance
    ? regFormSubmissionBalance.balance.invoices
        .filter(
          (i) =>
            !i.isAdditionalCharge &&
            (i.invoiceStatus === 1 || i.invoiceStatus === 2)
        )
        .reduce((r, c) => r + c.balanceInCents, 0)
    : 0;
  const adminRegAssociation = state.regAssociations.value.find(
    (a) =>
      a.pkRegAssociation.toString() ===
      regAssociation?.pkRegAssociation?.toString()
  );
  const currency = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value.toString() ===
      adminRegAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString()
  )?.abbr;

  useEffect(() => {
    if (isOpen) {
      setFormState({
        invoiceAmount: 0,
        description: '',
        isOfflineInvoice: false,
        invoiceStatus: '',
        isAdditionalCharge: !invoiceRemainingBalance,
        customerPaysServiceFees:
          regFormSubmission?.regAssociationDivisionForm.customerPaysServiceFees,
        loading: false,
        showConfirm: false
      });

      if (
        regFormSubmissionBalance?.pkRegFormSubmission !==
        regFormSubmission.pkRegFormSubmission
      ) {
        fetchAdminRegFormSubmissionPayments(
          regFormSubmission.pkRegFormSubmission
        );
      }

      hasFetchedPayments.current = true;
    }
  }, [isOpen]);

  useEffect(() => {
    if (regFormSubmissionBalance && invoiceRemainingBalance) {
      setFormState({
        ...formState,
        invoiceAmount: invoiceRemainingBalance
          ? remainingRegistrationBalance
          : 0
      });
    }
  }, [regFormSubmissionBalance]);

  const passedOnServiceFee =
    regFormSubmissionBalance &&
    regFormSubmission?.regAssociationDivisionForm.customerPaysServiceFees &&
    regFormSubmissionBalance.balance.serviceFeeRates
      ? calculatePassedOnFeeInCents(
          formState.invoiceAmount * 100,
          regFormSubmissionBalance.balance.serviceFeeRates
            .serviceFlatFeeInCents +
            (formState.invoiceAmount >= 150
              ? regFormSubmissionBalance.balance.serviceFeeRates
                  .platformFlatFeeInCents
              : 0),
          regFormSubmissionBalance.balance.serviceFeeRates.servicePercentageFee
        ) / 100
      : 0;

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: formState.showConfirm ? 'Back' : 'Cancel',
        isLoading: formState.loading,
        onCancel: () => {
          if (!formState.showConfirm) {
            onClose();
          }
          else {
            setFormState({
              ...formState,
              showConfirm: false
            });
          }
        },
        onSubmit: () => {
          if (!formState.showConfirm) {
            setFormState({ ...formState, showConfirm: true });
          }
          else {
            setFormState({
              ...formState,
              loading: true
            });
            sendAdminRegistrationInvoice(
              regFormSubmission.pkRegFormSubmission,
              {
                invoiceAmount: formState.invoiceAmount,
                isOfflineInvoice: formState.isOfflineInvoice,
                description: formState.description,
                isAdditionalCharge:
                  formState.isOfflineInvoice || formState.isAdditionalCharge,
                customerPaysServiceFees:
                  !formState.isOfflineInvoice &&
                  regFormSubmission.regAssociationDivisionForm
                    .customerPaysServiceFees &&
                  formState.customerPaysServiceFees
              },
              () => {
                fetchAdminRegFormSubmissionPayments(
                  regFormSubmission.pkRegFormSubmission
                );
                triggerNotification(
                  formState.isOfflineInvoice
                    ? 'Invoice created'
                    : 'Invoice Sent',
                  'Success',
                  'green'
                );
                onClose();
                if (onInvoice) {
                  onInvoice();
                }
              },
              (e) => {
                setFormState({
                  ...formState,
                  loading: false
                });
                triggerNotification(e);
              }
            );
          }
        },
        submitTitle: formState.showConfirm ? 'Invoice' : 'Continue to preview'
      }}
      isLoading={isLoading}
      onClose={onClose}
      opened={isOpen}
      size={600}
      title="Invoice User"
    >
      {!isLoading && (
        <AppStack style={{ gap: 20 }}>
          <RegistrationListItem
            regAssociation={regAssociation}
            regFormSubmission={regFormSubmission}
          />
          {formState.showConfirm ? (
            <>
              <Divider />
              <AppText style={{ textAlign: 'center' }}>
                Are you sure you want to <b>invoice</b> the selected
                registration?
              </AppText>
            </>
          ) : (
            <AppStack style={{ gap: 20 }}>
              <AppRadioGroup
                disabled
                onChange={(value) =>
                  setFormState({
                    ...formState,
                    isOfflineInvoice: value === 'offline'
                  })
                }
                size="sm"
                styles={{
                  input: { cursor: 'pointer !important' },
                  label: { cursor: 'pointer' }
                }}
                value={formState.isOfflineInvoice ? 'offline' : 'stripe'}
              >
                <Radio
                  disabled={invoiceRemainingBalance}
                  label="Email invoice to user"
                  style={{ fontSize: 14, fontWeight: 500 }}
                  value="stripe"
                />
                <Radio
                  disabled={invoiceRemainingBalance}
                  label="Offline payments only"
                  style={{ fontSize: 14, fontWeight: 500 }}
                  value="offline"
                />
              </AppRadioGroup>
              <NumberFormat
                allowNegative={false}
                customInput={TextInput}
                decimalScale={2}
                disabled={formState.loading || invoiceRemainingBalance}
                fixedDecimalScale
                label="Charge Amount"
                name="chargeAmount"
                onValueChange={(values) => {
                  let invoiceAmount = values.floatValue;
                  if (!formState.isAdditionalCharge) {
                    if (invoiceAmount > remainingRegistrationBalance) {
                      invoiceAmount = remainingRegistrationBalance;
                    }
                  }
                  setFormState({
                    ...formState,
                    invoiceAmount
                  });
                }}
                placeholder="$100.00"
                prefix="$"
                required
                rightSection={
                  currency && (
                    <AppText
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#999',
                        paddingRight: 5
                      }}
                    >
                      {currency}
                    </AppText>
                  )
                }
                style={{ flex: 1 }}
                thousandSeparator
                value={formState.invoiceAmount}
              />
              {!formState.isOfflineInvoice &&
                remainingRegistrationBalance - registrationInvoiceBalance >
                  0 && (
                  <AppRadioGroup
                    onChange={(value) =>
                      setFormState({
                        ...formState,
                        isAdditionalCharge: value === 'ADD-ON',
                        invoiceAmount:
                          formState.invoiceAmount > remainingRegistrationBalance
                            ? remainingRegistrationBalance
                            : formState.invoiceAmount
                      })
                    }
                    size="sm"
                    spacing="lg"
                    style={{ flex: 1 }}
                    styles={{
                      label: { cursor: 'pointer' },
                      radio: { cursor: 'pointer' }
                    }}
                    value={
                      formState.isAdditionalCharge ? 'ADD-ON' : 'REGISTRATION'
                    }
                  >
                    <Radio
                      disabled={invoiceRemainingBalance}
                      label="Create invoice for additional cost"
                      value="ADD-ON"
                    />
                    <Radio
                      disabled={invoiceRemainingBalance}
                      label={`Create invoice from current balance (${currencyFormat(
                        remainingRegistrationBalance
                      )} ${currency})`}
                      value="REGISTRATION"
                    />
                  </AppRadioGroup>
                )}
              <TextInput
                label="Charge Description"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    description: e.currentTarget.value
                  })
                }
                required
                value={formState.description}
              />
              {!formState.isOfflineInvoice && passedOnServiceFee > 0 && (
                <Checkbox
                  checked={formState.customerPaysServiceFees}
                  label={`Include Service Fee (${currencyFormat(
                    passedOnServiceFee
                  )})`}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      customerPaysServiceFees: e.currentTarget.checked
                    })
                  }
                />
              )}
              {formState.isOfflineInvoice ? (
                <Alert
                  color="blue"
                  icon={<AlertCircle />}
                  style={{ marginTop: 10 }}
                  styles={{ icon: { width: 28, height: 28 } }}
                  variant="outline"
                >
                  <AppText style={{ marginTop: 4, fontSize: 14 }}>
                    The customer will not receive an email to pay-by-card. You
                    will need to deal with the customer directly for offline
                    invoicing, and record your actions here. You can still
                    choose to email a card-payment invoice later.
                  </AppText>
                </Alert>
              ) : (
                !formState.isAdditionalCharge && (
                  <Alert
                    color="blue"
                    icon={<AlertCircle />}
                    style={{ marginTop: 10 }}
                    styles={{ icon: { width: 28, height: 28 } }}
                    variant="outline"
                  >
                    <AppText style={{ marginTop: 4, fontSize: 14 }}>
                      The customer will receive an invoice to pay for the
                      remaining registration cost instead of receiving an
                      additional charge.
                    </AppText>
                  </Alert>
                )
              )}
            </AppStack>
          )}
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

RegistrationInvoiceCreateModal.propTypes = {
  invoiceRemainingBalance: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onInvoice: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmission: PropTypes.object
};
export default RegistrationInvoiceCreateModal;
