import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Divider, Select } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ReportInput from './ReportInput';
import ResponsiveModal from '../../common/ResponsiveModal';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import { PAYMENT_PROVIDER_LIST } from '../../../config/constants';
import FormReportFilter from './FormReportFilter';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import EXTERNAL_CONNECTION_FORM_SECTION_CONFIG from '../Forms/externalConnectionFormSectionConfig';

const GenerateReportModal = ({
  isOpen,
  onClose,
  pkRegAssociation,
  regReportTemplate
}) => {
  const {
    state,
    generateAdminRegAssociationReport,
    fetchAdminRegReportInputSources
  } = useContext(RegistrationAdminContext);
  const [isGenerating, setIsGenerating] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const navigate = useNavigate();
  const isLoading =
    state.regReportInputSources.loading || state.regAssociation.loading;
  const hasSeasonInput = !!regReportTemplate?.inputs.find((i) =>
    i.inputSources.includes('SEASONS')
  );
  const hasDivisionInput = !!regReportTemplate?.inputs.find((i) =>
    i.inputSources.includes('DIVISIONS')
  );

  const availablePkDivisions =
    state.regAssociation.value?.regAssociationDivisions
      .filter(
        (d) =>
          d.fkRegAssociationSeason.toString() ===
          inputValues.pkRegAssociationSeason?.toString()
      )
      .map((d) => d.pkRegAssociationDivision.toString()) ?? [];

  const seasonOptions =
    state.regAssociation.value?.regAssociationSeasons
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((s) => ({
        label: s.name,
        value: s.pkRegAssociationSeason.toString()
      })) ?? [];

  const regAssociation = state.regAssociations.value.find(
    (a) => a.pkRegAssociation.toString() === pkRegAssociation?.toString()
  );
  const paymentProvider = PAYMENT_PROVIDER_LIST.find(
    (p) =>
      p.value ===
      regAssociation?.regAssociationPaymentProvider?.fkPaymentProvider
  );
  const useFormFilters = regReportTemplate?.guid === 'FORM';

  const externalConnections =
    (['ACCOUNTS_RECEIVABLE', 'ACCOUNTS_SETTLED', 'REGISTRANT', 'FORM'].includes(
      regReportTemplate?.guid
    ) &&
      regAssociation?.regAssociationExternalConnections
        ?.map((c) => ({
          ...c,
          config:
            EXTERNAL_CONNECTION_FORM_SECTION_CONFIG[
              c.fkRegExternalConnectionType
            ]
        }))
        .filter((c) => c.config?.reportable)) ||
    [];

  useEffect(() => {
    if (isOpen) {
      setIsGenerating(false);

      if (regReportTemplate.inputs.length > 0) {
        let inputSourcesFetched = true;
        for (const input of regReportTemplate.inputs) {
          const existingInputSource = state.regReportInputSources.value.find(
            (s) =>
              s.fkRegAssociation.toString() === pkRegAssociation?.toString() &&
              input.inputSources.includes(s.type)
          );
          if (existingInputSource == null) {
            inputSourcesFetched = false;
            break;
          }
        }

        if (!inputSourcesFetched) {
          fetchAdminRegReportInputSources(
            pkRegAssociation,
            regReportTemplate.pkRegReportTemplate
          );

          setInputValues(
            regReportTemplate.inputs.reduce(
              (obj, input) => Object.assign(obj, { [input.valueName]: '' }),
              {}
            )
          );
        }
      }
    }
  }, [isOpen]);

  const onError = (e) => {
    triggerNotification(e);
    setIsGenerating(false);
  };

  return (
    <ResponsiveModal
      centered
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title={<AppTitle order={2}>Generate Report</AppTitle>}
    >
      {!isLoading && (
        <FormSection
          isLoading={isGenerating}
          onCancel={onClose}
          onSubmit={() => {
            setIsGenerating(true);
            generateAdminRegAssociationReport(
              {
                pkRegAssociation,
                pkRegReportTemplate: regReportTemplate.pkRegReportTemplate,
                inputAnswers: Object.keys(inputValues)
                  .map((key) => ({
                    valueName: key,
                    value: inputValues[key]
                  }))
                  .filter((a) => a.valueName !== 'regExternalConnections'),
                regExternalConnections:
                  inputValues.regExternalConnections?.filter(
                    (c) => c.include
                  ) || null
              },
              () =>
                navigate(
                  `/admin/reports/${pkRegAssociation}/${
                    regReportTemplate.pkRegReportTemplate
                  }?r=${new Date().getTime()}`
                ),
              onError
            );
          }}
          style={{ gap: 30 }}
          submitTitle="Generate"
        >
          {useFormFilters ? (
            <FormReportFilter
              filterState={{
                uuid: inputValues.uuid || '',
                pkRegAssociationSeason:
                  inputValues.pkRegAssociationSeason || '',
                pkRegAssociationDivisions:
                  inputValues.pkRegAssociationDivisions
                    ?.split(',')
                    .filter((v) => !!v) || []
              }}
              isLoading={isGenerating}
              onDivisionChange={(v) =>
                setInputValues({
                  ...inputValues,
                  pkRegAssociationDivisions: v.join()
                })
              }
              onFormChange={(v) =>
                setInputValues({
                  ...inputValues,
                  uuid: v,
                  pkRegAssociationSeason: '',
                  pkRegAssociationDivisions: ''
                })
              }
              onSeasonChange={(v) =>
                setInputValues({
                  ...inputValues,
                  pkRegAssociationSeason: v,
                  pkRegAssociationDivisions: ''
                })
              }
              pkRegAssociation={pkRegAssociation}
            />
          ) : (
            <>
              {!hasSeasonInput && (
                <>
                  <Select
                    clearable
                    data={seasonOptions}
                    disabled={isGenerating}
                    label="Season"
                    onChange={(v) =>
                      setInputValues({
                        ...inputValues,
                        pkRegAssociationSeason: v,
                        pkRegAssociationDivision: '',
                        pkRegAssociationDivisionTeam: ''
                      })
                    }
                    searchable
                    value={inputValues.pkRegAssociationSeason}
                  />
                  {!hasDivisionInput && (
                    <Select
                      clearable
                      data={
                        state.regAssociation.value?.regAssociationDivisions
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .filter(
                            (d) =>
                              !inputValues.pkRegAssociationSeason ||
                              d.fkRegAssociationSeason.toString() ===
                                inputValues.pkRegAssociationSeason.toString()
                          )
                          .map((s) => ({
                            label: s.name,
                            value: s.pkRegAssociationDivision.toString()
                          })) ?? []
                      }
                      disabled={isGenerating}
                      label="Division"
                      onChange={(v) =>
                        setInputValues({
                          ...inputValues,
                          pkRegAssociationDivision: v,
                          pkRegAssociationDivisionTeam: ''
                        })
                      }
                      searchable
                      value={inputValues.pkRegAssociationDivision}
                    />
                  )}
                </>
              )}
              {regReportTemplate?.inputs.map((input) => {
                let filter = null;
                switch (input.inputSources[0]) {
                  case 'DIVISIONS':
                    filter = (d) =>
                      !inputValues.pkRegAssociationSeason ||
                      d.fkEntity.toString() ===
                        inputValues.pkRegAssociationSeason?.toString();
                    break;
                  case 'TEAMS':
                    filter = (t) =>
                      (!inputValues.pkRegAssociationDivision &&
                        !inputValues.pkRegAssociationSeason) ||
                      (!inputValues.pkRegAssociationDivision &&
                        availablePkDivisions.includes(t.fkEntity.toString())) ||
                      t.fkEntity.toString() ===
                        inputValues.pkRegAssociationDivision?.toString();
                    break;
                  default:
                    break;
                }

                return (
                  <ReportInput
                    key={input.pkRegReportTemplateInput}
                    disabled={isGenerating}
                    filter={filter}
                    inputSource={state.regReportInputSources.value.find(
                      (s) =>
                        s.fkRegAssociation.toString() ===
                          pkRegAssociation?.toString() &&
                        input.inputSources.includes(s.type)
                    )}
                    label={input.title}
                    name={input.valueName}
                    onChange={(name, value) =>
                      setInputValues({
                        ...inputValues,
                        [name]: value
                      })
                    }
                    required={!input.optional}
                    type={input.type}
                    value={inputValues[input.valueName]}
                  />
                );
              })}
              {regReportTemplate?.guid === 'ACCOUNTS_SETTLED' && (
                <>
                  <Select
                    clearable
                    data={[
                      {
                        label: `${paymentProvider?.label || 'Online'} Payments`,
                        value: 'stripe'
                      },
                      {
                        label: 'Offline Payments',
                        value: 'offline'
                      }
                    ]}
                    disabled={isGenerating}
                    label="Payment Types"
                    onChange={(v) =>
                      setInputValues({
                        ...inputValues,
                        payment_types: v
                      })
                    }
                    placeholder="Include all payments"
                    searchable
                    value={inputValues.payment_types}
                  />
                  <Checkbox
                    checked={inputValues.includeMerchPackages === 'yes'}
                    disabled={isGenerating}
                    label="Include purchased merchandise"
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        includeMerchPackages: e.currentTarget.checked
                          ? 'yes'
                          : 'no'
                      })
                    }
                    style={{ fontWeight: 500, marginTop: 10 }}
                  />
                </>
              )}
            </>
          )}

          {externalConnections.length > 0 && (
            <>
              <Divider />

              <AppStack style={{ gap: 8 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  External Connections
                </AppText>

                {externalConnections.map((c) => {
                  const connectionFormState = inputValues.regExternalConnections?.find(
                    (ec) =>
                      ec.fkRegExternalConnectionType ===
                      c.fkRegExternalConnectionType
                  );
                  const isSelected = connectionFormState?.include || false;

                  return (
                    <Checkbox
                      key={c.pkRegAssociationExternalConnection}
                      checked={isSelected}
                      disabled={isGenerating}
                      label={`Include ${c.config.title}`}
                      onChange={() => {
                        setInputValues({
                          ...inputValues,
                          regExternalConnections: [
                            ...(inputValues.regExternalConnections?.filter(
                              (f) =>
                                f.fkRegExternalConnectionType !==
                                c.fkRegExternalConnectionType
                            ) || []),
                            {
                              fkRegExternalConnectionType:
                                c.fkRegExternalConnectionType,
                              include: !isSelected
                            }
                          ]
                        });
                      }}
                      style={{ fontWeight: 500, marginTop: 10 }}
                    />
                  );
                })}
              </AppStack>
            </>
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

GenerateReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regReportTemplate: PropTypes.object
};

export default GenerateReportModal;
