import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AlertCircle,
  Edit,
  GripVertical,
  Plus,
  Trash
} from 'tabler-icons-react';
import {
  ActionIcon,
  Button,
  Skeleton,
  TextInput,
  Textarea
} from '@mantine/core';
import PropTypes from 'prop-types';
import {
  useGlobalFormState,
  useMediaQueries,
  useModalState
} from '../../../../helpers/hooks';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../../providers/RegistrationAdminDashboardProvider';
import ConfirmModal from '../../../../components/common/ConfirmModal';
import AppStack from '../../../../components/common/AppStack';
import AppText from '../../../../components/common/AppText';
import { triggerNotification } from '../../../../helpers/notificationHelper';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppCard from '../../../../components/common/AppCard';
import PageView from '../../../../components/common/PageView';
import SortableList from '../../../../components/common/SortableList';
import ActionableIcon from '../../../../components/common/ActionableIcon';
import {
  REG_FORM_CONTROL_TYPES,
  VIEW_ACTIONS_ENUM
} from '../../../../config/constants';
import EditTemplateQuestionModal from './EditTemplateQuestionModal';

const TaskListTemplateEditView = ({ duplicate }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { isLargeMobileOrSmaller, isTabletOrSmaller } = useMediaQueries();
  const { pkRegAssociationTaskTemplate } = useParams();
  const {
    state,
    fetchRegAssociationTaskTemplates,
    createRegAssociationTaskTemplates,
    updateRegAssociationTaskTemplates,
    deleteRegAssociationTaskTemplates
  } = useContext(RegistrationAdminContext);
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state: modalState,
    onOpenModal,
    onCloseModal,
    onChangeModalLoading
  } = useModalState();
  const {
    hasInitialized,
    hasUnsavedChanges,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    {
      name: '',
      description: '',
      regAssociationTaskTemplateQuestions: []
    },
    { confirmDiscard: true, containerWidth: 950 }
  );

  const taskTemplate = state.regAssociationTaskTemplates.value.find(
    (t) =>
      t.pkRegAssociationTaskTemplate.toString() === pkRegAssociationTaskTemplate
  );

  const hasAlreadyFetched =
    hasFetched.current ||
    taskTemplate?.pkRegAssociationTaskTemplate.toString() ===
      pkRegAssociationTaskTemplate;

  const loading = pkRegAssociationTaskTemplate
    ? !hasInitialized ||
      !hasAlreadyFetched ||
      state.regAssociationTaskTemplates.loading
    : !hasInitialized;

  const showNotFound =
    !loading && !taskTemplate && !!pkRegAssociationTaskTemplate;

  useEffect(() => {
    if (
      dashboardState.regAssociation.value &&
      pkRegAssociationTaskTemplate &&
      taskTemplate?.pkRegAssociationTaskTemplate.toString() !==
        pkRegAssociationTaskTemplate
    ) {
      if (state.regAssociationTaskTemplates.value.length === 0) {
        fetchRegAssociationTaskTemplates(
          dashboardState.regAssociation.value.pkRegAssociation,
          {},
          null,
          (error) => {
            triggerNotification(error);
          }
        );
        hasFetched.current = true;
      }
    }
  }, [pkRegAssociationTaskTemplate, dashboardState.regAssociation.value]);

  useEffect(() => {
    if (taskTemplate || !pkRegAssociationTaskTemplate) {
      resetFormState({
        name: taskTemplate?.name ?? '',
        description: taskTemplate?.description ?? '',
        regAssociationTaskTemplateQuestions:
          taskTemplate?.regAssociationTaskTemplateQuestions.map((p) => ({
            pkRegAssociationTaskTemplateQuestion:
              p.pkRegAssociationTaskTemplateQuestion,
            fkRegFormControlType: p.fkRegFormControlType,
            key: p.pkRegAssociationTaskTemplateQuestion,
            value: p.value,
            sort: p.sort
          })) ?? []
      });
    }
  }, [taskTemplate, state.regAssociationTaskTemplates.value, duplicate]);

  const onRemoveQuestion = (key) => {
    setFormState({
      ...formState,
      regAssociationTaskTemplateQuestions: formState.regAssociationTaskTemplateQuestions
        .filter((p) => p.key !== key)
        .sort((a, b) => a.sort - b.sort)
        .map((p, i) => ({ ...p, sort: i + 1 })),
      hasUnsavedChanges: true
    });
  };

  const onAddQuestion = (question) => {
    setFormState({
      ...formState,
      regAssociationTaskTemplateQuestions: [
        ...formState.regAssociationTaskTemplateQuestions.filter(
          (p) => p.key !== question.key
        ),
        {
          ...question,
          key: question.key || new Date().getTime()
        }
      ]
        .sort((a, b) => a.sort - b.sort)
        .map((p, i) => ({ ...p, sort: i + 1 })),
      hasUnsavedChanges: true
    });
  };

  const onSaveTaskTemplate = () => {
    submitFormState((formData, onErrorCallback) => {
      if (taskTemplate && !duplicate) {
        updateRegAssociationTaskTemplates(
          dashboardState.regAssociation.value.pkRegAssociation,
          {
            regAssociationTaskTemplates: [
              {
                ...formState,
                pkRegAssociationTaskTemplate:
                  taskTemplate.pkRegAssociationTaskTemplate
              }
            ]
          },
          () => {
            triggerNotification('Task template updated!', 'Success', 'green');
          },
          (error) => {
            triggerNotification(error);
            setFormState({ ...formState, loading: false });
          }
        );
      }
      else {
        createRegAssociationTaskTemplates(
          dashboardState.regAssociation.value.pkRegAssociation,
          { regAssociationTaskTemplates: [formState] },
          (data) => {
            navigate(
              `/admin/task-templates/${data[0].pkRegAssociationTaskTemplate}`
            );
            triggerNotification('Task template created!', 'Success', 'green');
          },
          onErrorCallback
        );
      }
    });
  };

  return showNotFound ? (
    <>not found</>
  ) : !loading && (!pkRegAssociationTaskTemplate || hasInitialized) ? (
    <>
      <PageView
        buttonAction={<></>}
        title={taskTemplate?.name || 'Create Task Template'}
        width={1050}
      >
        <AppStack
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSaveTaskTemplate();
          }}
        >
          {duplicate ? (
            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="xs"
              style={{ padding: 8 }}
              withBorder
            >
              <AppFlexbox style={{ gap: 8 }}>
                <AppStack>
                  <AlertCircle color="#eca70a" style={{ size: 18 }} />
                </AppStack>
                <AppText style={{ fontSize: 14 }}>
                  Duplicate this task template by changing the <b>Title</b> and
                  clicking save.
                </AppText>
              </AppFlexbox>
            </AppCard>
          ) : (
            <AppCard
              radius={isTabletOrSmaller ? 0 : 'md'}
              shadow="xs"
              style={{ padding: 8 }}
              withBorder
            >
              <AppFlexbox style={{ gap: 8 }}>
                <AppStack>
                  <AlertCircle color="dodgerblue" style={{ size: 18 }} />
                </AppStack>
                <AppText style={{ fontSize: 14 }}>
                  Use task templates to define a series of tasks that need to be
                  completed, and associate them with specific resources.
                </AppText>
              </AppFlexbox>
            </AppCard>
          )}

          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 16 }}
            withBorder
          >
            <AppStack>
              <TextInput
                disabled={isSubmitting}
                label="Title"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    name: e.currentTarget.value,
                    hasUnsavedChanges: true
                  })
                }
                required
                value={formState.name}
              />

              <Textarea
                autosize
                disabled={isSubmitting}
                label="Description"
                minRows={4}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    description: e.currentTarget.value,
                    hasUnsavedChanges: true
                  })
                }
                value={formState.description}
              />
            </AppStack>
          </AppCard>

          <AppCard
            radius={isTabletOrSmaller ? 0 : 'md'}
            shadow="xs"
            style={{ padding: 0 }}
            withBorder
          >
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox
                style={{
                  padding: 16,
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <AppText style={{ fontSize: 16, fontWeight: 700 }}>
                  Tasks
                </AppText>

                <Button
                  color="dark"
                  disabled={isSubmitting}
                  leftSection={<Plus />}
                  onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.CREATE)}
                  size="compact-sm"
                  type="button"
                  variant="filled"
                >
                  Add
                </Button>
              </AppFlexbox>
              <AppStack style={{ gap: 0 }}>
                {formState.regAssociationTaskTemplateQuestions.length === 0 ? (
                  <AppStack
                    style={{
                      padding: 16,
                      gap: 10,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AppText style={{ fontWeight: 500, color: '#666' }}>
                      This template currently has no tasks assigned.{' '}
                    </AppText>
                    <Button
                      color="dark"
                      disabled={isSubmitting}
                      leftSection={<Plus />}
                      onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.CREATE)}
                      size="compact-sm"
                      type="button"
                      variant="outline"
                    >
                      Add task
                    </Button>
                  </AppStack>
                ) : (
                  <SortableList
                    items={formState.regAssociationTaskTemplateQuestions}
                    onChange={(newList) => {
                      setFormState({
                        ...formState,
                        regAssociationTaskTemplateQuestions: newList.map(
                          (p, index) => ({
                            ...p,
                            sort: index + 1
                          })
                        ),
                        hasUnsavedChanges: true
                      });
                    }}
                    renderItem={(
                      item,
                      {
                        attributes,
                        listeners,
                        isDragging,
                        setNodeRef,
                        style,
                        isOverlay
                      }
                    ) => (
                      <AppCard
                        innerRef={setNodeRef}
                        radius={0}
                        shadow="none"
                        style={{
                          ...style,
                          padding: 0,
                          outline: isOverlay ? 'solid 1px #dee2e6' : 'none',
                          backgroundColor: isDragging
                            ? 'rgba(56, 56, 56, 0.1)'
                            : '#FFF',
                          opacity: isDragging ? 0.4 : 1,
                          cursor: isDragging ? 'grabbing' : 'default'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            padding: 10,
                            gap: 10,
                            borderTop: isOverlay ? 'none' : 'solid 1px #dee2e6',
                            flex: 1,
                            alignItems: 'center'
                          }}
                        >
                          <ActionIcon
                            color="dark"
                            disabled={isSubmitting}
                            variant="subtle"
                            {...attributes}
                            {...listeners}
                          >
                            <GripVertical size={20} />
                          </ActionIcon>

                          <AppFlexbox
                            style={{
                              flex: 1,
                              gap: 10,
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <AppFlexbox style={{ alignItems: 'center' }}>
                              <AppText
                                style={{ fontSize: 18, fontWeight: 500 }}
                              >
                                {item.sort}.
                              </AppText>
                              <AppStack style={{ fles: 1, gap: 0 }}>
                                <AppText
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 500
                                  }}
                                >
                                  {item.value}
                                </AppText>
                                <AppText
                                  style={{ fontSize: 12, color: '#666' }}
                                >
                                  {REG_FORM_CONTROL_TYPES.find(
                                    (p) =>
                                      p.value.toString() ===
                                      item.fkRegFormControlType?.toString()
                                  )?.label || 'Checkbox'}
                                </AppText>
                              </AppStack>
                            </AppFlexbox>

                            <AppFlexbox style={{ alignItems: 'center' }}>
                              <ActionableIcon
                                color="dark"
                                disabled={isSubmitting}
                                onClick={() =>
                                  onOpenModal(VIEW_ACTIONS_ENUM.CREATE, item)
                                }
                                radius="md"
                                variant="subtle"
                              >
                                <Edit />
                              </ActionableIcon>

                              <ActionableIcon
                                color="red"
                                disabled={isSubmitting}
                                onClick={() => onRemoveQuestion(item.key)}
                                radius="md"
                                variant="subtle"
                              >
                                <Trash />
                              </ActionableIcon>
                            </AppFlexbox>
                          </AppFlexbox>
                        </AppFlexbox>
                      </AppCard>
                    )}
                  />
                )}
              </AppStack>
            </AppStack>
          </AppCard>

          <AppFlexbox
            p={{ base: 16, sm: '16px 0px' }}
            style={{
              justifyContent: 'flex-end',
              flexDirection: isLargeMobileOrSmaller ? 'column-reverse' : 'row'
            }}
          >
            {!!taskTemplate && !duplicate && (
              <Button
                color="red"
                disabled={isSubmitting}
                onClick={() => {
                  onOpenModal(VIEW_ACTIONS_ENUM.DELETE, taskTemplate);
                }}
                radius="md"
                size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
                type="button"
                variant="filled"
              >
                Delete collection
              </Button>
            )}
            <Button
              color="dark"
              disabled={!hasUnsavedChanges}
              id="save-button"
              loading={isSubmitting}
              radius="md"
              size={isTabletOrSmaller ? 'sm' : 'compact-sm'}
              type="submit"
              variant="filled"
            >
              Save
            </Button>
          </AppFlexbox>
        </AppStack>
      </PageView>

      {ConfirmDiscardModal}

      <EditTemplateQuestionModal
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.CREATE
        }
        onClose={onCloseModal}
        onConfirm={(data) => {
          onAddQuestion(data);
          onCloseModal();
        }}
        regAssociationTaskTemplateQuestion={modalState.item}
      />

      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, delete task template"
        isLoading={modalState.loading}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DELETE
        }
        message={
          <AppStack style={{ gap: 16 }}>
            <AppText>
              Are you sure you want to delete <b>{modalState.item?.name}</b>?
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              This action can’t be undone.
            </AppText>
          </AppStack>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onChangeModalLoading(true);
          deleteRegAssociationTaskTemplates(
            dashboardState.regAssociation.value.pkRegAssociation,
            [modalState.item.pkRegAssociationTaskTemplate],
            () => {
              triggerNotification('Task template deleted!', 'Success', 'green');
              navigate('/admin/task-templates');
            },
            (error) => {
              triggerNotification(error);
              onChangeModalLoading(false);
            }
          );
        }}
        title={`Delete ${modalState.item?.name}?`}
      />
    </>
  ) : (
    <PageView
      actionButton={!!pkRegAssociationTaskTemplate && !duplicate ? <></> : null}
      loading
    >
      <AppStack style={{ gap: 16 }}>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
        <AppCard
          radius={isTabletOrSmaller ? 0 : 'md'}
          shadow="xs"
          style={{ padding: 16 }}
          withBorder
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="100%" />
            <Skeleton height={10} width="50%" />
          </AppStack>
        </AppCard>
      </AppStack>
    </PageView>
  );
};

TaskListTemplateEditView.propTypes = { duplicate: PropTypes.bool };

export default TaskListTemplateEditView;
