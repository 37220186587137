import React, { useContext, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import OrderDetailsInfo from '../orders/OrderDetailsInfo';
import AppStack from '../../common/AppStack';
import SendOrderReceiptModal from './SendOrderReceiptModal';

const RegistrationOrderSummaryModal = ({
  isOpen,
  onClose,
  regFormSubmission
}) => {
  const fetchedPkRegFormSubmission = useRef(null);
  const { state, fetchAdminRegFormSubmissionOrderHistory } = useContext(
    RegistrationAdminContext
  );
  const [showEntireOrder, setShowEntireOrder] = useState(false);
  const [receiptModalState, setReceiptModalState] = useState({ isOpen: false });
  const orderHistory = state.regFormSubmissionOrderHistory.value;
  const cartFormSubmission = orderHistory?.regCartProducts.find(
    (p) =>
      p.regFormSubmission.pkRegFormSubmission ===
      regFormSubmission?.pkRegFormSubmission
  );

  const submissionOrderHistory = orderHistory
    ? {
        ...orderHistory,
        cart: {
          ...orderHistory.cart,
          lineItems: orderHistory.cart.lineItems.filter(
            (i) => i.entityId === regFormSubmission?.pkRegFormSubmission
          )
        },
        regCartProducts: orderHistory.regCartProducts.filter(
          (p) =>
            p.regFormSubmission.pkRegFormSubmission ===
            regFormSubmission?.pkRegFormSubmission
        )
      }
    : null;

  const isMultiRegistrationOrder = orderHistory
    ? orderHistory.cart.lineItems.length >
      submissionOrderHistory.cart.lineItems.length
    : false;

  useEffect(() => {
    if (
      isOpen &&
      regFormSubmission &&
      fetchedPkRegFormSubmission.current !==
        regFormSubmission.pkRegFormSubmission
    ) {
      fetchAdminRegFormSubmissionOrderHistory(
        regFormSubmission.pkRegFormSubmission
      );
      fetchedPkRegFormSubmission.current =
        regFormSubmission.pkRegFormSubmission;
      setShowEntireOrder(false);
    }
  }, [isOpen, regFormSubmission]);

  return (
    <ResponsiveModal
      isLoading={!cartFormSubmission}
      onClose={onClose}
      opened={isOpen}
      size={1100}
      title={<AppTitle order={2}>Order Summary</AppTitle>}
    >
      <FormSection
        cancelTitle="Close"
        isSubmitHidden={!isMultiRegistrationOrder}
        onCancel={onClose}
        onSubmit={() => {
          setShowEntireOrder(!showEntireOrder);
        }}
        submitTitle={
          showEntireOrder ? 'Show this submission only' : 'Show entire order'
        }
        submitVariant={showEntireOrder ? 'outline' : 'filled'}
      >
        <AppStack style={{ gap: 20 }}>
          <Button
            onClick={() =>
              setReceiptModalState({
                ...receiptModalState,
                isOpen: true
              })
            }
            style={{ maxWidth: 250 }}
            variant="outline"
          >
            Email Receipt
          </Button>
          <OrderDetailsInfo
            hideTitle
            regCart={showEntireOrder ? orderHistory : submissionOrderHistory}
            withBorder
          />
        </AppStack>
      </FormSection>

      <SendOrderReceiptModal
        isAdminViewing
        isOpen={receiptModalState.isOpen}
        onClose={() =>
          setReceiptModalState({
            ...receiptModalState,
            isOpen: false
          })
        }
        pkRegFormSubmissions={[regFormSubmission?.pkRegFormSubmission]}
      />
    </ResponsiveModal>
  );
};

RegistrationOrderSummaryModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regFormSubmission: PropTypes.object
};
export default RegistrationOrderSummaryModal;
