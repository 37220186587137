import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TaskListTemplateTable from './TaskListTemplateTable';
import TaskListTemplateEditView from './TaskListTemplateEditView';

const TaskListTemplateViews = () => (
  <Routes>
    <Route element={<TaskListTemplateTable />} path="/" />
    <Route element={<TaskListTemplateEditView />} path="/new" />
    <Route
      element={<TaskListTemplateEditView duplicate />}
      path="/:pkRegAssociationTaskTemplate/duplicate"
    />
    <Route
      element={<TaskListTemplateEditView />}
      path="/:pkRegAssociationTaskTemplate/*"
    />
    <Route element={<Navigate replace to="/admin/task-templates" />} path="*" />
  </Routes>
);

export default TaskListTemplateViews;
