import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Checkbox, TextInput } from '@mantine/core';
import AppText from '../../../../components/common/AppText';
import ResponsiveModal from '../../../../components/common/ResponsiveModal';
import AppStack from '../../../../components/common/AppStack';
import EXTERNAL_CONNECTION_FORM_SECTION_CONFIG from '../../../../components/content/Forms/externalConnectionFormSectionConfig';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../../helpers/notificationHelper';

const RegistrationExternalConnectionVerifyModal = ({
  isOpen,
  onClose,
  regFormSubmission,
  externalConnection
}) => {
  const { updateRegFormSubmission } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    value: '',
    verified: true,
    loading: false
  });

  const config =
    EXTERNAL_CONNECTION_FORM_SECTION_CONFIG[
      externalConnection?.fkRegExternalConnectionType.toString()
    ];

  useEffect(() => {
    if (isOpen) {
      setFormState({
        value: externalConnection?.value || '',
        verified: true,
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        onSubmit: () => {
          setFormState({ ...formState, loading: true });

          updateRegFormSubmission(
            regFormSubmission.regAssociation.pkRegAssociation,
            {
              pkRegFormSubmission: regFormSubmission.pkRegFormSubmission,
              regFormSubmissionExternalIds: [
                {
                  value: formState.value,
                  fkRegAssociationExternalConnection:
                    externalConnection.fkRegAssociationExternalConnection,
                  isVerified: formState.value ? formState.verified : false,
                  clearValue: !formState.value
                }
              ]
            },
            () => {
              triggerNotification(
                !formState.value || !formState.verified
                  ? 'Connection updated'
                  : 'Connection verified',
                'Success',
                'green'
              );
              onClose();
            },
            (error) => {
              triggerNotification(error);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        },
        isLoading: formState.loading,
        submitTitle: !externalConnection?.value ? 'Save Changes' : 'Confirm'
      }}
      onClose={onClose}
      onMouseDown={(e) => e.stopPropagation()}
      opened={isOpen && !!config && !!externalConnection}
      size={600}
      title={
        !externalConnection?.value
          ? `Enter ${config?.title}`
          : externalConnection.verifiedAt
          ? `Update ${config?.title}`
          : `Verify ${config?.title}`
      }
    >
      <AppStack style={{ gap: 16 }}>
        {externalConnection && config && (
          <>
            {externalConnection?.pkRegFormSubmissionExternalId &&
            !externalConnection.verifiedAt ? (
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Please confirm the {config.title} for this registrant.
              </AppText>
            ) : (
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Please enter the {config.title} for this registrant.
              </AppText>
            )}

            <TextInput
              disabled={formState.loading}
              label={config.title}
              onChange={(e) => {
                setFormState({ ...formState, value: e.currentTarget.value });
              }}
              required
              value={formState.value}
            />

            <Checkbox
              checked={formState.verified && !!formState.value}
              disabled={formState.loading || !formState.value}
              label="Mark this ID as verified"
              onChange={() =>
                setFormState({
                  ...formState,
                  verified: !formState.verified
                })
              }
            />
          </>
        )}
      </AppStack>
    </ResponsiveModal>
  );
};

RegistrationExternalConnectionVerifyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  externalConnection: PropTypes.object,
  regFormSubmission: PropTypes.object
};

export default RegistrationExternalConnectionVerifyModal;
