import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logout, Receipt, X } from 'tabler-icons-react';
import { Avatar } from '@mantine/core';
import BulkEditView from '../../../../components/content/bulkEdit/BulkEditView';
import { BULK_EDIT_FIELD_TYPE_ENUM } from '../../../../config/constants';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import { triggerNotification } from '../../../../helpers/notificationHelper';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../../providers/RegistrationAdminDashboardProvider';
import EXTERNAL_CONNECTION_FORM_SECTION_CONFIG from '../../../../components/content/Forms/externalConnectionFormSectionConfig';

const DATA_FIELDS = {
  regPersonName: 'regPersonName',
  regFromName: 'formName',
  fkRegAssociationDivision: 'fkRegAssociationDivision',
  regExternalConnection: 'regExternalConnection'
};

const getEditFields = (externalConnections) => [
  {
    value: DATA_FIELDS.regPersonName,
    label: 'Registrant',
    dataField: DATA_FIELDS.regPersonName,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
    disabled: true,
    displayed: true,
    staticDisplay: true,
    category: 'Registration',
    firstPosition: true,
    leftSectionWidth: 70,
    noBulkEdit: true,
    getLeftSection: (altValue) => (
      <AppFlexbox
        style={{
          width: 25,
          height: 25,
          borderRadius: 5,
          boxSizing: 'border-box',
          border: 'solid 1px #dee2e6 ',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Avatar key={altValue} color="initials" name={altValue} />
      </AppFlexbox>
    )
  },
  {
    value: DATA_FIELDS.regFromName,
    label: 'Form',
    dataField: DATA_FIELDS.regFromName,
    type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT,
    disabled: true,
    displayed: true,
    category: 'Registration'
  },
  {
    value: DATA_FIELDS.fkRegAssociationDivision,
    label: 'Division',
    dataField: DATA_FIELDS.fkRegAssociationDivision,
    type: BULK_EDIT_FIELD_TYPE_ENUM.SELECT,
    getOptions: (optionsState) => optionsState.regAssociationDivisions,
    displayed: true,
    category: 'Registration'
  },
  ...externalConnections
    .map((e) => [
      {
        value: e.config.dataField,
        label: e.config.title,
        dataField: e.config.dataField,
        type: BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT,
        displayed: true,
        groupedField: e.config.dataField,
        category: 'Registration'
      },
      {
        value: e.config.verifiedDataField,
        label: 'Verified',
        dataField: e.config.verifiedDataField,
        type: BULK_EDIT_FIELD_TYPE_ENUM.CHECKBOX,
        displayed: true,
        groupedField: e.config.dataField,
        category: 'Registration',
        required: true,
        getDisabledMessage: (rowColumns) =>
          !rowColumns.find((c) => c.key === e.config.dataField)?.value
            ? "Enter the registrant's ID before verifying."
            : null
      }
    ])
    .flat()
];

const mapRegistrationToForm = (s, externalConnections) => ({
  key: s.pkRegFormSubmission.toString(),
  pkRegFormSubmission: s.pkRegFormSubmission.toString(),
  columns: [
    {
      key: DATA_FIELDS.regPersonName,
      value: `${s.regPerson.firstName} ${s.regPerson.lastName}`,
      altValue: `${s.regPerson.firstName} ${s.regPerson.lastName}`,
      disabled: true
    },
    {
      key: DATA_FIELDS.regFromName,
      value: s.regAssociationDivisionForm.regForm.name,
      disabled: true
    },
    {
      key: DATA_FIELDS.fkRegAssociationDivision,
      value:
        s.regAssociationDivisionOverride?.pkRegAssociationDivision.toString() ||
        s.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision.toString()
    },
    ...externalConnections
      .map((e) => {
        const submissionId = s.regFormSubmissionExternalIds.find(
          (i) =>
            i.regAssociationExternalConnection.fkRegExternalConnectionType ===
            e.fkRegExternalConnectionType
        );

        return [
          {
            key: e.config.dataField,
            value: submissionId?.value || ''
          },
          {
            key: e.config.verifiedDataField,
            value: !!submissionId?.verifiedAt
          }
        ];
      })
      .flat()
  ]
});

const BulkEditRegistrationsView = () => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    fetchRegFormSubmissionExportData,
    fetchAdminRegAssociationDivisions,
    updateRegFormSubmissionsBulk
  } = useContext(RegistrationAdminContext);

  const [formState, setFormState] = useState({ loading: false });
  const searchParmas = new URLSearchParams(search);
  const filter = {
    season: searchParmas.get('season') || null,
    division: searchParmas.get('division') || null,
    form: searchParmas.get('form') || null,
    status: searchParmas.get('status') || null,
    hockeyCanadaIdStatus: searchParmas.get('hockeyCanadaIdStatus') || null,
    search: searchParmas.get('search') || null
  };

  const pkRegFormSubmissionIds = searchParmas.get('ids')?.split(',') ?? [];

  const regAssociation = dashboardState.regAssociation.value;

  const selectedRegFormSubmissions = state.regFormSubmissionsExport.value.filter(
    (p) =>
      p.regAssociationDivisionForm.regProduct.regAssociation
        .pkRegAssociation === regAssociation.pkRegAssociation &&
      (pkRegFormSubmissionIds.length === 0 ||
        pkRegFormSubmissionIds.includes(p.pkRegFormSubmission.toString()))
  );

  const externalConnections =
    regAssociation?.regAssociationExternalConnections
      ?.map((c) => ({
        ...c,
        config:
          EXTERNAL_CONNECTION_FORM_SECTION_CONFIG[
            c?.fkRegExternalConnectionType.toString()
          ]
      }))
      .filter((c) => !!c.config) || [];

  const regAssociationDivisions =
    !state.regAssociationDivisions.loading &&
    state.regAssociationDivisions.type === 'form-divisions'
      ? state.regAssociationDivisions.value.filter(
          (d) =>
            !d.deleted &&
            d.regAssociationSeason.fkRegAssociation ===
              regAssociation?.pkRegAssociation
        )
      : [];

  const regFormSubmissionDivisions = selectedRegFormSubmissions.reduce(
    (r, c) => {
      if (c.regAssociationDivisionOverride) {
        const existingOverride = r.find(
          (d) =>
            d.value ===
            c.regAssociationDivisionOverride.pkRegAssociationDivision
        );
        if (!existingOverride) {
          r.push({
            label: `${c.regAssociationDivisionOverride.name} (${c.regAssociationDivisionOverride.regAssociationSeason.name})`,
            value: c.regAssociationDivisionOverride.pkRegAssociationDivision
          });
        }
      }

      const existingDivision = r.find(
        (d) =>
          d.value ===
          c.regAssociationDivisionForm.regAssociationDivision
            .pkRegAssociationDivision
      );
      if (!existingDivision) {
        r.push({
          label: `${c.regAssociationDivisionForm.regAssociationDivision.name} (${c.regAssociationDivisionForm.regAssociationDivision.regAssociationSeason.name})`,
          value:
            c.regAssociationDivisionForm.regAssociationDivision
              .pkRegAssociationDivision
        });
      }
      return r;
    },
    []
  );

  useEffect(() => {
    if (regAssociation) {
      fetchRegFormSubmissionExportData(
        {
          ...filter,
          fkRegAssociation: regAssociation.pkRegAssociation,
          pkRegFormSubmissions: pkRegFormSubmissionIds
        },
        null,
        (error) => {
          triggerNotification(error);
        }
      );

      fetchAdminRegAssociationDivisions();
      hasFetched.current = true;
    }
  }, [regAssociation]);

  const onSaveChanges = (data, onSuccess, onErrorCallback) => {
    setFormState({
      ...formState,
      loading: true
    });

    updateRegFormSubmissionsBulk(
      regAssociation.pkRegAssociation,
      {
        regFormSubmissions: data
          .filter((d) => d.hasUnsavedChanges)
          .map((d) => ({
            pkRegFormSubmission: d.pkRegFormSubmission,
            fkRegAssociationDivision: d.fkRegAssociationDivision,
            regFormSubmissionExternalIds: externalConnections.map((e) => ({
              value: d[e.config.dataField],
              fkRegAssociationExternalConnection:
                e.pkRegAssociationExternalConnection,
              isVerified: d[e.config.verifiedDataField],
              clearValue: !d[e.config.dataField]
            }))
          }))
      },
      () => {
        onSuccess();
        triggerNotification('Registrations updated.', 'Success', 'green');
        setFormState({
          ...formState,
          loading: false
        });
      },
      (error) => {
        onErrorCallback();
        triggerNotification(error);
        setFormState({
          ...formState,
          loading: false
        });
      }
    );
  };

  return (
    <BulkEditView
      data={selectedRegFormSubmissions
        .sort(
          (a, b) =>
            a.regPerson.firstName.localeCompare(b.regPerson.firstName) ||
            a.regPerson.lastName.localeCompare(b.regPerson.lastName) ||
            a.pkRegFormSubmission - b.pkRegFormSubmission
        )
        .map((p) => mapRegistrationToForm(p, externalConnections))}
      emptyContent={{
        title: 'No registrations found',
        description:
          "Oops looks like you haven't selected any registrations to edit. Try selecting a registrant from your list of registrations and try again.",
        icon: <Receipt color="#000" size={125} />,
        primaryButton: {
          label: 'Back to registrations',
          icon: <Logout size={18} style={{ transform: 'rotate(180deg)' }} />,
          link: '/admin/financials/registrations'
        }
      }}
      fields={getEditFields(externalConnections)}
      loading={!hasFetched.current || state.regFormSubmissionsExport.loading}
      onCancel={() => {
        navigate('/admin/financials/registrations');
      }}
      onSave={onSaveChanges}
      optionsState={{
        regAssociationDivisions: [
          ...regFormSubmissionDivisions.map((d) => ({
            label: d.label,
            value: d.value.toString()
          })),
          ...regAssociationDivisions
            .filter(
              (d) =>
                !regFormSubmissionDivisions.find(
                  (r) => r.value === d.pkRegAssociationDivision
                )
            )
            .map((d) => ({
              label: `${d.name} (${d.regAssociationSeason.name})`,
              value: d.pkRegAssociationDivision.toString()
            }))
        ].sort((a, b) => a.label.localeCompare(b.label))
      }}
      saving={formState.loading}
      title={`Editing ${selectedRegFormSubmissions.length} registrations`}
    />
  );
};

BulkEditRegistrationsView.propTypes = {};

export default BulkEditRegistrationsView;
