import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Tabs, TextInput } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import ResponsiveModal from '../../../../components/common/ResponsiveModal';
import AppStack from '../../../../components/common/AppStack';
import AppText from '../../../../components/common/AppText';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../../providers/RegistrationAdminDashboardProvider';
import PaginationList from '../../../../components/common/PaginationList';
import SelectableListItem from '../../../../components/common/SelectableListItem';
import { useRegResource } from '../../../../helpers/hooks';
import { triggerNotification } from '../../../../helpers/notificationHelper';

const AssignTasksModal = ({
  isOpen,
  onClose,
  resourceId,
  fkRegResourceType
}) => {
  const { regResourceTaskTemplates, onUpdateRegResource } = useRegResource({
    resourceId,
    fkRegResourceType
  });
  const { state, fetchRegAssociationTaskTemplates } = useContext(
    RegistrationAdminContext
  );
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const [formState, setFormState] = useState({
    regResourceTaskTemplates: [],
    customValue: '',
    showCustomTask: false,
    search: '',
    pageIndex: 1
  });
  const regAssociation = dashboardState.regAssociation.value;

  const taskTemplates = state.regAssociationTaskTemplates.value.filter(
    (t) => t.fkRegAssociation === regAssociation?.pkRegAssociation
  );
  const filteredTaskTemplates = taskTemplates
    .filter(
      (f) =>
        !formState.search ||
        f.name.toLowerCase().includes(formState.search.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  const allFilteredTaskTemplatesSelected = filteredTaskTemplates.every(
    (f) =>
      !!formState.regFormExternalConnections?.find(
        (c) =>
          c.fkRegAssociationTaskTemplate === f.pkRegAssociationTaskTemplate &&
          !c.deleted
      )
  );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        regResourceTaskTemplates: regResourceTaskTemplates.map((t) => ({
          pkRegResourceTaskTemplate: t.pkRegResourceTaskTemplate,
          fkRegAssociationTaskTemplate: t.fkRegAssociationTaskTemplate
        })),
        customValue: '',
        showCustomTask: false,
        search: '',
        pageIndex: 1
      });

      if (!state.regAssociationTaskTemplates.lastUpdated) {
        fetchRegAssociationTaskTemplates(regAssociation.pkRegAssociation);
      }
    }
  }, [isOpen]);

  const onSelectedTaskChange = (
    pkRegAssociationTaskTemplates,
    removeSelected = false
  ) => {
    const taskTemplatesState = formState.regResourceTaskTemplates || [];
    setFormState({
      ...formState,
      regResourceTaskTemplates: [
        ...taskTemplatesState.map((c) =>
          pkRegAssociationTaskTemplates.includes(c.fkRegAssociationTaskTemplate)
            ? {
                ...c,
                deleted: removeSelected
              }
            : c
        ),
        ...pkRegAssociationTaskTemplates
          .filter(
            (pk) =>
              !taskTemplatesState.find(
                (c) => c.fkRegAssociationTaskTemplate === pk
              )
          )
          .map((pk) => ({
            fkRegAssociationTaskTemplate: pk,
            deleted: removeSelected
          }))
      ]
    });
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        isLoading: formState.loading,
        isSubmitDisabled:
          !formState.showCustomTask && taskTemplates.length === 0,
        onSubmit: () => {
          setFormState({
            ...formState,
            loading: true
          });
          onUpdateRegResource(
            formState.showCustomTask
              ? {
                  regResourceTaskTemplateAnswers: formState.showCustomTask
                    ? [{ customValue: formState.customValue }]
                    : null
                }
              : {
                  regResourceTaskTemplates: formState.regResourceTaskTemplates.filter(
                    (t) => !!t.pkRegResourceTaskTemplate || !t.deleted
                  )
                },
            () => {
              onClose();
              triggerNotification('Tasks updated!', 'Success', 'green');
            },
            (error) => {
              setFormState({
                ...formState,
                false: true
              });
              triggerNotification(error);
            }
          );
        },
        submitTitle: formState.showCustomTask ? 'Add Task' : 'Assign',
        padding: 0
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={600}
      title={
        formState.showCustomTask ? 'Add Custom Task' : 'Assign Task Templates'
      }
    >
      <AppStack style={{ gap: 16 }}>
        <Tabs
          defaultValue="template"
          onChange={(v) => {
            if (!formState.loading) {
              setFormState({
                ...formState,
                search: '',
                pageIndex: 1,
                showCustomTask: v === 'custom'
              });
            }
          }}
          value={formState.showCustomTask ? 'custom' : 'template'}
          variant="outline"
        >
          <Tabs.List>
            <Tabs.Tab value="template">Templates</Tabs.Tab>
            <Tabs.Tab value="custom">Custom Task</Tabs.Tab>
          </Tabs.List>
        </Tabs>
        <AppStack style={{ gap: 16 }}>
          {formState.showCustomTask ? (
            <AppStack style={{ gap: 32, padding: '16px 0px' }}>
              <TextInput
                disabled={formState.loading}
                label="Custom Task"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    customValue: e.currentTarget.value
                  })
                }
                placeholder="Enter a task"
                required
                style={{ flex: 1 }}
                value={formState.customValue}
              />
              <AppFlexbox style={{ padding: '0px 2rem' }}>
                <AppText
                  style={{
                    fontSize: 14,
                    color: '#666',
                    textAlign: 'center',
                    fontStyle: 'italic'
                  }}
                >
                  Custom tasks are not saved and will not be available for
                  future use. If you want to save this task for future use,
                  consider creating a task template.
                </AppText>
              </AppFlexbox>
            </AppStack>
          ) : (
            <AppStack style={{ gap: 8 }}>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Task Templates
                </AppText>

                <Checkbox
                  checked={allFilteredTaskTemplatesSelected}
                  disabled={
                    filteredTaskTemplates.length === 0 || formState.loading
                  }
                  label="Select all"
                  onChange={() => {
                    onSelectedTaskChange(
                      filteredTaskTemplates.map(
                        (f) => f.pkRegAssociationTaskTemplate
                      ),
                      allFilteredTaskTemplatesSelected
                    );
                  }}
                  styles={{ label: { fontWeight: 500 } }}
                />
              </AppFlexbox>
              <TextInput
                disabled={formState.loading}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    search: e.currentTarget.value,
                    pageIndex: 1
                  })
                }
                placeholder="Search templates by name"
                value={formState.search || ''}
              />

              {state.regAssociationTaskTemplates.loading ? (
                <PaginationList
                  isLoading
                  LoadingComponent={SelectableListItem}
                  loadingComponentCount={2}
                />
              ) : taskTemplates.length === 0 ? (
                <AppStack
                  style={{
                    gap: 16,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '30px 0px'
                  }}
                >
                  <AppText
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      textAlign: 'center'
                    }}
                  >
                    No task templates created.
                  </AppText>
                  <Button
                    color="dark"
                    component={Link}
                    leftSection={<Plus size={18} />}
                    to={`/admin/task-templates/new?from=registrations&fromId=${resourceId}`}
                    variant="outline"
                  >
                    Create template
                  </Button>
                </AppStack>
              ) : filteredTaskTemplates.length === 0 ? (
                <AppStack
                  style={{
                    padding: 16,
                    border: '1px solid #ccc',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>
                    No forms found by search.
                  </AppText>
                  <Button
                    color="dark"
                    onClick={() =>
                      setFormState({
                        ...formState,
                        search: '',
                        pageIndex: 1
                      })
                    }
                    variant="outline"
                  >
                    Clear search
                  </Button>
                </AppStack>
              ) : (
                <PaginationList
                  items={[
                    ...filteredTaskTemplates.map((f) => {
                      const isSelected = !!formState.regResourceTaskTemplates?.find(
                        (c) =>
                          c.fkRegAssociationTaskTemplate ===
                            f.pkRegAssociationTaskTemplate && !c.deleted
                      );

                      return (
                        <AppFlexbox
                          key={f.pkRegAssociationTaskTemplate}
                          onClick={() => {
                            if (!formState.loading) {
                              onSelectedTaskChange(
                                [f.pkRegAssociationTaskTemplate],
                                isSelected
                              );
                            }
                          }}
                          style={{
                            padding: 8,
                            alignItems: 'center',
                            cursor: formState.loading
                              ? 'not-allowed'
                              : 'pointer'
                          }}
                        >
                          <Checkbox
                            checked={isSelected}
                            disabled={formState.loading}
                            onChange={() => {}}
                          />
                          <AppText style={{ fontSize: 14 }}>{f.name}</AppText>
                        </AppFlexbox>
                      );
                    })
                  ]}
                  itemsPerPage={8}
                  onPageChange={(index) => {
                    setFormState({ ...formState, pageIndex: index });
                  }}
                  pageIndex={formState.pageIndex}
                />
              )}
            </AppStack>
          )}
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

AssignTasksModal.propTypes = {
  fkRegResourceType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AssignTasksModal;
