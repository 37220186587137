import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Alert,
  Badge,
  Button,
  Divider,
  Loader,
  Skeleton,
  TextInput,
  useMantineTheme
} from '@mantine/core';
import { Receipt, Ticket, Trash, X } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import ViewRegistrationFormModal from '../Forms/ViewRegistrationFormModal';
import { formatUtcDate } from '../../../helpers/format';
import RecordPaymentModal from '../financials/RecordPaymentModal';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppCard from '../../common/AppCard';
import AppImage from '../../common/AppImage';
import ActionableIcon from '../../common/ActionableIcon';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const CheckoutItem = ({
  lineItem,
  cartProduct,
  isDisabled,
  isLoading,
  withBorder,
  onRedeemCoupon
}) => {
  const mqIndex = useMediaQueryIndex();
  const theme = useMantineTheme();
  const { removeProductFromCart, updateProductInCart } = useContext(
    RegistrationContext
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [couponCodeState, setCouponCodeState] = useState({
    code: '',
    isRemoveLoading: false,
    isLoading: false
  });
  const [regFormModalState, setRegFormModalState] = useState({
    isOpen: false,
    loading: false,
    error: ''
  });

  const latestRegistrationDate = cartProduct?.regWaitlistReservations
    .filter((r) => !r.deleted)
    .sort((a, b) => new Date(a.expiryDate) - new Date(b.expiryDate))[0];
  const expiryDate = latestRegistrationDate
    ? formatUtcDate(latestRegistrationDate.expiryDate)
    : null;
  let expiryTimeHours = latestRegistrationDate
    ? dayjs(expiryDate).diff(dayjs(new Date()), 'hour')
    : 0;
  const expiryTimeMinutes = latestRegistrationDate
    ? Math.ceil(dayjs(expiryDate).diff(dayjs(new Date()), 'minute', true))
    : 0;
  const expiryTimeDays = Math.ceil(expiryTimeHours / 24);
  expiryTimeHours -= Math.floor(expiryTimeHours / 24) * 24;

  useEffect(() => {
    if (!isLoading) {
      setCouponCodeState({
        code: '',
        isRemoveLoading: false,
        isLoading: false
      });
    }
  }, [isLoading]);

  const openRegFormModal = () => {
    setRegFormModalState({
      isOpen: true,
      loading: false,
      error: ''
    });
  };

  const closeRegFormModal = () => {
    setRegFormModalState({
      isOpen: false,
      loading: false,
      error: ''
    });
  };

  const onError = (e) => {
    triggerNotification(e);
    setRegFormModalState({
      ...regFormModalState,
      loading: false,
      error: 'Oops something went wrong.'
    });
  };

  const updateCartProduct = (formData) => {
    updateProductInCart(
      cartProduct.pkRegCartProduct,
      {
        count: cartProduct.count,
        regFormSubmissionAnswers: formData.regFormSubmissionAnswers,
        divisionRoles: formData.divisionRoles,
        financialAssistanceId: formData.isFinancialAssistanceEnabled
          ? formData.financialAssistanceId
          : null,
        regFormSubmissionExternalIds: formData.regFormSubmissionExternalIds,
        regFormSubmissionPersonContacts:
          formData.regFormSubmissionPersonContacts
      },
      () => {
        triggerNotification('Form updated successfully', 'Success', 'green');
        closeRegFormModal();
      },
      onError
    );
  };

  return cartProduct ? (
    <>
      <RecordPaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        regAssociation={cartProduct.regProduct.regAssociation}
        regFormSubmission={cartProduct.regFormSubmission}
      />
      <ViewRegistrationFormModal
        error={regFormModalState.error}
        isDisabled={isDisabled}
        isLoading={regFormModalState.loading}
        isOpen={regFormModalState.isOpen}
        merchPackageRegAssociation={cartProduct.regProduct.regAssociation}
        onClose={closeRegFormModal}
        onSubmit={(formData) => {
          setRegFormModalState({
            ...regFormModalState,
            loading: true,
            error: ''
          });
          updateCartProduct(formData);
        }}
        pkRegFormSubmission={cartProduct.regFormSubmission?.pkRegFormSubmission}
        showMerchPackageShowcase={!isDisabled}
      />
      <AppCard
        key={cartProduct.pkRegCartProduct}
        style={{
          padding: 0,
          borderRadius: rs([0, 0, 0, 4], mqIndex),
          border: withBorder ? 'solid 1px lightgrey' : 'none'
        }}
      >
        {!isDisabled && cartProduct.regWaitlistReservations.length > 0 && (
          <Alert color="red" style={{ borderRadius: 0 }} variant="filled">
            {!latestRegistrationDate ||
            new Date() > new Date(formatUtcDate(expiryDate)) ? (
              <AppText>Registration has expired</AppText>
            ) : (
              <AppText>
                Registration expires in{' '}
                {expiryTimeDays > 1 ? (
                  <b>
                    {expiryTimeDays} {expiryTimeDays === 1 ? 'day' : 'days'}{' '}
                  </b>
                ) : expiryTimeHours > 0 ? (
                  <b>
                    {expiryTimeHours} {expiryTimeHours === 1 ? 'hour' : 'hours'}{' '}
                  </b>
                ) : (
                  expiryTimeMinutes > 0 && (
                    <b>
                      {expiryTimeMinutes}{' '}
                      {expiryTimeMinutes === 1 ? 'minute' : 'minutes'}{' '}
                    </b>
                  )
                )}
              </AppText>
            )}
          </Alert>
        )}
        <Divider style={{ display: rs(['flex', 'flex', 'none'], mqIndex) }} />
        <AppStack style={{ gap: 0, flexWrap: 'nowrap' }}>
          <AppFlexbox
            style={{
              flex: 1,
              margin: 30,
              flexWrap: 'nowrap',
              flexDirection: rs(['column', 'column', 'row'], mqIndex)
            }}
          >
            <AppFlexbox
              style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}
            >
              <AppFlexbox style={{ alignSelf: 'start', flex: 1 }}>
                <AppFlexbox
                  component={Link}
                  to={`/association/${cartProduct.regProduct.regAssociation.pkRegAssociation}`}
                >
                  <AppImage
                    fit="contain"
                    height={100}
                    src={cartProduct.regProduct.regAssociation.logoImageUrl}
                    width={100}
                  />
                </AppFlexbox>

                <AppStack
                  style={{
                    flex: 1,
                    gap: 0,
                    textDecoration: 'none',
                    justifyContent: 'center',
                    color: '#000'
                  }}
                >
                  <AppText size="lg">
                    {cartProduct.regFormSubmission.regAssociationDivisionForm
                      .titleOverride
                      ? cartProduct.regFormSubmission.regAssociationDivisionForm
                          .titleOverride
                      : cartProduct.regFormSubmission.regAssociationDivisionForm
                          .regForm.name}
                  </AppText>
                  <AppText>
                    Ends{' '}
                    {dayjs(
                      cartProduct.regFormSubmission.regAssociationDivisionForm
                        .regForm.endDate
                    ).format('MMM D, YYYY')}
                  </AppText>

                  {lineItem.addOns.length > 0 && (
                    <>
                      <Divider
                        label={
                          <AppText color="dark" weight={700}>
                            Add-ons
                          </AppText>
                        }
                        labelPosition="center"
                        style={{ marginTop: 10 }}
                      />

                      <AppStack style={{ marginTop: 10, gap: 15 }}>
                        {lineItem.addOns.map((addOn) => (
                          <AppFlexbox
                            key={addOn.productName}
                            style={{ gap: 5, justifyContent: 'space-between' }}
                          >
                            <AppText>{addOn.productName}</AppText>
                            <AppFlexbox style={{ gap: 5 }}>
                              <AppText weight={700}>
                                <NumberFormat
                                  decimalScale={2}
                                  displayType="text"
                                  fixedDecimalScale
                                  prefix="$"
                                  thousandSeparator
                                  value={addOn.unitAmountInCents / 100}
                                />{' '}
                                {lineItem.currency?.toUpperCase()}
                              </AppText>
                            </AppFlexbox>
                          </AppFlexbox>
                        ))}
                      </AppStack>
                    </>
                  )}
                </AppStack>
              </AppFlexbox>
            </AppFlexbox>

            <AppStack
              style={{
                flex: 1,
                alignSelf: 'start',
                placeItems: 'end',
                marginLeft: 'auto',
                gap: rs([5, 10], mqIndex),
                flexDirection: rs(
                  ['row-reverse', 'row-reverse', 'column'],
                  mqIndex
                ),
                justifyContent: rs(
                  ['space-between', 'space-between', 'inital'],
                  mqIndex
                ),
                width: rs(['100%', '100%', 'unset'], mqIndex)
              }}
            >
              <AppStack style={{ gap: 0, alignItems: 'end' }}>
                <AppFlexbox style={{ gap: 10, flexWrap: 'nowrap' }}>
                  <AppText
                    size="xl"
                    style={{
                      textDecoration: lineItem.financialAssistance
                        ? 'line-through'
                        : 'normal'
                    }}
                    weight={700}
                  >
                    <NumberFormat
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={lineItem.subtotalInCents / 100}
                    />{' '}
                    {lineItem.currency?.toUpperCase()}
                  </AppText>
                </AppFlexbox>
                {lineItem.coupons.length > 0 && (
                  <AppStack style={{ gap: 5 }}>
                    {lineItem.coupons.map((c) => (
                      <AppFlexbox
                        key={`${c.couponName}-${c.unitAmountInCents}`}
                        style={{
                          gap: 5,
                          flexWrap: 'nowrap',
                          alignItems: 'center'
                        }}
                      >
                        <Badge
                          color="gray"
                          leftSection={<Ticket size={16} />}
                          rightSection={
                            isDisabled ||
                            c.isGroupCoupon ? null : couponCodeState.isRemoveLoading ? (
                              <Loader color="dark" size={14} />
                            ) : (
                              <ActionableIcon
                                color="gray"
                                onClick={() => {
                                  setCouponCodeState({
                                    code: '',
                                    isRemoveLoading: true,
                                    isLoading: false
                                  });
                                  onRedeemCoupon();
                                }}
                                size="xs"
                                variant="transparent"
                              >
                                <X size={15} />
                              </ActionableIcon>
                            )
                          }
                          variant="outline"
                        >
                          {c.couponName}
                        </Badge>
                        <AppText size="md" weight={500}>
                          <NumberFormat
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            thousandSeparator
                            value={
                              c.unitAmountInCents
                                ? (c.unitAmountInCents / 100) * -1
                                : ((lineItem.subtotalInCents *
                                    (c.percentageAmount / 100)) /
                                    100) *
                                  -1
                            }
                          />{' '}
                          {lineItem.currency?.toUpperCase()}
                        </AppText>
                      </AppFlexbox>
                    ))}
                  </AppStack>
                )}
                {lineItem.taxInCents > 0 && (
                  <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                    <AppText
                      size="md"
                      style={{
                        textDecoration: lineItem.financialAssistance
                          ? 'line-through'
                          : 'normal'
                      }}
                      weight={500}
                    >
                      +{' '}
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={lineItem.taxInCents / 100}
                      />{' '}
                      @{' '}
                      <NumberFormat
                        displayType="text"
                        fixedDecimalScale
                        suffix="%"
                        thousandSeparator
                        value={lineItem.taxRate * 100}
                      />{' '}
                      TAX
                    </AppText>
                  </AppFlexbox>
                )}
                {lineItem.customerPaysServiceFees &&
                  lineItem.serviceFeeInCents > 0 && (
                    <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                      <AppText
                        size="md"
                        style={{
                          textDecoration: lineItem.financialAssistance
                            ? 'line-through'
                            : 'normal'
                        }}
                        weight={500}
                      >
                        +{' '}
                        <NumberFormat
                          decimalScale={2}
                          displayType="text"
                          fixedDecimalScale
                          prefix="$"
                          thousandSeparator
                          value={lineItem.serviceFeeInCents / 100}
                        />{' '}
                        Service fee
                      </AppText>
                    </AppFlexbox>
                  )}
                {lineItem.financialAssistance && (
                  <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                    <AppText size="md" weight={500}>
                      Jumpstart{' '}
                      <NumberFormat
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="$"
                        thousandSeparator
                        value={0.0}
                      />{' '}
                      {lineItem.currency?.toUpperCase()}
                    </AppText>
                  </AppFlexbox>
                )}
              </AppStack>
              {!isDisabled ? (
                <AppStack style={{ gap: 20, flex: 1 }}>
                  <AppFlexbox
                    onClick={() =>
                      removeProductFromCart(cartProduct.pkRegCartProduct)
                    }
                    style={{
                      cursor: 'pointer',
                      gap: 10,
                      alignSelf: rs(['start', 'start', 'end'], mqIndex)
                    }}
                  >
                    <Trash />
                    <AppText>Remove</AppText>
                  </AppFlexbox>
                  {lineItem.subtotalInCents > 0 && (
                    <AppStack
                      component="form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        setCouponCodeState({
                          ...couponCodeState,
                          isLoading: true
                        });
                        onRedeemCoupon(couponCodeState.code);
                      }}
                      style={{ gap: 10, flex: 1 }}
                    >
                      <TextInput
                        disabled={isLoading}
                        onChange={(e) =>
                          setCouponCodeState({
                            ...couponCodeState,
                            code: e.currentTarget.value
                              .toUpperCase()
                              .replace(' ', '')
                          })
                        }
                        placeholder="Enter Coupon Code"
                        style={{ flex: 1, maxWidth: 200 }}
                        value={couponCodeState.code}
                      />
                      {couponCodeState.code && (
                        <Button
                          color="blue"
                          loading={couponCodeState.isLoading}
                          style={{ flex: 1, maxWidth: 200 }}
                          styles={{ inner: { height: 35 } }}
                          type="submit"
                        >
                          Apply
                        </Button>
                      )}
                    </AppStack>
                  )}
                </AppStack>
              ) : (
                <AppFlexbox
                  onClick={() => setShowPaymentModal(true)}
                  style={{ cursor: 'pointer', gap: 10 }}
                >
                  <Receipt />
                  <AppText>View Payments</AppText>
                </AppFlexbox>
              )}
            </AppStack>
          </AppFlexbox>
          <AppFlexbox
            style={{
              backgroundColor: theme.black,
              color: '#FFF',
              opacity: 0.8,
              padding: 30,
              justifyContent: 'space-between'
            }}
          >
            <AppStack style={{ gap: 0 }}>
              <AppText weight={700}>Participant</AppText>
              <AppText>
                {cartProduct.regFormSubmission.regPerson.firstName}{' '}
                {cartProduct.regFormSubmission.regPerson.lastName}
              </AppText>
            </AppStack>

            {isDisabled ? (
              <Button
                color="gray"
                disabled={isLoading}
                onClick={openRegFormModal}
                radius="xs"
                size="lg"
                style={{
                  color: theme.white,
                  borderColor: theme.white,
                  width: 200
                }}
                variant="outline"
              >
                View Form
              </Button>
            ) : cartProduct.regWaitlistReservations.length > 0 &&
              (!latestRegistrationDate ||
                new Date() > new Date(formatUtcDate(expiryDate))) ? (
              <Button
                color="gray"
                disabled
                radius="xs"
                size="lg"
                style={{ width: 200 }}
                variant="filled"
              >
                Expired
              </Button>
            ) : cartProduct.regFormSubmission.isCompleted ? (
              <Button
                color="blue"
                disabled={isLoading}
                onClick={openRegFormModal}
                radius="xs"
                size="lg"
                style={{ width: 200 }}
                variant="filled"
              >
                Update Form
              </Button>
            ) : (
              <Button
                color="gray"
                disabled={isLoading}
                onClick={openRegFormModal}
                radius="xs"
                size="lg"
                style={{
                  color: theme.white,
                  borderColor: theme.white,
                  animationName: 'pulse',
                  animationDuration: '2s',
                  animationIterationCount: 'infinite',
                  width: 200,
                  '@keyframes pulse': { '50%': { borderColor: 'red' } }
                }}
                variant="outline"
              >
                Fill out form
              </Button>
            )}
          </AppFlexbox>
        </AppStack>
      </AppCard>
    </>
  ) : (
    <AppCard
      style={{
        padding: 0,
        borderRadius: rs([0, 0, 0, 4], mqIndex),
        border: withBorder ? 'solid 1px lightgrey' : 'none'
      }}
    >
      <Divider style={{ display: rs(['flex', 'flex', 'none'], mqIndex) }} />
      <AppStack style={{ gap: 0, flexWrap: 'nowrap' }}>
        <AppFlexbox
          style={{
            margin: 30,
            flexWrap: 'nowrap',
            flexDirection: rs(['column', 'column', 'column', 'row'], mqIndex)
          }}
        >
          <AppFlexbox
            style={{ flexWrap: 'nowrap', flex: 2, alignSelf: 'stretch' }}
          >
            <Skeleton height={100} width={100} />
            <AppStack
              style={{
                flex: 1,
                gap: 10,
                textDecoration: 'none',
                justifyContent: 'center',
                color: '#000'
              }}
            >
              <Skeleton height={16} width="50%" />
              <Skeleton height={14} width="40%" />
            </AppStack>
          </AppFlexbox>

          <AppStack
            style={{
              flex: 1,
              alignSelf: 'start',
              placeItems: 'end',
              marginLeft: 'auto',
              gap: rs([0, 30], mqIndex),
              flexDirection: rs(
                ['row-reverse', 'row-reverse', 'row-reverse', 'column'],
                mqIndex
              ),
              justifyContent: rs(
                ['space-between', 'space-between', 'space-between', 'inital'],
                mqIndex
              ),
              width: rs(['100%', '100%', '100%', 'unset'], mqIndex)
            }}
          >
            <AppStack style={{ gap: 0, alignItems: 'end' }}>
              <Skeleton height={20} width={100} />
            </AppStack>

            {!isDisabled && (
              <AppFlexbox style={{ cursor: 'pointer', gap: 10 }}>
                <Skeleton height={15} width={80} />
              </AppFlexbox>
            )}
          </AppStack>
        </AppFlexbox>
        <AppFlexbox
          style={{
            backgroundColor: theme.black,
            color: '#FFF',
            opacity: 0.8,
            padding: 30,
            justifyContent: 'space-between'
          }}
        >
          <AppStack style={{ gap: 10 }}>
            <Skeleton height={10} width={100} />
            <Skeleton height={10} width={100} />
          </AppStack>

          <Skeleton height={48} width={200} />
        </AppFlexbox>
      </AppStack>
    </AppCard>
  );
};

CheckoutItem.propTypes = {
  cartProduct: PropTypes.object,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  lineItem: PropTypes.object,
  onRedeemCoupon: PropTypes.func,
  withBorder: PropTypes.bool
};

export default CheckoutItem;
