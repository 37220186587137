import React, { useState } from 'react';
import { Button, Checkbox, Menu, ScrollArea, TextInput } from '@mantine/core';
import { Check, Columns } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import MenuContent from '../../common/MenuContent';

const BulkEditColumnSelectMenu = ({
  isTableLoading,
  dataFields,
  onSelectFields
}) => {
  const { isTabletOrSmaller } = useMediaQueryIndex();
  const [filterState, setFilterState] = useState({ search: '' });

  const fieldCategories =
    dataFields
      ?.filter(
        (f) =>
          !f.staticDisplay &&
          !f.hidden &&
          (!filterState.search ||
            f.label.toLowerCase().includes(filterState.search.toLowerCase()))
      )
      .reduce((r, c) => {
        const existing = r.find((f) => f.label === c.category);
        if (!existing) {
          r.push({
            label: c.category,
            fields: [c]
          });
        }
        else {
          existing.fields.push(c);
        }
        return r;
      }, []) ?? [];

  const allFieldsSelected = fieldCategories.every((c) =>
    c.fields.every((f) => f.displayed)
  );

  return (
    (dataFields.length > 0 || isTableLoading) && (
      <AppFlexbox
        style={{ alignItems: 'center', padding: '10px 20px', gap: 5 }}
      >
        <MenuContent
          closeOnItemClick={false}
          control={
            isTabletOrSmaller ? (
              <Button
                color="dark"
                disabled={isTableLoading}
                size="compact-md"
                type="button"
                variant="subtle"
              >
                <Columns color="#000" size={18} />
              </Button>
            ) : (
              <Button
                color="dark"
                disabled={isTableLoading}
                leftSection={<Columns color="#000" size={18} />}
                type="button"
                variant="subtle"
              >
                Columns
              </Button>
            )
          }
          onClose={() => {
            setFilterState({
              ...filterState,
              search: ''
            });
          }}
          width={300}
        >
          <AppStack style={{ gap: 5 }}>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Select columns
              </AppText>
              <AppFlexbox
                onClick={() => {
                  if (!filterState.search) {
                    onSelectFields([
                      ...dataFields
                        .map((df) => ({
                          ...df,
                          displayed:
                            !df.hidden &&
                            (!allFieldsSelected || df.staticDisplay)
                        }))
                        .filter((f) => f.displayed)
                    ]);
                  }
                }}
                style={{
                  gap: 5,
                  cursor: filterState.fieldSearch ? 'not-allowed' : 'pointer',
                  alignItems: 'center'
                }}
              >
                <Checkbox
                  checked={!filterState.search && allFieldsSelected}
                  disabled={!!filterState.search}
                  onChange={() => {}}
                  size="xs"
                />
                <AppText
                  style={{
                    fontSize: 14,
                    color: filterState.search ? '#666' : '#000',
                    fontWeight: 500
                  }}
                >
                  Select all
                </AppText>
              </AppFlexbox>
            </AppFlexbox>
            <TextInput
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  search: e.currentTarget.value
                })
              }
              placeholder="Search by name..."
              size="xs"
              value={filterState.search}
            />
            <Menu.Divider />

            {fieldCategories.length === 0 ? (
              <AppStack
                style={{
                  padding: 40,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 5
                }}
              >
                <AppText
                  style={{ fontSize: 14, fontWeight: 500, color: '#666' }}
                >
                  No columns found.
                </AppText>
                <Button
                  color="dark"
                  onClick={() =>
                    setFilterState({
                      ...filterState,
                      search: ''
                    })
                  }
                  size="compact-sm"
                  type="buttons"
                  variant="filled"
                >
                  Clear search
                </Button>
              </AppStack>
            ) : (
              <ScrollArea h={450} offsetScrollbars type="auto">
                <AppStack
                  style={{
                    flex: 1,
                    gap: 10
                  }}
                >
                  {fieldCategories.map((c, index) => {
                    const allCategoryFieldsSelected = c.fields.every(
                      (f) => f.displayed
                    );
                    return (
                      <React.Fragment key={c.label}>
                        {index > 0 && <Menu.Divider />}
                        <AppStack style={{ gap: 0 }}>
                          {fieldCategories.length > 1 && (
                            <AppFlexbox
                              style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '5px 12px'
                              }}
                            >
                              <AppText
                                style={{
                                  fontSize: 12,
                                  color: '#666',
                                  fontWeight: 500
                                }}
                              >
                                {c.label}
                              </AppText>
                              {!filterState.search && (
                                <AppFlexbox
                                  onClick={() => {
                                    onSelectFields([
                                      ...dataFields
                                        .map((df) => ({
                                          ...df,
                                          displayed: c.fields.some(
                                            (f) => f.value === df.value
                                          )
                                            ? !allCategoryFieldsSelected
                                            : df.displayed
                                        }))
                                        .filter((f) => f.displayed)
                                    ]);
                                  }}
                                  style={{
                                    gap: 5,
                                    cursor: 'pointer',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      !filterState.search &&
                                      allCategoryFieldsSelected
                                    }
                                    onChange={() => {}}
                                    size="xs"
                                  />
                                  <AppText
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 500
                                    }}
                                  >
                                    Select all
                                  </AppText>
                                </AppFlexbox>
                              )}
                            </AppFlexbox>
                          )}

                          {c.fields
                            .filter((f) => !f.groupedField)
                            .sort((a, b) =>
                              (a.menuLabel ?? a.label).localeCompare(
                                b.menuLabel ?? b.label
                              )
                            )
                            .map((f) => (
                              <Menu.Item
                                key={f.value}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const groupedFields = dataFields.filter(
                                    (df) => df.groupedField === f.value
                                  );
                                  onSelectFields([
                                    ...dataFields
                                      .map((d) =>
                                        d.value === f.value ||
                                        groupedFields.some(
                                          (gf) => gf.value === d.value
                                        )
                                          ? { ...d, displayed: !f.displayed }
                                          : d
                                      )
                                      .filter((d) => d.displayed)
                                  ]);
                                }}
                                rightSection={
                                  f.displayed ? (
                                    <Check color="#067D62" size={18} />
                                  ) : null
                                }
                              >
                                {f.menuLabel ?? f.label}
                              </Menu.Item>
                            ))}
                        </AppStack>
                      </React.Fragment>
                    );
                  })}
                </AppStack>
              </ScrollArea>
            )}
          </AppStack>
        </MenuContent>
      </AppFlexbox>
    )
  );
};

BulkEditColumnSelectMenu.propTypes = {
  dataFields: PropTypes.array,
  isTableLoading: PropTypes.bool,
  onSelectFields: PropTypes.func
};

export default BulkEditColumnSelectMenu;
