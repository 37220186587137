import React, { useContext } from 'react';
import Sidebar from './Sidebar';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { REGISTRATION_ADMIN_NAVIGATION_LINKS } from './links';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppImage from '../../../../components/common/AppImage';
import AppStack from '../../../../components/common/AppStack';
import AppText from '../../../../components/common/AppText';
import SportsHeadzCircleLogo from '../../../../images/sportsheads-logo.png';
import FilterInput from '../../../../components/common/FilterInput';

const RegistrationAdminSideBar = () => {
  const { state } = useContext(RegistrationAdminContext);

  return (
    <Sidebar
      navigation={REGISTRATION_ADMIN_NAVIGATION_LINKS}
      state={state}
      subTitle="Merchant"
      title="SportsHeadz"
      topSection={
        <AppStack style={{ gap: 0 }}>
          <AppFlexbox
            style={{
              height: 70,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AppFlexbox
              style={{
                alignItems: 'center',
                gap: 10
              }}
            >
              <AppImage height={50} src={SportsHeadzCircleLogo} width={50} />
              <AppStack
                style={{
                  gap: 0,
                  textAlign: 'center'
                }}
              >
                <AppText
                  style={{ fontSize: 18, lineHeight: '20px', fontWeight: 700 }}
                >
                  SportsHeadz
                </AppText>
                <AppText
                  color="grey"
                  style={{ fontSize: 14, lineHeight: '16px' }}
                >
                  Registration
                </AppText>
              </AppStack>
            </AppFlexbox>
          </AppFlexbox>
          <AppStack style={{ padding: '0px 5px' }}>
            <FilterInput preventUrlUpdate type="association" />
          </AppStack>
        </AppStack>
      }
    />
  );
};

export default RegistrationAdminSideBar;
