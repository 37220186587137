import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Anchor, Divider, Skeleton } from '@mantine/core';
import PageCard from '../../../../components/common/PageCard';
import AppStack from '../../../../components/common/AppStack';
import { useMediaQueries } from '../../../../helpers/hooks';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppText from '../../../../components/common/AppText';

const RegistrationLeagueInfoCard = ({ regFormSubmission }) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueries();

  return regFormSubmission ? (
    <PageCard style={{ padding: 0 }}>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Division
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox>
                <AppText style={{ fontSize: 13 }}>
                  {
                    regFormSubmission.regAssociationDivisionForm
                      .regAssociationDivision.name
                  }
                </AppText>
                {/* <Anchor
                  component={Link}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  to={`/admin/league/divisions/${regFormSubmission.regAssociationDivisionForm.regAssociationDivision.pkRegAssociationDivision}`}
                >
                  {
                    regFormSubmission.regAssociationDivisionForm
                      .regAssociationDivision.name
                  }
                </Anchor> */}
              </AppFlexbox>
            </AppStack>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Registration
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 13 }}>
                {regFormSubmission.regAssociationDivisionForm.regForm.name}
              </AppText>
            </AppStack>
          </AppStack>
        </AppStack>
      </AppStack>
    </PageCard>
  ) : (
    <PageCard style={{ padding: 0 }}>
      <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
        <AppStack style={{ flex: 1, gap: 12 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="30%" />
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox>
              <Skeleton height={18} width="35%" />
            </AppFlexbox>
          </AppStack>
        </AppStack>

        <AppStack style={{ flex: 1, gap: 8 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="40%" />
          </AppFlexbox>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={18} width="35%" />
            <Skeleton height={18} width="50%" />
          </AppStack>
        </AppStack>
      </AppStack>

      <Divider
        orientation={
          !isTabletOrSmaller && isDesktopOrSmaller ? 'vertical' : 'horizontal'
        }
      />
    </PageCard>
  );
};

RegistrationLeagueInfoCard.propTypes = { regFormSubmission: PropTypes.object };

export default RegistrationLeagueInfoCard;
