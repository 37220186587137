import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { triggerNotification } from './notificationHelper';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../providers/AuthProvider';
import {
  DEFUALT_EVALUATION_BUTTON_OPTIONS,
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_PERMISSION_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../config/constants';

const useEvaluationTemplateState = (pkRegScoutReport) => {
  const hasFetched = useRef(false);
  const hasInitialized = useRef(false);
  const { state, fetchRegScoutReport, updateRegScoutReport } = useContext(
    RegistrationAdminContext
  );
  const [formState, setFormState] = useState({
    name: '',
    regScoutReportQuestions: [],
    regScoutReportSnippets: [],
    selectedQuestionKey: null,
    hasUnsavedChanges: false,
    isSaving: false
  });

  const regScoutReport =
    hasFetched.current &&
    !state.regScoutReport.loading &&
    state.regScoutReport.value?.pkRegScoutReport.toString() === pkRegScoutReport
      ? state.regScoutReport.value
      : null;

  const attributeQuestions = formState.regScoutReportQuestions.filter(
    (q) =>
      q.fkRegScoutReportQuestionType ===
      REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
  );

  const evaluationQuestions = formState.regScoutReportQuestions.filter(
    (q) =>
      q.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
  );

  useEffect(() => {
    if (pkRegScoutReport) {
      fetchRegScoutReport(pkRegScoutReport, null, triggerNotification);
      hasFetched.current = true;
      hasInitialized.current = false;
    }
  }, [pkRegScoutReport]);

  useEffect(() => {
    if (
      hasFetched.current &&
      !hasInitialized.current &&
      !state.regScoutReport.loading &&
      regScoutReport
    ) {
      // eslint-disable-next-line no-use-before-define
      onDiscardChanges();
      hasInitialized.current = true;
    }
  }, [regScoutReport]);

  const onUpdateTemplateSettings = ({ name }) => {
    setFormState({
      ...formState,
      name: name || formState.name,
      hasUnsavedChanges: true
    });
  };

  const onAddReportQuestion = (fkRegScoutReportQuestionType) => {
    if (fkRegScoutReportQuestionType) {
      const existingQuestions = formState.regScoutReportQuestions.filter(
        (q) => q.fkRegScoutReportQuestionType === fkRegScoutReportQuestionType
      );

      const isAttribute =
        fkRegScoutReportQuestionType ===
        REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

      const questionData = {
        key: new Date().getTime(),
        value: '',
        fkRegScoutReportQuestionType,
        fkRegFormControlType: isAttribute
          ? REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
          : REG_FORM_CONTROL_TYPE_ENUM.SLIDER,
        regScoutReportQuestionOptions: isAttribute
          ? []
          : DEFUALT_EVALUATION_BUTTON_OPTIONS,
        regScoutReportQuestionSnippets: [],
        regScoutReportQuestionRestrictions: [],
        sort:
          existingQuestions.length === 0
            ? 1
            : Math.max(...existingQuestions.map((i) => i.sort)) + 1
      };

      setFormState({
        ...formState,
        regScoutReportQuestions: [
          ...formState.regScoutReportQuestions,
          questionData
        ],
        hasUnsavedChanges: true
      });
    }
  };

  const onApplyRestrictions = (regScoutReportQuestions, updatedQuestion) =>
    regScoutReportQuestions.map((q) => ({
      ...q,
      regScoutReportQuestionRestrictions: [
        ...q.regScoutReportQuestionRestrictions.filter((r) =>
          updatedQuestion.fkRegScoutReportQuestionType ===
          REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
            ? r.questionConditionRefNumber !== updatedQuestion.key
            : r.questionRestrictedRefNumber !== updatedQuestion.key
        ),
        ...updatedQuestion.regScoutReportQuestionRestrictions.filter((r) =>
          q.fkRegScoutReportQuestionType ===
          REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE
            ? r.questionConditionRefNumber === q.key
            : r.questionRestrictedRefNumber === q.key
        )
      ]
    }));

  const onUpdateListOrder = (newListOrdered, fkRegScoutReportQuestionType) => {
    if (fkRegScoutReportQuestionType) {
      setFormState({
        ...formState,
        regScoutReportQuestions: [
          ...formState.regScoutReportQuestions.filter(
            (q) =>
              q.fkRegScoutReportQuestionType !== fkRegScoutReportQuestionType
          ),
          ...newListOrdered
        ],
        hasUnsavedChanges: true
      });
    }
  };

  const onRemoveReportQuestion = (item) => {
    setFormState({
      ...formState,
      regScoutReportQuestions: [
        ...formState.regScoutReportQuestions.filter((q) => q.key !== item.key)
      ],
      hasUnsavedChanges: true
    });
  };

  const onCopyQuestion = (question) => {
    const existingQuestions = formState.regScoutReportQuestions.filter(
      (q) =>
        q.fkRegScoutReportQuestionType === question.fkRegScoutReportQuestionType
    );
    setFormState({
      ...formState,
      regScoutReportQuestions: [
        ...onApplyRestrictions(formState.regScoutReportQuestions, question),
        {
          ...question,
          key: new Date().getTime(),
          pkRegScoutReportQuestion: null,
          sort:
            existingQuestions.length === 0
              ? 1
              : Math.max(...existingQuestions.map((i) => i.sort)) + 1
        }
      ],
      hasUnsavedChanges: true
    });
  };

  const onChangeReportQuestion = (question) => {
    setFormState({
      ...formState,
      regScoutReportQuestions: [
        ...onApplyRestrictions(
          formState.regScoutReportQuestions.filter(
            (q) => q.key !== question.key
          ),
          question
        ),
        question
      ],
      hasUnsavedChanges: true
    });
  };

  const onSelectReportQuestion = (item) => {
    setFormState({
      ...formState,
      selectedQuestionKey: item.key
    });
  };

  const onRemoveRestriction = (questionRestriction) => {
    setFormState({
      ...formState,
      regScoutReportQuestions: [
        ...formState.regScoutReportQuestions.map((q) => ({
          ...q,
          regScoutReportQuestionRestrictions: q.regScoutReportQuestionRestrictions.filter(
            (r) =>
              !(
                r.condition === questionRestriction.condition &&
                r.conditionValue === questionRestriction.conditionValue
              )
          )
        }))
      ],
      hasUnsavedChanges: true
    });
  };

  const onDiscardChanges = () => {
    setFormState({
      name: regScoutReport.name,
      regScoutReportQuestions: regScoutReport.regScoutReportQuestions.map(
        (q) => ({
          ...q,
          key: q.pkRegScoutReportQuestion,
          regScoutReportQuestionOptions: q.regScoutReportQuestionOptions.map(
            (op) => ({
              ...op,
              key: op.pkRegScoutReportQuestionOption
            })
          ),
          regScoutReportQuestionRestrictions: q.regScoutReportQuestionRestrictions.map(
            (r) => ({
              ...r,
              key: r.pkRegScoutReportQuestionRestriction,
              questionRestrictedRefNumber: r.questionRestricted,
              questionConditionRefNumber: r.questionCondition
            })
          )
        })
      ),
      selectedQuestionKey: null,
      hasUnsavedChanges: false,
      isSaving: false
    });
  };

  const onSaveChanges = (onSuccessCallback = null, onErrorCallback = null) => {
    const missingInput = formState.regScoutReportQuestions.find(
      (q) => !q.value?.trim()
    );
    if (missingInput) {
      onErrorCallback('Please fill out all the required inputs', missingInput);
    }
    else {
      setFormState({ ...formState, isSaving: true });

      updateRegScoutReport(
        regScoutReport.pkRegScoutReport,
        {
          ...formState,
          regScoutReportQuestions: formState.regScoutReportQuestions.map(
            (q) => ({
              ...q,
              regScoutReportQuestionOptions:
                q.fkRegFormControlType ===
                  REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT ||
                q.fkRegFormControlType === REG_FORM_CONTROL_TYPE_ENUM.SLIDER
                  ? []
                  : q.regScoutReportQuestionOptions
            })
          )
        },
        (data) => {
          setFormState({
            ...formState,
            hasUnsavedChanges: false,
            isSaving: false
          });
          if (onSuccessCallback) {
            onSuccessCallback(data);
          }
        },
        (error) => {
          setFormState({
            ...formState,
            isSaving: false
          });
          if (onErrorCallback) {
            onErrorCallback(error);
          }
          else {
            triggerNotification(error);
          }
        }
      );
    }
  };

  return {
    isLoading: !hasInitialized.current,
    isSaving: formState.isSaving,
    hasUnsavedChanges: formState.hasUnsavedChanges,
    templateName: formState.name,
    regScoutReportQuestions: formState.regScoutReportQuestions,
    attributeQuestions,
    evaluationQuestions,
    selectedQuestionKey: formState.selectedQuestionKey,
    regScoutReportEvaluationSessions:
      regScoutReport?.regScoutReportEvaluationSessions || [],
    onUpdateTemplateSettings,
    onAddReportQuestion,
    onRemoveReportQuestion,
    onCopyQuestion,
    onChangeReportQuestion,
    onSelectReportQuestion,
    onUpdateListOrder,
    onRemoveRestriction,
    onDiscardChanges,
    onSaveChanges
  };
};

const useEvaluationSessionState = (
  pkRegScoutReportEvaluationSession,
  config = { fetchOptions: false }
) => {
  const hasFetched = useRef(false);
  const hasInitializedSession = useRef(false);
  const hasInitializedPlayers = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchScoutReportEvaluationSession,
    fetchScoutReportEvaluationPlayers,
    fetchScoutReportTemplates,
    updateRegScoutReportEvaluationSession,
    updateRegScoutReportEvaluationSessionEntry
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    name: '',
    regScoutReportEvaluationSessionEntries: [],
    regScoutReportEvaluationSessionTemplates: [],
    regScoutReportEvaluations: [],
    hasUnsavedChanges: false,
    isSaving: false
  });

  const evaluationSession =
    state.regScoutReportEvaluationSession.value?.pkRegScoutReportEvaluationSession.toString() ===
    pkRegScoutReportEvaluationSession
      ? state.regScoutReportEvaluationSession.value
      : null;

  const regAssociationDivisionPlayers = state.regScoutReportEvaluationPlayers.value.filter(
    (p) =>
      p.regAssociationDivision.pkRegAssociationDivision ===
      evaluationSession?.regAssociationDivision.pkRegAssociationDivision
  );

  const assignedRegScoutReportEvaluations = formState.regScoutReportEvaluations.filter(
    (e) => !e.deleted
  );

  const regScoutReports =
    evaluationSession?.regScoutReportEvaluationSessionTemplates.map(
      (t) => t.regScoutReport
    ) || [];
  const regScoutReportQuestions = regScoutReports.reduce(
    (r, c) => [...r, ...c.regScoutReportQuestions],
    []
  );

  const isEvaluationLoading =
    !hasFetched.current ||
    state.regScoutReportEvaluationSession.loading ||
    !evaluationSession;

  const isPlayersLoading =
    !hasInitializedSession.current ||
    state.regScoutReportEvaluationPlayers.loading;

  const userEvaluationSessionEntry = evaluationSession?.regScoutReportEvaluationSessionEntries.find(
    (e) => e.regAssociationAdmin.user.pkUser === authState.userData.user.pkUser
  );

  useEffect(() => {
    if (pkRegScoutReportEvaluationSession) {
      fetchScoutReportEvaluationSession(
        pkRegScoutReportEvaluationSession,
        null,
        triggerNotification
      );
      hasFetched.current = true;
    }
  }, [pkRegScoutReportEvaluationSession]);

  useEffect(() => {
    if (!isEvaluationLoading && evaluationSession) {
      if (!hasInitializedSession.current) {
        setFormState({
          name: evaluationSession.name,
          regScoutReportEvaluationSessionEntries:
            evaluationSession.regScoutReportEvaluationSessionEntries,
          regScoutReportEvaluationSessionTemplates:
            evaluationSession.regScoutReportEvaluationSessionTemplates,
          regScoutReportEvaluations: evaluationSession.regScoutReportEvaluations.map(
            (e) => ({
              ...e,
              key: e.pkRegScoutReportEvaluation
            })
          ),
          hasUnsavedChanges: false,
          isSaving: false
        });

        if (config.fetchOptions) {
          fetchScoutReportTemplates(
            evaluationSession.regAssociationDivision.regAssociationSeason
              .fkRegAssociation
          );
          fetchScoutReportEvaluationPlayers(
            evaluationSession.regAssociationDivision.regAssociationSeason
              .fkRegAssociation,
            {
              pkRegAssociationDivision:
                evaluationSession.regAssociationDivision
                  .pkRegAssociationDivision
            }
          );
        }
      }
      hasInitializedSession.current = true;
    }
  }, [isEvaluationLoading, evaluationSession]);

  useEffect(() => {
    if (!isPlayersLoading && !hasInitializedPlayers.current) {
      const removedPkEvaluations = evaluationSession.regScoutReportEvaluations
        .filter(
          (e) =>
            !regAssociationDivisionPlayers.some(
              (p) =>
                e.person.entityId === p.person.entityId &&
                e.person.isCustomPerson === p.person.isCustomPerson
            )
        )
        .map((e) => e.pkRegScoutReportEvaluation);
      setFormState((currentFormState) => ({
        ...currentFormState,
        regScoutReportEvaluations: evaluationSession.regScoutReportEvaluations.map(
          (e) => ({
            ...e,
            key: e.pkRegScoutReportEvaluation,
            deleted:
              e.deleted ||
              removedPkEvaluations.includes(e.pkRegScoutReportEvaluation)
          })
        )
      }));

      hasInitializedPlayers.current = true;
    }
  }, [state.regScoutReportEvaluationPlayers.value]);

  const onUpdateEvaluationSession = ({
    name = null,
    regScoutReportEvaluationSessionTemplates = null
  }) => {
    setFormState({
      ...formState,
      name: name || formState.name,
      regScoutReportEvaluationSessionTemplates:
        regScoutReportEvaluationSessionTemplates ||
        formState.regScoutReportEvaluationSessionTemplates,
      hasUnsavedChanges: true
    });
  };

  const onSelectPlayers = (playerKeys, isCustomPerson = false) => {
    const newKeys = playerKeys.filter(
      (k) =>
        !formState.regScoutReportEvaluations.some(
          (e) =>
            e.person.entityId === k &&
            e.person.isCustomPerson === isCustomPerson
        )
    );

    const duplicateKeys = [];
    setFormState((c) => ({
      ...c,
      regScoutReportEvaluations: [
        ...formState.regScoutReportEvaluations
          .sort(
            (a, b) =>
              a.pkRegScoutReportEvaluation - b.pkRegScoutReportEvaluation
          )
          .map((e) => {
            const deleted =
              duplicateKeys.includes(e.person.entityId) ||
              !playerKeys.includes(e.person.entityId);

            if (e.person.isCustomPerson === isCustomPerson && !deleted) {
              duplicateKeys.push(e.person.entityId);
            }

            return {
              ...e,
              deleted:
                e.person.isCustomPerson === isCustomPerson ? deleted : e.deleted
            };
          }),
        ...newKeys
          .filter((k) =>
            regAssociationDivisionPlayers.find(
              (p) =>
                p.person.entityId === k &&
                p.person.isCustomPerson === isCustomPerson
            )
          )
          .map((k, i) => ({
            key: new Date().getTime() + i,
            person:
              regAssociationDivisionPlayers.find(
                (p) =>
                  p.person.entityId === k &&
                  p.person.isCustomPerson === isCustomPerson
              )?.person || null,
            pkRegScoutReportEvaluation: null,
            notes: null,
            regScoutReportQuestionAnswers: [],
            deleted: false
          }))
      ],
      hasUnsavedChanges: true
    }));
  };

  const onUpdateEvaluation = (newEvaluationState) => {
    setFormState({
      ...formState,
      regScoutReportEvaluations: [
        ...formState.regScoutReportEvaluations.filter(
          (e) => e.key !== newEvaluationState.key
        ),
        newEvaluationState
      ],
      hasUnsavedChanges: true
    });
  };

  const onUpdateEvaluations = (newEvaluations) => {
    setFormState({
      ...formState,
      regScoutReportEvaluations: [
        ...formState.regScoutReportEvaluations.filter(
          (e) => !newEvaluations.some((s) => s.key === e.key)
        ),
        ...newEvaluations
      ],
      hasUnsavedChanges: true
    });
  };

  const onUpdateEvaluationAnswer = (
    evaluation,
    question,
    answerValue,
    notes = null,
    fkRegScoutReportEvaluationSessionEntry = null
  ) => {
    const isAttributeQuestion =
      question.fkRegScoutReportQuestionType ===
      REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

    const pkSessionEntry = isAttributeQuestion
      ? null
      : fkRegScoutReportEvaluationSessionEntry;
    const existingAnswer = evaluation.regScoutReportQuestionAnswers.find(
      (f) =>
        f.fkRegScoutReportQuestion === question.pkRegScoutReportQuestion &&
        f.fkRegScoutReportEvaluationSessionEntry === pkSessionEntry
    );

    onUpdateEvaluation({
      ...evaluation,
      regScoutReportQuestionAnswers: [
        ...evaluation.regScoutReportQuestionAnswers.filter(
          (f) =>
            !(
              f.fkRegScoutReportQuestion ===
                question.pkRegScoutReportQuestion &&
              f.fkRegScoutReportEvaluationSessionEntry === pkSessionEntry
            )
        ),
        existingAnswer
          ? {
              ...existingAnswer,
              value: answerValue,
              notes: notes !== null ? notes : existingAnswer.notes
            }
          : {
              customQuestion: null,
              fkRegScoutReportEvaluation: evaluation.pkRegScoutReportEvaluation,
              fkRegScoutReportQuestion: question.pkRegScoutReportQuestion,
              value: answerValue,
              notes,
              fkRegScoutReportEvaluationSessionEntry: pkSessionEntry
            }
      ]
    });
  };

  const onUpdateEvaluationAnswers = (
    evaluations,
    question,
    answerValue,
    notes = null,
    fkRegScoutReportEvaluationSessionEntry = null
  ) => {
    const newEvaluations = evaluations.map((evaluation) => {
      const isAttributeQuestion =
        question.fkRegScoutReportQuestionType ===
        REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE;

      const pkSessionEntry = isAttributeQuestion
        ? null
        : fkRegScoutReportEvaluationSessionEntry;
      const existingAnswer = evaluation.regScoutReportQuestionAnswers.find(
        (f) =>
          f.fkRegScoutReportQuestion === question.pkRegScoutReportQuestion &&
          f.fkRegScoutReportEvaluationSessionEntry === pkSessionEntry
      );

      return {
        ...evaluation,
        regScoutReportQuestionAnswers: [
          ...evaluation.regScoutReportQuestionAnswers.filter(
            (f) =>
              !(
                f.fkRegScoutReportQuestion ===
                  question.pkRegScoutReportQuestion &&
                f.fkRegScoutReportEvaluationSessionEntry === pkSessionEntry
              )
          ),
          existingAnswer
            ? {
                ...existingAnswer,
                value: answerValue,
                notes: notes !== null ? notes : existingAnswer.notes
              }
            : {
                customQuestion: null,
                fkRegScoutReportEvaluation:
                  evaluation.pkRegScoutReportEvaluation,
                fkRegScoutReportQuestion: question.pkRegScoutReportQuestion,
                value: answerValue,
                notes,
                fkRegScoutReportEvaluationSessionEntry: pkSessionEntry
              }
        ]
      };
    });

    onUpdateEvaluations(newEvaluations);
  };

  const onSaveEvaluationEntry = (
    pkRegScoutReportEvaluationSessionEntry,
    onSuccessCallback = null,
    onErrorCallback = null,
    { clearAnswers = false, uploadedEvaluations = [] } = {}
  ) => {
    setFormState({
      ...formState,
      isSaving: true
    });

    updateRegScoutReportEvaluationSessionEntry(
      evaluationSession.pkRegScoutReportEvaluationSession,
      {
        pkRegScoutReportEvaluationSessionEntry,
        regScoutReportEvaluations: assignedRegScoutReportEvaluations
          .map((evaluation) => {
            const uploadedEvaluation = uploadedEvaluations.find(
              (e) =>
                e.person.entityId === evaluation.person.entityId &&
                e.person.isCustomPerson === evaluation.person.isCustomPerson
            );

            const oldAnswersState =
              evaluationSession.regScoutReportEvaluations
                .find(
                  (e) =>
                    e.pkRegScoutReportEvaluation ===
                    evaluation.pkRegScoutReportEvaluation
                )
                ?.regScoutReportQuestionAnswers.filter(
                  (a) =>
                    a.fkRegScoutReportEvaluationSessionEntry ===
                    pkRegScoutReportEvaluationSessionEntry
                ) || [];
            const currentAnswersState = [
              ...evaluation.regScoutReportQuestionAnswers
                .filter(
                  (a) =>
                    a.fkRegScoutReportEvaluationSessionEntry ===
                    pkRegScoutReportEvaluationSessionEntry
                )
                .map((q) => {
                  const uploadedAnswer = uploadedEvaluation?.regScoutReportQuestionAnswers.find(
                    (a) =>
                      a.fkRegScoutReportQuestion ===
                        q.fkRegScoutReportQuestion &&
                      a.fkRegScoutReportEvaluationSessionEntry ===
                        pkRegScoutReportEvaluationSessionEntry
                  );

                  return {
                    ...q,
                    value: clearAnswers
                      ? ''
                      : uploadedAnswer
                      ? uploadedAnswer.value ?? ''
                      : q.value ?? ''
                  };
                }),
              ...(uploadedEvaluation
                ? uploadedEvaluation.regScoutReportQuestionAnswers
                    .filter(
                      (a) =>
                        !evaluation.regScoutReportQuestionAnswers.some(
                          (s) =>
                            s.fkRegScoutReportQuestion ===
                              a.fkRegScoutReportQuestion &&
                            s.fkRegScoutReportEvaluationSessionEntry ===
                              a.pkRegScoutReportEvaluationSessionEntry
                        ) &&
                        regScoutReportQuestions.some(
                          (q) =>
                            q.pkRegScoutReportQuestion ===
                            a.fkRegScoutReportQuestion
                        )
                    )
                    .map((a) => ({
                      fkRegScoutReportEvaluation:
                        evaluation.pkRegScoutReportEvaluation,
                      fkRegScoutReportQuestion: a.fkRegScoutReportQuestion,
                      value: a.value ?? '',
                      fkRegScoutReportEvaluationSessionEntry: pkRegScoutReportEvaluationSessionEntry
                    }))
                : [])
            ];

            const changedAnswers = currentAnswersState.filter(
              (a) =>
                !oldAnswersState.find(
                  (e) =>
                    e.fkRegScoutReportQuestion === a.fkRegScoutReportQuestion &&
                    e.value === a.value
                )
            );
            return {
              fkRegPerson: evaluation.person.pkRegPerson,
              fkRegScoutReportPerson: evaluation.person.pkRegScoutReportPerson,
              regScoutReportQuestionAnswers: changedAnswers
            };
          })
          .filter((e) => e.regScoutReportQuestionAnswers.length > 0)
      },
      (data) => {
        if (onSuccessCallback) {
          setFormState((c) => ({
            name: data.name,
            regScoutReportEvaluationSessionEntries:
              data.regScoutReportEvaluationSessionEntries,
            regScoutReportEvaluationSessionTemplates:
              data.regScoutReportEvaluationSessionTemplates,
            regScoutReportEvaluations: data.regScoutReportEvaluations.map(
              (e) => ({
                ...e,
                key: e.pkRegScoutReportEvaluation,
                regScoutReportQuestionAnswers: clearAnswers
                  ? e.regScoutReportQuestionAnswers
                  : c.regScoutReportEvaluations.find(
                      (f) =>
                        f.pkRegScoutReportEvaluation ===
                        e.pkRegScoutReportEvaluation
                    )?.regScoutReportQuestionAnswers ||
                    e.regScoutReportQuestionAnswers
              })
            ),
            hasUnsavedChanges:
              clearAnswers || uploadedEvaluations.length > 0
                ? false
                : !data.regScoutReportEvaluations
                    .filter((e) => !e.deleted)
                    .every((e) => {
                      const currentEval = c.regScoutReportEvaluations.find(
                        (f) =>
                          f.pkRegScoutReportEvaluation ===
                          e.pkRegScoutReportEvaluation
                      );

                      return (
                        currentEval &&
                        currentEval.regScoutReportQuestionAnswers.length ===
                          e.regScoutReportQuestionAnswers.length &&
                        currentEval.regScoutReportQuestionAnswers.every((a) =>
                          e.regScoutReportQuestionAnswers.some(
                            (b) =>
                              b.customQuestion === a.customQuestion &&
                              b.fkRegScoutReportQuestion ===
                                a.fkRegScoutReportQuestion &&
                              b.fkRegScoutReportEvaluationSessionEntry ===
                                a.fkRegScoutReportEvaluationSessionEntry &&
                              b.value === a.value &&
                              b.notes === a.notes
                          )
                        )
                      );
                    }),
            isSaving: false
          }));
          onSuccessCallback(data);
        }
      },
      (error) => {
        setFormState({
          ...formState,
          isSaving: false
        });
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        else {
          triggerNotification(error);
        }
      }
    );
  };

  const onSaveChanges = (
    onSuccessCallback = null,
    onErrorCallback = null,
    {
      regScoutReportEvaluationSessionTemplates = null,
      regScoutReportEvaluationSessionEntries = null
    } = {}
  ) => {
    setFormState({
      ...formState,
      isSaving: true
    });
    updateRegScoutReportEvaluationSession(
      evaluationSession.pkRegScoutReportEvaluationSession,
      {
        ...formState,
        regScoutReportEvaluations: formState.regScoutReportEvaluations
          .filter((e) => !e.deleted)
          .map((evaluation) => ({
            ...evaluation,
            fkRegPerson: evaluation.person.pkRegPerson,
            fkRegScoutReportPerson: evaluation.person.pkRegScoutReportPerson
          })),
        regScoutReportEvaluationSessionTemplates:
          regScoutReportEvaluationSessionTemplates ||
          formState.regScoutReportEvaluationSessionTemplates,
        regScoutReportEvaluationSessionEntries:
          regScoutReportEvaluationSessionEntries ||
          formState.regScoutReportEvaluationSessionEntries
      },
      (data) => {
        if (onSuccessCallback) {
          setFormState({
            name: data.name,
            regScoutReportEvaluationSessionEntries:
              data.regScoutReportEvaluationSessionEntries,
            regScoutReportEvaluationSessionTemplates:
              data.regScoutReportEvaluationSessionTemplates,
            regScoutReportEvaluations: data.regScoutReportEvaluations.map(
              (e) => ({
                ...e,
                key: e.pkRegScoutReportEvaluation
              })
            ),
            hasUnsavedChanges: false,
            isSaving: false
          });
          onSuccessCallback(data);
        }
      },
      (error) => {
        setFormState({
          ...formState,
          isSaving: false
        });
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        else {
          triggerNotification(error);
        }
      }
    );
  };

  return {
    isLoading: !hasInitializedSession.current,
    isSaving: formState.isSaving,
    isPlayersLoading,
    hasUnsavedChanges: formState.hasUnsavedChanges,
    regScoutReportEvaluationSession: evaluationSession,
    evaluationSessionName: formState.name,
    regScoutReports,
    regScoutReportQuestions,
    regAssociationDivisionPlayers,
    regScoutReportEvaluations: formState.regScoutReportEvaluations,
    assignedRegScoutReportEvaluations,
    userEvaluationSessionEntry,
    onUpdateEvaluationSession,
    onSelectPlayers,
    onUpdateEvaluation,
    onUpdateEvaluations,
    onUpdateEvaluationAnswer,
    onUpdateEvaluationAnswers,
    onSaveEvaluationEntry,
    onSaveChanges
  };
};

const useEvaluationSessionEntryState = (
  pkRegScoutReportEvaluationSession,
  pkRegScoutReportEvaluationSessionEntry = null
) => {
  const navigate = useNavigate();
  const { state: authState } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    selectedPkRegScoutReportEvaluation: null,
    selectedPkRegScoutReportQuestion: null
  });
  const {
    isLoading,
    isSaving,
    hasUnsavedChanges,
    regScoutReportEvaluationSession,
    regScoutReports,
    assignedRegScoutReportEvaluations,
    regScoutReportQuestions,
    userEvaluationSessionEntry,
    onUpdateEvaluationAnswer,
    onUpdateEvaluations,
    onSaveEvaluationEntry
  } = useEvaluationSessionState(pkRegScoutReportEvaluationSession);

  const selectedEvaluationSessionEntry =
    regScoutReportEvaluationSession?.regScoutReportEvaluationSessionEntries.find(
      (e) =>
        e.pkRegScoutReportEvaluationSessionEntry.toString() ===
        pkRegScoutReportEvaluationSessionEntry
    ) || userEvaluationSessionEntry;

  const selectedRegScoutReportEvaluation = assignedRegScoutReportEvaluations.find(
    (e) =>
      e.pkRegScoutReportEvaluation ===
      formState.selectedPkRegScoutReportEvaluation
  );

  const selectedRegScoutReportQuestion = regScoutReportQuestions.find(
    (q) =>
      q.pkRegScoutReportQuestion === formState.selectedPkRegScoutReportQuestion
  );

  useEffect(() => {
    if (
      selectedEvaluationSessionEntry &&
      selectedEvaluationSessionEntry.regAssociationAdmin.user.pkUser !==
        authState.userData.user.pkUser
    ) {
      const regAssociationAdmin = authState.regAssociationAdmins.value.find(
        (a) =>
          a.regAssociation.pkRegAssociation ===
          regScoutReportEvaluationSession?.regAssociationDivision
            .regAssociationSeason.fkRegAssociation
      );
      const isAssociationSuperAdmin = regAssociationAdmin?.regAssociationAdminPermissions.some(
        (p) => p.fkRegPermission === REG_PERMISSION_ENUM.SUPER_ADMIN
      );

      if (!isAssociationSuperAdmin) {
        navigate('/');
      }
    }
  }, [selectedEvaluationSessionEntry]);

  const onSelectEvaluation = (
    pkRegScoutReportEvaluation,
    pkRegScoutReportQuestion = null
  ) => {
    setFormState({
      ...formState,
      selectedPkRegScoutReportEvaluation: pkRegScoutReportEvaluation,
      selectedPkRegScoutReportQuestion: pkRegScoutReportQuestion
    });
  };

  const onUpdateEvaluationEntryAnswer = (
    evaluation,
    question,
    answerValue,
    notes = null
  ) => {
    onUpdateEvaluationAnswer(
      evaluation,
      question,
      answerValue,
      notes,
      selectedEvaluationSessionEntry.pkRegScoutReportEvaluationSessionEntry
    );
  };

  const onSelectQuestion = (
    pkRegScoutReportQuestion,
    pkRegScoutReportEvaluation = null
  ) => {
    setFormState({
      ...formState,
      selectedPkRegScoutReportEvaluation: pkRegScoutReportEvaluation,
      selectedPkRegScoutReportQuestion: pkRegScoutReportQuestion
    });
  };

  const onSaveChanges = (
    onSuccessCallback = null,
    onErrorCallback = null,
    { clearAnswers = false } = {}
  ) => {
    onSaveEvaluationEntry(
      selectedEvaluationSessionEntry.pkRegScoutReportEvaluationSessionEntry,
      onSuccessCallback,
      onErrorCallback,
      { clearAnswers }
    );
  };

  return {
    isLoading,
    isSaving: isSaving || formState.isSaving,
    hasUnsavedChanges,
    regScoutReportEvaluationSession,
    regScoutReports,
    regScoutReportQuestions,
    regScoutReportEvaluations: assignedRegScoutReportEvaluations,
    selectedEvaluationSessionEntry,
    selectedRegScoutReportEvaluation,
    selectedRegScoutReportQuestion,
    onSelectEvaluation,
    onSelectQuestion,
    onUpdateEvaluationAnswer: onUpdateEvaluationEntryAnswer,
    onUpdateEvaluations,
    onSaveChanges
  };
};

export {
  useEvaluationTemplateState,
  useEvaluationSessionState,
  useEvaluationSessionEntryState
};
