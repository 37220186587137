import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, NumberFormatter, Skeleton, Tooltip } from '@mantine/core';
import { ChevronDown, ChevronUp, InfoCircle } from 'tabler-icons-react';
import AppCard from '../../../../components/common/AppCard';
import AppStack from '../../../../components/common/AppStack';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppText from '../../../../components/common/AppText';

const PaymentSummaryInfoCard = ({ payments, actionMenu, loading }) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [infoModalState, setInfoModalState] = useState({
    isOpen: false,
    title: '',
    info: ''
  });

  const paymentsGrouped =
    payments?.reduce((r, c) => {
      const existing = r.find((g) => g.group === c.group);
      if (existing) {
        existing.payments.push(c);
      }
      else {
        r.push({ group: c.group, payments: [c] });
      }
      return r;
    }, []) ?? [];

  return !loading ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      {/* <ResponsiveModal
        formSectionProps={{
          cancelTitle: 'Close',
          onCancel: () =>
            setInfoModalState({ ...infoModalState, isOpen: false }),
          isSubmitHidden: true
        }}
        isOpen={infoModalState.isOpen}
        onClose={() => setInfoModalState({ ...infoModalState, isOpen: false })}
        size={500}
        title={infoModalState.title || 'Details'}
      >
        {infoModalState.info}
      </ResponsiveModal> */}

      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>Payment</AppText>
          {actionMenu}
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            {paymentsGrouped.map((g, index) => (
              <React.Fragment key={g.group}>
                {index > 0 && <Divider />}

                <AppStack style={{ padding: 16, gap: 8 }}>
                  {g.payments.map((p) => {
                    const isExpanded =
                      !!p.children && expandedKeys.includes(p.key);

                    return (
                      <AppStack
                        key={p.key}
                        onClick={() => {
                          if (p.children) {
                            setExpandedKeys(
                              isExpanded
                                ? expandedKeys.filter((k) => k !== p.key)
                                : [...expandedKeys, p.key]
                            );
                          }
                        }}
                        style={{
                          gap: 8,
                          cursor: p.children ? 'pointer' : 'default'
                        }}
                      >
                        <AppFlexbox
                          style={{
                            gap: 8,
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <AppFlexbox
                            key={p.key}
                            style={{
                              gap: 8,
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              flex: 1
                            }}
                          >
                            <Tooltip
                              disabled={!p.tooltip}
                              label={p.tooltip}
                              multiline
                              onClick={() => {
                                if (p.infoContent) {
                                  setInfoModalState({
                                    isOpen: true,
                                    title: p.infoTitle,
                                    info: p.infoContent
                                  });
                                }
                              }}
                              w={250}
                              withArrow
                            >
                              <AppFlexbox
                                style={{
                                  gap: 8,
                                  alignItems: 'center',
                                  cursor: p.infoContent ? 'pointer' : 'default',
                                  flex: 1
                                }}
                              >
                                <AppText
                                  style={{
                                    fontSize: 14,
                                    color: p.color ?? '#000',
                                    fontWeight: p.weight ?? 'normal',
                                    flex: 1,
                                    width: '100%',
                                    maxWidth: 150
                                  }}
                                >
                                  {p.label}
                                </AppText>
                                {p.tooltip &&
                                  (p.tooltipIcon || (
                                    <InfoCircle color="dodgerblue" size={18} />
                                  ))}
                              </AppFlexbox>
                            </Tooltip>
                            <AppFlexbox
                              style={{ flex: 1, gap: 5, alignItems: 'center' }}
                            >
                              {p.description &&
                                (typeof p.description === 'string' ? (
                                  <AppText
                                    style={{
                                      fontSize: 14,
                                      color: p.color ?? '#000',
                                      fontWeight: p.weight ?? 'normal'
                                    }}
                                  >
                                    {p.description}
                                  </AppText>
                                ) : (
                                  p.description
                                ))}
                              {p.children &&
                                (isExpanded ? (
                                  <ChevronUp
                                    color={p.color ?? '#000'}
                                    size={16}
                                  />
                                ) : (
                                  <ChevronDown
                                    color={p.color ?? '#000'}
                                    size={16}
                                  />
                                ))}
                            </AppFlexbox>
                          </AppFlexbox>
                          <AppFlexbox
                            style={{ minWidth: 70, justifyContent: 'flex-end' }}
                          >
                            {!isExpanded && (
                              <NumberFormatter
                                decimalScale={2}
                                fixedDecimalScale
                                prefix={p.prefix || '$'}
                                style={{
                                  fontSize: 14,
                                  color: p.valueColor ?? p.color ?? '#000',
                                  fontWeight: p.weight ?? 'normal'
                                }}
                                thousandSeparator
                                value={p.value}
                              />
                            )}
                          </AppFlexbox>
                        </AppFlexbox>

                        {isExpanded && p.children && (
                          <AppStack style={{ paddingLeft: 16, gap: 8 }}>
                            {p.children.map((c) => (
                              <AppFlexbox
                                key={c.key}
                                style={{
                                  gap: 8,
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <AppFlexbox
                                  style={{
                                    gap: 8,
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1
                                  }}
                                >
                                  <Tooltip
                                    disabled={!c.tooltip}
                                    label={c.tooltip}
                                    multiline
                                    onClick={() => {
                                      if (c.infoContent) {
                                        setInfoModalState({
                                          isOpen: true,
                                          title: c.infoTitle,
                                          info: c.infoContent
                                        });
                                      }
                                    }}
                                    w={250}
                                    withArrow
                                  >
                                    <AppFlexbox
                                      style={{
                                        gap: 8,
                                        alignItems: 'center',
                                        flex: 1
                                      }}
                                    >
                                      <AppText
                                        style={{
                                          fontSize: 14,
                                          color: c.color ?? '#000',
                                          fontWeight: c.weight ?? 'normal',
                                          flex: 1,
                                          width: '100%',
                                          maxWidth: 150
                                        }}
                                      >
                                        {c.label}
                                      </AppText>
                                      {c.tooltip &&
                                        (c.tooltipIcon || (
                                          <InfoCircle
                                            color="dodgerblue"
                                            size={18}
                                          />
                                        ))}
                                    </AppFlexbox>
                                  </Tooltip>
                                </AppFlexbox>
                                <NumberFormatter
                                  decimalScale={2}
                                  fixedDecimalScale
                                  prefix={c.prefix || '$'}
                                  style={{
                                    fontSize: 14,
                                    color: c.valueColor ?? c.color ?? '#000',
                                    fontWeight: c.weight ?? 'normal'
                                  }}
                                  thousandSeparator
                                  value={c.value}
                                />
                              </AppFlexbox>
                            ))}
                          </AppStack>
                        )}
                      </AppStack>
                    );
                  })}
                </AppStack>
              </React.Fragment>
            ))}
          </AppStack>
        </AppCard>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ padding: 16, gap: 8 }}>
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Skeleton height={22} width="20%" />
        </AppFlexbox>

        <AppCard radius="md" shadow="none" style={{ padding: 0 }} withBorder>
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>

            <Divider />
            <AppStack style={{ padding: 16, gap: 8 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox style={{ flex: 1, maxWidth: 150 }}>
                  <Skeleton height={18} width="50%" />
                </AppFlexbox>
                <AppFlexbox style={{ flex: 1 }}>
                  <Skeleton height={18} width="15%" />
                </AppFlexbox>
                <Skeleton height={18} width="15%" />
              </AppFlexbox>
            </AppStack>
          </AppStack>
        </AppCard>
      </AppStack>
    </AppCard>
  );
};

PaymentSummaryInfoCard.propTypes = {
  actionMenu: PropTypes.node,
  loading: PropTypes.bool,
  payments: PropTypes.array
};

export default PaymentSummaryInfoCard;
