import React, { useState } from 'react';
import {
  ActionIcon,
  Button,
  Menu,
  Textarea,
  TextInput,
  Timeline
} from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { DotsVertical, Edit, Point, Trash } from 'tabler-icons-react';
import { formatUtcDate } from '../../helpers/format';
import AppStack from './AppStack';
import AppText from './AppText';
import { REG_RESOURCE_TYPE_LOG_ENUM } from '../../config/constants';
import AppFlexbox from './AppFlexbox';
import MenuContent from './MenuContent';
import ConfirmModal from './ConfirmModal';

const HistoryTimelineItemEditable = ({
  title,
  message,
  content,
  date,
  createdBy,
  fkRegResourceLogType,
  onSaveNote,
  onDeleteNote
}) => {
  const [formState, setFormState] = useState({
    title,
    message,
    showEditView: false,
    loading: false
  });

  const isUserNote =
    fkRegResourceLogType?.toString() === REG_RESOURCE_TYPE_LOG_ENUM.USER_NOTE;

  return (
    <Timeline.Item>
      <AppStack style={{ gap: 8 }}>
        {formState.showEditView ? (
          <AppStack
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setFormState({
                ...formState,
                loading: true
              });
              onSaveNote(
                {
                  title: formState.title,
                  message: formState.message
                },
                () => {
                  setFormState({
                    ...formState,
                    loading: false
                  });
                  setFormState({
                    ...formState,
                    showEditView: false
                  });
                },
                () => {
                  setFormState({
                    ...formState,
                    loading: false
                  });
                }
              );
            }}
            style={{ gap: 8 }}
          >
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Edit Note
              </AppText>
              <AppFlexbox>
                <Button
                  color="dark"
                  loading={formState.loading}
                  onClick={() => {
                    setFormState({
                      ...formState,
                      showEditView: false
                    });
                  }}
                  size="compact-sm"
                  type="submit"
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  color="blue"
                  disabled={!formState.message || !formState.title}
                  loading={formState.loading}
                  size="compact-sm"
                  type="submit"
                  variant="filled"
                >
                  Save changes
                </Button>
              </AppFlexbox>
            </AppFlexbox>
            <TextInput
              disabled={formState.loading}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  title: e.currentTarget.value.substring(0, 255)
                });
              }}
              placeholder="Title"
              required
              value={formState.title}
            />
            <Textarea
              autosize
              disabled={formState.loading}
              minRows={3}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  message: e.currentTarget.value
                });
              }}
              placeholder="Add a note..."
              required
              value={formState.message.substring(0, 1000)}
            />
          </AppStack>
        ) : (
          <AppStack style={{ gap: 5 }}>
            <AppFlexbox style={{ gap: 8, justifyContent: 'space-between' }}>
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                {title}
              </AppText>

              {isUserNote && (onSaveNote || onDeleteNote) && (
                <MenuContent
                  control={
                    <ActionIcon color="dark" variant="subtle">
                      <DotsVertical color="#000" />
                    </ActionIcon>
                  }
                >
                  {onSaveNote && (
                    <Menu.Item
                      leftSection={<Edit size={18} />}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          showEditView: true,
                          title,
                          message
                        });
                      }}
                    >
                      Edit
                    </Menu.Item>
                  )}

                  {onDeleteNote && (
                    <Menu.Item
                      leftSection={<Trash color="#c40000" size={18} />}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          showDeleteView: true
                        });
                      }}
                      style={{ color: '#c40000' }}
                    >
                      Delete
                    </Menu.Item>
                  )}
                </MenuContent>
              )}
            </AppFlexbox>
            <AppFlexbox style={{ gap: 8 }}>{content}</AppFlexbox>
          </AppStack>
        )}

        <AppFlexbox style={{ gap: 3, alignItems: 'center' }}>
          {createdBy && (
            <AppText style={{ fontSize: 13, color: '#666' }}>
              {createdBy}
            </AppText>
          )}
          {date && (
            <>
              {createdBy && <Point color="#666" size={14} />}
              <AppText style={{ fontSize: 13, color: '#666' }}>
                {dayjs(formatUtcDate(date)).format('MMMM D, YYYY')} at{' '}
                {dayjs(formatUtcDate(date)).format('h:mm A')}
              </AppText>
            </>
          )}
        </AppFlexbox>

        {formState.showDeleteView && (
          <ConfirmModal
            cancelActionText="Back"
            confirmActionColor="red"
            confirmActionText="Yes, delete note"
            isLoading={formState.loading}
            isOpen
            message={
              <AppStack style={{ gap: 10 }}>
                <AppText style={{ fontSize: 16 }}>
                  Are you sure you want to delete this note?
                </AppText>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  This action cannot be undone.
                </AppText>
              </AppStack>
            }
            onCancel={() => {
              setFormState({
                ...formState,
                showDeleteView: false
              });
            }}
            onConfirm={() => {
              setFormState({
                ...formState,
                loading: true
              });
              onDeleteNote(
                () => {
                  setFormState({
                    ...formState,
                    loading: false,
                    showDeleteView: false
                  });
                },
                () => {
                  setFormState({
                    ...formState,
                    loading: false
                  });
                }
              );
            }}
            title="Delete note?"
            topPadding={0}
          />
        )}
      </AppStack>
    </Timeline.Item>
  );
};

HistoryTimelineItemEditable.propTypes = {
  content: PropTypes.element,
  createdBy: PropTypes.string,
  date: PropTypes.string,
  fkRegResourceLogType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  message: PropTypes.string,
  onDeleteNote: PropTypes.func,
  onSaveNote: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.string
};

export default HistoryTimelineItemEditable;
