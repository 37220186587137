import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import RegistrationDetailsView from './RegistrationDetailsView';
import { Context as RegistrationAdminDashboardContext } from '../../../../providers/RegistrationAdminDashboardProvider';
import { Context as RegistrationAdminContext } from '../../../../providers/RegistrationAdminProvider';
import { triggerNotification } from '../../../../helpers/notificationHelper';
import ResourceChangeAssociationView from '../../../../components/common/ResourceChangeAssociationView';

const RegistrationViews = () => {
  const fetchedPk = useRef(false);
  const { pkRegFormSubmission } = useParams();
  const { state: helperState } = useContext(RegistrationAdminDashboardContext);
  const { state, fetchRegFormSubmissionRegistration } = useContext(
    RegistrationAdminContext
  );

  const regFormSubmissionWithResource =
    state.regFormSubmission.value?.regFormSubmission.pkRegFormSubmission.toString() ===
    pkRegFormSubmission
      ? state.regFormSubmission.value
      : null;

  const regFormSubmission =
    state.regFormSubmissionBalance.value?.pkRegFormSubmission.toString() ===
    pkRegFormSubmission
      ? state.regFormSubmissionBalance.value
      : null;

  const loading =
    fetchedPk.current !== pkRegFormSubmission ||
    state.regFormSubmission.loading ||
    !regFormSubmission ||
    !regFormSubmissionWithResource;

  const showSwitchAssociationView =
    regFormSubmission &&
    regFormSubmission.regAssociationDivisionForm.regProduct.fkRegAssociation !==
      helperState.regAssociation.value?.pkRegAssociation;

  useEffect(() => {
    if (pkRegFormSubmission) {
      fetchRegFormSubmissionRegistration(pkRegFormSubmission, {}, null, (e) => {
        triggerNotification(e);
      });

      fetchedPk.current = pkRegFormSubmission;
    }
  }, [pkRegFormSubmission]);

  return showSwitchAssociationView || (!loading && !regFormSubmission) ? (
    <ResourceChangeAssociationView
      backPath="/admin/financials/registrations"
      fkRegAssociation={
        regFormSubmission.regAssociationDivisionForm.regProduct.fkRegAssociation
      }
      resourceIdentifier="registration"
    />
  ) : (
    <Routes>
      <Route
        element={
          <RegistrationDetailsView
            loading={loading}
            regFormSubmission={regFormSubmission}
          />
        }
        path="/"
      />
      <Route
        element={
          <Navigate
            replace
            to={`/admin/financials/registrations/${pkRegFormSubmission}`}
          />
        }
        path="*"
      />
    </Routes>
  );
};

export default RegistrationViews;
