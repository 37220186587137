import sportsheadzApi from '../apis/sportsheadzApi';

const getErrorMessage = (e) => {
  let error = 'Oops something went wrong!';
  let type = 'general';
  if (e?.response?.status === 409 && e.response.data) {
    error = e.response.data.error;
    type = e.response.data.type;
  }
  return { error, type, data: e.response?.data, status: e.response?.status };
};

const handleError = (e, stateName, dispatch, onErrorCallback = null) => {
  const { error, type, data, status } = getErrorMessage(e);

  if (stateName && dispatch) {
    dispatch({
      type: 'error',
      stateName,
      payload: error
    });
  }
  if (onErrorCallback) {
    onErrorCallback(error, type, data, status);
  }
};

const onApiRequest = async (
  {
    dispatch,
    type,
    stateName,
    url,
    data,
    params,
    successAction,
    removeFilter,
    transformPayload = null,
    selectFirstItem = false,
    filter = null,
    resetStateValue = undefined,
    skipLoading = false,
    forceLoading = false
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  try {
    let responseData = null;
    switch (type) {
      case 'get': {
        const fetchFilter = filter || params ? { ...filter, ...params } : null;
        if (dispatch && stateName && !skipLoading) {
          dispatch({
            type: 'fetching',
            stateName,
            payload:
              resetStateValue !== undefined
                ? { value: resetStateValue, filter: fetchFilter }
                : { filter: fetchFilter }
          });
        }
        const response = await sportsheadzApi.get(url, { params });
        if (dispatch && stateName) {
          dispatch({
            type: successAction ?? 'success',
            stateName,
            payload: transformPayload
              ? transformPayload(response.data)
              : {
                  value:
                    selectFirstItem && Array.isArray(response.data)
                      ? response.data[0]
                      : response.data,
                  filter: removeFilter
                    ? (item) => removeFilter(item, response.data)
                    : fetchFilter
                }
          });
        }
        responseData = response.data;
        break;
      }
      case 'post': {
        if (dispatch && stateName && forceLoading) {
          dispatch({
            type: 'fetching',
            stateName
          });
        }
        const response = await sportsheadzApi.post(url, data, { params });
        if (dispatch && stateName) {
          dispatch({
            type: successAction ?? 'append',
            stateName,
            payload: transformPayload
              ? transformPayload(response.data)
              : {
                  value:
                    selectFirstItem && Array.isArray(response.data)
                      ? response.data[0]
                      : response.data
                }
          });
        }
        responseData = response.data;
        break;
      }
      case 'put': {
        const response = await sportsheadzApi.put(url, data, { params });
        if (dispatch && stateName) {
          dispatch({
            type: successAction ?? 'replace',
            stateName,
            payload: transformPayload
              ? transformPayload(response.data)
              : {
                  filter: removeFilter
                    ? (item) => removeFilter(item, response.data)
                    : null,
                  value:
                    selectFirstItem && Array.isArray(response.data)
                      ? response.data[0]
                      : response.data
                }
          });
        }
        responseData = response.data;
        break;
      }
      case 'delete': {
        const response = await sportsheadzApi.delete(url, { params });
        if (dispatch && stateName) {
          dispatch({
            type: successAction ?? 'remove',
            stateName,
            payload: transformPayload
              ? transformPayload(response.data)
              : (item) => removeFilter(item, response.data)
          });
        }
        responseData = response.data;
        break;
      }
      default: {
        throw new Error('Invalid request type');
      }
    }
    if (onSuccessCallback) {
      onSuccessCallback(responseData);
    }
  } catch (e) {
    handleError(e, stateName, dispatch, onErrorCallback);
  }
};

export default onApiRequest;
