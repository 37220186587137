import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useLocation } from 'react-router-dom';
import AppText from '../components/common/AppText';
import AppFlexbox from '../components/common/AppFlexbox';
import { getFromStorage, saveToStorage } from './storageHelper';
import { Context as RegistrationAdminContext } from '../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../providers/RegistrationAdminDashboardProvider';
import { triggerNotification } from './notificationHelper';
import ConfirmModal from '../components/common/ConfirmModal';
import { usePrompt } from './prompt';

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [ref.current]);

  return isIntersecting;
};

const useMediaQueryIndex = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isLaptop = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const isLargeDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

  const savedMqIndex = Number(getFromStorage('mqIndexRef'));
  if (isMobile === undefined) {
    return savedMqIndex;
  }

  const mqIndex = isMobile
    ? 0
    : isTablet
    ? 1
    : isLaptop
    ? 2
    : isDesktop
    ? 3
    : isLargeDesktop
    ? 4
    : 5;

  if (mqIndex !== savedMqIndex) {
    saveToStorage('mqIndexRef', mqIndex.toString());
  }
  return mqIndex;
};

const useMediaQueries = ({ viewPortAdjustment } = {}) => {
  const theme = useMantineTheme();
  const isMobileOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xs} + ${viewPortAdjustment})`
        : theme.breakpoints.xs
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isLargeMobileOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xsm} + ${viewPortAdjustment})`
        : theme.breakpoints.xsm
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isTabletOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.sm} + ${viewPortAdjustment})`
        : theme.breakpoints.sm
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isLaptopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.md} + ${viewPortAdjustment})`
        : theme.breakpoints.md
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isDesktopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.lg} + ${viewPortAdjustment})`
        : theme.breakpoints.lg
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isLargeDesktopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xlg} + ${viewPortAdjustment})`
        : theme.breakpoints.xlg
    })`,
    true,
    { getInitialValueInEffect: false }
  );
  const isGiantDesktopOrSmaller = useMediaQuery(
    `(max-width: ${
      viewPortAdjustment
        ? `calc(${theme.breakpoints.xl} + ${viewPortAdjustment})`
        : theme.breakpoints.xl
    })`,
    true,
    { getInitialValueInEffect: false }
  );

  const mqIndex = isMobileOrSmaller
    ? 0
    : isTabletOrSmaller
    ? 1
    : isLaptopOrSmaller
    ? 2
    : isDesktopOrSmaller
    ? 3
    : isLargeDesktopOrSmaller
    ? 4
    : isGiantDesktopOrSmaller
    ? 5
    : 6;

  const getResponsiveStyle = (styles = []) =>
    mqIndex > styles.length - 1 ? styles[styles.length - 1] : styles[mqIndex];

  return {
    mqIndex,
    isMobileOrSmaller,
    isLargeMobileOrSmaller,
    isTabletOrSmaller,
    isLaptopOrSmaller,
    isDesktopOrSmaller,
    isLargeDesktopOrSmaller,
    isGiantDesktopOrSmaller,
    getResponsiveStyle
  };
};

const useModalState = ({ closeOnOpen } = {}) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: null,
    item: null,
    secondItem: null,
    loading: false
  });

  useEffect(() => {
    if (modalState.isOpen && closeOnOpen) {
      // eslint-disable-next-line no-use-before-define
      onCloseModal();
    }
  }, [modalState.isOpen]);

  const onOpenModal = (action, item, secondItem) => {
    setModalState({
      isOpen: true,
      action,
      item,
      secondItem,
      loading: false
    });
  };

  const onCloseModal = () => {
    setModalState({
      ...modalState,
      isOpen: false
    });
  };

  const onChangeModalLoading = (isLoading = null) => {
    setModalState({
      ...modalState,
      loading: isLoading ?? !modalState.loading
    });
  };

  return { state: modalState, onCloseModal, onOpenModal, onChangeModalLoading };
};

const useSortByFilter = (
  sortableTableColumns,
  config = { defaultSort: null, defaultDescending: false }
) => {
  const [sortFilter, setSortFilter] = useState({
    sortBy:
      config.defaultSort != null
        ? config.defaultSort
        : sortableTableColumns[0]?.value,
    descendingOrder: config.defaultDescending || false
  });
  const selectedSort = sortableTableColumns.find(
    (c) => c.value === sortFilter.sortBy
  );

  useEffect(() => {
    setSortFilter({
      sortBy:
        config.defaultSort != null
          ? config.defaultSort
          : sortableTableColumns[0]?.value,
      descendingOrder: config.defaultDescending || false
    });
  }, []);

  const onChangeSortBy = (value, isDescending = false) => {
    setSortFilter({
      ...sortFilter,
      sortBy: value,
      descendingOrder: isDescending
    });
  };

  return {
    sortValue: sortFilter.sortBy,
    isDescendingSort: sortFilter.descendingOrder,
    onChangeSortBy,
    customFilterData: {
      type: 'custom-select',
      value: sortFilter.sortBy,
      placeholder: 'Sort by...',
      searchValue: sortFilter.sortBy ? (
        <AppFlexbox style={{ gap: 5, textWrap: 'nowrap' }}>
          <AppText color="#808080" style={{ fontSize: 14 }} weight={400}>
            Sort by
          </AppText>
          <AppText color="#000" style={{ fontSize: 14, flex: 1 }} weight={400}>
            {selectedSort?.label}
          </AppText>
        </AppFlexbox>
      ) : (
        ''
      ),
      onlyShowSearchValue: true,
      data: sortableTableColumns.map((c) => ({
        label: c.label,
        value: c.value
      })),
      onChange: (value) => {
        if (value && value !== sortFilter.sortBy) {
          onChangeSortBy(value);
        }
      }
    }
  };
};

const useDashboardCookies = () => {
  const isClassicViewCookie = getFromStorage('isClassicView');
  const pkRegAssociation = getFromStorage('pkRegAssociation');
  const { state, changeClassicViewSettings, changeAssociation } =
    useContext(RegistrationAdminDashboardContext) || {};
  const isClassicView = !isClassicViewCookie || isClassicViewCookie === 'true';

  useEffect(() => {
    if (state) {
      if (isClassicView !== state.view.isClassicView) {
        changeClassicViewSettings({ isClassicView }, true);
      }

      if (
        pkRegAssociation &&
        pkRegAssociation !== state.regAssociation.pkRegAssociation
      ) {
        changeAssociation({ pkRegAssociation }, true);
      }
    }
  }, []);

  return {
    pkRegAssociation,
    isClassicView
  };
};

const usePaginationFilter = (initialFilter, onFilter, resultState) => {
  const hasInitialized = useRef(false);
  const [debouncedFilter, setDebouncedFilter] = useState(null);
  const [filterState, setFilterState] = useState({});
  const {
    pageIndex,
    pageSize,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data,
    loading,
    filter
  } = resultState;
  const currentFilter = { ...initialFilter, ...filter, ...filterState };
  const isDescendingSort =
    currentFilter.orderBy === 'desc' || currentFilter.sort?.endsWith('_desc');

  useEffect(() => {
    if (debouncedFilter !== null) {
      // eslint-disable-next-line no-use-before-define
      onFilterChange(debouncedFilter, true);
    }
  }, [debouncedFilter]);

  useEffect(() => {
    if (hasInitialized.current) {
      const handler = setTimeout(() => {
        setDebouncedFilter(filterState);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }

    hasInitialized.current = true;
    return () => {};
  }, [filterState]);

  const onPageChange = (page) => {
    // eslint-disable-next-line no-use-before-define
    onFilterChange(currentFilter, true, page);
  };

  const onFilterChange = (newFilter, skipDebounce = false, page = null) => {
    if (skipDebounce) {
      onFilter({
        ...newFilter,
        page: page || 1
      });
    }
    else {
      setFilterState(newFilter);
    }
  };

  const onSortChange = (sortBy, isDescending) => {
    const sortValue = isDescending ? `${sortBy}_desc` : sortBy;
    if (sortValue !== filter.sort) {
      onFilterChange(
        {
          ...filter,
          sort: sortValue
        },
        true
      );
    }
  };

  const onRefresh = () => {
    onFilter({
      ...filter,
      page: pageIndex
    });
  };

  return {
    pageIndex,
    pageSize,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data,
    filter: currentFilter,
    loading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh,
    usePaginationFilter
  };
};

const useGlobalFormState = (
  initialValues,
  config = {
    confirmDiscard: false,
    containerWidth: 950,
    initializeOnMount: false,
    saveButtonId: 'save-button'
  }
) => {
  const hasInitialized = useRef(false);
  const [initalState, setInitialState] = useState({
    data: initialValues,
    showModal: false,
    discardCallback: null
  });
  const { state: helperState, setFormData } = useContext(
    RegistrationAdminDashboardContext
  );
  const { form: formState } = helperState;

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    !formState.loading && formState.hasUnsavedChanges
  );

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    resetFormState(initialValues, true && !config.initializeOnMount);
  }, []);

  useEffect(() => {
    if (formState.triggerDiscard) {
      // eslint-disable-next-line no-use-before-define
      discardFormChanges();
    }
  }, [formState.triggerDiscard]);

  useEffect(() => {
    if (formState.triggerSubmit) {
      document.getElementById(config.saveButtonId ?? 'save-button')?.click();
    }
  }, [formState.triggerSubmit]);

  const setFormState = (data) => {
    setFormData({
      data: {
        ...formState.data,
        ...data
      },
      containerWidth: config.containerWidth,
      hasUnsavedChanges: true
    });
  };

  const resetFormState = (
    data = null,
    isInitialValues = false,
    hasUnsavedChanges = false
  ) => {
    setInitialState({
      ...initalState,
      data: data ?? initalState.data,
      showModal: false
    });
    setFormData({
      data: { ...(data ?? initalState.data) },
      containerWidth: config.containerWidth,
      hasUnsavedChanges
    });
    hasInitialized.current =
      !isInitialValues && (!!data || hasInitialized.current);
  };

  const discardFormChanges = (onCallback = null) => {
    if (config.confirmDiscard) {
      setInitialState({
        ...initalState,
        showModal: true,
        discardCallback: onCallback
      });
    }
    else {
      // eslint-disable-next-line no-use-before-define
      resetFormState();
      if (onCallback) {
        onCallback();
      }
    }
  };

  const submitFormState = (submitFunction) => {
    setFormData({ ...formState, loading: true });
    submitFunction(formState.data, (error) => {
      triggerNotification(error);
      setFormData({ ...formState, loading: false });
    });
  };

  return {
    hasInitialized: hasInitialized.current && formState.initialized,
    hasUnsavedChanges: formState.hasUnsavedChanges,
    formState: hasInitialized.current ? formState.data : initialValues,
    errors: formState.errors,
    isSubmitting: formState.loading,
    setFormState,
    resetFormState,
    discardFormChanges,
    submitFormState,
    ConfirmDiscardModal: config.confirmDiscard ? (
      <ConfirmModal
        confirmActionColor="red"
        confirmActionText="Yes, discard changes"
        isOpen={initalState.showModal}
        message="Are you sure you want to discard all unsaved changes?"
        onCancel={() => {
          setInitialState({ ...initalState, showModal: false });
          setFormData({
            data: formState.data,
            containerWidth: config.containerWidth
          });
          if (initalState.discardCallback) {
            initalState.discardCallback(false);
          }
        }}
        onConfirm={() => {
          setInitialState({ ...initalState, showModal: false });
          resetFormState();
          if (initalState.discardCallback) {
            initalState.discardCallback(true);
          }
        }}
        title="Discard changes?"
      />
    ) : null
  };
};

const useBackPath = (defaultPathOverride = null) => {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const fromLocations = searchParams.get('from')?.split(',') || [];
  const fromId = searchParams.get('fromId');

  const firstFrom = fromLocations[0]?.toLowerCase();
  const params =
    fromLocations.length > 1 ? `?from=${fromLocations.slice(1).join(',')}` : '';

  const routes = {
    players: '/admin/league/players',
    guardians: '/admin/league/guardians',
    'bench-staff': '/admin/league/bench-staff',
    seasons: '/admin/league/seasons',
    divisions: '/admin/league/divisions',
    teams: '/admin/league/teams',
    registrations: '/admin/financials/registrations'
  };

  const basePath = pathname.split('/').slice(0, -1).join('/') || '/';
  const backPath = routes[firstFrom]
    ? `${routes[firstFrom]}${fromId ? `/${fromId}` : ''}${params}`
    : defaultPathOverride || basePath;

  return { backPath };
};

const useRegResource = ({ resourceId, fkRegResourceType }) => {
  const { state: helperState } = useContext(RegistrationAdminDashboardContext);
  const { state, updateRegResource } = useContext(RegistrationAdminContext);

  const regResource =
    state.regResource.value?.resourceId.toString() === resourceId.toString() &&
    state.regResource.value?.fkRegResourceType.toString() ===
      fkRegResourceType.toString() &&
    state.regResource.value?.fkRegAssociation ===
      helperState.regAssociation.value?.pkRegAssociation
      ? state.regResource.value
      : null;

  const onUpdateRegResource = (
    {
      regResourceLogs,
      regResourceTaskTemplates,
      regResourceTaskTemplateAnswers
    } = {},
    onSuccessCallback = null,
    onErrorCallback = null
  ) => {
    updateRegResource(
      helperState.regAssociation.value.pkRegAssociation,
      {
        resourceId,
        fkRegResourceType,
        regResourceLogs,
        regResourceTaskTemplates,
        regResourceTaskTemplateAnswers
      },
      onSuccessCallback,
      onErrorCallback
    );
  };

  return {
    regResource,
    regResourceLogs: regResource?.regResourceLogs || [],
    regResourceTaskTemplates: regResource?.regResourceTaskTemplates || [],
    regResourceTaskTemplateAnswers:
      regResource?.regResourceTaskTemplateAnswers || [],
    onUpdateRegResource
  };
};

export {
  useOnScreen,
  useMediaQueries,
  useMediaQueryIndex,
  useModalState,
  useSortByFilter,
  useDashboardCookies,
  usePaginationFilter,
  useGlobalFormState,
  useBackPath,
  useRegResource
};
