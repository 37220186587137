import { Button } from '@mantine/core';
import dayjs from 'dayjs';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { DeviceFloppy, Table } from 'tabler-icons-react';
import { deleteFileFromStorage } from '../../../helpers/awsHelper';
import { formatUtcDate } from '../../../helpers/format';
import {
  calcualteNewPosition,
  calcualteResizePosition,
  calculateGridSize,
  calculateMinSize,
  isNewPositionOverlapping
} from '../../../helpers/formBuilderHelper';
import { usePrompt } from '../../../helpers/prompt';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FormBuilderMenu from './FormBuilderMenu';
import RegistrationForm from './RegistrationForm';
import { triggerNotification } from '../../../helpers/notificationHelper';
import LoadableView from '../../common/LoadableView';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';

const initialFormBuilderState = {
  pkRegFormSection: null,
  pkRegFormControl: null,
  initialPosition: null,
  dragPosition: null,
  gridSize: [1, 1],
  initialMousePosition: null,
  mousePosition: null,
  showGrid: false,
  isBeingDragged: false,
  isBeingResized: false,
  isOverlapping: false,
  sections: [],
  hasUnsavedChanges: false,
  isAutoSaving: false,
  updating: false
};

const formBuilderReducer = (state, action) => {
  switch (action.type) {
    case 'select-control':
      return {
        ...state,
        pkRegFormControl: action.payload.pkRegFormControl,
        pkRegFormSection:
          action.payload.pkRegFormSection ?? state.pkRegFormSection,
        gridSize: action.payload.gridSize ?? state.gridSize
      };
    case 'select-section':
      return {
        ...state,
        pkRegFormControl: action.payload.pkRegFormControl,
        pkRegFormSection: action.payload.pkRegFormSection
      };
    case 'move-control':
      return {
        ...state,
        isBeingDragged: true,
        dragPosition: action.payload.dragPosition,
        isOverlapping: action.payload.isOverlapping,
        initialMousePosition: action.payload.initialMousePosition,
        hasUnsavedChanges: true
      };
    case 'move-control-stop':
      return {
        ...state,
        isBeingDragged: false,
        dragPosition: null,
        mousePosition: null,
        initialMousePosition: null,
        isOverlapping: false,
        sections: action.payload?.sections ?? state.sections
      };
    case 'resize-control':
      return {
        ...state,
        isBeingResized: true,
        isOverlapping: action.payload.isOverlapping,
        initialPosition: action.payload.initialPosition,
        sections: action.payload?.sections ?? state.sections,
        hasUnsavedChanges: true
      };
    case 'resize-control-stop':
      return {
        ...state,
        isBeingResized: false,
        isOverlapping: false,
        initialPosition: null
      };
    case 'mouse-move':
      return {
        ...state,
        initialMousePosition:
          action.payload.initialMousePosition ?? state.initialMousePosition,
        mousePosition: action.payload.mousePosition,
        gridSize: action.payload.gridSize ?? state.gridSize
      };
    case 'toggle-grid':
      return {
        ...state,
        showGrid: !state.showGrid
      };
    case 'update-sections':
      return {
        ...state,
        sections: action.payload?.sections ?? state.sections,
        hasUnsavedChanges: true
      };
    case 'sync-sections':
      return {
        ...state,
        sections: action.payload?.sections ?? state.sections,
        hasUnsavedChanges: false,
        isAutoSaving: false,
        updating: false
      };
    case 'auto-save':
      return {
        ...state,
        isAutoSaving: true
      };
    case 'save-form':
      return {
        ...state,
        updating: true
      };
    case 'error':
      return {
        ...state,
        isAutoSaving: false,
        updating: false
      };
    default:
      return state;
  }
};

const FormBuilder = () => {
  const mqIndex = useMediaQueryIndex();
  const [formBuilderState, dispatch] = useReducer(
    formBuilderReducer,
    initialFormBuilderState
  );
  const saveChangesIntervalRef = useRef();
  const hasFetchedForm = useRef(false);
  const { uuid } = useParams();
  const [oldDocumentsUploadHistory, setOldDocumentsUploadHistory] = useState(
    []
  );
  const [openFormSectionModal, setOpenFormSectionModal] = useState(false);
  const { state, fetchAdminRegForm, updateAdminRegForm } = useContext(
    RegistrationAdminContext
  );
  const isLoading = !hasFetchedForm.current || state.regForm.loading;
  const lowestCostDivisionForm = state.regForm.value?.regAssociationDivisionForms.sort(
    (a, b) => a.regProduct.price - b.regProduct.price
  )[0];

  const largestAddOnDiscountAmount =
    formBuilderState.sections.reduce(
      (total, s) =>
        total +
        s.regFormControls.reduce((subtotal, c) => {
          const controlHighestDiscount =
            c.product?.price ??
            c.options.sort((a, b) =>
              !a.product ? 0 : a.product.price - b.product.price
            )[0]?.product?.price ??
            0;
          if (
            controlHighestDiscount < 0 ||
            c.constraints.some(
              (constraint) =>
                constraint.name === 'required' && constraint.value === 'true'
            )
          ) {
            return subtotal + controlHighestDiscount;
          }
          return subtotal;
        }, 0),
      0
    ) * -1;

  usePrompt(
    'Are you sure you want to leave without saving?',
    formBuilderState.hasUnsavedChanges
  );

  useEffect(() => {
    if (uuid) {
      fetchAdminRegForm(uuid);
      hasFetchedForm.current = true;
      setOldDocumentsUploadHistory([]);
    }
  }, []);

  useEffect(() => {
    if (formBuilderState.hasUnsavedChanges) {
      saveChangesIntervalRef.current = setInterval(() => {
        dispatch({ type: 'auto-save' });
      }, 300000);
    }
    else {
      clearInterval(saveChangesIntervalRef.current);
    }

    return () => clearInterval(saveChangesIntervalRef.current);
  }, [formBuilderState.hasUnsavedChanges]);

  useEffect(() => {
    if (formBuilderState.isAutoSaving && !formBuilderState.updating) {
      const sectionsCopy = JSON.parse(
        JSON.stringify(formBuilderState.sections)
      );
      for (const section of sectionsCopy) {
        if (typeof section.pkRegFormSection === 'string') {
          section.pkRegFormSection = null;
        }
        for (const control of section.regFormControls) {
          if (typeof control.pkRegFormControl === 'string') {
            control.pkRegFormControl = null;
          }
          if (control.options?.length > 0) {
            for (const option of control.options) {
              if (typeof option.pkRegFormControlOption === 'string') {
                option.pkRegFormControlOption = null;
              }
            }
          }
        }
      }
      dispatch({ type: 'save-form' });
      updateAdminRegForm(
        state.regForm.value.uuid,
        {
          ...state.regForm.value,
          taxRate:
            state.regForm.value.regAssociationDivisionForms[0].regProduct
              .taxRate,
          sections: sectionsCopy,
          regFormExternalConnections: null
        },
        () => {
          triggerNotification('Form saved successfully.', 'Success', 'green');
          oldDocumentsUploadHistory.forEach((oldDocument) => {
            deleteFileFromStorage(oldDocument.url);
          });
          setOldDocumentsUploadHistory([]);
        },
        (message) => {
          triggerNotification(message);
          dispatch({ type: 'error' });
        }
      );
    }
  }, [formBuilderState.isAutoSaving]);

  useEffect(() => {
    if (state.regForm.value) {
      let selectedTempSection = null;
      if (formBuilderState.pkRegFormSection) {
        selectedTempSection = formBuilderState.sections.find(
          (s) => s.pkRegFormSection === formBuilderState.pkRegFormSection
        );
      }
      dispatch({
        type: 'sync-sections',
        payload: { sections: [...state.regForm.value.regFormSections] }
      });

      let selectedPkRegFormSection = null;
      if (selectedTempSection != null) {
        selectedPkRegFormSection = state.regForm.value.regFormSections.find(
          (s) => s.positionOrder === selectedTempSection.positionOrder
        )?.pkRegFormSection;
      }
      else {
        selectedPkRegFormSection = state.regForm.value.regFormSections
          .filter((f) => !f.isManaged)
          .sort((a, b) => a.positionOrder - b.positionOrder)[0]
          ?.pkRegFormSection;
      }
      dispatch({
        type: 'select-section',
        payload: { pkRegFormSection: selectedPkRegFormSection }
      });
    }
  }, [state.regForm.value]);

  useEffect(() => {
    if (!state.regForm.value && state.regForm.error) {
      triggerNotification(state.regForm.error);
    }
  }, [state.regForm.error]);

  const findSelectedSectionAndControl = () => {
    const section = formBuilderState.sections.find(
      (s) => s.pkRegFormSection === formBuilderState.pkRegFormSection
    );
    const control = section?.regFormControls.find(
      (c) => c.pkRegFormControl === formBuilderState.pkRegFormControl
    );

    return {
      section,
      control
    };
  };

  const selectControl = (e, control) => {
    if (!control) {
      dispatch({
        type: 'select-control',
        payload: { pkRegFormControl: null }
      });
    }
    else {
      const gridSize = calculateGridSize(e);
      if (
        formBuilderState.gridSize[0] !== gridSize.columnWidth ||
        formBuilderState.gridSize[1] !== gridSize.rowHeight ||
        control.pkRegFormControl !== formBuilderState.pkRegFormControl
      ) {
        dispatch({
          type: 'select-control',
          payload: {
            pkRegFormControl: control.pkRegFormControl,
            pkRegFormSection: formBuilderState.sections.find((s) =>
              s.regFormControls.some(
                (c) => c.pkRegFormControl === control.pkRegFormControl
              )
            ).pkRegFormSection,
            gridSize: [gridSize.columnWidth, gridSize.rowHeight],
            initialPosition: {
              positionRow: control.positionRow,
              positionColumn: control.positionColumn,
              positionSpan: control.positionSpan
            }
          }
        });
      }
    }
  };

  const mouseMove = (e, sectionRowInfo) => {
    const { control } = findSelectedSectionAndControl();
    if (control) {
      const { clientY, clientX } = e;
      const { gridSize } = formBuilderState;

      let initalPosition = null;
      if (formBuilderState.mousePosition) {
        const controlRow =
          formBuilderState.dragPosition?.positionRow ?? control.positionRow;
        const currentMouseRow =
          sectionRowInfo.findIndex(
            (c) => c.y <= clientY && c.y + c.height >= clientY
          ) + 1;
        initalPosition = formBuilderState.initialMousePosition ?? {
          clientY: e.clientY,
          clientX: e.clientX
        };
        if (!currentMouseRow || currentMouseRow === controlRow) {
          if (
            clientY < formBuilderState.mousePosition.clientY &&
            (!sectionRowInfo[currentMouseRow - 1] ||
              clientY < sectionRowInfo[currentMouseRow - 1].y + 60)
          ) {
            gridSize[1] = sectionRowInfo[controlRow - 2]?.height ?? 80;
          }
          else if (clientY > formBuilderState.mousePosition.clientY) {
            if (
              sectionRowInfo[controlRow - 1] &&
              initalPosition.clientY >= sectionRowInfo[controlRow - 1].y &&
              initalPosition.clientY <=
                sectionRowInfo[controlRow - 1].y +
                  sectionRowInfo[controlRow - 1].height
            ) {
              gridSize[1] = 80;
            }
            else {
              gridSize[1] = sectionRowInfo[controlRow - 1]?.height ?? 80;
            }
          }
        }
      }

      if (gridSize[1] < 80) {
        gridSize[1] = 80;
      }

      dispatch({
        type: 'mouse-move',
        payload: {
          initialMousePosition: initalPosition,
          mousePosition: {
            clientY,
            clientX
          },
          gridSize
        }
      });
    }
  };

  const moveControl = (e, data, ref, sectionRowInfo) => {
    if (!formBuilderState.isBeingResized) {
      const selectedData = findSelectedSectionAndControl();
      const columnWidth = formBuilderState.gridSize[0];
      const rowHeight = 80;
      const newPosition = calcualteNewPosition(
        data,
        selectedData.control,
        columnWidth,
        rowHeight,
        sectionRowInfo
      );

      const isOverlapping = isNewPositionOverlapping(
        newPosition,
        selectedData.section.regFormControls.filter(
          (c) => c.pkRegFormControl !== formBuilderState.pkRegFormControl
        )
      );

      dispatch({
        type: 'move-control',
        payload: {
          isOverlapping,
          dragPosition: newPosition,
          initialMousePosition: formBuilderState.initialMousePosition ?? {
            clientY: e.clientY,
            clientX: e.clientX
          }
        }
      });
    }
  };

  const stopMovingControl = (e, data, ref, sectionRowInfo) => {
    if (!formBuilderState.isBeingResized) {
      const selectedData = findSelectedSectionAndControl();
      const columnWidth = formBuilderState.gridSize[0];
      const rowHeight = 80;
      const newPosition = calcualteNewPosition(
        data,
        selectedData.control,
        columnWidth,
        rowHeight,
        sectionRowInfo
      );

      const isOverlapping = isNewPositionOverlapping(
        newPosition,
        selectedData.section.regFormControls.filter(
          (c) => c.pkRegFormControl !== selectedData.control.pkRegFormControl
        )
      );

      if (!isOverlapping) {
        selectedData.control.positionRow = newPosition.positionRow;
        selectedData.control.positionColumn = newPosition.positionColumn;

        const updatedSections = [
          ...formBuilderState.sections.filter(
            (s) => s.pkRegFormSection !== selectedData.section.pkRegFormSection
          ),
          selectedData.section
        ];
        dispatch({
          type: 'move-control-stop',
          payload: { sections: updatedSections }
        });
      }
      else {
        dispatch({ type: 'move-control-stop' });
      }
    }
  };

  const resizeControl = (control, data, direction, ref) => {
    const selectedData = findSelectedSectionAndControl();
    const controlPositionRef = formBuilderState.initialPosition ?? {
      positionRow: selectedData.control.positionRow,
      positionColumn: selectedData.control.positionColumn,
      positionSpan: selectedData.control.positionSpan
    };

    const columnWidth = formBuilderState.gridSize[0];
    const newPosition = calcualteResizePosition(
      data,
      selectedData.control,
      controlPositionRef,
      columnWidth,
      direction
    );

    const isOverlapping = isNewPositionOverlapping(
      newPosition,
      selectedData.section.regFormControls.filter(
        (c) => c.pkRegFormControl !== selectedData.control.pkRegFormControl
      )
    );

    const minSpan = calculateMinSize(control, columnWidth, ref);
    let hasReachedMinSize = false;
    if (direction === 'right' && newPosition.positionSpan < minSpan) {
      newPosition.positionSpan = minSpan;
      hasReachedMinSize = true;
    }
    else if (direction === 'left' && newPosition.positionSpan < minSpan) {
      newPosition.positionSpan = minSpan;
      newPosition.positionColumn = control.positionColumn;
      hasReachedMinSize = true;
    }

    if (
      !isOverlapping &&
      (selectedData.control.positionColumn !== newPosition.positionColumn ||
        selectedData.control.positionSpan !== newPosition.positionSpan)
    ) {
      selectedData.control.positionColumn = newPosition.positionColumn;
      selectedData.control.positionSpan = newPosition.positionSpan;

      const updatedSections = [
        ...formBuilderState.sections.filter(
          (s) => s.pkRegFormSection !== selectedData.section.pkRegFormSection
        ),
        selectedData.section
      ];

      dispatch({
        type: 'resize-control',
        payload: {
          isOverlapping: hasReachedMinSize,
          initialPosition: controlPositionRef,
          sections: updatedSections
        }
      });
    }
    else if (
      isOverlapping !== formBuilderState.isOverlapping ||
      !formBuilderState.initialPosition
    ) {
      dispatch({
        type: 'resize-control',
        payload: { isOverlapping, initialPosition: controlPositionRef }
      });
    }
  };

  const stopResizingControl = () => {
    dispatch({ type: 'resize-control-stop' });
  };

  return (
    <LoadableView isLoading={isLoading}>
      {!isLoading && (
        <>
          <AppCard
            radius={0}
            shadow="md"
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: rs(
                [formBuilderState.hasUnsavedChanges ? 125 : 80, 80],
                mqIndex
              ),
              borderBottom: `solid 1px lightgrey`,
              padding: rs([10, '0px 20px', '0px 20px'], mqIndex),
              position: 'fixed',
              width: '100%',
              zIndex: 11
            }}
          >
            <AppFlexbox
              style={{
                flex: 1,
                flexDirection: rs(['column', 'row'], mqIndex),
                overflow: 'hidden',
                flexWrap: 'nowrap',
                gap: 10,
                alignSelf: 'center'
              }}
            >
              <AppFlexbox
                style={{
                  flex: 1,
                  justifyContent: 'start',
                  maxWidth: '100%',
                  minWidth: 0,
                  alignSelf: 'start'
                }}
              >
                <AppStack
                  style={{
                    gap: 0,
                    flex: 1,
                    alignItems: 'start',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <AppText
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    weight={700}
                  >
                    {state.regForm.value.name}
                  </AppText>
                  {formBuilderState.updating ? (
                    <AppText>Saving...</AppText>
                  ) : (
                    <AppText>
                      Last Updated{' '}
                      {dayjs(
                        formatUtcDate(state.regForm.value.lastUpdated)
                      ).format('MM/DD/YYYY h:mm a')}
                    </AppText>
                  )}
                </AppStack>
              </AppFlexbox>
              <AppFlexbox
                style={{
                  justifyContent: rs(['start', 'flex-end'], mqIndex),
                  alignItems: 'center',
                  flexDirection: rs(['row', 'row-reverse'], mqIndex),
                  flexWrap: rs(['wrap', 'nowrap'], mqIndex),
                  display: rs(
                    [
                      formBuilderState.hasUnsavedChanges ? 'flex' : 'none',
                      'flex'
                    ],
                    mqIndex
                  ),
                  width: rs(['100%', 'unset'], mqIndex)
                }}
              >
                <Button
                  color="blue"
                  leftSection={<Table />}
                  onClick={() => dispatch({ type: 'toggle-grid' })}
                  style={{
                    color: formBuilderState.showGrid ? '#FFF' : '#000',
                    display: rs(['none', 'none', 'inline-block'], mqIndex)
                  }}
                  variant={formBuilderState.showGrid ? 'filled' : 'subtle'}
                >
                  Show Grid
                </Button>
                {formBuilderState.hasUnsavedChanges && (
                  <Button
                    color="blue"
                    leftSection={<DeviceFloppy />}
                    loading={formBuilderState.updating}
                    onClick={() => {
                      dispatch({ type: 'auto-save' });
                    }}
                    style={{
                      color: '#FFF',
                      width: rs(['100%', 'unset'], mqIndex)
                    }}
                  >
                    Save Changes
                  </Button>
                )}
              </AppFlexbox>
            </AppFlexbox>
          </AppCard>
          <AppFlexbox
            style={{
              flex: 1,
              alignItems: 'stretch',
              flexDirection: rs(['column', 'column', 'row'], mqIndex),
              gap: 0,
              marginTop: rs(
                [formBuilderState.hasUnsavedChanges ? 125 : 80, 80, 80],
                mqIndex
              ),
              position: 'relative'
            }}
          >
            <FormBuilderMenu
              isFormSectionModalOpen={openFormSectionModal}
              largestAddOnDiscountAmount={largestAddOnDiscountAmount}
              lowestCostDivisionForm={lowestCostDivisionForm}
              onChangeSectionData={(sections) =>
                dispatch({
                  type: 'update-sections',
                  payload: { sections }
                })
              }
              onCloseFormModal={() => setOpenFormSectionModal(false)}
              onDocumentUpload={(oldDocument) => {
                if (oldDocument) {
                  setOldDocumentsUploadHistory([
                    ...oldDocumentsUploadHistory,
                    oldDocument
                  ]);
                }
              }}
              onSelectControl={(control) => {
                dispatch({
                  type: 'select-control',
                  payload: { pkRegFormControl: control?.pkRegFormControl }
                });
              }}
              onSelectSection={(section) => {
                dispatch({
                  type: 'select-section',
                  payload: {
                    pkRegFormSection: section?.pkRegFormSection,
                    pkRegFormControl: null
                  }
                });
              }}
              sections={formBuilderState.sections}
              selectedPkRegFormControl={formBuilderState.pkRegFormControl}
              selectedPkRegFormSection={formBuilderState.pkRegFormSection}
            />
            <AppStack
              style={{
                flex: 1,
                padding: rs([0, 0, 0, 0, 40], mqIndex),
                zIndex: 9
              }}
            >
              <AppCard
                style={{
                  margin: 'auto',
                  flex: 1,
                  width: '100%',
                  maxWidth: rs(
                    ['unset', 'unset', 'unset', 'unset', 900],
                    mqIndex
                  ),
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 15
                }}
              >
                <RegistrationForm
                  editComponentState={formBuilderState}
                  form={state.regForm.value}
                  isEditable
                  onMouseMove={mouseMove}
                  onMoveControl={moveControl}
                  onOpenFormSectionModal={() => setOpenFormSectionModal(true)}
                  onResizeControl={resizeControl}
                  onSelectControl={selectControl}
                  onStopMovingControl={stopMovingControl}
                  onStopResizingControl={stopResizingControl}
                  sections={formBuilderState.sections}
                />
              </AppCard>
            </AppStack>
          </AppFlexbox>
        </>
      )}
    </LoadableView>
  );
};

export default FormBuilder;
