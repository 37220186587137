import React, { useContext, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import '@mantine/core/styles.css';
// eslint-disable-next-line import/no-unresolved
import '@mantine/notifications/styles.css';
// eslint-disable-next-line import/no-unresolved
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/tiptap/styles.css';
import './styles/globalStyles.module.css';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  Provider as AuthProvider,
  Context as AuthContext
} from './providers/AuthProvider';
import {
  Provider as RegistrationProvider,
  Context as RegistrationContext
} from './providers/RegistrationProvider';
import {
  Context as RegistrationAdminContext,
  Provider as RegistrationAdminProvider
} from './providers/RegistrationAdminProvider';
import { Provider as RegistrationAdminDashboardProvider } from './providers/RegistrationAdminDashboardProvider';
import { Provider as SportsheadzWebProvider } from './providers/SportsheadzWebProvider';
import { theme } from './styles/theme';
import ScrollToTop from './components/common/ScrollToTop';
import Header from './components/content/navigation/Header';
import AppShell from './components/common/AppShell';
import Footer from './components/content/navigation/Footer';
import Home from './views/Home';
import AuthView from './views/AuthView';
import UserViews from './views/UserViews';
import RegistrationsView from './views/RegistrationsView';
import AssociationsView from './views/AssociationsView';
import AssocioationRedirectView from './views/AssocioationRedirectView';
import { useDashboardCookies } from './helpers/hooks';
import EmailUnsubscribeView from './views/EmailUnsubscribeView';

dayjs.extend(customParseFormat);

const hiddenNavRoutes = [/\/admin\/evaluations\/sessions\/(\d*)\/evaluate/];

const App = () => {
  const { pathname } = useLocation();
  const { tokenSignin, state: authState } = useContext(AuthContext);
  const { fetchAdminRegAssociations } = useContext(RegistrationAdminContext);
  const { fetchCart } = useContext(RegistrationContext);
  const isAdminView = pathname.startsWith('/admin');
  const showHeader = !hiddenNavRoutes.some((r) => pathname.match(r));
  const {} = useDashboardCookies();

  useEffect(() => {
    if (!authState.tokenAttempted) {
      tokenSignin();
    }
  }, [authState.tokenAttempted]);

  useEffect(() => {
    if (authState.isAuthenticated) {
      if (!pathname.startsWith('/checkout')) {
        fetchCart();
      }

      if (authState.userData.isRegistrationAdmin) {
        fetchAdminRegAssociations();
      }
    }
  }, [authState.isAuthenticated]);

  return (
    <AppShell
      footer={!isAdminView && <Footer />}
      header={showHeader && <Header />}
    >
      <Routes>
        <Route element={<Home />} path="/" />
        <Route
          element={<AssocioationRedirectView />}
          path="websiteredirect/*"
        />
        <Route element={<EmailUnsubscribeView />} path="unsubscribe" />
        <Route element={<AssociationsView />} path="association/*" />
        <Route element={<AuthView />} path="auth/*" />
        <Route element={<RegistrationsView />} path="leagues/*" />
        <Route element={<UserViews />} path="*" />
        <Route element={<Navigate replace to="/" />} path="*" />
      </Routes>
    </AppShell>
  );
};

export default () => (
  <AuthProvider>
    <RegistrationAdminProvider>
      <RegistrationProvider>
        <SportsheadzWebProvider>
          <RegistrationAdminDashboardProvider>
            <MantineProvider theme={theme}>
              <Notifications position="top-right" style={{ marginTop: 90 }} />
              <Router
                future={{
                  v7_startTransition: true,
                  v7_relativeSplatPath: true
                }}
              >
                <ScrollToTop />
                <App />
              </Router>
            </MantineProvider>
          </RegistrationAdminDashboardProvider>
        </SportsheadzWebProvider>
      </RegistrationProvider>
    </RegistrationAdminProvider>
  </AuthProvider>
);
