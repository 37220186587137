import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import HockeyCanadaConfigForm from './HockeyCanadaConfigForm';
import { REG_EXTERNAL_CONNECTION_TYPE_ENUM } from '../../../config/constants';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';

const EXTERNAL_CONNECTION_FORM_CONFIG = [
  {
    title: 'Hockey Canada ID',
    fkRegExternalConnectionType:
      REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA,
    Component: HockeyCanadaConfigForm
  }
];

const ExternalConnectionSetupModal = ({
  isOpen,
  onClose,
  fkRegExternalConnectionType,
  showDisconnect
}) => {
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    updateRegAssociationExternalConnections,
    deleteRegAssociationExternalConnections,
    fetchAdminRegAssociations,
    fetchAdminRegForms
  } = useContext(RegistrationAdminContext);
  const [formState, setFormState] = useState({
    isRequired: false,
    showFormExclusions: false,
    regFormExternalConnections: [],
    loading: false
  });

  const config = EXTERNAL_CONNECTION_FORM_CONFIG.find(
    (c) =>
      c.fkRegExternalConnectionType.toString() ===
      fkRegExternalConnectionType?.toString()
  );
  const associationConnectionConfig = state.regAssociations.value
    .find(
      (a) =>
        a.pkRegAssociation ===
        dashboardState.regAssociation.value?.pkRegAssociation
    )
    ?.regAssociationExternalConnections?.find(
      (a) =>
        a.fkRegExternalConnectionType.toString() ===
        fkRegExternalConnectionType?.toString()
    );

  useEffect(() => {
    if (isOpen) {
      setFormState({
        isRequired: associationConnectionConfig?.isRequired || false,
        showFormExclusions: false,
        regFormExternalConnections: [],
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isLoading: formState.loading,
        onCancel: onClose,
        onSubmit: () => {
          setFormState({ ...formState, loading: true });

          if (showDisconnect) {
            deleteRegAssociationExternalConnections(
              dashboardState.regAssociation.value.pkRegAssociation,
              {
                pkRegAssociationExternalConnections: [
                  associationConnectionConfig.pkRegAssociationExternalConnection
                ]
              },
              () => {
                triggerNotification('Connection removed!', 'Success', 'green');
                fetchAdminRegAssociations();
                onClose();
              },
              (e) => {
                triggerNotification(e);
                setFormState({ ...formState, loading: false });
              }
            );
          }
          else {
            updateRegAssociationExternalConnections(
              dashboardState.regAssociation.value.pkRegAssociation,
              {
                regAssociationExternalConnections: [
                  {
                    isRequired: formState.isRequired,
                    fkRegExternalConnectionType,
                    regFormExternalConnections: formState.showFormExclusions
                      ? formState.regFormExternalConnections
                      : null
                  }
                ]
              },
              () => {
                triggerNotification('Connection updated!', 'Success', 'green');

                if (formState.showFormExclusions) {
                  fetchAdminRegForms();
                }

                onClose();
              },
              (e) => {
                triggerNotification(e);
                setFormState({ ...formState, loading: false });
              }
            );
          }
        },
        submitColor: showDisconnect ? 'red' : 'blue',
        submitTitle: showDisconnect
          ? 'Yes, remove connection'
          : !associationConnectionConfig || associationConnectionConfig.deleted
          ? 'Confirm setup'
          : 'Save Changes'
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={
        showDisconnect
          ? `Remove ${config?.title}?`
          : `Configure ${config?.title}`
      }
    >
      {showDisconnect ? (
        <AppStack style={{ gap: 16 }}>
          <AppText>
            Are you sure you want to remove the setup for {config.title}?
          </AppText>

          <AppText>
            This will remove the input from all forms and will no longer require
            new registrations to enter their ID.
          </AppText>

          <AppText
            style={{
              fontSize: 14,
              color: '#666',
              fontStyle: 'italic',
              textAlign: 'center'
            }}
          >
            You will still be able to see the data from previous registrations.
          </AppText>
        </AppStack>
      ) : (
        config && (
          <config.Component formState={formState} setFormState={setFormState} />
        )
      )}
    </ResponsiveModal>
  );
};

ExternalConnectionSetupModal.propTypes = {
  fkRegExternalConnectionType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showDisconnect: PropTypes.bool
};

export default ExternalConnectionSetupModal;
