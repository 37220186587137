import React, { useContext, useEffect, useRef, useState } from 'react';
import { FileText, Plus } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useModalState, useSortByFilter } from '../../../helpers/hooks';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import AppCard from '../../common/AppCard';
import TableView from '../../common/TableView';
import AdminView from '../../common/AdminView';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import FormSelectorModal from './FormSelectorModal';
import SUPPORT_LINKS from '../navigation/supportLinks';
import { FORM_VIEW_TABS } from '../../../experimental/components/content/navigation/links';

const VIEW_ACTIONS_ENUM = {
  create: 'CREATE',
  edit: 'EDIT',
  copy: 'COPY',
  delete: 'DELETE'
};

const VIEW_ACTIONS = [
  {
    label: 'Edit',
    value: VIEW_ACTIONS_ENUM.edit
  },
  {
    label: 'Copy',
    value: VIEW_ACTIONS_ENUM.copy
  },
  {
    label: 'Delete',
    value: VIEW_ACTIONS_ENUM.delete
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    sortable: true
  },
  {
    label: 'Start Date',
    value: 'startDate',
    sortable: true
  },
  {
    label: 'End Date',
    value: 'endDate',
    sortable: true
  },
  {
    label: 'Financial Assistance',
    value: 'financialAssistance',
    sortable: true
  },
  {
    label: 'Order Emails',
    value: 'orderEmails',
    sortable: true
  },
  {
    label: 'Version',
    value: 'version',
    sortable: true
  }
];

const FormView = () => {
  const hasFetched = useRef(false);
  const [filterState, setFilterState] = useState({
    fkRegAssociationDivision: '',
    fkRegAssociationSeason: '',
    search: ''
  });
  const { search } = useLocation();
  const modalAction = new URLSearchParams(search).get('modal');
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    sortValue,
    isDescendingSort,
    onChangeSortBy,
    customFilterData
  } = useSortByFilter(TABLE_COLUMNS);
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const {
    state,
    fetchAdminRegForms,
    fetchAdminRegAssociationDivisions
  } = useContext(RegistrationAdminContext);
  const isLoading =
    !hasFetched.current ||
    state.regForms.loading ||
    state.regAssociationDivisions.loading ||
    state.regAssociations.loading;

  const regAssociation = dashboardState.regAssociation.value;
  const associationForms = state.regForms.value.filter(
    (f) => f.fkRegAssociation === regAssociation?.pkRegAssociation
  );

  const filterOptions = associationForms.reduce(
    (r, c) => {
      c.regAssociationDivisionForms.forEach((df) => {
        if (
          !r.divisionOptions.find(
            (f) =>
              f.value ===
              df.regAssociationDivision.pkRegAssociationDivision.toString()
          )
        ) {
          r.divisionOptions.push({
            label: df.regAssociationDivision.name,
            value: df.regAssociationDivision.pkRegAssociationDivision.toString(),
            fkRegAssociationSeason: df.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString()
          });
        }

        if (
          !r.seasonOptions.find(
            (f) =>
              f.value ===
              df.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString()
          )
        ) {
          r.seasonOptions.push({
            label: df.regAssociationDivision.regAssociationSeason.name,
            value: df.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString()
          });
        }
      });
      return r;
    },
    { divisionOptions: [], seasonOptions: [] }
  );

  useEffect(() => {
    fetchAdminRegForms();
    fetchAdminRegAssociationDivisions();
    hasFetched.current = true;
  }, []);

  useEffect(() => {
    if (modalAction) {
      switch (modalAction) {
        case 'create':
          onOpenModal('CREATE');
          break;
        default:
          break;
      }
    }
  }, [modalAction]);

  return (
    <AdminView
      actionControl={{
        label: 'Create Form',
        icon: <Plus />,
        action: 'CREATE'
      }}
      headerTitle="Templates"
      isViewLoading={isLoading}
      onAction={onOpenModal}
      tabs={FORM_VIEW_TABS}
    >
      <AppCard style={{ flex: 1, padding: 0, height: '100%' }}>
        <TableView
          columns={TABLE_COLUMNS}
          emptyMessage="No Forms Available"
          filters={[
            {
              key: 1,
              label: 'Season',
              type: 'select',
              value: filterState.fkRegAssociationSeason,
              placeholder: 'Filter by season',
              data: filterOptions.seasonOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
              ),
              clearable: true,
              onChange: (value) => {
                setFilterState({
                  ...filterState,
                  fkRegAssociationSeason: value,
                  fkRegAssociationDivision: null
                });
              }
            },
            {
              key: 2,
              label: 'Division',
              type: 'select',
              value: filterState.fkRegAssociationDivision,
              placeholder: 'Filter by division',
              data: filterOptions.divisionOptions
                .filter(
                  (f) =>
                    !filterState.fkRegAssociationSeason ||
                    filterState.fkRegAssociationSeason ===
                      f.fkRegAssociationSeason
                )
                .sort((a, b) => a.label.localeCompare(b.label)),
              clearable: true,
              onChange: (value) => {
                setFilterState({
                  ...filterState,
                  fkRegAssociationDivision: value
                });
              }
            },
            {
              key: 3,
              label: 'Form Name',
              type: 'text',
              value: filterState.search,
              placeholder: 'Filter by form name',
              onChange: (value) =>
                setFilterState({
                  ...filterState,
                  search: value
                })
            }
          ]}
          isDescendingSort={isDescendingSort}
          isLoading={isLoading}
          lastUpdated={state.regForms.lastUpdated}
          onAction={(action, item) => {
            onOpenModal(
              action,
              associationForms.find((s) => s.pkRegForm === item.key)
            );
          }}
          onChangeSortBy={onChangeSortBy}
          onRefresh={() => fetchAdminRegForms(regAssociation?.pkRegAssociation)}
          rows={associationForms
            .filter(
              (f) =>
                (!filterState.search ||
                  f.name
                    .replace(' ', '')
                    .toLowerCase()
                    .includes(
                      filterState.search.replace(' ', '').toLowerCase()
                    )) &&
                (!filterState.fkRegAssociationDivision ||
                  f.regAssociationDivisionForms.some(
                    (d) =>
                      d.regAssociationDivision.pkRegAssociationDivision.toString() ===
                      filterState.fkRegAssociationDivision
                  )) &&
                (!filterState.fkRegAssociationSeason ||
                  f.regAssociationDivisionForms.some(
                    (d) =>
                      d.regAssociationDivision.regAssociationSeason.pkRegAssociationSeason.toString() ===
                      filterState.fkRegAssociationSeason
                  ))
            )
            .map((f) => {
              const hasOrderEmails = f.regAssociationDivisionForms.some(
                (s) => s.orderEmailEnabled
              );

              return {
                key: f.pkRegForm,
                actions: VIEW_ACTIONS,
                columns: [
                  {
                    key: 1,
                    label: f.name,
                    onClick: () => onOpenModal(VIEW_ACTIONS.edit, f),
                    weight: 500,
                    subLabel: `${singularPluralFormat(
                      f.regAssociationDivisionForms.filter(
                        (d) =>
                          d.active &&
                          !d.regAssociationDivision.deleted &&
                          (!d.regAssociationDivision.regAssociationSeason ||
                            (!d.regAssociationDivision.regAssociationSeason
                              .deleted &&
                              new Date(
                                d.regAssociationDivision.regAssociationSeason.endDate
                              ) >= new Date()))
                      ).length,
                      'Division',
                      'Divisions'
                    )}`,
                    icon: <FileText size={30} />
                  },
                  {
                    key: 2,
                    label: dayjs(formatUtcDate(f.startDate)).format(
                      'MMM D, YYYY'
                    ),
                    sortValue: new Date(f.startDate),
                    sortValueType: 'date'
                  },
                  {
                    key: 3,
                    label: dayjs(formatUtcDate(f.endDate)).format(
                      'MMM D, YYYY'
                    ),
                    sortValue: new Date(f.endDate),
                    sortValueType: 'date'
                  },
                  {
                    key: 4,
                    label: f.regFormFinancialAssistance ? 'Yes' : 'No'
                  },
                  {
                    key: 5,
                    label: hasOrderEmails ? 'Yes' : 'No'
                  },
                  {
                    key: 6,
                    label: f.version + 1,
                    sortValueType: 'number'
                  }
                ]
              };
            })}
          sortBy={sortValue}
          sortFilter={customFilterData}
          tableTitle="Forms"
        />
      </AppCard>

      <FormSelectorModal
        copyForm={modalState.action === VIEW_ACTIONS_ENUM.copy}
        isOpen={modalState.isOpen}
        onClose={onCloseModal}
        pkRegAssociation={regAssociation?.pkRegAssociation}
        regForm={modalState.item}
        showDelete={modalState.action === VIEW_ACTIONS_ENUM.delete}
      />
    </AdminView>
  );
};

export default FormView;
