import {
  REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM,
  REG_SCOUT_REPORT_QUESTION_TYPE
} from '../config/constants';

// normalize ratings to a 1-5 scale // ((rating - 1) / (maxRating - 1)) * (targetMax - targetMin) + targetMin
const normalizeRating = (rating, maxRating) =>
  ((rating - 1) / (maxRating - 1)) * (5 - 1) + 1;

function abbreviateString(str, simpleAbbreviation = false) {
  if (simpleAbbreviation) {
    return str.replace(' ', '').slice(0, 3).toUpperCase();
  }

  let abbreviation = str.charAt(0).toUpperCase();

  for (let i = 1; i < str.length && abbreviation.length < 3; i += 1) {
    const char = str.charAt(i).toUpperCase();
    if (!['A', 'E', 'I', 'O', 'U'].includes(char)) {
      abbreviation += char;
    }
  }
  abbreviation += 'XX'.slice(0, 3 - abbreviation.length);

  return abbreviation;
}

const validateEvaluationQuestionRestrictions = (evaluation, question) => {
  const questionRestrictions = question.regScoutReportQuestionRestrictions.filter(
    (r) => r.questionRestricted === question.pkRegScoutReportQuestion
  );

  const conditionCheck = (answerValue, conditionValue, condition) => {
    switch (condition.toString()) {
      case REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.EQUAL.toString():
        return (
          answerValue?.trim().toLowerCase() ===
          conditionValue.trim().toLowerCase()
        );
      case REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.NOT_EQUAL.toString():
        return (
          answerValue?.trim().toLowerCase() !==
          conditionValue.trim().toLowerCase()
        );
      default:
        return false;
    }
  };

  const equalToConditions = questionRestrictions.filter(
    (r) =>
      r.condition.toString() ===
      REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.EQUAL.toString()
  );

  const notEqualToConditions = questionRestrictions.filter(
    (r) =>
      r.condition.toString() ===
      REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.NOT_EQUAL.toString()
  );

  return (
    (equalToConditions.length === 0 ||
      equalToConditions.some(
        (r) =>
          (r.condition.toString() ===
            REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.NOT_EQUAL.toString() &&
            !evaluation.regScoutReportQuestionAnswers.find(
              (a) => a.fkRegScoutReportQuestion === r.questionCondition
            )) ||
          !!evaluation.regScoutReportQuestionAnswers.find(
            (a) =>
              a.fkRegScoutReportQuestion === r.questionCondition &&
              conditionCheck(a.value, r.conditionValue, r.condition)
          )
      )) &&
    (notEqualToConditions.length === 0 ||
      notEqualToConditions.every(
        (r) =>
          (r.condition.toString() ===
            REG_SCOUT_REPORT_QUESTION_RESTRICTION_CONDITION_ENUM.NOT_EQUAL.toString() &&
            !evaluation.regScoutReportQuestionAnswers.find(
              (a) => a.fkRegScoutReportQuestion === r.questionCondition
            )) ||
          !!evaluation.regScoutReportQuestionAnswers.find(
            (a) =>
              a.fkRegScoutReportQuestion === r.questionCondition &&
              conditionCheck(a.value, r.conditionValue, r.condition)
          )
      ))
  );
};

const getEvaluationEntryProgress = (evaluationEntry, evaluationSession) => {
  const regScoutReports = evaluationSession.regScoutReportEvaluationSessionTemplates.map(
    (t) => t.regScoutReport
  );
  const reportQuestions = regScoutReports.reduce(
    (r, c) => [...r, ...c.regScoutReportQuestions],
    []
  );
  const metricQuestions = reportQuestions.filter(
    (q) =>
      q.fkRegScoutReportQuestionType === REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
  );

  const assignedEvaluations = evaluationSession.regScoutReportEvaluations.filter(
    (e) => !e.deleted
  );

  return assignedEvaluations.length === 0 ||
    regScoutReports.length === 0 ||
    metricQuestions.length === 0
    ? 0
    : (assignedEvaluations.filter((e) =>
        metricQuestions
          .filter((q) => validateEvaluationQuestionRestrictions(e, q))
          .every(
            (q) =>
              q.fkRegScoutReportQuestionType ===
                REG_SCOUT_REPORT_QUESTION_TYPE.ATTRIBUTE ||
              !!e.regScoutReportQuestionAnswers.find(
                (f) =>
                  f.fkRegScoutReportQuestion === q.pkRegScoutReportQuestion &&
                  f.fkRegScoutReportEvaluationSessionEntry ===
                    evaluationEntry.pkRegScoutReportEvaluationSessionEntry
              )?.value
          )
      ).length /
        assignedEvaluations.length) *
        100;
};

const getEvaluationRating = (
  evaluation,
  metricQuestions,
  evaluationSessionEntries = []
) => {
  const validatedMetricQuestions = metricQuestions.filter((q) =>
    validateEvaluationQuestionRestrictions(evaluation, q)
  );

  const entryKeys = evaluationSessionEntries.map(
    (e) => e.pkRegScoutReportEvaluationSessionEntry
  );
  const evaluationMetricAnswers = evaluation.regScoutReportQuestionAnswers.filter(
    (a) =>
      entryKeys.includes(a.fkRegScoutReportEvaluationSessionEntry) &&
      validatedMetricQuestions.some(
        (q) => q.pkRegScoutReportQuestion === a.fkRegScoutReportQuestion
      )
  );

  const groupedAnswerRatings = evaluationMetricAnswers.reduce((r, c) => {
    const question = validatedMetricQuestions.find(
      (q) => q.pkRegScoutReportQuestion === c.fkRegScoutReportQuestion
    );

    const maxRating =
      question.regScoutReportQuestionOptions.length > 0
        ? Math.max(...question.regScoutReportQuestionOptions.map((p) => p.sort))
        : 5;

    const rating =
      Number(c.value) ||
      Number(
        question.regScoutReportQuestionOptions.find(
          (op) => op.value === c.value
        )?.sort
      ) ||
      0;

    const existingRating = r.find((a) => a.label === question.value);
    const newRating = Math.round(normalizeRating(rating, maxRating) * 10) / 10;
    return [
      ...r.filter((f) => f.label !== question.value),
      {
        label: question.value,
        abbreviation: abbreviateString(question.value, true),
        values: existingRating
          ? [...existingRating.values, newRating]
          : [newRating]
      }
    ];
  }, []);

  const answerRatings = groupedAnswerRatings.map((a) => ({
    ...a,
    value:
      Math.round((a.values.reduce((r, c) => r + c, 0) / a.values.length) * 10) /
      10
  }));

  const overallRating =
    answerRatings.length === 0
      ? null
      : answerRatings.reduce((r, c) => r + c.value, 0) / answerRatings.length;
  return {
    isCompleted:
      validatedMetricQuestions.length === evaluationMetricAnswers.length,
    regScoutReportEvaluation: evaluation,
    answerRatings,
    overallRating: Math.round(overallRating * 10) / 10
  };
};

const getEvaluationRatingForSessions = (
  { pkRegAssociationDivision, pkRegPerson, pkRegScoutReportPerson },
  regScoutReportEvaluationSessions
) => {
  const playerEvaluationSessions = pkRegAssociationDivision
    ? regScoutReportEvaluationSessions.filter(
        (s) =>
          s.regAssociationDivision.pkRegAssociationDivision.toString() ===
            pkRegAssociationDivision.toString() &&
          s.regScoutReportEvaluations.some(
            (e) =>
              e.person.pkRegPerson === pkRegPerson &&
              e.person.pkRegScoutReportPerson === pkRegScoutReportPerson &&
              !e.deleted
          )
      )
    : [];

  const groupedRatings = playerEvaluationSessions.reduce(
    (r, c) => {
      const evaluation = c.regScoutReportEvaluations.find(
        (e) =>
          e.person.pkRegPerson === pkRegPerson &&
          e.person.pkRegScoutReportPerson === pkRegScoutReportPerson &&
          !e.deleted
      );
      if (evaluation) {
        const metricQuestions = c.regScoutReportEvaluationSessionTemplates.reduce(
          (r1, c1) => [
            ...r1,
            ...c1.regScoutReport.regScoutReportQuestions.filter(
              (q) =>
                q.fkRegScoutReportQuestionType ===
                REG_SCOUT_REPORT_QUESTION_TYPE.METRIC
            )
          ],
          []
        );

        const entryProgress = getEvaluationRating(
          evaluation,
          metricQuestions,
          c.regScoutReportEvaluationSessionEntries.filter(
            (e) => getEvaluationEntryProgress(e, c) >= 100
          )
        );

        return {
          regScoutReportEvaluations: [
            ...r.regScoutReportEvaluations,
            entryProgress.regScoutReportEvaluation
          ],
          answerRatings: [...r.answerRatings, ...entryProgress.answerRatings],
          overallRatings: [...r.overallRatings, entryProgress.overallRating]
        };
      }

      return r;
    },
    {
      regScoutReportEvaluations: [],
      answerRatings: [],
      overallRatings: []
    }
  );

  const finalPlayerEvaluationRating = {
    regScoutReportEvaluation: groupedRatings.regScoutReportEvaluations[0],
    answerRatings: groupedRatings.answerRatings.reduce((r, c) => {
      const existingRating = r.find((rating) => rating.label === c.label);
      if (!existingRating) {
        const answerValues = groupedRatings.answerRatings.filter(
          (a) => a.label === c.label
        );
        r.push({
          ...c,
          value:
            answerValues.reduce((r1, c1) => r1 + c1.value, 0) /
            answerValues.length
        });
      }
      return r;
    }, []),
    overallRating:
      Math.round(
        (groupedRatings.overallRatings.reduce((r, c) => r + c, 0) /
          groupedRatings.overallRatings.length) *
          10
      ) / 10
  };

  return finalPlayerEvaluationRating;
};

export {
  validateEvaluationQuestionRestrictions,
  getEvaluationEntryProgress,
  getEvaluationRating,
  getEvaluationRatingForSessions
};
