import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import EditFormModalSection from './EditFormModalSection';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import FormSection from '../../common/FormSection';
import AssociationSelectInput from '../associations/AssociationSelectInput';

const FormSelectorModal = ({
  isOpen,
  onClose,
  pkRegAssociation,
  regForm,
  copyForm,
  showDelete
}) => {
  const { state } = useContext(RegistrationAdminContext);
  const { state: authState } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    fkRegAssociationCopy: '',
    regForm: null,
    isCopyFormSelect: false,
    showDelete: false
  });

  const selectedPkRegAssociation =
    authState.userData.isAdmin && copyForm
      ? formState.fkRegAssociationCopy
      : pkRegAssociation;

  const sortedRegAssociations = state.regAssociations.value.sort((a, b) =>
    a.association.name.localeCompare(b.association.name)
  );
  const associationDivisionOptions = state.regAssociationDivisions.value
    .filter(
      (d) =>
        !d.deleted &&
        d.regAssociationSeason.fkRegAssociation.toString() ===
          selectedPkRegAssociation?.toString()
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((d) => ({
      ...d,
      value: d.pkRegAssociationDivision.toString(),
      hasOrderEmails: d.hasOrderEmails,
      label: `${d.regAssociationSeason.name} - ${d.name}`
    }));

  useEffect(() => {
    if (isOpen) {
      setFormState({
        isCopyFormSelect: copyForm && authState.userData.isAdmin,
        fkRegAssociationCopy:
          pkRegAssociation?.toString() ||
          sortedRegAssociations[0]?.pkRegAssociation.toString(),
        regForm,
        showDelete: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={
        formState.isCopyFormSelect || showDelete || formState.showDelete
          ? 600
          : 1000
      }
      title={
        <AppTitle>
          {copyForm
            ? 'Copy Form'
            : showDelete
            ? 'Delete Form'
            : regForm
            ? 'Edit Form'
            : 'Create Form'}
        </AppTitle>
      }
    >
      {formState.isCopyFormSelect ? (
        <FormSection
          cancelTitle="Cancel"
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              isCopyFormSelect: false
            });
          }}
          submitTitle="Continue"
        >
          <AssociationSelectInput
            autoPopulate
            description="Select the association to copy the form to."
            label="Destination Association"
            onChange={(fkRegAssociationCopy) =>
              setFormState({ ...formState, fkRegAssociationCopy })
            }
            preventUrlUpdate
            required
            style={{ flex: 1 }}
            value={formState.fkRegAssociationCopy}
          />
        </FormSection>
      ) : (
        <EditFormModalSection
          associationDivisions={associationDivisionOptions}
          copyForm={copyForm}
          onClose={() => {
            if (formState.showDelete) {
              setFormState({
                ...formState,
                showDelete: false
              });
            }
            else {
              onClose();
            }
          }}
          onShowDelete={() =>
            setFormState({
              ...formState,
              showDelete: true
            })
          }
          pkRegAssociation={selectedPkRegAssociation}
          regForm={regForm}
          selectedRegForm={regForm}
          showDelete={showDelete || formState.showDelete}
        />
      )}
    </ResponsiveModal>
  );
};

FormSelectorModal.propTypes = {
  copyForm: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkRegAssociation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regForm: PropTypes.object,
  showDelete: PropTypes.bool
};

export default FormSelectorModal;
