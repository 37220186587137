import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { ActionIcon, Alert, Button, Divider } from '@mantine/core';
import { AlertCircle, Edit, Trash } from 'tabler-icons-react';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import ParticipantListItem from './ParticipantListItem';
import {
  CANADA_PROVINCE_LIST,
  USA_STATE_LIST
} from '../../../config/constants';
import { formatUtcDate } from '../../../helpers/format';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';
import AppFlexbox from '../../common/AppFlexbox';
import CustomLabelTextInput from '../../common/CustomLabelInput';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import SecondaryContactModal from './SecondaryContactModal';

const ParticipantForm = ({
  onCancel,
  participant,
  participantFirstName,
  participantLastName,
  showDelete,
  hideEdit,
  setShowDelete,
  setShowOverride,
  readOnly
}) => {
  const mqIndex = useMediaQueryIndex();
  const { createRegistrant, updateRegistrant, deleteRegistrant } = useContext(
    RegistrationContext
  );
  const [formState, setFormState] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dob: null,
    gender: '',
    address1: '',
    address2: '',
    city: '',
    province: null,
    country: 'Canada',
    postalCode: '',
    phoneNumber: '',
    regPersonContacts: [],
    errors: {},
    loading: false,
    showOverrideView: false
  });
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const isFormInputsDisabled = formState.loading || readOnly;

  useEffect(() => {
    if (participant) {
      setFormState({
        firstName: participant.firstName,
        middleName: participant.middleName ?? '',
        lastName: participant.lastName,
        dob: new Date(formatUtcDate(participant.dob)),
        gender: participant.gender,
        address1: participant.address1,
        address2: participant.address2 ?? '',
        city: participant.city,
        province: participant.province,
        country: participant.country,
        postalCode: participant.postalCode,
        phoneNumber: participant.phoneNumber,
        regPersonContacts:
          participant.regPersonContacts?.map((c) => ({
            ...c,
            key: c.pkRegPersonContact
          })) || [],
        errors: {},
        loading: false,
        showOverrideView: false
      });
    }
    else {
      setFormState({
        firstName: participantFirstName ?? '',
        middleName: '',
        lastName: participantLastName ?? '',
        dob: null,
        gender: '',
        address1: '',
        address2: '',
        city: '',
        province: null,
        country: 'Canada',
        postalCode: '',
        phoneNumber: '',
        regPersonContacts: [],
        errors: {},
        loading: false,
        showOverrideView: false
      });
    }
  }, [participant]);

  const onError = (errorMessage, type, errorData) => {
    triggerNotification(errorMessage);
    if (errorData?.hasDuplicateRegistrant) {
      setShowOverride(true);
    }
    setFormState({
      ...formState,
      loading: false,
      showOverrideView: errorData?.hasDuplicateRegistrant
    });
  };

  const handleFormChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const onSaveChanges = (updateExisting = false) => {
    const formDate = {
      ...formState,
      regPersonContacts: formState.regPersonContacts.filter(
        (f) => !f.deleted || f.pkRegPersonContact
      ),
      updateExisting
    };

    setFormState({
      ...formState,
      loading: true
    });
    if (participant) {
      updateRegistrant(participant.pkRegPerson, formDate, onCancel, onError);
    }
    else {
      createRegistrant(formDate, onCancel, onError);
    }
  };

  return formState.showOverrideView ? (
    <FormSection
      cancelTitle="Cancel"
      isLoading={formState.loading}
      onCancel={() => {
        setShowOverride(false);
        setFormState({
          ...formState,
          showOverrideView: false
        });
      }}
      onSubmit={() => {
        onSaveChanges(true);
      }}
      submitTitle="Update"
    >
      <Alert
        icon={<AlertCircle size={30} />}
        styles={{
          wrapper: { alignItems: 'center' },
          icon: { width: 30, height: 25 }
        }}
        title={
          <AppTitle order={4} style={{ height: 30 }}>
            Warning
          </AppTitle>
        }
        variant="outline"
      >
        <AppStack style={{ gap: 10 }}>
          <AppText>
            <b>
              {formState.firstName} {formState.lastName}
            </b>{' '}
            already exists as a registrant.
          </AppText>
          <AppText>
            Are you sure you want to <b>update</b> the existing registrant?
          </AppText>
        </AppStack>
      </Alert>
    </FormSection>
  ) : showDelete ? (
    <FormSection
      hideDivider
      isLoading={formState.loading}
      onCancel={() => (hideEdit ? onCancel() : setShowDelete(false))}
      onSubmit={() => {
        setFormState({
          ...formState,
          loading: true
        });
        deleteRegistrant(participant.pkRegPerson, onCancel, onError);
      }}
      style={{ gap: 30 }}
      submitColor="red"
      submitTitle="Delete Participant"
    >
      <AppStack>
        <Divider />
        <ParticipantListItem participant={participant} />
        <Divider />
        <AppText style={{ textAlign: 'center' }}>
          Are you sure you want to delete the selected participant?
        </AppText>
        <Divider />
      </AppStack>
    </FormSection>
  ) : (
    <FormSection
      cancelTitle={readOnly ? 'Close' : 'Cancel'}
      isLoading={formState.loading}
      isSubmitHidden={readOnly}
      onCancel={onCancel}
      onSubmit={() => {
        if (!formState.dob) {
          triggerNotification('Please enter a valid date of birth.');
          setFormState({
            ...formState,
            loading: false,
            errors: { dob: { value: 'Invalid date of birth.' } }
          });
        }
        else {
          onSaveChanges();
        }
      }}
      style={{ marginTop: 0, gap: rs([20, 40], mqIndex), flex: 1 }}
      submitTitle={participant ? 'Save Changes ' : 'Create'}
    >
      <Divider />

      <AppFlexbox
        style={{
          gap: 0,
          alignItems: 'start',
          flexDirection: rs(['column', 'row'], mqIndex)
        }}
      >
        <AppText
          style={{
            display: rs(['none', 'block'], mqIndex),
            paddingTop: rs([0, 10], mqIndex),
            flex: 1,
            marginBottom: rs([10, 0], mqIndex)
          }}
          weight={700}
        >
          Name
        </AppText>
        <AppStack style={{ flex: 4, width: rs(['100%', 'inital'], mqIndex) }}>
          <AppFlexbox
            style={{
              gap: rs([15, 20], mqIndex),
              flexDirection: rs(['column', 'row'], mqIndex)
            }}
          >
            <CustomLabelTextInput
              disabled={isFormInputsDisabled || participantFirstName}
              label="First Name"
              name="firstName"
              onChange={handleFormChange}
              required
              value={formState.firstName}
            />

            <CustomLabelTextInput
              disabled={isFormInputsDisabled}
              label="Middle Name"
              name="middleName"
              onChange={handleFormChange}
              value={formState.middleName}
            />
          </AppFlexbox>
          <AppFlexbox style={{ gap: 20 }}>
            <CustomLabelTextInput
              disabled={isFormInputsDisabled || participantLastName}
              label="Last Name"
              name="lastName"
              onChange={handleFormChange}
              required
              value={formState.lastName}
            />

            <AppStack
              style={{ flex: 1, display: rs(['none', 'flex'], mqIndex) }}
            />
          </AppFlexbox>
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{
          gap: 0,
          alignItems: 'start',
          flexDirection: rs(['column', 'row'], mqIndex)
        }}
      >
        <AppText
          style={{
            display: rs(['none', 'block'], mqIndex),
            paddingTop: rs([0, 10], mqIndex),
            flex: 1,
            marginBottom: rs([10, 0], mqIndex)
          }}
          weight={700}
        >
          Birth Info
        </AppText>
        <AppFlexbox
          style={{
            flex: 4,
            width: rs(['100%', 'inital'], mqIndex),
            gap: rs([15, 20], mqIndex),
            flexDirection: rs(['column', 'row'], mqIndex)
          }}
        >
          <CustomLabelTextInput
            data={[
              { value: 'Male', label: 'Male' },
              { value: 'Female', label: 'Female' },
              { value: 'Not Specified', label: 'Not Specified' }
            ]}
            disabled={isFormInputsDisabled}
            label="Gender"
            name="gender"
            onChange={(value) =>
              handleFormChange({
                target: {
                  name: 'gender',
                  value
                }
              })
            }
            required
            type="select"
            value={formState.gender}
          />

          <CustomLabelTextInput
            disabled={isFormInputsDisabled}
            error={formState.errors.dob?.value}
            label="Birth Date"
            maxDate={dayjs(new Date()).toDate()}
            name="dob"
            onChange={(value) =>
              setFormState({
                ...formState,
                dob: value,
                errors: {
                  ...formState.errors,
                  dob: null
                }
              })
            }
            required
            type="date"
            value={formState.dob}
          />
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{
          gap: 0,
          alignItems: 'start',
          flexDirection: rs(['column', 'row'], mqIndex)
        }}
      >
        <AppText
          style={{
            display: rs(['none', 'block'], mqIndex),
            paddingTop: rs([0, 10], mqIndex),
            flex: 1,
            marginBottom: rs([10, 0], mqIndex)
          }}
          weight={700}
        >
          Address
        </AppText>
        <AppStack style={{ flex: 4, width: rs(['100%', 'inital'], mqIndex) }}>
          <CustomLabelTextInput
            disabled={isFormInputsDisabled || participantLastName}
            label="Address 1"
            name="address1"
            onChange={handleFormChange}
            required
            value={formState.address1}
          />
          <CustomLabelTextInput
            disabled={isFormInputsDisabled || participantLastName}
            label="Address 2"
            name="address2"
            onChange={handleFormChange}
            value={formState.address2}
          />

          <AppFlexbox
            style={{
              gap: rs([15, 20], mqIndex),
              flexDirection: rs(['column', 'row'], mqIndex)
            }}
          >
            <CustomLabelTextInput
              disabled={isFormInputsDisabled || participantLastName}
              label="City"
              name="city"
              onChange={handleFormChange}
              required
              value={formState.city}
            />

            <CustomLabelTextInput
              data={
                formState.country === 'Canada'
                  ? CANADA_PROVINCE_LIST.map((p) => ({
                      value: p,
                      label: p
                    }))
                  : USA_STATE_LIST.map((s) => ({
                      value: s,
                      label: s
                    }))
              }
              disabled={isFormInputsDisabled || participantLastName}
              label={formState.country === 'Canada' ? 'Province' : 'State'}
              name="province"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  province: value
                })
              }
              required
              type="select"
              value={formState.province ?? ''}
            />
          </AppFlexbox>

          <AppFlexbox
            style={{
              gap: rs([15, 20], mqIndex),
              flexDirection: rs(['column', 'row'], mqIndex)
            }}
          >
            <CustomLabelTextInput
              disabled={isFormInputsDisabled || participantLastName}
              label="Postal Code"
              name="postalCode"
              onChange={handleFormChange}
              required
              value={formState.postalCode}
            />

            <CustomLabelTextInput
              data={[
                { value: 'Canada', label: 'Canada' },
                { value: 'United States', label: 'United States' }
              ]}
              disabled={isFormInputsDisabled || participantLastName}
              label="Country"
              name="country"
              onChange={(value) =>
                setFormState({
                  ...formState,
                  country: value,
                  province: ''
                })
              }
              required
              type="select"
              value={formState.country}
            />
          </AppFlexbox>
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{
          gap: 0,
          alignItems: 'start',
          flexDirection: rs(['column', 'row'], mqIndex)
        }}
      >
        <AppText
          style={{
            display: rs(['none', 'block'], mqIndex),
            paddingTop: rs([0, 10], mqIndex),
            flex: 1,
            marginBottom: rs([10, 0], mqIndex)
          }}
          weight={700}
        >
          Phone Number
        </AppText>
        <AppStack style={{ flex: 4, width: rs(['100%', 'inital'], mqIndex) }}>
          <AppFlexbox style={{ gap: 20 }}>
            <CustomLabelTextInput
              disabled={isFormInputsDisabled || participantLastName}
              format="+1 (###) ###-####"
              label="Phone Number"
              mask="_"
              name="phoneNumber"
              onChange={(values) => {
                handleFormChange({
                  target: {
                    name: 'phoneNumber',
                    value: values.value
                  }
                });
              }}
              required
              type="number"
              value={formState.phoneNumber}
            />

            <AppStack
              style={{ flex: 1, display: rs(['none', 'flex'], mqIndex) }}
            />
          </AppFlexbox>
        </AppStack>
      </AppFlexbox>

      <AppStack style={{ gap: 8 }}>
        <AppStack style={{ gap: 0 }}>
          <AppFlexbox style={{ justifyContent: 'space-between', gap: 8 }}>
            <AppText style={{}} weight={700}>
              Secondary contacts
            </AppText>
          </AppFlexbox>
          <AppText style={{ fontSize: 14, color: '#666' }}>
            Add a secondary contact as a backup in case of an emergency or if
            you're unreachable. You can also designate a contact to receive all
            emails alongside the primary contact.
          </AppText>
        </AppStack>

        {formState.regPersonContacts?.length === 0 ? (
          <AppStack style={{ gap: 0, border: 'solid 1px lightgrey' }}>
            <AppStack style={{ alignItems: 'center', gap: 8, padding: 16 }}>
              <AppText style={{ fontSize: 14, textAlign: 'center' }}>
                No secondary contacts added
              </AppText>

              <Button
                color="blue"
                disabled={formState.loading}
                onClick={() => onOpenModal('secondary_contact')}
                size="compact-md"
                style={{}}
                variant="outline"
              >
                Add contact
              </Button>
            </AppStack>
          </AppStack>
        ) : (
          <AppStack style={{ gap: 8 }}>
            <AppStack style={{ gap: 0, border: 'solid 1px lightgrey' }}>
              {formState.regPersonContacts
                ?.filter((f) => !f.deleted)
                .sort((a, b) =>
                  `${a.firstName}${a.lastName}`.localeCompare(
                    `${b.firstName}${b.lastName}`
                  )
                )
                .map((c, index) => (
                  <React.Fragment key={c.key}>
                    {index > 0 && <Divider />}
                    <AppFlexbox
                      style={{
                        gap: 0,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 8
                      }}
                    >
                      <AppStack style={{ gap: 0 }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {c.firstName} {c.lastName}
                        </AppText>
                        <AppText style={{ fontSize: 14, color: '#666' }}>
                          {c.email},{' '}
                          {c.phone && (
                            <NumberFormat
                              displayType="text"
                              format="+1 (###) ###-####"
                              mask="_"
                              prefix=", "
                              value={c.phone}
                            />
                          )}
                        </AppText>
                      </AppStack>
                      {!formState.loading && !readOnly && (
                        <AppFlexbox>
                          <ActionIcon
                            color="dark"
                            onClick={() => onOpenModal('secondary_contact', c)}
                            variant="subtle"
                          >
                            <Edit />
                          </ActionIcon>
                        </AppFlexbox>
                      )}
                    </AppFlexbox>
                  </React.Fragment>
                ))}
            </AppStack>
            <AppStack style={{ alignItems: 'center', gap: 8, padding: 16 }}>
              <Button
                color="blue"
                disabled={formState.loading}
                onClick={() => onOpenModal('secondary_contact')}
                size="compact-md"
                style={{}}
                variant="outline"
              >
                Add contact
              </Button>
            </AppStack>
          </AppStack>
        )}
      </AppStack>

      {participant && !readOnly && (
        <>
          <Divider
            style={{
              marginBottom: rs([20, 0], mqIndex),
              marginTop: rs([20, 0], mqIndex)
            }}
          />
          <AppFlexbox style={{ justifyContent: 'center' }}>
            <Button
              color="red"
              disabled={formState.loading}
              onClick={() => setShowDelete(true)}
              radius="xs"
              size="md"
              style={{ flex: 1, maxWidth: rs(['unset', 250], mqIndex) }}
              variant="outline"
            >
              Delete Participant
            </Button>
          </AppFlexbox>
        </>
      )}

      <SecondaryContactModal
        isOpen={modalState.isOpen && modalState.action === 'secondary_contact'}
        onClose={onCloseModal}
        onConfirm={(contact) => {
          const contactKey = modalState.item?.key ?? new Date().getTime();

          setFormState({
            ...formState,
            regPersonContacts: [
              ...formState.regPersonContacts.filter(
                (f) => f.key !== contactKey
              ),
              {
                ...contact,
                key: contactKey
              }
            ]
          });
          onCloseModal();
        }}
        onRemove={() => {
          setFormState({
            ...formState,
            regPersonContacts: formState.regPersonContacts.map((c) =>
              c.key === modalState.item.key ? { ...c, deleted: true } : c
            )
          });
          onCloseModal();
        }}
        regPersonContact={modalState.item}
      />
    </FormSection>
  );
};

ParticipantForm.propTypes = {
  hideEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  participant: PropTypes.object,
  participantFirstName: PropTypes.string,
  participantLastName: PropTypes.string,
  readOnly: PropTypes.bool,
  setShowDelete: PropTypes.func,
  setShowOverride: PropTypes.func,
  showDelete: PropTypes.bool
};

export default ParticipantForm;
