import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@mantine/core';
import AppStack from './AppStack';
import ResponsiveModal from './ResponsiveModal';
import { singularPluralFormat } from '../../helpers/format';

const SELECT_TYPE_OPTIONS_ENUM = {
  ALL: 'all',
  ALL_FILTERED: 'all_filtered',
  SELECTED: 'selected'
};

const TableSelectConfirmModal = ({
  isOpen,
  onCancel,
  onConfirm,
  ids,
  itemType,
  itemTypePlural,
  filteredCount,
  totalCount
}) => {
  const [formState, setFormState] = useState({ selectType: '' });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        selectType:
          ids?.length === filteredCount
            ? SELECT_TYPE_OPTIONS_ENUM.ALL_FILTERED
            : ids?.length > 0
            ? SELECT_TYPE_OPTIONS_ENUM.SELECTED
            : SELECT_TYPE_OPTIONS_ENUM.ALL
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle: 'Cancel',
        onCancel,
        onSubmit: () => {
          onConfirm(
            formState.selectType === SELECT_TYPE_OPTIONS_ENUM.SELECTED
              ? ids
              : null,
            formState.selectType === SELECT_TYPE_OPTIONS_ENUM.ALL_FILTERED
          );
        },
        submitColor: 'blue',
        submitTitle: 'Continue'
      }}
      onClose={onCancel}
      opened={isOpen}
      size={600}
      title="Confirm selected"
    >
      <AppStack style={{ gap: 5 }}>
        <Radio.Group
          description={`Select the ${itemTypePlural} you want to continue with.`}
          label={`Select ${itemTypePlural}`}
          onChange={(v) => {
            setFormState({
              ...formState,
              selectType: v
            });
          }}
          value={formState.selectType}
        >
          <AppStack style={{ gap: 10, marginTop: 10 }}>
            <Radio
              label={`All ${itemTypePlural}`}
              value={SELECT_TYPE_OPTIONS_ENUM.ALL}
            />

            {totalCount !== filteredCount && (
              <Radio
                disabled={!filteredCount || filteredCount === 0}
                label={`All Filtered ${itemTypePlural} (${filteredCount})`}
                value={SELECT_TYPE_OPTIONS_ENUM.ALL_FILTERED}
              />
            )}

            <Radio
              disabled={
                !ids || ids.length === 0 || ids.length === filteredCount
              }
              label={`Selected ${singularPluralFormat(
                ids?.length ?? 0,
                itemType,
                itemTypePlural
              )}`}
              value={SELECT_TYPE_OPTIONS_ENUM.SELECTED}
            />
          </AppStack>
        </Radio.Group>
      </AppStack>
    </ResponsiveModal>
  );
};

TableSelectConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  itemType: PropTypes.string.isRequired,
  itemTypePlural: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  filteredCount: PropTypes.number,
  ids: PropTypes.array,
  totalCount: PropTypes.number
};

export default TableSelectConfirmModal;
