import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from '@mantine/core';
import { uploadFileToStorage } from '../../helpers/awsHelper';

const ImageUploadButton = ({
  label,
  identifier,
  onUploadComplete,
  ...rest
}) => {
  const imageInputRef = useRef(null);
  const [loadingState, setLoadingState] = useState(false);

  return (
    <>
      <Button
        color="dark"
        loading={loadingState}
        onClick={() => imageInputRef.current.click()}
        type="button"
        {...rest}
      >
        {label || 'Upload Image'}
      </Button>
      <input
        ref={imageInputRef}
        accept="image/png, image/jpeg"
        data-testid="fileInput"
        hidden
        onChange={(e) => {
          setLoadingState(true);
          uploadFileToStorage(
            `identifier-${new Date().getTime()}`,
            e.target.files[0],
            (url) => {
              setLoadingState(false);
              onUploadComplete(url);
            },
            () => setLoadingState(false)
          );
        }}
        type="file"
      />
    </>
  );
};

ImageUploadButton.propTypes = {
  identifier: PropTypes.string,
  label: PropTypes.string,
  onUploadComplete: PropTypes.func
};

export default ImageUploadButton;
