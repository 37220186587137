import React, { useContext, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Divider, Progress, Skeleton } from '@mantine/core';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import FinancialRegistrationTransactions from './FinancialRegistrationTransactions';
import { calculateRegFormSubmissionBalanceTotals } from '../../../helpers/paymentHelper';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppTitle from '../../common/AppTitle';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import RecordPaymentPriceItem from './RecordPaymentPriceItem';

const RecordPaymentModal = ({
  isOpen,
  onClose,
  isAdminViewing,
  regAssociation,
  regFormSubmission,
  isDetailedView
}) => {
  const mqIndex = useMediaQueryIndex();
  const hasFetched = useRef(false);
  const { state, fetchAdminRegFormSubmissionPayments } = useContext(
    RegistrationAdminContext
  );
  const {
    loading: balanceLoading,
    value: regFormSubmissionBalance
  } = state.regFormSubmissionBalance;

  const loading =
    !regFormSubmissionBalance ||
    (regFormSubmissionBalance.pkRegFormSubmission !==
      regFormSubmission?.pkRegFormSubmission &&
      (!hasFetched.current || balanceLoading));

  const isPendingState =
    !loading &&
    (regFormSubmissionBalance?.status === 'PENDING' ||
      regFormSubmissionBalance?.status === 'PENDING_PAYMENT');

  const balanceTotals = regFormSubmissionBalance
    ? calculateRegFormSubmissionBalanceTotals(regFormSubmissionBalance)
    : {};
  const currency = regFormSubmissionBalance?.balance?.currency?.toUpperCase();

  const transactionTotal =
    balanceTotals.registrationCost +
    balanceTotals.invoiceCost -
    balanceTotals.invoiceAmountRefunded;
  const totalPayments =
    balanceTotals.registrationAmountPaid +
    balanceTotals.invoiceAmountPaid -
    balanceTotals.totalRefundAmount;

  useEffect(() => {
    if (
      isOpen &&
      regFormSubmission &&
      regFormSubmissionBalance?.pkRegFormSubmission !==
        regFormSubmission.pkRegFormSubmission
    ) {
      fetchAdminRegFormSubmissionPayments(
        regFormSubmission.pkRegFormSubmission
      );
    }
  }, [isOpen, regFormSubmission]);

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={1100}
      title={<AppTitle order={3}>Registration Payment Summary</AppTitle>}
    >
      <AppStack style={{ gap: 20 }}>
        <Divider />
        <AppFlexbox
          style={{
            gap: rs([20, 20, 40], mqIndex),
            alignItems: 'start',
            flexDirection: rs(['column', 'column', 'row'], mqIndex)
          }}
        >
          <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
            <AppText size="lg" style={{ color: 'dodgerblue' }} weight={700}>
              Payment Status
            </AppText>
            <Divider size={2} />

            {loading ? (
              <AppStack style={{ gap: 5 }}>
                <Skeleton height={12} width="40%" />
                <Skeleton height={8} width="100%" />
              </AppStack>
            ) : regFormSubmissionBalance.status === 'ACCEPTED' ? (
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 16 }} weight={500}>
                  Paid in full (payments)
                </AppText>
                <Progress value={100} />
              </AppStack>
            ) : regFormSubmissionBalance.status === 'REFUNDED' ? (
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 16 }} weight={500}>
                  Refunded
                </AppText>
                <Progress value={0} />
              </AppStack>
            ) : (
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 16 }} weight={500}>
                  Waiting for payment
                </AppText>
                <Progress value={(totalPayments / transactionTotal) * 100} />
              </AppStack>
            )}
          </AppStack>
          <AppStack style={{ flex: 1, gap: 5, alignSelf: 'stretch' }}>
            <AppText style={{ color: 'dodgerblue', fontSize: 18 }} weight={700}>
              Overview
            </AppText>
            <Divider size={2} />
            {loading ? (
              <>
                <RecordPaymentPriceItem isLoading label="Subtotal" />
                <RecordPaymentPriceItem isLoading label="Transaction Total" />
                <RecordPaymentPriceItem isLoading label="Total Payments" />
                <RecordPaymentPriceItem isLoading label="Balance" />
              </>
            ) : (
              <>
                <RecordPaymentPriceItem
                  currency={currency}
                  label="Subtotal"
                  value={regFormSubmissionBalance.balance.subtotalInCents / 100}
                />

                {regFormSubmissionBalance.balance.discountInCents > 0 &&
                  regFormSubmissionBalance.balance.coupons.map((c) => (
                    <RecordPaymentPriceItem
                      key={`${c.couponName}`}
                      currency={currency}
                      label={`Discount (${c.couponName})`}
                      value={c.amountSavedInCents / 100}
                    />
                  ))}

                {regFormSubmissionBalance.balance.taxInCents > 0 && (
                  <RecordPaymentPriceItem
                    currency={currency}
                    label="Tax"
                    value={regFormSubmissionBalance.balance.taxInCents / 100}
                    valueLabel={`@ ${
                      regFormSubmissionBalance.balance.taxRate * 100
                    }%`}
                  />
                )}

                {regFormSubmissionBalance.balance.customerPaysServiceFees &&
                  regFormSubmissionBalance.balance.serviceFeeInCents > 0 && (
                    <RecordPaymentPriceItem
                      currency={currency}
                      label="Service Fee"
                      value={
                        regFormSubmissionBalance.balance.serviceFeeInCents / 100
                      }
                    />
                  )}

                {balanceTotals.registrationAmountRefunded > 0 && (
                  <RecordPaymentPriceItem
                    currency={currency}
                    label="Refunded Amount"
                    value={balanceTotals.registrationAmountRefunded}
                  />
                )}

                {balanceTotals.invoiceCost > 0 && (
                  <>
                    <RecordPaymentPriceItem
                      currency={currency}
                      label="Invoice Total"
                      value={balanceTotals.invoiceCost}
                    />

                    {balanceTotals.invoiceAmountRefunded > 0 && (
                      <RecordPaymentPriceItem
                        currency={currency}
                        label="Invoice Refunds"
                        value={balanceTotals.invoiceAmountRefunded}
                      />
                    )}
                  </>
                )}
                <RecordPaymentPriceItem
                  currency={currency}
                  label="Transaction Total"
                  value={transactionTotal}
                  weight={700}
                />

                <RecordPaymentPriceItem
                  color="#37b24d"
                  currency={currency}
                  label="Total Payments"
                  value={totalPayments}
                />

                {balanceTotals.totalForgivenessAmount > 0 && (
                  <RecordPaymentPriceItem
                    color="#37b24d"
                    currency={currency}
                    label="Forgiveness"
                    value={balanceTotals.totalForgivenessAmount}
                  />
                )}

                <RecordPaymentPriceItem
                  currency={currency}
                  isDividerHidden
                  label="Balance"
                  value={
                    transactionTotal -
                    totalPayments -
                    balanceTotals.totalForgivenessAmount
                  }
                  weight={700}
                />
              </>
            )}
          </AppStack>
        </AppFlexbox>
        <FinancialRegistrationTransactions
          isAdminViewing={isAdminViewing}
          isDetailedView={isDetailedView}
          isLoading={loading}
          isPendingPayment={isPendingState}
          regAssociation={regAssociation}
          regFormSubmission={regFormSubmission}
          regFormSubmissionBalance={regFormSubmissionBalance}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

RecordPaymentModal.propTypes = {
  isAdminViewing: PropTypes.bool,
  isDetailedView: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormSubmission: PropTypes.object
};
export default RecordPaymentModal;
