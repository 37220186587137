import React from 'react';
import {
  Checkbox,
  NumberFormatter,
  NumberInput,
  Select,
  TextInput,
  Tooltip
} from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import { DateTimePicker } from '@mantine/dates';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { BULK_EDIT_FIELD_TYPE_ENUM } from '../../../config/constants';
import CustomMultiSelect from '../../common/CustomMultiSelect';
import classes from '../../../styles/nestedStyles.module.css';
import CustomMenuSelectInput from './CustomMenuSelectInput';

const BULK_EDIT_INPUTS = {
  [BULK_EDIT_FIELD_TYPE_ENUM.TEXT]: {
    render: ({ value, paddingLeft }) =>
      value && (
        <AppFlexbox
          className={classes['bulk-edit-column-text']}
          style={{ paddingLeft: paddingLeft ?? 8 }}
        >
          <AppText style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
            {value}
          </AppText>
        </AppFlexbox>
      )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.TEXT_PRICE]: {
    render: ({ value, paddingLeft }) =>
      value && (
        <AppFlexbox
          className={classes['bulk-edit-column-text']}
          style={{ paddingLeft: paddingLeft ?? 8 }}
        >
          <NumberFormatter
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
            style={{ fontSize: 14 }}
            thousandSeparator
            value={value}
          />
        </AppFlexbox>
      )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.TEXT_INPUT]: {
    render: ({
      value,
      altValue,
      disabled,
      onChange,
      fieldData,
      paddingLeft,
      error,
      selected,
      onSelect,
      variant = 'filled',
      noLeftSection
    }) =>
      !disabled ? (
        <Tooltip
          color="red"
          disabled={!error}
          label={error}
          multiline
          w={200}
          withArrow
        >
          <TextInput
            className={classes['bulk-edit-column-input']}
            disabled={disabled}
            error={error}
            leftSection={
              !noLeftSection &&
              (fieldData.getLeftSection || fieldData.firstPosition) ? (
                <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                  {fieldData.firstPosition && onSelect && (
                    <Checkbox
                      checked={selected ?? false}
                      onChange={() => onSelect(!selected)}
                    />
                  )}
                  {fieldData.getLeftSection &&
                    fieldData.getLeftSection(altValue)}
                </AppFlexbox>
              ) : null
            }
            leftSectionWidth={noLeftSection ? 0 : fieldData.leftSectionWidth}
            onChange={(e) => onChange(e.currentTarget.value)}
            radius={0}
            required={fieldData.required}
            rightSection={disabled ? <></> : <Pencil color="#666" size={14} />}
            styles={{ error: { display: 'none' } }}
            value={value ?? ''}
            variant={variant}
          />
        </Tooltip>
      ) : (
        value && (
          <AppFlexbox
            className={classes['bulk-edit-column-text']}
            style={{ paddingLeft: paddingLeft ?? 8, gap: 8 }}
          >
            {fieldData.firstPosition && onSelect && (
              <Checkbox
                checked={selected ?? false}
                onChange={() => onSelect(!selected)}
                style={{ zIndex: 1003, pointerEvents: 'all' }}
              />
            )}
            {fieldData.getLeftSection && fieldData.getLeftSection(altValue)}
            <AppText style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
              {value}
            </AppText>
          </AppFlexbox>
        )
      )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.SELECT]: {
    render: ({
      value,
      disabled,
      onChange,
      fieldData,
      optionsState,
      error,
      variant = 'filled'
    }) => (
      <Tooltip
        color="red"
        disabled={!error}
        label={error}
        multiline
        w={200}
        withArrow
      >
        {value ? (
          <Select
            className={classes['bulk-edit-column-input']}
            clearable={fieldData.clearable}
            data={
              fieldData.getOptions
                ? fieldData.getOptions(optionsState)
                : fieldData.options ?? []
            }
            disabled={disabled}
            error={error}
            onChange={onChange}
            radius={0}
            required={fieldData.required}
            rightSection={disabled ? <></> : null}
            searchable
            styles={{ error: { display: 'none' } }}
            value={value}
            variant={variant}
          />
        ) : (
          <Select
            className={classes['bulk-edit-column-input']}
            clearable={fieldData.clearable}
            data={
              fieldData.getOptions
                ? fieldData.getOptions(optionsState)
                : fieldData.options ?? []
            }
            disabled={disabled}
            error={error}
            onChange={onChange}
            radius={0}
            required={fieldData.required}
            rightSection={disabled ? <></> : null}
            searchable
            styles={{ error: { display: 'none' } }}
            value={null}
            variant={variant}
          />
        )}
      </Tooltip>
    )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.PRICE]: {
    render: ({
      value,
      disabled,
      onChange,
      prefix,
      suffix,
      fieldData,
      error,
      variant = 'filled'
    }) => (
      <Tooltip
        color="red"
        disabled={!error}
        label={error}
        multiline
        w={200}
        withArrow
      >
        <NumberInput
          allowNegative={fieldData.allowNegative ?? false}
          clampBehavior={fieldData.clampBehavior}
          className={classes['bulk-edit-column-input']}
          decimalScale={fieldData.decimalScale ?? 2}
          disabled={disabled}
          error={error}
          fixedDecimalScale
          hideControls={disabled}
          max={fieldData.max}
          min={fieldData.min ?? 0}
          onChange={onChange}
          onFocus={(e) => {
            if (suffix) {
              setTimeout(() => e.target.select(), [10]);
            }
            else {
              e.target.select();
            }
          }}
          prefix={prefix ?? '$'}
          radius={0}
          required={fieldData.required}
          step={fieldData.step}
          styles={{ error: { display: 'none' } }}
          suffix={suffix ?? ''}
          thousandSeparator
          value={value ?? ''}
          variant={variant}
        />
      </Tooltip>
    )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.NUMBER]: {
    render: ({
      value,
      disabled,
      onChange,
      prefix,
      suffix,
      fieldData,
      error,
      variant = 'filled'
    }) => (
      <Tooltip
        color="red"
        disabled={!error}
        label={error}
        multiline
        w={200}
        withArrow
      >
        <NumberInput
          allowNegative={fieldData.allowNegative ?? false}
          clampBehavior={fieldData.clampBehavior}
          className={classes['bulk-edit-column-input']}
          decimalScale={fieldData.decimalScale ?? 2}
          disabled={disabled}
          error={error}
          fixedDecimalScale
          hideControls={disabled}
          max={fieldData.max}
          min={fieldData.min}
          onChange={onChange}
          onFocus={(e) => e.target.select()}
          prefix={prefix ?? ''}
          radius={0}
          required={fieldData.required}
          step={fieldData.step}
          styles={{ error: { display: 'none' } }}
          suffix={suffix ?? ''}
          value={value ?? ''}
          variant={variant}
        />
      </Tooltip>
    )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.SELECT_MULTIPLE]: {
    render: ({
      value,
      disabled,
      onChange,
      fieldData,
      optionsState,
      error,
      variant = 'unstyled'
    }) => (
      <CustomMultiSelect
        controlProps={{ className: classes['bulk-edit-column-input'] }}
        data={
          fieldData.getOptions
            ? fieldData.getOptions(optionsState)
            : fieldData.options ?? []
        }
        disabled={disabled}
        error={error}
        onChange={onChange}
        radius={0}
        required={fieldData.required}
        rightSection={disabled ? <></> : null}
        value={value ?? []}
        variant={variant}
      />
    )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.DATE_TIME]: {
    render: ({
      value,
      disabled,
      onChange,
      fieldData,
      error,
      variant = 'filled'
    }) => (
      <Tooltip
        color="red"
        disabled={!error}
        label={error}
        multiline
        w={200}
        withArrow
      >
        <DateTimePicker
          className={classes['bulk-edit-column-input']}
          disabled={disabled}
          error={error}
          maxDate={fieldData.maxDate}
          minDate={fieldData.minDate}
          onChange={onChange}
          radius={0}
          required={fieldData.required}
          rightSection={disabled ? <></> : null}
          styles={{ error: { display: 'none' } }}
          value={value}
          valueFormat="MM/DD/YYYY hh:mm A"
          variant={variant}
        />
      </Tooltip>
    )
  },
  [BULK_EDIT_FIELD_TYPE_ENUM.CHECKBOX]: {
    render: ({
      value,
      disabled,
      onChange,
      fieldData,
      error,
      variant = 'unstyled'
    }) => (
      <CustomMenuSelectInput
        className={classes['bulk-edit-column-input']}
        data={[
          {
            value: 'true',
            label: 'Yes'
          },
          {
            value: 'false',
            label: 'No'
          }
        ]}
        disabled={disabled}
        error={error}
        onChange={(v) => onChange(v === 'true')}
        radius={0}
        required={fieldData.required}
        rightSection={disabled ? <></> : null}
        value={value ? 'true' : value === false ? 'false' : ''}
        variant={variant}
      />
    )
  }
};

export { BULK_EDIT_INPUTS };
