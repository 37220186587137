import React from 'react';
import PropTypes from 'prop-types';
import { QuestionMark } from 'tabler-icons-react';
import { NavLink } from '@mantine/core';
import { Link } from 'react-router-dom';
import SidebarNavSections from './SidebarNavSections';
import SidebarNavButton from './SidebarNavButton';
import AppCard from '../../../../components/common/AppCard';
import AppStack from '../../../../components/common/AppStack';
import SUPPORT_LINKS from '../../../../components/content/navigation/supportLinks';

const Sidebar = ({ topSection, navigation, state }) => (
  <AppCard
    shadow="xs"
    style={{
      height: 'calc(100vh - 90px)',
      width: '100%',
      padding: 0,
      maxWidth: 250,
      minWidth: 250,
      position: '-webkit-sticky',
      position: 'sticky',
      top: 60,
      flex: 1,
      gap: 0,
      backgroundColor: '#FFF'
    }}
    visibleFrom="sm"
  >
    <AppStack
      style={{
        overflow: 'auto',
        flex: 1,
        gap: 8
      }}
    >
      {topSection}
      <AppStack
        style={{
          padding: topSection ? '0px 0px 16px 0px' : '16px 0px',
          gap: 0,
          flex: 1
        }}
      >
        {navigation.main && navigation.main.length > 0 && (
          <SidebarNavSections sections={navigation.main} state={state} />
        )}

        {navigation.sections?.map((s, index) => (
          <AppStack
            key={s.title}
            style={{
              gap: 0,
              marginTop:
                index === 0 &&
                (!navigation.main || navigation.main.length === 0)
                  ? 0
                  : 20
            }}
          >
            <NavLink
              component={Link}
              label={s.title}
              rightSection={s.icon ? <s.icon color="grey" size={15} /> : null}
              style={{
                fontSize: 16,
                fontWeight: 600,
                padding: '3px 10px',
                color: 'grey'
              }}
              to={s.links[0]?.to}
            />
            <SidebarNavSections
              key={s.title}
              sections={s.links}
              state={state}
            />
          </AppStack>
        ))}
      </AppStack>
      <AppStack
        style={{
          padding: 5,
          paddingBottom: 10,
          gap: 0
        }}
      >
        <SidebarNavButton
          color="#000"
          IconComponent={QuestionMark}
          label="Support"
          link={SUPPORT_LINKS.index.href}
          target="_blank"
        />
      </AppStack>
    </AppStack>
  </AppCard>
);

Sidebar.propTypes = {
  navigation: PropTypes.object,
  state: PropTypes.object,
  topSection: PropTypes.any
};

export default Sidebar;
