import { Alert, Button, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { AlertCircle, Calendar } from 'tabler-icons-react';
import { useNavigate } from 'react-router';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { formatUtcDate } from '../../../helpers/format';
import ResponsiveModal from '../../common/ResponsiveModal';
import { triggerNotification } from '../../../helpers/notificationHelper';
import FormSection from '../../common/FormSection';
import AppText from '../../common/AppText';
import AppTitle from '../../common/AppTitle';
import DeleteItemView from '../../common/DeleteItemView';
import AppStack from '../../common/AppStack';

const LeagueSeasonModal = ({
  isOpen,
  onClose,
  regAssociationSeason,
  regAssociation,
  showDelete,
  createCopy
}) => {
  const navigate = useNavigate();
  const {
    createAdminRegAssociationSeason,
    updateAdminRegAssociationSeason,
    deleteAdminRegAssociationSeason
  } = useContext(RegistrationAdminContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    endDate: null,
    fkRegAssociation: null,
    pkRegAssociationSeasonDuplicate: null,
    errors: {},
    loading: false
  });

  const noSupportEmail = regAssociation && !regAssociation?.supportEmail;
  const sportsHeadzSupportEmail =
    regAssociation &&
    regAssociation.supportEmail?.toLowerCase().includes('@sportsheadz.com');

  useEffect(() => {
    if (isOpen) {
      setShowDeleteConfirmation(false);
      if (regAssociationSeason) {
        setFormState({
          name: regAssociationSeason.name,
          endDate: regAssociationSeason.endDate
            ? new Date(formatUtcDate(regAssociationSeason.endDate))
            : null,
          fkRegAssociation: regAssociationSeason.fkRegAssociation,
          pkRegAssociationSeasonDuplicate: createCopy
            ? regAssociationSeason.pkRegAssociationSeason
            : null,
          errors: {},
          loading: false
        });
      }
      else {
        setFormState({
          name: '',
          endDate: null,
          loading: false,
          errors: {},
          fkRegAssociation: regAssociation.pkRegAssociation,
          pkRegAssociationSeasonDuplicate: null
        });
      }
    }
  }, [isOpen]);

  const onError = (errorMessage) => {
    triggerNotification(errorMessage);
    setFormState({
      ...formState,
      loading: false
    });
  };

  return (
    <ResponsiveModal
      onClose={onClose}
      opened={isOpen}
      size={600}
      title={
        <AppTitle order={2}>
          {!regAssociationSeason
            ? 'Create New Season'
            : createCopy
            ? 'Duplicate Season'
            : showDelete || showDeleteConfirmation
            ? 'Delete Season'
            : 'Edit Season'}
        </AppTitle>
      }
    >
      {(!regAssociationSeason || createCopy) &&
      (noSupportEmail || sportsHeadzSupportEmail) ? (
        <Alert variant="outline">
          <AppStack
            style={{
              gap: 20,
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10
            }}
          >
            <AppText style={{ textAlign: 'center' }} weight={500}>
              Support email is required to create a season.
            </AppText>
            <Button
              color="blue"
              onClick={() => {
                navigate(`/admin/settings/${regAssociation.pkRegAssociation}`, {state: { openModal: true }});
              }}
              style={{ maxWidth: 250, width: '100%' }}
            >
              Setup Support Email
            </Button>
          </AppStack>
        </Alert>
      ) : showDeleteConfirmation || showDelete ? (
        <DeleteItemView
          isLoading={formState.loading}
          itemLabel="Season"
          onCancel={() => {
            if (showDeleteConfirmation) {
              setShowDeleteConfirmation(false);
            }
            else {
              onClose();
            }
          }}
          onDelete={() => {
            setFormState({
              ...formState,
              error: '',
              loading: true
            });
            deleteAdminRegAssociationSeason(
              regAssociationSeason.pkRegAssociationSeason,
              onClose,
              onError
            );
          }}
          warnings={[
            'Associated divisions or teams.',
            'Any record of financial registrations.'
          ]}
        />
      ) : (
        <FormSection
          isLoading={formState.loading}
          onCancel={onClose}
          onSubmit={() => {
            if (
              !formState.endDate ||
              ((!regAssociationSeason ||
                regAssociationSeason?.endDate > new Date()) &&
                formState.endDate <= new Date())
            ) {
              triggerNotification('Invalid end date.');
              setFormState({
                ...formState,
                errors: { endDate: { value: 'Invalid date' } },
                loading: false
              });
            }
            else {
              setFormState({
                ...formState,
                errors: {},
                loading: true
              });

              if (regAssociationSeason && !createCopy) {
                updateAdminRegAssociationSeason(
                  regAssociationSeason.pkRegAssociationSeason,
                  formState,
                  onClose,
                  onError
                );
              }
              else {
                createAdminRegAssociationSeason(formState, onClose, onError);
              }
            }
          }}
          style={{ gap: 30 }}
          submitTitle={
            createCopy
              ? 'Duplicate'
              : regAssociationSeason
              ? 'Save Changes'
              : 'Create'
          }
        >
          <TextInput
            disabled={formState.loading}
            label="Season Name"
            name="title"
            onChange={(e) =>
              setFormState({
                ...formState,
                error: '',
                name: e.currentTarget.value
              })
            }
            placeholder="2022 - 2023 Junior Hockey"
            required
            value={formState.name}
          />
          <DatePickerInput
            clearable
            disabled={formState.loading}
            error={formState.errors.endDate?.value}
            label="End Date"
            leftSection={<Calendar color="#000" size={16} />}
            minDate={
              formState.endDate && formState.endDate <= new Date()
                ? formState.endDate
                : new Date()
            }
            onChange={(value) =>
              setFormState({
                ...formState,
                errors: {},
                endDate: value
              })
            }
            placeholder="MM/DD/YYYY"
            required
            value={formState.endDate}
            valueFormat="MM/DD/YYYY"
          />

          {createCopy ? (
            <Alert
              icon={<AlertCircle size={24} style={{ paddingTop: 3 }} />}
              variant="outline"
            >
              <AppText weight={500}>
                Any divisions will also be duplicated to the new season.
              </AppText>
            </Alert>
          ) : (
            regAssociationSeason && (
              <Button
                color="red"
                disabled={formState.loading}
                onClick={() => setShowDeleteConfirmation(true)}
                style={{ width: '100%', maxWidth: 250, alignSelf: 'center' }}
              >
                Delete Season
              </Button>
            )
          )}
        </FormSection>
      )}
    </ResponsiveModal>
  );
};

LeagueSeasonModal.propTypes = {
  createCopy: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  regAssociation: PropTypes.object,
  regAssociationSeason: PropTypes.object,
  showDelete: PropTypes.bool
};

export default LeagueSeasonModal;
