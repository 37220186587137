import { saveToStorage } from '../helpers/storageHelper';
import createDataProvider from './createDataProvider';
import { registrationReducer } from './reducer';

const initialState = {
  form: {
    data: {},
    initialized: false,
    hasUnsavedChanges: false,
    loading: false,
    triggerSubmit: false,
    triggerDiscard: false,
    containerWidth: null,
    errors: {}
  },
  regAssociation: { value: null, pkRegAssociation: null },
  view: { isClassicView: true }
};

const setFormData = (dispatch) => async ({
  data,
  containerWidth,
  hasUnsavedChanges = true,
  triggerDiscard = false,
  loading = false
}) => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: {
      data,
      hasUnsavedChanges,
      containerWidth,
      triggerDiscard,
      loading,
      initialized: true,
      triggerSubmit: false
    }
  });
};

const triggerFormDiscard = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: { triggerDiscard: true }
  });
};

const triggerFormSubmit = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: { triggerSubmit: true }
  });
};

const resetForm = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'form',
    payload: {
      data: {},
      hasUnsavedChanges: false,
      loading: false,
      triggerSubmit: false,
      triggerDiscard: false,
      initialized: false,
      errors: {}
    }
  });
};

const changeAssociation = (dispatch) => async (
  { pkRegAssociation, regAssociation },
  skipStorage = false
) => {
  try {
    if (!skipStorage) {
      saveToStorage('pkRegAssociation', pkRegAssociation);
    }
    dispatch({
      type: 'success',
      stateName: 'regAssociation',
      payload: {
        pkRegAssociation: pkRegAssociation?.toString(),
        value: regAssociation
      }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regAssociation',
      payload: 'Oops something went wrong'
    });
  }
};

const changeClassicViewSettings = (dispatch) => async (
  { isClassicView },
  skipStorage = false
) => {
  try {
    if (!skipStorage) {
      saveToStorage('isClassicView', isClassicView.toString());
    }
    dispatch({
      type: 'success',
      stateName: 'view',
      payload: { isClassicView }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'view',
      payload: 'Oops something went wrong'
    });
  }
};

export const { Provider, Context } = createDataProvider(
  registrationReducer,
  {
    changeAssociation,
    changeClassicViewSettings,
    resetForm,
    setFormData,
    triggerFormDiscard,
    triggerFormSubmit
  },
  initialState
);
