import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SettingsView from './SettingsView';
import ExternalConnectionsView from './ExternalConnectionsView';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as AuthContext } from '../../../providers/AuthProvider';

const SettingsViews = () => {
  const { fetchAdminRegAssociations } = useContext(RegistrationAdminContext);
  const { state: authState } = useContext(AuthContext);

  useEffect(() => {
    if (authState.tokenAttempted) {
      fetchAdminRegAssociations();
    }
  }, [authState.tokenAttempted]);

  return (
    <Routes>
      <Route element={<SettingsView />} path="/" />
      <Route
        element={<ExternalConnectionsView />}
        path="/external-connections"
      />
      <Route element={<SettingsView />} path=":pkRegAssociation/*" />
      <Route element={<Navigate replace to="/admin/settings" />} path="*" />
    </Routes>
  );
};

export default SettingsViews;
