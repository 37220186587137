import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import FormSection from '../../common/FormSection';
import AssignDivisionAccordionListItem from './AssignDivisionAccordionListItem';
import AppStack from '../../common/AppStack';
import { CURRENCY_TYPE_LIST } from '../../../config/constants';
import RegistrationInfoModal from '../associations/RegistrationInfoModal';
import { isDarkText } from '../../../helpers/format';

const AssignDivisionsModalSection = ({
  regAssociation,
  assignedDivisions,
  divisions,
  regFormState,
  onCancel,
  onDivisionChange,
  onSubmit
}) => {
  const [previewInfoModalState, setPreviewInfoModalState] = useState({
    isOpen: false,
    division: null,
    divisionFormState: null
  });
  const currencyAbbr = CURRENCY_TYPE_LIST.find(
    (c) =>
      c.value === regAssociation?.regAssociationPaymentProvider?.fkCurrencyType
  )?.abbr;

  return (
    <FormSection
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitTitle="Assign Divisions"
    >
      <AppStack style={{ gap: 0 }}>
        {divisions
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((division) => {
            const formState = assignedDivisions.find(
              (d) => d.pkRegAssociationDivision === division.value
            );
            return (
              <AssignDivisionAccordionListItem
                key={division.value}
                currencyAbbr={currencyAbbr}
                division={division}
                formState={formState}
                onAssignChange={(e) => {
                  const existingAssignedDivision = assignedDivisions.find(
                    (d) => d.pkRegAssociationDivision === division.value
                  );

                  if (!e.currentTarget.checked) {
                    const filteredDivisions = assignedDivisions.filter(
                      (d) => d.pkRegAssociationDivision !== division.value
                    );
                    onDivisionChange(
                      existingAssignedDivision
                        ? [
                            ...filteredDivisions,
                            { ...existingAssignedDivision, isActive: false }
                          ]
                        : filteredDivisions
                    );
                  }
                  else {
                    onDivisionChange([
                      ...assignedDivisions.filter(
                        (d) => d.pkRegAssociationDivision !== division.value
                      ),
                      existingAssignedDivision
                        ? { ...existingAssignedDivision, isActive: true }
                        : {
                            pkRegAssociationDivision: division.value,
                            price: 0,
                            orderEmailEnabled: division.hasOrderEmails,
                            customerPaysServiceFees: false,
                            daysOfPlay: [],
                            isAdditionalInfoHyperlink: false,
                            additionalInfo: '',
                            titleOverride: '',
                            isActive: true
                          }
                    ]);
                  }
                }}
                onPreviewInfoModal={() => {
                  setPreviewInfoModalState({
                    isOpen: true,
                    division,
                    divisionFormState: formState
                  });
                }}
                onValueChange={(newState) =>
                  onDivisionChange([
                    ...assignedDivisions.filter(
                      (d) => d.pkRegAssociationDivision !== division.value
                    ),
                    newState
                  ])
                }
              />
            );
          })}

        <RegistrationInfoModal
          isOpen={previewInfoModalState.isOpen}
          isPreview
          onClose={() =>
            setPreviewInfoModalState({
              ...previewInfoModalState,
              isOpen: false
            })
          }
          primaryColor={regAssociation.primaryColor}
          primaryTextColor={
            isDarkText(regAssociation.primaryColor) ? '#000' : '#FFF'
          }
          regAssociationDivisionForm={
            previewInfoModalState.divisionFormState
              ? {
                  additionalInfoImage:
                    previewInfoModalState.divisionFormState.additionalInfoImage,
                  titleOverride:
                    previewInfoModalState.divisionFormState.titleOverride,
                  regProduct: {price: previewInfoModalState.divisionFormState.price},
                  additionalInfo:
                    previewInfoModalState.divisionFormState.additionalInfo,
                  isAdditionalInfoHyperlink:
                    previewInfoModalState.divisionFormState
                      .isAdditionalInfoHyperlink,
                  regForm: {
                    name: regFormState.name,
                    additionalInfoImage: regFormState.additionalInfoImage
                  },
                  regAssociationDivision: previewInfoModalState.division
                }
              : null
          }
          secondaryColor={regAssociation.secondaryColor}
          secondaryTextColor={
            isDarkText(regAssociation.secondaryColor) ? '#000' : '#FFF'
          }
        />
      </AppStack>
    </FormSection>
  );
};

AssignDivisionsModalSection.propTypes = {
  assignedDivisions: PropTypes.array,
  divisions: PropTypes.array,
  onCancel: PropTypes.func,
  onDivisionChange: PropTypes.func,
  onSubmit: PropTypes.func,
  regAssociation: PropTypes.object,
  regFormState: PropTypes.object
};

export default AssignDivisionsModalSection;
