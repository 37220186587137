import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Skeleton, Tooltip } from '@mantine/core';
import { Lock } from 'tabler-icons-react';
import RegistrationFormControl from './RegistrationFormControl';
import { getSectionRowInfo } from '../../../helpers/formBuilderHelper';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import RegPersonContactFormControls from './RegPersonContactFormControls';

const RegistrationFormSection = ({
  isEditable,
  isDisabled,
  isViewable,
  isViewerAdmin,
  section,
  onMoveControl,
  onStopMovingControl,
  onResizeControl,
  onStopResizingControl,
  onSelectControl,
  onInputChange,
  onMouseMove,
  editComponentState,
  regFormSubmissionAnswers,
  regFormSubmission,
  formState,
  setFormState,
  errors,
  isLoading
}) => {
  const mqIndex = useMediaQueryIndex();
  const sectionRef = useRef(null);
  const [sectionRowInfo, setSectionRowInfo] = useState([]);
  const mousePosition = useRef(null);
  const isSelected =
    isEditable &&
    !section.isManaged &&
    editComponentState.pkRegFormSection === section.pkRegFormSection;
  const maxRows =
    section.regFormControls.length > 0
      ? Math.max(...section.regFormControls.map((c) => c.positionRow))
      : 1;
  const sortedControls = section.regFormControls.sort(
    (a, b) => a.positionColumn - b.positionColumn
  );
  const isTablet = mqIndex <= 3;

  let extraDragRowsCount = 0;
  if (editComponentState) {
    if (
      editComponentState.pkRegFormSection === section.pkRegFormSection &&
      editComponentState.dragPosition?.positionRow >= maxRows
    ) {
      extraDragRowsCount =
        editComponentState.dragPosition.positionRow - maxRows + 1;
    }
  }

  const countryInput = section?.regFormControls.find(
    (c) => c.title === 'Country'
  );
  const countrySubmissionAnswer = regFormSubmissionAnswers?.find(
    (a) => a.fkRegFormControl === countryInput?.pkRegFormControl
  );

  const showGrid =
    editComponentState?.showGrid ||
    (isSelected && editComponentState?.isBeingDragged) ||
    (isSelected && editComponentState?.isBeingResized);

  const selectedControl = sortedControls.find(
    (c) => c.pkRegFormControl === editComponentState?.pkRegFormControl
  );

  useEffect(() => {
    if (sectionRef.current && !editComponentState?.isBeingDragged) {
      setSectionRowInfo(getSectionRowInfo(sectionRef.current));
    }
  }, [
    sectionRef.current,
    editComponentState?.isBeingDragged,
    editComponentState?.sections
  ]);

  return (
    <AppStack style={{ gap: 5 }}>
      <AppFlexbox style={{ display: 'inline-flex' }}>
        {isLoading ? (
          <Skeleton
            height={16}
            style={{ width: rs(['40%', '25%'], mqIndex) }}
          />
        ) : isEditable && section.isManaged ? (
          <Tooltip
            color="blue"
            label="This section is automatically-managed to autofill user details and cannot be modified."
            multiline
            width={250}
            withArrow
            withinPortal
          >
            <AppFlexbox style={{ gap: 5 }}>
              <AppText weight={700}>{section.title}</AppText>
              <Lock size={20} />
            </AppFlexbox>
          </Tooltip>
        ) : (
          <AppText weight={700}>{section.title}</AppText>
        )}
      </AppFlexbox>

      <Tooltip
        color="blue"
        disabled={!isEditable || (!isTablet && !section.isManaged)}
        label={
          section.isManaged
            ? 'This is a managed section and can not be modified'
            : 'You are viewing in tablet/mobile view. Please zoom-out to edit'
        }
        multiline
        width={250}
        withArrow
        withinPortal
      >
        <AppStack
          onMouseMove={(e) => onMouseMove && onMouseMove(e, sectionRowInfo)}
          role="region"
          style={{
            border: 'solid 1px lightgrey',
            position: 'relative',
            cursor:
              (section.isManaged && !isViewerAdmin) || (isEditable && isTablet)
                ? 'not-allowed'
                : 'normal'
          }}
        >
          <Grid
            ref={sectionRef}
            id={`form-section-${section.pkRegFormSection}`}
            styles={{
              root: { margin: 10, overflow: 'visible' },
              inner: {
                margin: 0,
                padding: 0,
                width: 'unset',
                position: 'relative'
              },
              col: { padding: 0 }
            }}
          >
            {[...Array(maxRows)].map((x, i) => {
              const rowControls = sortedControls.filter(
                (c) => c.positionRow === i + 1
              );
              let columnIndex = 1;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${section.pkRegFormSection}-${i}`}>
                  {rowControls.map((c, i2) => {
                    let controlLabel = c.title;
                    if (section.isManaged && countrySubmissionAnswer) {
                      if (
                        controlLabel === 'Province' &&
                        countrySubmissionAnswer.answer === 'United States'
                      ) {
                        controlLabel = 'State';
                      }
                    }
                    const columnSpacing = c.positionColumn - columnIndex;
                    columnIndex = c.positionColumn + c.positionSpan;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={`${c.pkRegFormControl}-${i2}`}>
                        {columnSpacing >= 1 && (
                          <Grid.Col
                            role="gridcell"
                            span={columnSpacing}
                            style={{
                              height: 80,
                              display: 'block'
                            }}
                            visibleFrom="lg"
                          />
                        )}
                        {isLoading ? (
                          <Grid.Col
                            role="gridcell"
                            span={{
                              sm: 12,
                              md: c.type === 'TEXT' ? 12 : 6,
                              lg: c.positionSpan
                            }}
                            style={{ height: 80 }}
                          >
                            <AppStack style={{ flex: 1, gap: 5, padding: 10 }}>
                              <Skeleton height={14} width="50%" />
                              <Skeleton height={32} width="100%" />
                            </AppStack>
                          </Grid.Col>
                        ) : (
                          <RegistrationFormControl
                            control={c}
                            draggableSectionId={`#form-section-${section.pkRegFormSection}`}
                            errors={errors}
                            gridSize={editComponentState?.gridSize}
                            isDisabled={isDisabled || (isEditable && isTablet)}
                            isMoveable={
                              isEditable && !section.isManaged && !isTablet
                            }
                            isOverlapping={
                              isEditable && editComponentState.isOverlapping
                            }
                            isSelected={
                              isEditable &&
                              c.pkRegFormControl ===
                                editComponentState.pkRegFormControl
                            }
                            isViewable={isViewable}
                            label={controlLabel}
                            onInputChange={onInputChange}
                            onMoveControl={(...props) =>
                              onMoveControl(...props, sectionRowInfo)
                            }
                            onResizeControl={onResizeControl}
                            onSelectControl={(...props) =>
                              onSelectControl(...props, mousePosition.current)
                            }
                            onStopMovingControl={(...props) =>
                              onStopMovingControl(...props, sectionRowInfo)
                            }
                            onStopResizingControl={onStopResizingControl}
                            showGrid={showGrid}
                            submissionAnswer={regFormSubmissionAnswers?.find(
                              (s) => s.fkRegFormControl === c.pkRegFormControl
                            )}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                  {columnIndex <= 12 && (
                    <Grid.Col
                      role="gridcell"
                      span={12 - columnIndex + 1}
                      style={{
                        height: 80,
                        display: 'block'
                      }}
                      visibleFrom="lg"
                    />
                  )}
                </React.Fragment>
              );
            })}
            {[...Array(extraDragRowsCount)].map((x, i) => (
              <Grid.Col
                // eslint-disable-next-line react/no-array-index-key
                key={`${section.pkRegFormSection}-extra-${i}`}
                id={`${section.pkRegFormSection}-extra-${i}`}
                role="gridcell"
                span={12}
                style={{
                  height: 80,
                  display: 'block'
                }}
              />
            ))}

            {!isLoading &&
              regFormSubmission &&
              section?.positionOrder === 0 && (
                <RegPersonContactFormControls
                  formState={formState}
                  regPerson={regFormSubmission.regPerson}
                  setFormState={setFormState}
                />
              )}
          </Grid>
          {showGrid && (
            <>
              <Grid
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  boxSizing: 'border-box',
                  zIndex: 1
                }}
                styles={{
                  inner: {
                    margin: 0,
                    padding: 10,
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }
                }}
              >
                {[...Array(12)].map((x, i) => (
                  <Grid.Col
                    // eslint-disable-next-line react/no-array-index-key
                    key={`grid-column-${i}`}
                    role="gridcell"
                    span={1}
                    style={{
                      borderLeft: i === 0 ? 'solid #d3d3d3 1px' : 'none',
                      borderRight: 'solid #d3d3d3 1px',
                      boxSizing: 'border-box'
                    }}
                  />
                ))}
              </Grid>

              <Grid
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  boxSizing: 'border-box',
                  zIndex: 1
                }}
                styles={{
                  inner: {
                    margin: 0,
                    padding: 10,
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }
                }}
              >
                {sectionRowInfo.map((row, index) => {
                  let { height } = row;
                  if (
                    editComponentState.isBeingDragged &&
                    selectedControl &&
                    selectedControl.positionRow - 1 === index
                  ) {
                    height = 80;
                  }

                  return (
                    <Grid.Col
                      // eslint-disable-next-line react/no-array-index-key
                      key={`grid-row-${index}`}
                      role="gridcell"
                      span={12}
                      style={{
                        height,
                        borderTop: index === 0 ? 'solid #e5e5e5 1px' : 0,
                        borderBottom: 'solid #e5e5e5 1px',
                        boxSizing: 'border-box'
                      }}
                    />
                  );
                })}
                {[...Array(extraDragRowsCount)].map((x, i) => (
                  <Grid.Col
                    // eslint-disable-next-line react/no-array-index-key
                    key={`grid-row-${i}`}
                    role="gridcell"
                    span={12}
                    style={{
                      height: 80,
                      borderBottom: 'solid #e5e5e5 1px',
                      boxSizing: 'border-box'
                    }}
                  />
                ))}
              </Grid>
            </>
          )}
        </AppStack>
      </Tooltip>
    </AppStack>
  );
};

RegistrationFormSection.propTypes = {
  editComponentState: PropTypes.object,
  errors: PropTypes.object,
  formState: PropTypes.object,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isViewable: PropTypes.bool,
  isViewerAdmin: PropTypes.bool,
  onInputChange: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMoveControl: PropTypes.func,
  onResizeControl: PropTypes.func,
  onSelectControl: PropTypes.func,
  onStopMovingControl: PropTypes.func,
  onStopResizingControl: PropTypes.func,
  regFormSubmission: PropTypes.object,
  regFormSubmissionAnswers: PropTypes.array,
  section: PropTypes.object,
  setFormState: PropTypes.func
};

export default RegistrationFormSection;
