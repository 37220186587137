import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Anchor,
  Badge,
  Button,
  Checkbox,
  ColorInput,
  Divider,
  Select,
  Switch,
  Tabs,
  TextInput,
  Tooltip
} from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ChevronRight, ExternalLink, List } from 'tabler-icons-react';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import {
  CURRENCY_TYPE_ENUM,
  CURRENCY_TYPE_LIST,
  PAYMENT_PROVIDERS,
  PAYMENT_PROVIDER_LIST,
  REG_ASSOCIATION_SOCIAL_TYPES,
  REG_FORM_SORT_TYPES
} from '../../../config/constants';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import HeaderView from '../../common/HeaderView';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppImage from '../../common/AppImage';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { getResponsiveStyle as rs } from '../../../helpers/styles';
import SUPPORT_LINKS from '../navigation/supportLinks';
import AdminView from '../../common/AdminView';
import SettingCard from './SettingCard';
import EditAssociationSidebars from '../associations/EditAssociationSidebars';
import { triggerNotification } from '../../../helpers/notificationHelper';
import ChangeCurrencyModal from './ChangeCurrencyModal';
import SettingSelector from '../../common/SettingSelector';

const SettingsView = () => {
  const mqIndex = useMediaQueryIndex();
  const navigate = useNavigate();
  const { pkRegAssociation } = useParams();
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );
  const { state, updateRegAssociation } = useContext(RegistrationAdminContext);
  const logoImageInputRef = useRef(null);
  const bannerImageInputRef = useRef(null);
  const [formState, setFormState] = useState({
    heroImageUrl: '',
    logoImageUrl: '',
    primaryColor: '',
    secondaryColor: '',
    supportEmail: '',
    fkRegFormSortType: '',
    fkCurrencyType: '',
    isSortDescending: false,
    lightModeTheme: false,
    socials: [],
    sidebars: [],
    sandboxEnabled: false,
    treasurerAlertsEnabled: false,
    treasurerAlertsEmail: '',
    hasChanges: false,
    loading: false,
    showCurrencyModal: false
  });
  const regAssociation = state.regAssociations.value.find(
    (a) =>
      a.pkRegAssociation ===
      dashboardState.regAssociation.value?.pkRegAssociation
  );
  const selectedCurrency = CURRENCY_TYPE_LIST.find(
    (c) => c.value.toString() === formState.fkCurrencyType?.toString()
  );
  const isSportsPayPaymentProvider =
    regAssociation?.regAssociationPaymentProvider?.fkPaymentProvider ===
    PAYMENT_PROVIDERS.sportspay.value;

  const hasExternalConnections =
    regAssociation &&
    regAssociation.regAssociationExternalConnections?.filter((c) => !c.deleted)
      .length > 0;

  useEffect(() => {
    if (regAssociation) {
      // eslint-disable-next-line no-use-before-define
      initializeFormState();
    }
  }, [regAssociation]);

  useEffect(() => {
    if (
      dashboardState.regAssociation.value &&
      dashboardState.regAssociation.value.pkRegAssociation?.toString() !==
        pkRegAssociation
    ) {
      navigate(
        `/admin/settings/${dashboardState.regAssociation.value.pkRegAssociation}`
      );
    }
  }, [dashboardState.regAssociation]);

  const initializeFormState = () => {
    const sidebar1 = regAssociation.sidebars[0];
    const sidebar2 = regAssociation.sidebars[1];

    const initialSidebars = [
      sidebar1 ?? {
        type: 'TEXT',
        title: '',
        hyperlink: '',
        imageUrl: '',
        content: '',
        visible: false,
        order: 1
      },
      sidebar2 ?? {
        type: 'TEXT',
        title: '',
        hyperlink: '',
        imageUrl: '',
        content: '',
        visible: false,
        order: 2
      }
    ];

    setFormState({
      heroImageUrl: regAssociation?.heroImageUrl,
      logoImageUrl: regAssociation?.logoImageUrl,
      primaryColor: regAssociation?.primaryColor,
      secondaryColor: regAssociation?.secondaryColor,
      supportEmail: regAssociation?.supportEmail,
      fkRegFormSortType: regAssociation?.fkRegFormSortType?.toString(),
      fkCurrencyType:
        regAssociation?.regAssociationPaymentProvider?.fkCurrencyType?.toString() ??
        CURRENCY_TYPE_ENUM.CAD.toString(),
      isSortDescending: regAssociation?.isSortDescending,
      lightModeTheme: regAssociation?.lightModeTheme,
      socials: regAssociation?.socials,
      sidebars: initialSidebars,
      sandboxEnabled: regAssociation?.sandboxEnabled,
      treasurerAlertsEnabled: regAssociation
        ? !!regAssociation.treasurerAlertsEmail
        : false,
      treasurerAlertsEmail: regAssociation?.treasurerAlertsEmail ?? '',
      hasChanges: false,
      loading: false,
      showCurrencyModal: false
    });
  };

  const handleFormChange = (target) => {
    setFormState({
      ...formState,
      [target.name]: target.value,
      hasChanges: true
    });
  };

  return (
    <AdminView
      breadcrumbs={
        regAssociation
          ? [
              {
                to: `/admin/settings/${regAssociation.pkRegAssociation}`,
                title: regAssociation.association.name
              }
            ]
          : null
      }
      headerTitle="Settings"
      helpLinks={[SUPPORT_LINKS.setupLinks.branding]}
      indexHelpLink={SUPPORT_LINKS.setupLinks.index}
    >
      <HeaderView maxViewWidth={1200} viewProps={{ style: { marginTop: 0 } }}>
        <AppStack
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setFormState({
              ...formState,
              loading: true
            });
            updateRegAssociation(
              pkRegAssociation,
              {
                ...formState,
                treasurerAlertsEmail: formState.treasurerAlertsEnabled
                  ? formState.treasurerAlertsEmail
                  : null
              },
              () => {
                setFormState({
                  ...formState,
                  hasChanges: false,
                  loading: false
                });
                triggerNotification('Association Updated', 'Success', 'green');
              },
              (message) => {
                triggerNotification(message);
                setFormState({
                  ...formState,
                  loading: false
                });
              }
            );
          }}
          style={{ gap: 20 }}
        >
          <AppFlexbox
            style={{
              gap: 5,
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 22, fontWeight: 700 }}>
              Association Settings
            </AppText>
            {formState.hasChanges && (
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Button
                  aria-label="cancelChanges"
                  color="dark"
                  disabled={formState.loading}
                  onClick={() => initializeFormState()}
                  role="button"
                  size="compact-sm"
                  type="button"
                >
                  Reset
                </Button>
                <Button
                  aria-label="saveChanges"
                  color="blue"
                  loading={formState.loading}
                  role="button"
                  size="compact-sm"
                  type="submit"
                >
                  Save Changes
                </Button>
              </AppFlexbox>
            )}
          </AppFlexbox>
          <AppStack style={{ gap: 50 }}>
            <SettingCard
              description="Customize the visual identity of your association by selecting colors, uploading your logo, and choosing a banner image. Additionally, you can toggle between light and dark modes, adjusting the header and footer colors accordingly. Your selections will be displayed on your registration page."
              title="Branding"
            >
              <AppStack style={{ gap: 5 }}>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppText weight={700}>Banner image</AppText>
                  <Button
                    color="dark"
                    disabled={formState.loading}
                    onClick={() => bannerImageInputRef.current.click()}
                    size="compact-sm"
                    type="button"
                  >
                    {formState.heroImageUrl ? 'Change Banner' : 'Upload Banner'}
                  </Button>
                  <input
                    ref={bannerImageInputRef}
                    accept="image/png, image/jpeg"
                    data-testid="fileInput"
                    hidden
                    onChange={(e) =>
                      uploadFileToStorage(
                        `regAssociation-${pkRegAssociation}`,
                        e.target.files[0],
                        (url) =>
                          handleFormChange({
                            name: 'heroImageUrl',
                            value: url
                          })
                      )
                    }
                    type="file"
                  />
                </AppFlexbox>
                <AppStack
                  style={{
                    border: 'solid 1px lightgrey',
                    gap: 0,
                    height: 150,
                    justifyContent: 'center'
                  }}
                >
                  {formState.heroImageUrl ? (
                    <AppImage
                      fit="fill"
                      height={150}
                      src={formState.heroImageUrl}
                    />
                  ) : (
                    <AppText style={{ textAlign: 'center' }} weight={700}>
                      No Banner Selected
                    </AppText>
                  )}
                </AppStack>
              </AppStack>

              <AppFlexbox style={{ flex: 1 }}>
                <AppStack style={{ gap: 5, flex: 1 }}>
                  <AppText weight={700}>Colors</AppText>
                  <AppStack style={{ gap: 10, flex: 1 }}>
                    <ColorInput
                      disabled={formState.loading}
                      label="Primary color"
                      name="primaryColor"
                      onChange={(value) =>
                        handleFormChange({
                          name: 'primaryColor',
                          value
                        })
                      }
                      placeholder="Pick color"
                      required
                      value={formState.primaryColor ?? ''}
                    />
                    <ColorInput
                      disabled={formState.loading}
                      label="Secondary color"
                      name="secondaryColor"
                      onChange={(value) =>
                        handleFormChange({
                          name: 'secondaryColor',
                          value
                        })
                      }
                      placeholder="Pick color"
                      required
                      style={{ marginTop: 15 }}
                      value={formState.secondaryColor ?? ''}
                    />
                    <Switch
                      checked={formState.lightModeTheme}
                      color="dodgerblue"
                      disabled={formState.loading}
                      label="Light Mode"
                      onChange={(e) =>
                        handleFormChange({
                          name: 'lightModeTheme',
                          value: e.currentTarget.checked
                        })
                      }
                      style={{ marginTop: 10, fontWeight: 500 }}
                      styles={{ input: { cursor: 'pointer' } }}
                    />
                  </AppStack>
                </AppStack>
                <Divider orientation="vertical" />
                <AppStack style={{ gap: 5, flex: 1 }}>
                  <AppFlexbox
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <AppText weight={700}>Logo</AppText>
                    <Button
                      color="dark"
                      disabled={formState.loading}
                      onClick={() => logoImageInputRef.current.click()}
                      size="compact-sm"
                      type="button"
                    >
                      Upload Logo
                    </Button>
                    <input
                      ref={logoImageInputRef}
                      accept="image/png, image/jpeg"
                      data-testid="fileInput"
                      hidden
                      onChange={(e) =>
                        uploadFileToStorage(
                          `regAssociation-${pkRegAssociation}`,
                          e.target.files[0],
                          (url) =>
                            handleFormChange({
                              name: 'logoImageUrl',
                              value: url
                            })
                        )
                      }
                      type="file"
                    />
                  </AppFlexbox>
                  <AppFlexbox
                    style={{
                      border: 'solid 1px lightgrey',
                      gap: 0,
                      minHeight: 200,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {formState.logoImageUrl ? (
                      <AppImage
                        fit="contain"
                        height={200}
                        src={formState.logoImageUrl}
                        width="100%"
                      />
                    ) : (
                      <AppText weight={700}>No Logo Selected</AppText>
                    )}
                  </AppFlexbox>
                </AppStack>
              </AppFlexbox>
            </SettingCard>
            <SettingCard
              description="Integrate your association's social media accounts by linking to Facebook, Twitter, Instagram, and LinkedIn profiles. These links will appear as a sidebar on your registration page."
              padding={0}
              title="Social Media"
            >
              <AppStack>
                <Tabs
                  defaultValue={REG_ASSOCIATION_SOCIAL_TYPES[0].value}
                  styles={{ panel: { padding: 16 } }}
                >
                  <Tabs.List>
                    {REG_ASSOCIATION_SOCIAL_TYPES.map((t) => (
                      <Tabs.Tab
                        key={t.value}
                        disabled={t.disabled}
                        leftSection={<t.icon size={18} />}
                        value={t.value}
                      >
                        <AppText
                          style={{
                            fontSize: t.fontSize || 14,
                            lineHeight: t.fontSize ? 'unset' : '14px',
                            overflow: 'visible'
                          }}
                          weight={t.fontWeight}
                        >
                          {t.label}
                        </AppText>
                      </Tabs.Tab>
                    ))}
                  </Tabs.List>
                  {REG_ASSOCIATION_SOCIAL_TYPES.map((s) => (
                    <Tabs.Panel
                      key={s.pkRegAssociationSocialType}
                      style={{ flex: rs([1, 'unset'], mqIndex) }}
                      value={s.value}
                    >
                      <TextInput
                        disabled={formState.loading}
                        label="Url"
                        name={s.label}
                        onChange={(e) => {
                          const foundSocial = formState.socials.find(
                            (f) =>
                              f.fkRegAssociationSocialType ===
                              s.pkRegAssociationSocialType
                          );
                          const existingSocial = regAssociation.socials.find(
                            (f) =>
                              f.fkRegAssociationSocialType ===
                              s.pkRegAssociationSocialType
                          );
                          if (foundSocial) {
                            setFormState({
                              ...formState,
                              socials: formState.socials.map((f) =>
                                f.fkRegAssociationSocialType ===
                                s.pkRegAssociationSocialType
                                  ? { ...f, value: e.target.value }
                                  : f
                              ),
                              hasChanges:
                                e.target.value !== (existingSocial?.value ?? '')
                            });
                          }
                          else {
                            setFormState({
                              ...formState,
                              socials: [
                                ...formState.socials,
                                {
                                  fkRegAssociationSocialType:
                                    s.pkRegAssociationSocialType,
                                  value: e.target.value
                                }
                              ],
                              hasChanges:
                                e.target.value !== (existingSocial?.value ?? '')
                            });
                          }
                        }}
                        placeholder={`Enter url to ${s.label}`}
                        style={{ marginBottom: 10 }}
                        value={
                          formState.socials.find(
                            (f) =>
                              f.fkRegAssociationSocialType ===
                              s.pkRegAssociationSocialType
                          )?.value ?? ''
                        }
                      />
                    </Tabs.Panel>
                  ))}
                </Tabs>
              </AppStack>
            </SettingCard>
            <SettingCard
              description="Create up to two pieces of sidebar content to display text and/or images. This content will be prominently featured on the registration page, providing additional information or visual interest to visitors."
              padding={0}
              title="Sidebar Information"
            >
              <EditAssociationSidebars
                isLoading={formState.loading}
                onSidebarChange={(sidebars, hasChanges) =>
                  setFormState({
                    ...formState,
                    sidebars,
                    hasChanges
                  })
                }
                primaryColor={formState.primaryColor}
                regAssociation={regAssociation}
                secondaryColor={formState.secondaryColor}
                sidebars={formState.sidebars}
              />
            </SettingCard>

            <SettingCard
              description="Configure the sorting of registration forms on your page. Options include sorting forms in descending order, with the default setting being ascending order by end date. This ensures the most relevant or urgent registration forms are easily accessible."
              title="Registration Sorting"
            >
              <AppFlexbox style={{ flex: 1, alignItems: 'end' }}>
                <Select
                  clearable
                  data={REG_FORM_SORT_TYPES}
                  disabled={formState.loading}
                  label="Form Sort Mode"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      fkRegFormSortType: value,
                      hasChanges: true
                    })
                  }
                  required
                  style={{ flex: 1 }}
                  value={formState.fkRegFormSortType ?? ''}
                />
                <Checkbox
                  checked={formState.isSortDescending}
                  description="Reverse the order and sort registration forms by descending."
                  disabled={formState.loading}
                  label="Sort Descending"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      isSortDescending: e.currentTarget.checked,
                      hasChanges: true
                    })
                  }
                  style={{ flex: 1, fontWeight: 500 }}
                />
              </AppFlexbox>
            </SettingCard>

            <SettingCard
              description="Set up the contact email for your association. This email will serve as the primary point of contact for official communications and inquiries related to the registration process."
              title="Contact Information"
            >
              <AppFlexbox style={{ flex: 1 }}>
                <TextInput
                  disabled={formState.loading}
                  label="Support Email"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      supportEmail: e.currentTarget.value,
                      hasChanges:
                        e.currentTarget.value !==
                        (regAssociation?.supportEmail ?? '')
                    })
                  }
                  placeholder={`support@${regAssociation?.association.name
                    .replace(/\s/g, '')
                    .toLowerCase()}.com`}
                  required
                  style={{ flex: 1 }}
                  value={formState.supportEmail ?? ''}
                />
                <AppStack style={{ flex: 1 }} />
              </AppFlexbox>
            </SettingCard>
            <SettingCard
              description="Manage the visibility of your association by enabling sandbox mode. This feature allows you to hide the association from public view, making it accessible only to authorized members while updates or changes are being made."
              title="Visibility"
            >
              <Switch
                checked={formState.sandboxEnabled}
                description="Enable sandbox mode to hide your association from public view while making changes."
                disabled={formState.loading}
                label="Sandbox Mode"
                offLabel="OFF"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    sandboxEnabled: e.currentTarget.checked,
                    hasChanges: true
                  })
                }
                onLabel="ON"
                size="lg"
                styles={{
                  label: { fontSize: 16, fontWeight: 500 },
                  description: { fontSize: 14 }
                }}
              />
            </SettingCard>

            <SettingCard
              description="Onboard or update your payment provider with Stripe or Sportspay. You can also change the currency for transactions and enable email notifications for every purchase, which will be sent to the designated treasurer alert email."
              title="Payments"
            >
              <AppStack style={{ gap: 5 }}>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Payment Provider
                  </AppText>
                  <Anchor
                    c="dodgerblue"
                    component={Link}
                    style={{ fontSize: 14 }}
                    to="/admin/financials/payee"
                  >
                    Setup
                  </Anchor>
                </AppFlexbox>
                <AppCard
                  radius="md"
                  shadow="none"
                  style={{ padding: 0 }}
                  withBorder
                >
                  <AppFlexbox
                    style={{
                      gap: 0,
                      flex: 1,
                      padding: '8px 16px',
                      alignItems: 'center'
                    }}
                  >
                    <AppStack style={{ flex: 1, gap: 0 }}>
                      {!regAssociation?.regAssociationPaymentProvider ? (
                        <AppText
                          style={{
                            fontSize: 16,
                            color: 'red',
                            fontWeight: 500
                          }}
                        >
                          Not connected
                        </AppText>
                      ) : (
                        <>
                          <AppText
                            style={{
                              fontSize: 14,
                              fontWeight: 500
                            }}
                          >
                            {
                              PAYMENT_PROVIDER_LIST.find(
                                (p) =>
                                  p.value ===
                                  regAssociation?.regAssociationPaymentProvider
                                    .fkPaymentProvider
                              )?.label
                            }
                          </AppText>
                          <AppText
                            style={{
                              fontSize: 16,
                              color: '#666'
                            }}
                          >
                            {
                              regAssociation?.regAssociationPaymentProvider
                                .accountId
                            }
                          </AppText>
                        </>
                      )}
                    </AppStack>
                    {regAssociation?.regAssociationPaymentProvider &&
                    regAssociation?.regAssociationPaymentProvider.completed ? (
                      <AppText
                        style={{
                          fontSize: 14,
                          color: 'green',
                          fontWeight: 500
                        }}
                      >
                        Active
                      </AppText>
                    ) : (
                      <AppText
                        style={{
                          fontSize: 14,
                          color: 'red',
                          fontWeight: 500
                        }}
                      >
                        Info Missing
                      </AppText>
                    )}
                  </AppFlexbox>
                </AppCard>
              </AppStack>

              {regAssociation?.regAssociationPaymentProvider && (
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Currency
                    </AppText>

                    {isSportsPayPaymentProvider ? (
                      <Tooltip
                        label="Changing currency is not available for SportsPay."
                        multiline
                        w={200}
                      >
                        <AppText
                          style={{ color: '#999', cursor: 'not-allowed' }}
                        >
                          Change
                        </AppText>
                      </Tooltip>
                    ) : (
                      <Anchor
                        c="dodgerblue"
                        onClick={() =>
                          setFormState({
                            ...formState,
                            showCurrencyModal: true
                          })
                        }
                        style={{ fontSize: 14 }}
                      >
                        Change
                      </Anchor>
                    )}
                  </AppFlexbox>

                  <AppCard
                    radius="md"
                    shadow="none"
                    style={{ padding: 0 }}
                    withBorder
                  >
                    <AppFlexbox
                      style={{
                        gap: 0,
                        flex: 1,
                        padding: 16,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AppStack style={{ flex: 1, gap: 0 }}>
                        {selectedCurrency && (
                          <Badge color="dark" variant="light">
                            {selectedCurrency.fullLabel}
                          </Badge>
                        )}
                      </AppStack>
                    </AppFlexbox>
                  </AppCard>
                </AppStack>
              )}

              <Switch
                checked={formState.treasurerAlertsEnabled}
                description="Receive email alerts when online purchases are made."
                disabled={formState.loading}
                label="Treasurer Purchase Alerts"
                offLabel="OFF"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    treasurerAlertsEnabled: e.currentTarget.checked,
                    hasChanges: true
                  })
                }
                onLabel="ON"
                size="lg"
                styles={{
                  label: { fontSize: 16, fontWeight: 500 },
                  description: { fontSize: 14 }
                }}
              />
              {formState.treasurerAlertsEnabled && (
                <AppFlexbox style={{ flex: 1 }}>
                  <TextInput
                    disabled={formState.loading}
                    label="Treasurer Email"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        treasurerAlertsEmail: e.currentTarget.value,
                        hasChanges: true
                      })
                    }
                    placeholder={`treasurer@${regAssociation?.association.name
                      .replace(/\s/g, '')
                      .toLowerCase()}.com`}
                    required
                    style={{ flex: 1 }}
                    type="email"
                    value={formState.treasurerAlertsEmail}
                  />
                </AppFlexbox>
              )}
            </SettingCard>

            <SettingCard
              description="Connect your association to external services to streamline your registration process."
              title="External Connections"
            >
              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>
                    External connections
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Connect to external services to streamline your registration
                    process.
                  </AppText>
                </AppStack>

                <SettingSelector
                  component={Link}
                  description={
                    hasExternalConnections ? 'Connected' : 'Not setup'
                  }
                  descriptionColor={
                    hasExternalConnections ? '#067D62' : '#c40000'
                  }
                  disabled={formState.loading}
                  leftSection={<ExternalLink size={32} />}
                  rightSection={
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Click to setup
                      </AppText>
                      <ChevronRight size={18} />
                    </AppFlexbox>
                  }
                  style={{ cursor: 'pointer', backgroundColor: '#FFF' }}
                  title="External connections"
                  to="/admin/settings/external-connections"
                  withBorder
                />
              </AppStack>
            </SettingCard>

            <SettingCard
              description="Setup task templates to organize and track tasks, allowing you to easily check them off as they are completed."
              title="Tasks lists"
            >
              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 5 }}>
                  <AppText style={{ fontWeight: 500 }}>Task Templates</AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Setup task templates to organize and track tasks.
                  </AppText>
                </AppStack>

                <SettingSelector
                  component={Link}
                  disabled={formState.loading}
                  leftSection={<List size={32} />}
                  rightSection={
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                        Click to setup
                      </AppText>
                      <ChevronRight size={18} />
                    </AppFlexbox>
                  }
                  style={{ cursor: 'pointer', backgroundColor: '#FFF' }}
                  title="Task Templates"
                  to="/admin/task-templates"
                  withBorder
                />
              </AppStack>
            </SettingCard>
          </AppStack>

          <AppFlexbox
            style={{
              gap: 5,
              alignItems: 'center',
              justifyContent: 'end'
            }}
          >
            {formState.hasChanges && (
              <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                <Button
                  aria-label="cancelChanges"
                  color="dark"
                  disabled={formState.loading}
                  onClick={() => initializeFormState()}
                  role="button"
                  size="compact-sm"
                  type="button"
                >
                  Reset
                </Button>
                <Button
                  aria-label="saveChanges"
                  color="blue"
                  loading={formState.loading}
                  role="button"
                  size="compact-sm"
                  type="submit"
                >
                  Save Changes
                </Button>
              </AppFlexbox>
            )}
          </AppFlexbox>
        </AppStack>
      </HeaderView>

      <ChangeCurrencyModal
        currencyType={formState.fkCurrencyType}
        isOpen={formState.showCurrencyModal}
        onClose={() =>
          setFormState({
            ...formState,
            showCurrencyModal: false
          })
        }
        onConfirm={(fkCurrencyType) => {
          setFormState({
            ...formState,
            fkCurrencyType,
            hasChanges: true,
            showCurrencyModal: false
          });
        }}
        sandboxEnabled={formState.sandboxEnabled}
      />
    </AdminView>
  );
};

export default SettingsView;
