import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Loader,
  Select
} from '@mantine/core';
import { AlertCircle, ArrowLeft, ArrowRight, Plus } from 'tabler-icons-react';
import dayjs from 'dayjs';
import RegistrationFormSection from './RegistrationFormSection';
import RegistrationFormRoleSection from './RegistrationFormRolesSection';
import RegistrationFormFinancialAssistanceSection from './RegistrationFormFinancialAssistanceSection';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppTitle from '../../common/AppTitle';
import { getFormSubmissionAddonProductsPrice } from '../../../helpers/paymentHelper';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import AppText from '../../common/AppText';
import MerchPackageShowcase from '../merch/MerchPackageShowcase';
import { ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM } from '../../../config/constants';
import ExternalConnectionFormSection from './ExternalConnectionFormSection';

const RegistrationForm = ({
  form,
  sections,
  divisionRoles,
  assignedRoles,
  regFormSubmission,
  isEditable,
  isDisabled,
  isViewable,
  isViewerAdmin,
  editComponentState,
  onMouseMove,
  onMoveControl,
  onStopMovingControl,
  onResizeControl,
  onStopResizingControl,
  onSelectControl,
  onOpenFormSectionModal,
  onCancel,
  onSubmit,
  isLoading,
  error,
  showMerchPackageShowcase,
  merchPackageRegAssociation
}) => {
  const [formState, setFormState] = useState({
    financialAssistanceId: '',
    isFinancialAssistanceEnabled: false,
    divisionRoles: [],
    regFormSubmissionAnswers: [],
    regFormSubmissionExternalIds: [],
    regFormSubmissionAnswerHistoryDates: [],
    includeRegPersonContacts: false,
    regFormSubmissionPersonContacts: [],
    selectedHistoryDateIndex: 0,
    regPerson: null,
    addOnProductsPrice: 0,
    showConfirmPriceChangeModal: false,
    confirmPriceChangeValue: '',
    doNotChargeForChanges: false,
    isAdminEdit: false,
    errors: {},
    error: ''
  });
  const availableRoles = divisionRoles
    ? divisionRoles.filter(
        (r) =>
          !assignedRoles.some(
            (a) => r.maxCount && a.role === r.name && r.maxCount <= a.count
          )
      )
    : [];

  const isFormControlsDisabled = isDisabled && !formState.isAdminEdit;
  const submissionAnswers = regFormSubmission
    ? [...regFormSubmission.regFormSubmissionAnswers]
    : [];
  const submissionAnswerHistory = submissionAnswers.reduce(
    (r, c) => [...r, ...c.regFormSubmissionAnswerHistory],
    []
  );
  const selectedHistoryDate = !formState.isAdminEdit
    ? formState.regFormSubmissionAnswerHistoryDates[
        formState.selectedHistoryDateIndex
      ]
    : null;
  const selectedHistory =
    regFormSubmission &&
    formState.selectedHistoryDateIndex ===
      formState.regFormSubmissionAnswerHistoryDates.length
      ? { modifiedBy: { name: regFormSubmission.user.name } }
      : submissionAnswerHistory.find(
          (h) => h.createdAt === selectedHistoryDate
        );

  const addOnProductsPrice = getFormSubmissionAddonProductsPrice(
    sections,
    formState.regFormSubmissionAnswers,
    formState.divisionRoles
  );
  const addOnsPriceDifference =
    addOnProductsPrice - formState.addOnProductsPrice;

  const sortedSections = sections.sort(
    (a, b) => a.positionOrder - b.positionOrder
  );

  const regAssociation =
    regFormSubmission?.regAssociation ||
    form?.regAssociationDivisionForms[0]?.regProduct.regAssociation;

  useEffect(() => {
    if (regFormSubmission) {
      // eslint-disable-next-line no-use-before-define
      resetFormSubmissionAnswers();
    }
    else {
      setFormState({
        financialAssistanceId: '',
        isFinancialAssistanceEnabled: false,
        regFormSubmissionAnswers: [],
        regFormSubmissionExternalIds: [],
        regFormSubmissionAnswerHistoryDates: [],
        includeRegPersonContacts: false,
        regFormSubmissionPersonContacts: [],
        selectedHistoryDateIndex: 0,
        regPerson: null,
        divisionRoles: [],
        showConfirmPriceChangeModal: false,
        confirmPriceChangeValue: '',
        showInvoiceModal: false,
        doNotChargeForChanges: false,
        errors: {},
        error: '',
        isAdminEdit: false
      });
    }
  }, []);

  const handleRegFormControlChange = (fkRegFormControl, answer) => {
    const regPerson = { ...formState.regPerson };

    const section = sections.find((s) =>
      s.regFormControls.some((c) => c.pkRegFormControl === fkRegFormControl)
    );
    if (section?.isManaged) {
      if (!isViewerAdmin) {
        return;
      }

      const control = section.regFormControls.find(
        (c) => c.pkRegFormControl === fkRegFormControl
      );
      switch (control.title) {
        case 'First Name':
          regPerson.firstName = answer;
          break;
        case 'Middle Name':
          regPerson.middleName = answer;
          break;
        case 'Last Name':
          regPerson.lastName = answer;
          break;
        case 'Date of Birth':
          regPerson.dob = answer;
          break;
        case 'Gender':
          regPerson.gender = answer;
          break;
        case 'Address 1':
          regPerson.address1 = answer;
          break;
        case 'Address 2':
          regPerson.address2 = answer;
          break;
        case 'Country':
          regPerson.country = answer;
          break;
        case 'Province':
        case 'State':
          regPerson.province = answer;
          break;
        case 'City':
          regPerson.city = answer;
          break;
        case 'Postal Code':
          regPerson.postalCode = answer;
          break;
        default:
          break;
      }
    }

    setFormState({
      ...formState,
      regPerson,
      regFormSubmissionAnswers: [
        ...formState.regFormSubmissionAnswers.filter(
          (s) => s.fkRegFormControl !== fkRegFormControl
        ),
        {
          fkRegFormControl,
          answer
        }
      ],
      errors: {},
      error: ''
    });
  };

  const autoFillAnswer = (title) => {
    switch (title) {
      case 'First Name':
        return regFormSubmission.regPerson.firstName;
      case 'Middle Name':
        return regFormSubmission.regPerson.middleName;
      case 'Last Name':
        return regFormSubmission.regPerson.lastName;
      case 'Date of Birth':
        return regFormSubmission.regPerson.dob;
      case 'Gender':
        return regFormSubmission.regPerson.gender;
      case 'Address 1':
        return regFormSubmission.regPerson.address1;
      case 'Address 2':
        return regFormSubmission.regPerson.address2;
      case 'Country':
        return regFormSubmission.regPerson.country;
      case 'Province':
      case 'State':
        return regFormSubmission.regPerson.province;
      case 'City':
        return regFormSubmission.regPerson.city;
      case 'Postal Code':
        return regFormSubmission.regPerson.postalCode;
      default:
        return '';
    }
  };

  const resetFormSubmissionAnswers = (
    isAdminEdit = false,
    historyDateIndex = 0
  ) => {
    const managedSections = sections.filter((s) => s.isManaged);
    const historyDates = [
      ...new Set(submissionAnswerHistory.map((c) => c.createdAt))
    ].sort((a, b) => new Date(b) - new Date(a));
    const selectedHisoryDate = historyDates[historyDateIndex - 1]
      ? new Date(historyDates[historyDateIndex - 1])
      : null;

    managedSections.forEach((section) => {
      section.regFormControls.forEach((control) => {
        const existingAnswer = submissionAnswers.find(
          (c) => c.fkRegFormControl === control.pkRegFormControl
        );
        if (!existingAnswer) {
          submissionAnswers.push({
            fkRegFormControl: control.pkRegFormControl,
            answer: autoFillAnswer(control.title)
          });
        }
      });
    });

    const formStateDivisionRoles = isDisabled
      ? regFormSubmission.regFormSubmissionRoleAnswers
      : regFormSubmission.regFormSubmissionRoleAnswers.filter((r) =>
          availableRoles.some((a) => a.name === r.role)
        );

    setFormState({
      financialAssistanceId:
        regFormSubmission.financialAssistance?.financialAssistanceId,
      isFinancialAssistanceEnabled: !!regFormSubmission.financialAssistance,
      regFormSubmissionAnswers: submissionAnswers.map((a) => {
        const historyValue = selectedHisoryDate
          ? a.regFormSubmissionAnswerHistory
              ?.filter((h) => new Date(h.createdAt) >= selectedHisoryDate)
              .sort(
                (a1, b1) => new Date(a1.createdAt) - new Date(b1.createdAt)
              )[0]?.oldValue
          : a.answer;

        return {
          ...a,
          answer: historyValue ?? a.answer
        };
      }),
      regFormSubmissionExternalIds: regFormSubmission.regFormSubmissionExternalIds?.map(
        (e) => ({ ...e, skipConnection: !e.value })
      ),
      regFormSubmissionAnswerHistoryDates: historyDates,
      includeRegPersonContacts:
        regFormSubmission?.regFormSubmissionPersonContacts?.length > 0,
      regFormSubmissionPersonContacts:
        regFormSubmission?.regFormSubmissionPersonContacts?.map((c) => ({
          ...c,
          expandAccordion: false,
          deleted: false
        })) || [],
      selectedHistoryDateIndex: historyDateIndex,
      divisionRoles: formStateDivisionRoles,
      regPerson: regFormSubmission.regPerson,
      addOnProductsPrice: getFormSubmissionAddonProductsPrice(
        sections,
        submissionAnswers,
        formStateDivisionRoles
      ),
      showConfirmPriceChangeModal: false,
      confirmPriceChangeValue: '',
      doNotChargeForChanges: false,
      errors: {},
      error: '',
      isAdminEdit
    });
  };

  return (
    <AppStack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        let formError = '';
        let errors = {};
        for (const section of sections) {
          for (const control of section.regFormControls) {
            if (control.type === 'SIGNATURE') {
              const requiredContraint = control.constraints.find(
                (c) => c.name === 'required'
              );
              if (requiredContraint && requiredContraint.value === 'true') {
                const answer = formState.regFormSubmissionAnswers.find(
                  (a) => a.fkRegFormControl === control.pkRegFormControl
                );
                if (!answer?.answer) {
                  formError = 'Signature is required.';
                  break;
                }
              }
            }
            else if (control.type === 'FILE_UPLOAD') {
              const requiredContraint = control.constraints.find(
                (c) => c.name === 'required'
              );
              if (requiredContraint && requiredContraint.value === 'true') {
                const answer = formState.regFormSubmissionAnswers.find(
                  (a) => a.fkRegFormControl === control.pkRegFormControl
                );
                if (!answer?.answer) {
                  formError = 'File Upload is required.';
                  break;
                }
              }
            }
            else if (control.type === 'DATE') {
              const requiredContraint = control.constraints.find(
                (c) => c.name === 'required'
              );
              if (requiredContraint && requiredContraint.value === 'true') {
                const answer = formState.regFormSubmissionAnswers.find(
                  (a) => a.fkRegFormControl === control.pkRegFormControl
                );
                if (!answer?.answer) {
                  formError = 'Invalid Date';
                  errors = {
                    [control.pkRegFormControl]: { value: 'Invalid Date' }
                  };
                  break;
                }
              }
            }
            else if (control.type === 'RADIO_GROUP') {
              const requiredContraint = control.constraints.find(
                (c) => c.name === 'required'
              );
              if (requiredContraint && requiredContraint.value === 'true') {
                const answer = formState.regFormSubmissionAnswers.find(
                  (a) => a.fkRegFormControl === control.pkRegFormControl
                );
                if (!answer?.answer) {
                  formError = `${control.title} is required.`;
                  errors = {
                    [control.pkRegFormControl]: { value: 'Input Required.' }
                  };
                  break;
                }
              }
            }
          }

          if (formError) {
            break;
          }
        }

        if (formError) {
          setFormState({
            ...formState,
            errors,
            error: formError
          });
        }
        else if (
          isViewerAdmin &&
          regFormSubmission &&
          addOnsPriceDifference !== 0
        ) {
          setFormState({
            ...formState,
            showConfirmPriceChangeModal: true,
            confirmPriceChangeValue: ''
          });
        }
        else {
          onSubmit({
            ...formState,
            regFormSubmissionExternalIds: formState.regFormSubmissionExternalIds.map(
              (i) => ({
                ...i,
                clearValue: i.skipConnection
              })
            ),
            regFormSubmissionPersonContacts: formState.regFormSubmissionPersonContacts.filter(
              (c) => !c.deleted || !!c.pkRegFormSubmissionPersonContact
            )
          });
        }
      }}
    >
      {isEditable && (
        <AppFlexbox style={{ justifyContent: 'center' }}>
          <AppTitle>{form.name}</AppTitle>
        </AppFlexbox>
      )}

      {sortedSections
        .filter((f) => f.isManaged)
        .map((s) => (
          <RegistrationFormSection
            key={s.pkRegFormSection}
            editComponentState={editComponentState}
            errors={formState.errors}
            formState={formState}
            isDisabled={
              isLoading ||
              (s.isManaged && (!isViewerAdmin || isEditable)) ||
              isFormControlsDisabled
            }
            isEditable={isEditable}
            isViewable={isViewable}
            isViewerAdmin={isViewerAdmin}
            onInputChange={handleRegFormControlChange}
            onMouseMove={onMouseMove}
            onMoveControl={onMoveControl}
            onResizeControl={onResizeControl}
            onSelectControl={onSelectControl}
            onStopMovingControl={onStopMovingControl}
            onStopResizingControl={onStopResizingControl}
            regFormSubmission={regFormSubmission}
            regFormSubmissionAnswers={formState.regFormSubmissionAnswers}
            section={s}
            setFormState={setFormState}
          />
        ))}

      {regAssociation?.regAssociationExternalConnections
        ?.filter(
          (c) =>
            !form.regFormExternalConnections.find(
              (e) =>
                e.regAssociationExternalConnection
                  .pkRegAssociationExternalConnection ===
                c.pkRegAssociationExternalConnection
            )?.isExcluded
        )
        .map((c) => (
          <ExternalConnectionFormSection
            key={c.pkRegAssociationExternalConnection}
            formState={formState.regFormSubmissionExternalIds.find(
              (e) =>
                e.fkRegAssociationExternalConnection ===
                c.pkRegAssociationExternalConnection
            )}
            isDisabled={isLoading || isFormControlsDisabled || isEditable}
            isEditorView={isEditable}
            onChange={(connectionState) => {
              setFormState({
                ...formState,
                regFormSubmissionExternalIds: [
                  ...formState.regFormSubmissionExternalIds.filter(
                    (e) =>
                      e.fkRegAssociationExternalConnection !==
                      c.pkRegAssociationExternalConnection
                  ),
                  {
                    ...connectionState,
                    fkRegAssociationExternalConnection:
                      c.pkRegAssociationExternalConnection
                  }
                ]
              });
            }}
            regAssociation={regAssociation}
            regAssociationExternalConnection={c}
          />
        ))}

      {sortedSections
        .filter((f) => !f.isManaged)
        .map((s) => (
          <RegistrationFormSection
            key={s.pkRegFormSection}
            editComponentState={editComponentState}
            errors={formState.errors}
            isDisabled={
              isLoading ||
              (s.isManaged && (!isViewerAdmin || isEditable)) ||
              isFormControlsDisabled
            }
            isEditable={isEditable}
            isViewable={isViewable}
            isViewerAdmin={isViewerAdmin}
            onInputChange={handleRegFormControlChange}
            onMouseMove={onMouseMove}
            onMoveControl={onMoveControl}
            onResizeControl={onResizeControl}
            onSelectControl={onSelectControl}
            onStopMovingControl={onStopMovingControl}
            onStopResizingControl={onStopResizingControl}
            regFormSubmissionAnswers={formState.regFormSubmissionAnswers}
            section={s}
          />
        ))}

      {regFormSubmission && form.regFormFinancialAssistance && (
        <RegistrationFormFinancialAssistanceSection
          financialAssistanceId={formState.financialAssistanceId}
          isChecked={formState.isFinancialAssistanceEnabled}
          isDisabled={isLoading || isFormControlsDisabled || isEditable}
          onChange={(financialAssistanceId) =>
            setFormState({
              ...formState,
              financialAssistanceId
            })
          }
          onCheck={(isChecked) =>
            setFormState({
              ...formState,
              isFinancialAssistanceEnabled: isChecked
            })
          }
        />
      )}
      {availableRoles && availableRoles.length > 0 && (
        <RegistrationFormRoleSection
          availableRoles={availableRoles}
          divisionRoles={divisionRoles}
          isDisabled={isFormControlsDisabled || isEditable}
          isLoading={isLoading}
          isRequired={
            regFormSubmission?.regAssociationDivisionForm.regAssociationDivision
              .rolesRequired ?? true
          }
          onSelectRole={(role) => {
            if (formState.divisionRoles[0]?.role === role) {
              setFormState({
                ...formState,
                divisionRoles: []
              });
            }
            else {
              const divisionRole = divisionRoles.find((r) => r.name === role);
              setFormState({
                ...formState,
                divisionRoles: [{ ...divisionRole, role: divisionRole.name }]
              });
            }
          }}
          selectedRoles={formState.divisionRoles}
        />
      )}

      {showMerchPackageShowcase &&
        regFormSubmission &&
        merchPackageRegAssociation?.merchEnabled && (
          <MerchPackageShowcase
            analyticCta={
              ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.FORM_SUBMISSION_FOOTER
            }
            extraPadding
            hideNoMerch
            includeUrgencyWarning
            regAssociationOptions={[merchPackageRegAssociation]}
          />
        )}

      {regFormSubmission &&
        formState.regFormSubmissionAnswerHistoryDates.length > 0 &&
        selectedHistory && (
          <AppFlexbox style={{ justifyContent: 'center', marginTop: 20 }}>
            <AppStack style={{ gap: 0 }}>
              <AppText weight={500}>Change History </AppText>
              <AppText>
                {selectedHistory.modifiedBy.name} (
                {selectedHistory.createdAt
                  ? dayjs(formatUtcDate(selectedHistory.createdAt)).format(
                      'MM/DD/YYYY h:mm a'
                    )
                  : 'Original Entry'}
                )
              </AppText>
              <AppFlexbox
                style={{
                  gap: 40,
                  marginTop: 10,
                  justifyContent: 'space-between'
                }}
              >
                <Button
                  color="dark"
                  disabled={formState.selectedHistoryDateIndex === 0}
                  onClick={() =>
                    resetFormSubmissionAnswers(
                      false,
                      formState.selectedHistoryDateIndex - 1
                    )
                  }
                  variant="outline"
                >
                  <ArrowLeft />
                </Button>

                <Button
                  color="dark"
                  disabled={
                    formState.selectedHistoryDateIndex ===
                    formState.regFormSubmissionAnswerHistoryDates.length
                  }
                  onClick={() =>
                    resetFormSubmissionAnswers(
                      false,
                      formState.selectedHistoryDateIndex + 1
                    )
                  }
                  variant="outline"
                >
                  <ArrowRight />
                </Button>
              </AppFlexbox>
            </AppStack>
          </AppFlexbox>
        )}

      {isEditable ? (
        <AppFlexbox
          style={{
            justifyContent: 'center',
            margin: 20,
            gap: 0
          }}
        >
          <Button
            color="green"
            leftSection={<Plus />}
            onClick={onOpenFormSectionModal}
            size="md"
            style={{ fontWeight: 'bold' }}
            variant="outline"
          >
            New Section
          </Button>
        </AppFlexbox>
      ) : isDisabled && !formState.isAdminEdit ? (
        <AppStack style={{ marginTop: 20, gap: 30 }}>
          <Divider />
          <AppFlexbox
            style={{
              justifyContent: 'center',
              gap: 25,
              paddingLeft: 15,
              paddingRight: 15
            }}
          >
            <Button
              color="dark"
              onClick={onCancel}
              style={{ flex: 1, maxWidth: 200 }}
              type="button"
            >
              Close
            </Button>
            {isViewerAdmin && (
              <Button
                color="blue"
                onClick={() => resetFormSubmissionAnswers(true)}
                style={{ flex: 1, maxWidth: 200 }}
                type="button"
              >
                Edit
              </Button>
            )}
          </AppFlexbox>
        </AppStack>
      ) : (
        <AppStack style={{ marginTop: 20, gap: 30 }}>
          {(error || formState.error) && (
            <AppFlexbox style={{ justifyContent: 'center' }}>
              <Alert
                color="red"
                icon={<AlertCircle size={16} />}
                radius="xs"
                variant="filled"
              >
                {formState.error ?? error}
              </Alert>
            </AppFlexbox>
          )}
          <Divider />

          {isLoading ? (
            <Loader color="dark" size="lg" style={{ margin: 'auto' }} />
          ) : (
            <AppFlexbox
              style={{
                justifyContent: 'center',
                gap: 25,
                paddingLeft: 15,
                paddingRight: 15
              }}
            >
              {formState.isAdminEdit ? (
                <Button
                  color="dark"
                  onClick={() => resetFormSubmissionAnswers(false)}
                  style={{ flex: 1, maxWidth: 200 }}
                  type="button"
                >
                  Reset
                </Button>
              ) : (
                <Button
                  color="dark"
                  onClick={onCancel}
                  style={{ flex: 1, maxWidth: 200 }}
                  type="button"
                >
                  Cancel
                </Button>
              )}

              <Button
                color="blue"
                style={{ flex: 1, maxWidth: 200 }}
                type="submit"
              >
                {isViewerAdmin && addOnsPriceDifference > 0
                  ? `Increase by ${currencyFormat(addOnsPriceDifference)}`
                  : isViewerAdmin && addOnsPriceDifference < 0
                  ? `Decrease by ${currencyFormat(addOnsPriceDifference)}`
                  : 'Save Changes'}
              </Button>
            </AppFlexbox>
          )}
        </AppStack>
      )}

      <ResponsiveModal
        isOpen={!!formState.showConfirmPriceChangeModal}
        onClose={() =>
          setFormState({
            ...formState,
            showConfirmPriceChangeModal: false
          })
        }
        size={600}
        title={<AppTitle order={3}>Confirm Price Change</AppTitle>}
      >
        <FormSection
          isLoading={isLoading}
          isSubmitDisabled={
            addOnsPriceDifference > 0 &&
            !formState.confirmPriceChangeValue &&
            !formState.doNotChargeForChanges
          }
          onCancel={() =>
            setFormState({
              ...formState,
              showConfirmPriceChangeModal: false
            })
          }
          onSubmit={() => {
            onSubmit(
              formState,
              addOnsPriceDifference > 0
                ? {
                    increaseCost: addOnsPriceDifference,
                    paymentMethod: formState.confirmPriceChangeValue,
                    doNotChargeForChanges: formState.doNotChargeForChanges
                  }
                : { decreaseCost: addOnsPriceDifference }
            );
          }}
          submitTitle="Save Changes"
        >
          {addOnsPriceDifference > 0 ? (
            <AppStack>
              <Alert variant="outline">
                <AppStack style={{ gap: 10 }}>
                  <AppText weight={500}>
                    Updating this form will increase it's price by{' '}
                    {currencyFormat(addOnsPriceDifference)}. The registrant will
                    be changed to 'Pending Payment' until you collect an
                    additional payment.
                  </AppText>
                  <AppText>
                    Please confirm the price change by selecting below how to
                    intend to collect payment.
                  </AppText>
                  <AppText color="grey" style={{ fontStyle: 'italic' }}>
                    Tax and processing fees will be caluclated separately.
                  </AppText>
                </AppStack>
              </Alert>

              {addOnsPriceDifference < 10 && (
                <Alert color="yellow" variant="outline">
                  <AppText weight={500}>
                    Invoice amount is less than $10.00. Please collect payment
                    offline.
                  </AppText>
                </Alert>
              )}
              <Checkbox
                checked={formState.doNotChargeForChanges}
                disabled={isLoading}
                label="Do not charge for this change"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    doNotChargeForChanges: e.target.checked
                  })
                }
                style={{ marginTop: 10, fontWeight: 500 }}
              />
              {!formState.doNotChargeForChanges && (
                <Select
                  data={[
                    {
                      value: 'invoice',
                      label: 'Invoice',
                      disabled: addOnsPriceDifference < 10
                    },
                    {
                      value: 'offline',
                      label: 'Offline Payment'
                    }
                  ]}
                  disabled={isLoading}
                  label="Payment Method"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      confirmPriceChangeValue: value
                    })
                  }
                  placeholder="Select Payment Method"
                  value={formState.confirmPriceChangeValue}
                />
              )}
            </AppStack>
          ) : (
            <AppStack>
              <Alert color="red" variant="outline">
                <AppStack style={{ gap: 5 }}>
                  <AppText weight={500}>
                    Updating this form will decrease it's price by{' '}
                    {currencyFormat(addOnsPriceDifference)}.
                  </AppText>
                  <AppText style={{ color: 'grey', fontStyle: 'italic' }}>
                    You will have to manually refund any difference.
                  </AppText>
                </AppStack>
              </Alert>
            </AppStack>
          )}
        </FormSection>
      </ResponsiveModal>
    </AppStack>
  );
};

RegistrationForm.propTypes = {
  assignedRoles: PropTypes.array,
  divisionRoles: PropTypes.array,
  editComponentState: PropTypes.object,
  error: PropTypes.string,
  form: PropTypes.object,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isViewable: PropTypes.bool,
  isViewerAdmin: PropTypes.bool,
  merchPackageRegAssociation: PropTypes.object,
  onCancel: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMoveControl: PropTypes.func,
  onOpenFormSectionModal: PropTypes.func,
  onResizeControl: PropTypes.func,
  onSelectControl: PropTypes.func,
  onStopMovingControl: PropTypes.func,
  onStopResizingControl: PropTypes.func,
  onSubmit: PropTypes.func,
  regFormSubmission: PropTypes.object,
  sections: PropTypes.array,
  showMerchPackageShowcase: PropTypes.bool
};

export default RegistrationForm;
