import React from 'react';
import { Checkbox, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import classes from '../../../styles/nestedStyles.module.css';

const BulkEditColumnHeader = ({
  label,
  subLabel,
  loading,
  firstPosition,
  selected,
  onSelect
}) => (
  <AppFlexbox
    className={classes['bulk-edit-header']}
    style={
      firstPosition
        ? { left: 0, zIndex: 1002, width: 350, maxWidth: 350 }
        : { zIndex: 1001 }
    }
  >
    {loading ? (
      <Skeleton height={18} width="50%" />
    ) : (
      <>
        <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
          {firstPosition && (
            <Checkbox checked={selected} onChange={() => onSelect()} />
          )}
          <AppText style={{ fontSize: 14, fontWeight: 650 }}>{label}</AppText>
        </AppFlexbox>

        {subLabel && (
          <AppText
            style={{
              fontSize: 14,
              color: '#666',
              fontWeight: 400
            }}
          >
            {subLabel}
          </AppText>
        )}
      </>
    )}
  </AppFlexbox>
);

BulkEditColumnHeader.propTypes = {
  firstPosition: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  subLabel: PropTypes.string
};

export default BulkEditColumnHeader;
