import React, { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { createRoot } from 'react-dom/client';
import ResponsiveModal from '../components/common/ResponsiveModal';
import { theme } from '../styles/theme';
import AppText from '../components/common/AppText';
import AppTitle from '../components/common/AppTitle';

const useConfirmExit = (confirmExit, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const { push } = navigator;

    navigator.push = (...args) => {
      const confirm = () => push(...args);
      confirmExit(confirm);
    };

    // eslint-disable-next-line consistent-return
    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
};

export const usePrompt = (message, when) => {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => message;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(
    (confirm) => {
      const element = document.createElement('div');
      element.setAttribute('id', 'prompt-dialog-container');
      element.setAttribute('aria-hidden', 'true');
      document.body.appendChild(element);
      const root = createRoot(element);

      const closePrompt = (state) => {
        if (element) {
          root.unmount();
        }
        if (!state) {
          document.body.removeChild(element);
        }
        else {
          confirm();
        }
      };

      root.render(
        <MantineProvider theme={theme}>
          <ResponsiveModal
            formSectionProps={{
              cancelTitle: 'Stay on page',
              onCancel: () => closePrompt(false),
              onSubmit: (e) => {
                e.stopPropagation();
                closePrompt(true);
              },
              style: { gap: 30, marginTop: 20 },
              submitColor: 'red',
              submitTitle: 'Leave Without Saving'
            }}
            onClose={() => closePrompt(false)}
            opened
            size={600}
            title={<AppTitle order={3}>Leave without saving?</AppTitle>}
          >
            <AppText style={{ textAlign: 'center' }}>{message}</AppText>
          </ResponsiveModal>
        </MantineProvider>
      );
    },
    [message]
  );

  useConfirmExit(confirmExit, when);
};
