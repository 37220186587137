import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Badge,
  Button,
  Divider,
  Menu,
  Skeleton
} from '@mantine/core';
import dayjs from 'dayjs';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Coin,
  CreditCard,
  DotsVertical,
  FileSearch,
  Mail,
  Receipt,
  Send,
  ShoppingCart,
  Tournament,
  X
} from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import PageView from '../../../../components/common/PageView';
import AppStack from '../../../../components/common/AppStack';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import { useMediaQueries, useModalState } from '../../../../helpers/hooks';
import { formatUtcDate } from '../../../../helpers/format';
import PlayerInfoCard from '../players/PlayerInfoCard';
import MenuContent from '../../../../components/common/MenuContent';
import PaymentSummaryInfoCard from '../payments/PaymentSummaryInfoCard';
import RegistrationLeagueInfoCard from './RegistrationLeagueInfoCard';
import ActionableIcon from '../../../../components/common/ActionableIcon';
import RegistrationListActionModals from '../../../../components/content/financials/RegistrationListActionModals';
import {
  REG_RESOURCE_TYPE_ENUM,
  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM,
  REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM_VALUES
} from '../../../../config/constants';
import NoteTimelineDisplay from '../../../../components/common/NoteTimelineDisplay';
import TaskListInfoCard from '../taskList/TaskListInfoCard';
import RegistrationExternalConnectionInfoCard from './RegistrationExternalConnectionInfoCard';

const RegistrationDetailsView = ({ loading, regFormSubmission }) => {
  const { isDesktopOrSmaller } = useMediaQueries();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const submission = regFormSubmission || {};
  const { balance: checkoutBalance } = submission;

  const isPendingPayment =
    submission.status === 'PENDING' || submission.status === 'PENDING_PAYMENT';

  const viewLoading = loading || !regFormSubmission;

  const balanceInvoices =
    checkoutBalance?.invoices.filter(
      (i) =>
        i.isAdditionalCharge &&
        (i.invoiceStatus.toString() ===
          REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM_VALUES.PENDING ||
          i.invoiceStatus.toString() ===
            REG_FORM_SUBMISSION_INVOICE_STATUS_ENUM_VALUES.PAID)
    ) || [];

  const invoiceTotals = checkoutBalance
    ? balanceInvoices.reduce(
        (r, c) => ({
          totalInCents: r.totalInCents + c.totalInCents,
          amountPaidInCents: r.amountPaidInCents + c.amountPaidInCents,
          balanceInCents:
            r.balanceInCents +
            (c.totalInCents - c.amountPaidInCents - c.forgivenessAmountInCents),
          forgivenessAmountInCents:
            r.forgivenessAmountInCents + c.forgivenessAmountInCents,
          refundedAmountInCents:
            r.refundedAmountInCents + c.refundedAmountInCents,
          serviceFeeInCents: r.serviceFeeInCents + c.serviceFeeInCents
        }),
        {
          totalInCents: 0,
          amountPaidInCents: 0,
          refundedAmountInCents: 0,
          balanceInCents: 0,
          forgivenessAmountInCents: 0,
          serviceFeeInCents: 0
        }
      )
    : {
        totalInCents: 0,
        amountPaidInCents: 0,
        balanceInCents: 0,
        forgivenessAmountInCents: 0,
        refundedAmountInCents: 0,
        serviceFeeInCents: 0
      };

  const statusInfo =
    submission.status === 'ACCEPTED'
      ? {
          color: 'green',
          label: 'ACCEPTED'
        }
      : submission.status === 'DENIED'
      ? {
          color: 'red',
          label: 'DENIED'
        }
      : submission.status === 'REFUNDED'
      ? {
          color: 'gray',
          label: 'REFUNDED'
        }
      : {
          color: 'blue',
          label: 'PENDING'
        };

  return !viewLoading ? (
    <PageView
      backPath="/admin/financials/registrations"
      badgeContent={
        <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
          <Badge color={statusInfo.color} variant="filled">
            {statusInfo.label}
          </Badge>
        </AppFlexbox>
      }
      buttonAction={
        <MenuContent
          control={
            <Button
              color="dark"
              rightSection={<ChevronDown size={18} />}
              size="compact-md"
              style={{ fontSize: 14, flex: 1 }}
              variant="light"
            >
              Actions
            </Button>
          }
        >
          <Menu.Item
            leftSection={<FileSearch size={18} />}
            onClick={() => {
              onOpenModal(
                REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.view,
                regFormSubmission
              );
            }}
          >
            View Form
          </Menu.Item>
          <Menu.Item
            leftSection={<ShoppingCart size={18} />}
            onClick={() => {
              onOpenModal(
                REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.orderSummary,
                regFormSubmission
              );
            }}
          >
            Order Summary
          </Menu.Item>
          <Menu.Item
            component="a"
            href={`mailto:${submission.overrideEmail ?? submission.user.email}`}
            leftSection={<Send size={18} />}
          >
            Email Contact
          </Menu.Item>
          <Menu.Item
            leftSection={<Mail size={18} />}
            onClick={() => {
              onOpenModal(
                REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeOverrideEmail,
                regFormSubmission
              );
            }}
          >
            Change email
          </Menu.Item>
          {submission.status !== 'REFUNDED' && (
            <Menu.Item
              leftSection={<Tournament size={18} />}
              onClick={() => {
                onOpenModal(
                  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.changeDivision,
                  regFormSubmission
                );
              }}
            >
              Change Division
            </Menu.Item>
          )}
          <Divider />
          {isPendingPayment ? (
            <Menu.Item
              leftSection={<Coin size={18} />}
              onClick={() => {
                onOpenModal(
                  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
                  regFormSubmission
                );
              }}
            >
              Record Payment
            </Menu.Item>
          ) : (
            <Menu.Item
              leftSection={<Receipt size={18} />}
              onClick={() => {
                onOpenModal(
                  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
                  regFormSubmission
                );
              }}
            >
              View Transactions
            </Menu.Item>
          )}
          {submission.status !== 'REFUNDED' && (
            <Menu.Item
              leftSection={<CreditCard size={18} />}
              onClick={() => {
                onOpenModal(
                  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.invoice,
                  regFormSubmission
                );
              }}
            >
              Invoice
            </Menu.Item>
          )}

          {submission.status === 'DENIED' && (
            <Menu.Item
              leftSection={<Check size={18} />}
              onClick={() => {
                onOpenModal(
                  REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.reinstate,
                  regFormSubmission
                );
              }}
            >
              Reinstate
            </Menu.Item>
          )}

          {submission.status === 'ACCEPTED' && (
            <>
              <Menu.Divider />
              {(isPendingPayment || submission.status === 'ACCEPTED') && (
                <Menu.Item
                  leftSection={<X color="#c40000" size={18} />}
                  onClick={() => {
                    onOpenModal(
                      REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.deny,
                      regFormSubmission
                    );
                  }}
                  style={{ color: '#c40000' }}
                >
                  Deny
                </Menu.Item>
              )}

              {submission.status === 'ACCEPTED' && (
                <Menu.Item
                  leftSection={<CreditCard color="#c40000" size={18} />}
                  onClick={() => {
                    onOpenModal(
                      REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.refund,
                      regFormSubmission
                    );
                  }}
                  style={{ color: '#c40000' }}
                >
                  Refund
                </Menu.Item>
              )}
            </>
          )}
        </MenuContent>
      }
      subTitle={`Registered 
        ${dayjs(formatUtcDate(regFormSubmission.submittedAt)).format(
          'MMMM D, YYYY'
        )}
        at 
        ${dayjs(formatUtcDate(regFormSubmission.submittedAt)).format(
          'h:mm A'
        )}`}
      title={`${regFormSubmission.regPerson.firstName} ${regFormSubmission.regPerson.lastName} - ${regFormSubmission.regAssociationDivisionForm.regForm.name}`}
      width={1050}
    >
      <AppStack>
        <AppFlexbox
          style={{
            flex: 1,
            flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row'
          }}
        >
          <AppStack style={{ flex: 1 }}>
            <PaymentSummaryInfoCard
              actionMenu={
                <MenuContent
                  control={
                    <ActionIcon color="dark" variant="subtle">
                      <DotsVertical />
                    </ActionIcon>
                  }
                >
                  {isPendingPayment ? (
                    <Menu.Item
                      leftSection={<Coin size={18} />}
                      onClick={() => {
                        onOpenModal(
                          REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
                          regFormSubmission
                        );
                      }}
                    >
                      Record Payment
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      leftSection={<Receipt size={18} />}
                      onClick={() => {
                        onOpenModal(
                          REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.viewPayments,
                          regFormSubmission
                        );
                      }}
                    >
                      View Transactions
                    </Menu.Item>
                  )}
                  {submission.status !== 'REFUNDED' && (
                    <Menu.Item
                      leftSection={<CreditCard size={18} />}
                      onClick={() => {
                        onOpenModal(
                          REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.invoice,
                          regFormSubmission
                        );
                      }}
                    >
                      Invoice
                    </Menu.Item>
                  )}

                  {submission.status === 'ACCEPTED' && (
                    <>
                      <Menu.Divider />
                      {(isPendingPayment ||
                        submission.status === 'ACCEPTED') && (
                        <Menu.Item
                          leftSection={<X color="#c40000" size={18} />}
                          onClick={() => {
                            onOpenModal(
                              REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.deny,
                              regFormSubmission
                            );
                          }}
                          style={{ color: '#c40000' }}
                        >
                          Deny
                        </Menu.Item>
                      )}

                      {submission.status === 'ACCEPTED' && (
                        <Menu.Item
                          leftSection={<CreditCard color="#c40000" size={18} />}
                          onClick={() => {
                            onOpenModal(
                              REG_FORM_SUBMISSION_MODAL_ACTIONS_ENUM.refund,
                              regFormSubmission
                            );
                          }}
                          style={{ color: '#c40000' }}
                        >
                          Refund
                        </Menu.Item>
                      )}
                    </>
                  )}
                </MenuContent>
              }
              payments={[
                {
                  key: 1,
                  group: 1,
                  label: 'Registration',
                  color: '#666',
                  description: checkoutBalance.productName,
                  value: checkoutBalance.subtotalInCents / 100
                },
                ...(checkoutBalance.coupons.length > 0
                  ? [
                      {
                        key: 2,
                        group: 1,
                        label: 'Discount',
                        color: '#666',
                        description: 'Discount details',
                        value: checkoutBalance.discountInCents / 100,
                        children: checkoutBalance.coupons.map((c) => ({
                          key: c.entityId,
                          label: c.couponName,
                          color: '#666',
                          value: c.unitAmountInCents / 100
                        }))
                      },
                      {
                        key: 1,
                        group: 2,
                        label: 'Subtotal',
                        weight: 500,
                        description: '',
                        value: checkoutBalance.discountedSubtotalInCents / 100
                      }
                    ]
                  : []),

                ...(checkoutBalance.taxInCents > 0
                  ? [
                      {
                        key: 3,
                        group: checkoutBalance.coupons?.length > 0 ? 2 : 1,
                        label: `Taxes (${checkoutBalance.taxRate * 100}%)`,
                        color: '#666',
                        description: '-',
                        value: checkoutBalance.taxInCents / 100
                      }
                    ]
                  : []),

                ...(checkoutBalance.customerPaysServiceFees &&
                checkoutBalance.serviceFeeInCents > 0
                  ? [
                      {
                        key: 4,
                        group: checkoutBalance.coupons?.length > 0 ? 2 : 1,
                        label: 'Service Fee',
                        color: '#666',
                        description: '-',
                        value: checkoutBalance.serviceFeeInCents / 100
                      }
                    ]
                  : []),

                ...(balanceInvoices.length > 0
                  ? [
                      {
                        key: 4,
                        group: checkoutBalance.coupons?.length > 0 ? 2 : 1,
                        label: 'Invoices',
                        color: '#666',
                        description: 'Invoice details',
                        value: invoiceTotals.totalInCents / 100,
                        children: balanceInvoices.map((i) => ({
                          key: i.entityId,
                          label: i.description,
                          color: '#666',
                          value: i.totalInCents / 100
                        }))
                      }
                    ]
                  : []),
                {
                  key: 5,
                  group: 3,
                  label: 'Total',
                  weight: 500,
                  description: '',
                  value:
                    (checkoutBalance.totalInCents +
                      invoiceTotals.totalInCents) /
                    100
                },
                {
                  key: 6,
                  group: 3,
                  label: 'Amount Paid',
                  color: '#067D62',
                  description:
                    balanceInvoices.length > 0 ? 'Payment details' : '',
                  value:
                    (checkoutBalance.amountPaidInCents +
                      invoiceTotals.amountPaidInCents) /
                    100,
                  children: [
                    {
                      key: 'main-payment',
                      label: 'Registration',
                      color: '#666',
                      value: checkoutBalance.amountPaidInCents / 100
                    },
                    ...(balanceInvoices.length > 0
                      ? [
                          ...balanceInvoices.map((i) => ({
                            key: i.entityId,
                            label: i.description,
                            color: '#666',
                            value: i.amountPaidInCents / 100
                          }))
                        ]
                      : [])
                  ]
                },
                ...(invoiceTotals.refundedAmountInCents +
                  checkoutBalance.refundedAmountInCents >
                0
                  ? [
                      {
                        key: 7,
                        group: 3,
                        label: 'Refunded',
                        color: '#C40000',
                        description: 'Refund details',
                        value:
                          ((invoiceTotals.refundedAmountInCents +
                            checkoutBalance.refundedAmountInCents) /
                            100) *
                          -1,
                        children:
                          balanceInvoices.length > 0
                            ? [
                                {
                                  key: 'main-payment',
                                  label: 'Registration',
                                  color: '#C40000',
                                  value:
                                    (checkoutBalance.refundedAmountInCents /
                                      100) *
                                    -1
                                },
                                ...balanceInvoices.map((i) => ({
                                  key: i.entityId,
                                  label: i.description,
                                  color: '#C40000',
                                  value: (i.refundedAmountInCents / 100) * -1
                                }))
                              ].filter((c) => c.value !== 0)
                            : []
                      }
                    ]
                  : []),
                {
                  key: 8,
                  group: 4,
                  label: 'Balance Due',
                  weight: 500,
                  description: '',
                  value:
                    (checkoutBalance.totalInCents -
                      checkoutBalance.amountPaidInCents -
                      checkoutBalance.forgivenessAmountInCents +
                      invoiceTotals.balanceInCents) /
                    100
                }
              ]}
            />

            <TaskListInfoCard
              fkRegResourceType={REG_RESOURCE_TYPE_ENUM.SUBMISSION}
              resourceId={regFormSubmission.pkRegFormSubmission}
              taskList={{}}
            />

            <NoteTimelineDisplay
              fkRegResourceType={REG_RESOURCE_TYPE_ENUM.SUBMISSION}
              resourceId={regFormSubmission.pkRegFormSubmission}
            />
          </AppStack>

          <AppStack
            style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
          >
            <PlayerInfoCard
              regFormSubmission={regFormSubmission}
              regPerson={regFormSubmission.regPerson}
              user={regFormSubmission.user}
            />

            <RegistrationLeagueInfoCard regFormSubmission={regFormSubmission} />

            <RegistrationExternalConnectionInfoCard
              regFormSubmission={regFormSubmission}
            />
          </AppStack>
        </AppFlexbox>
      </AppStack>

      <RegistrationListActionModals
        isDetailedView
        modalState={modalState}
        onCloseModal={onCloseModal}
      />
    </PageView>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to="/admin/financials/registrations"
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <PlayerInfoCard />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <PlayerInfoCard />
          <RegistrationLeagueInfoCard />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

RegistrationDetailsView.propTypes = {
  loading: PropTypes.bool,
  regFormSubmission: PropTypes.object
};

export default RegistrationDetailsView;
