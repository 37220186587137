import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Divider, Skeleton } from '@mantine/core';
import NumberFormat from 'react-number-format';
import PageCard from '../../../../components/common/PageCard';
import AppStack from '../../../../components/common/AppStack';
import { useMediaQueries, useModalState } from '../../../../helpers/hooks';
import AppFlexbox from '../../../../components/common/AppFlexbox';
import AppText from '../../../../components/common/AppText';
import PlayerSecondaryContactsModal from './ManageSecondaryContactsModal';

const PlayerInfoCard = ({
  regPerson,
  regFormSubmission,
  user,
  hideAddresses
}) => {
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueries();
  const [displayState, setDisplayState] = useState({ expandContacts: false });

  return regPerson ? (
    <PageCard style={{ padding: 0 }}>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                Player
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 5 }}>
              <AppFlexbox>
                <AppText style={{ fontSize: 13 }}>
                  {regPerson.firstName} {regPerson.lastName}
                </AppText>
                {/* <Anchor
                  component={Link}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  to={`/admin/league/players/${regPerson.pkRegPerson}`}
                >
                  {regPerson.firstName} {regPerson.lastName}
                </Anchor> */}
              </AppFlexbox>
            </AppStack>
          </AppStack>

          {user && (
            <AppStack style={{ flex: 1, gap: 8 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Guardian Information
                </AppText>
              </AppFlexbox>
              <AppStack style={{ gap: 5 }}>
                <AppText style={{ fontSize: 13 }}>{user.name}</AppText>
                {/* <Anchor
                  component={Link}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  to={`/admin/league/guardians/${user.pkUser}`}
                >
                  {user.name}
                </Anchor> */}

                <AppFlexbox>
                  <Anchor
                    href={`mailto:${user.email}`}
                    style={{ fontSize: 13, color: 'dodgerblue' }}
                    target="_blank"
                  >
                    {user.email}
                  </Anchor>
                </AppFlexbox>

                {user.phone && (
                  <NumberFormat
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    format="+1 (###) ###-####"
                    mask="_"
                    style={{ fontSize: 14, color: 'grey' }}
                    thousandSeparator
                    value={user.phone}
                  />
                )}
              </AppStack>
            </AppStack>
          )}
        </AppStack>

        <Divider
          orientation={
            !isTabletOrSmaller && isDesktopOrSmaller ? 'vertical' : 'horizontal'
          }
        />

        {!hideAddresses && (
          <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 500
                }}
              >
                Address
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 13 }}>{regPerson.address1}</AppText>
              {regPerson.address2 && (
                <AppText style={{ fontSize: 13 }}>{regPerson.address2}</AppText>
              )}
              <AppText style={{ fontSize: 13 }}>
                {regPerson.city} {regPerson.province} {regPerson.postalCode}
              </AppText>
              <AppText style={{ fontSize: 13 }}>{regPerson.country}</AppText>
              {regPerson.phone && (
                <NumberFormat
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  format="+1 (###) ###-####"
                  mask="_"
                  style={{ fontSize: 14, color: 'grey' }}
                  thousandSeparator
                  value={regPerson.phone}
                />
              )}
            </AppStack>
          </AppStack>
        )}

        {regFormSubmission && (
          <>
            <Divider
              orientation={
                !isTabletOrSmaller && isDesktopOrSmaller
                  ? 'vertical'
                  : 'horizontal'
              }
            />

            <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <AppText
                  style={{
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  Secondary Contacts
                </AppText>
                <Anchor
                  onClick={() =>
                    onOpenModal(
                      regFormSubmission.regFormSubmissionPersonContacts
                        .length === 0
                        ? 'create_contacts'
                        : 'manage_contacts'
                    )
                  }
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                >
                  {regFormSubmission.regFormSubmissionPersonContacts.length ===
                  0
                    ? 'Add'
                    : 'Manage'}
                </Anchor>
              </AppFlexbox>
              {regFormSubmission.regFormSubmissionPersonContacts.length ===
              0 ? (
                <AppStack>
                  <AppText
                    style={{
                      fontSize: 14,
                      color: '#666',
                      fontStyle: 'italic',
                      textAlign: 'center'
                    }}
                  >
                    No contacts added.
                  </AppText>
                </AppStack>
              ) : (
                <>
                  {regFormSubmission.regFormSubmissionPersonContacts
                    .sort(
                      (a, b) =>
                        b.receiveAllEmails - a.receiveAllEmails ||
                        a.firstName.localeCompare(b.firstName) ||
                        a.lastName.localeCompare(b.lastName)
                    )
                    .slice(
                      0,
                      displayState.expandContacts
                        ? regFormSubmission.regFormSubmissionPersonContacts
                            .length
                        : 1
                    )
                    .map((c) => (
                      <AppStack
                        key={c.pkRegFormSubmissionPersonContact}
                        style={{ gap: 0 }}
                      >
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {c.firstName} {c.lastName}
                        </AppText>

                        <AppFlexbox>
                          <Anchor
                            href={`mailto:${c.email}`}
                            style={{ fontSize: 13, color: 'dodgerblue' }}
                            target="_blank"
                          >
                            {c.email}
                          </Anchor>
                        </AppFlexbox>

                        {c.phone && (
                          <AppText style={{ fontSize: 14, color: '#666' }}>
                            <NumberFormat
                              displayType="text"
                              format="+1 (###) ###-####"
                              mask="_"
                              prefix=", "
                              value={c.phone}
                            />
                          </AppText>
                        )}
                        {c.note && (
                          <AppText style={{ fontSize: 14, color: '#666' }}>
                            {c.note}
                          </AppText>
                        )}

                        {c.receiveAllEmails && (
                          <AppText
                            style={{
                              fontSize: 14,
                              color: '#666',
                              fontStyle: 'italic'
                            }}
                          >
                            Receives all emails
                          </AppText>
                        )}
                      </AppStack>
                    ))}

                  {regFormSubmission.regFormSubmissionPersonContacts.length >
                    1 &&
                    !displayState.expandContacts && (
                      <Anchor
                        onClick={() =>
                          setDisplayState((prev) => ({
                            ...prev,
                            expandContacts: true
                          }))
                        }
                        style={{
                          color: 'dodgerblue',
                          fontSize: 14,
                          textAlign: 'center'
                        }}
                      >
                        Show more
                      </Anchor>
                    )}
                </>
              )}
            </AppStack>

            <PlayerSecondaryContactsModal
              createNew={modalState.action === 'create_contacts'}
              isOpen={
                modalState.isOpen &&
                (modalState.action === 'create_contacts' ||
                  (modalState.isOpen &&
                    modalState.action === 'manage_contacts'))
              }
              onClose={onCloseModal}
              regFormSubmission={regFormSubmission}
            />
          </>
        )}
      </AppStack>
    </PageCard>
  ) : (
    <PageCard style={{ padding: 0 }}>
      <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
        <AppStack style={{ flex: 1, gap: 12 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="30%" />
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox>
              <Skeleton height={18} width="35%" />
            </AppFlexbox>
          </AppStack>
        </AppStack>

        <AppStack style={{ flex: 1, gap: 8 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Skeleton height={22} width="40%" />
          </AppFlexbox>
          <AppStack style={{ gap: 5 }}>
            <Skeleton height={18} width="35%" />
            <Skeleton height={18} width="50%" />
          </AppStack>
        </AppStack>
      </AppStack>

      <Divider
        orientation={
          !isTabletOrSmaller && isDesktopOrSmaller ? 'vertical' : 'horizontal'
        }
      />
    </PageCard>
  );
};

PlayerInfoCard.propTypes = {
  hideAddresses: PropTypes.bool,
  regFormSubmission: PropTypes.object,
  regFormSubmissionPersonContacts: PropTypes.array,
  regPerson: PropTypes.object,
  user: PropTypes.object
};

export default PlayerInfoCard;
