const saveToStorage = (key, value) => {
  localStorage.setItem(key, value?.toString());
};

const getFromStorage = (key) => localStorage.getItem(key)?.toString();

const tryJsonParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

export { saveToStorage, getFromStorage, tryJsonParse };
