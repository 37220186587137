import React, { useRef } from 'react';
import { Tooltip } from '@mantine/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import classes from '../../../styles/nestedStyles.module.css';
import { BULK_EDIT_INPUTS } from './BulkEditInputs';
import { useOnScreen } from '../../../helpers/hooks';

const isSameAsBefore = (prevProps, nextProps) =>
  prevProps.value === nextProps.value &&
  prevProps.disabled === nextProps.disabled &&
  prevProps.selected === nextProps.selected &&
  prevProps.disabledMessage === nextProps.disabledMessage &&
  prevProps.errorMessage === nextProps.errorMessage &&
  prevProps.suffix === nextProps.suffix &&
  prevProps.prefix === nextProps.prefix &&
  prevProps.altValue === nextProps.altValue &&
  prevProps.fieldData.options?.length === nextProps.fieldData.options?.length;

const BulkEditDataColumn = React.memo(
  ({
    fieldData,
    disabled,
    disabledMessage,
    errorMessage,
    value,
    selected,
    altValue,
    suffix,
    prefix,
    onChange,
    onSelect,
    optionsState,
    paddingLeft
  }) => {
    const ref = useRef(null);
    const isColumnShowing = useOnScreen(ref);

    return (
      <AppFlexbox
        ref={ref}
        className={classNames(
          fieldData.firstPosition
            ? classes['bulk-edit-column-sticky']
            : classes['bulk-edit-column'],
          disabled ? classes['bulk-edit-column-disabled'] : ''
        )}
        style={fieldData.firstPosition ? { width: 350, maxWidth: 350 } : {}}
      >
        {isColumnShowing &&
          (disabledMessage ? (
            <Tooltip
              label={disabledMessage}
              multiline
              position="top"
              w={200}
              withArrow
            >
              <AppStack
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'not-allowed',
                  pointerEvents: 'all',
                  paddingLeft: 12,
                  justifyContent: 'center',
                  backgroundColor: 'rgb(245, 245, 245)'
                }}
              >
                <AppStack
                  style={{
                    height: 5,
                    width: 18,
                    borderRadius: 20,
                    backgroundColor: '#dee2e6'
                  }}
                />
              </AppStack>
            </Tooltip>
          ) : (
            <>
              {BULK_EDIT_INPUTS[fieldData.type].render({
                fieldData,
                disabled: disabled || disabledMessage,
                value: !disabledMessage ? value : '',
                altValue,
                suffix,
                prefix,
                onChange,
                optionsState,
                paddingLeft,
                error: errorMessage,
                selected,
                onSelect
              })}
            </>
          ))}
      </AppFlexbox>
    );
  },
  isSameAsBefore
);

BulkEditDataColumn.propTypes = {
  altValue: PropTypes.string,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  fieldData: PropTypes.object,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  optionsState: PropTypes.object,
  paddingLeft: PropTypes.number,
  prefix: PropTypes.string,
  selected: PropTypes.bool,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object
  ])
};

export default BulkEditDataColumn;
