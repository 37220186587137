import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'tabler-icons-react';
import { Skeleton } from '@mantine/core';
import AppStack from './AppStack';
import AppFlexbox from './AppFlexbox';
import AppText from './AppText';
import ActionableIcon from './ActionableIcon';
import { useBackPath } from '../../helpers/hooks';

const PageView = ({
  title,
  subTitle,
  backPath: backPathOverride,
  width,
  buttonAction,
  loading,
  badgeContent,
  children
}) => {
  const { backPath } = useBackPath();

  return (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{ height: '100%', width: '100%', overflow: 'auto' }}
    >
      <AppStack
        p={{ base: 8, sm: '16px 16px 80px 16px' }}
        style={{
          flex: 1,
          margin: 'auto',
          width: '100%',
          maxWidth: width || 1050,
          paddingTop: 16,
          gap: 16
        }}
      >
        {loading ? (
          <AppFlexbox
            style={{
              alignItems: 'start',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppFlexbox style={{ gap: 8 }}>
              <ActionableIcon
                color="dark"
                component={Link}
                radius="md"
                style={{ marginTop: 5 }}
                to={backPathOverride || backPath}
                variant="subtle"
              >
                <ArrowLeft />
              </ActionableIcon>
              <AppStack style={{ gap: 5 }}>
                <Skeleton height={36} width={200} />
                <Skeleton height={14} width={150} />
              </AppStack>
            </AppFlexbox>

            {!!buttonAction && (
              <AppFlexbox style={{ gap: 5 }}>
                <Skeleton height={30} width={125} />
              </AppFlexbox>
            )}
          </AppFlexbox>
        ) : (
          <AppFlexbox
            style={{
              alignItems: 'start',
              justifyContent: 'space-between',
              gap: 8
            }}
          >
            <AppFlexbox style={{ gap: 8 }}>
              <ActionableIcon
                color="dark"
                component={Link}
                radius="md"
                style={{ marginTop: 5 }}
                to={backPathOverride || backPath}
                variant="subtle"
              >
                <ArrowLeft />
              </ActionableIcon>

              <AppStack style={{ gap: 8 }}>
                <AppStack style={{ gap: 0 }}>
                  <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                    <AppText
                      style={{
                        flex: 1,
                        fontSize: 24,
                        fontWeight: 700,
                        wordBreak: 'break-word'
                      }}
                    >
                      {title}
                    </AppText>
                  </AppFlexbox>
                  {subTitle && (
                    <AppText
                      style={{
                        fontSize: 12,
                        lineHeight: '16px',
                        color: '#666'
                      }}
                    >
                      {subTitle}
                    </AppText>
                  )}
                </AppStack>

                {badgeContent}
              </AppStack>
            </AppFlexbox>

            {buttonAction && (
              <AppFlexbox
                style={{ alignItems: 'center', gap: 8, marginTop: 5 }}
              >
                {buttonAction}
              </AppFlexbox>
            )}
          </AppFlexbox>
        )}

        {children}
      </AppStack>
    </AppStack>
  );
};

PageView.propTypes = {
  backPath: PropTypes.string,
  badgeContent: PropTypes.node,
  buttonAction: PropTypes.node,
  children: PropTypes.node,
  loading: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number
};

export default PageView;
