import Auth from '@aws-amplify/auth';
import sportsheadzApi from '../apis/sportsheadzApi';
import createDataProvider from './createDataProvider';

const initialState = {
  merchPackages: { value: [], loading: false, error: null },
  regAssociations: { value: [], loading: false, error: null },
  regAssociation: { value: null, loading: false, error: null },
  regAssociationDivisionFormExternalInvite: {
    value: null,
    loading: false,
    error: null
  },
  regAssociationDivisionFormExternalInvites: {
    value: [],
    loading: false,
    error: null
  },
  regAssociationDivisionFormInvites: { value: [], loading: false, error: null },
  regAssociationEvaluatorInvites: { value: [], loading: false, error: null },
  regCart: { value: null, loading: false, error: null },
  regCartHistory: { value: [], loading: false, error: null },
  regCartOrderHistory: { value: null, loading: false, error: null },
  regEmailUnsubscribe: { value: null, loading: false, error: null },
  regRegistrants: { value: [], loading: false, error: null },
  regFormSubmission: { value: null, loading: false, error: null },
  regFormSubmissionEmails: { value: [], loading: false, error: null },
  regFormSubmissionInvoice: { value: null, loading: false, error: null },
  regFormSubmissionInvoices: { value: [], loading: false, error: null },
  regWaitlistRegistrants: { value: [], loading: false, error: null }
};

const registrationReducer = (state, action) => {
  switch (action.type) {
    case 'fetching':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: null,
          loading: true
        }
      };
    case 'success':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          ...action.payload,
          loading: false
        }
      };
    case 'append':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [...state[action.stateName].value, action.payload],
          loading: false
        }
      };
    case 'replace':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: [
            ...state[action.stateName].value.filter(action.payload.filter),
            action.payload.value
          ],
          loading: false
        }
      };
    case 'remove':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          value: state[action.stateName].value.filter(action.payload),
          loading: false
        }
      };
    case 'error':
      return {
        ...state,
        [action.stateName]: {
          ...state[action.stateName],
          error: action.payload,
          loading: false
        }
      };
    default:
      return state;
  }
};

const getErrorMessage = (e) => {
  let error = 'Oops something went wrong!';
  let type = 'general';
  if (e?.response?.status === 409 && e.response.data) {
    error = e.response.data.error;
    type = e.response.data.type;
  }
  return { error, type, data: e.response?.data };
};

const handleError = (e, stateName, dispatch, onErrorCallback = null) => {
  const { error, type, data } = getErrorMessage(e);

  if (stateName && dispatch) {
    dispatch({
      type: 'error',
      stateName,
      payload: error
    });
  }

  if (onErrorCallback) {
    onErrorCallback(error, type, data);
  }
};

const fetchRegAssociations = (dispatch) => async (
  { fkAssociation },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociations'
    });

    const response = await sportsheadzApi.get('/api/registration', {params: { fkAssociation }});
    dispatch({
      type: 'success',
      stateName: 'regAssociations',
      payload: { value: response.data }
    });

    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regAssociations', dispatch, onErrorCallback);
  }
};

const fetchRegAssociation = (dispatch) => async (
  pkRegAssociation,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociation'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/${pkRegAssociation}`
    );
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
    dispatch({
      type: 'success',
      stateName: 'regAssociation',
      payload: { value: response.data }
    });
  } catch (e) {
    handleError(e, 'regAssociation', dispatch, onErrorCallback);
  }
};

const fetchRegistrants = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regRegistrants'
    });
    const response = await sportsheadzApi.get(`/api/registration/registrant`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      stateName: 'regRegistrants',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regRegistrants',
      payload: 'Oops something went wrong'
    });
  }
};

const createRegistrant = (dispatch) => async (
  {
    firstName,
    middleName,
    lastName,
    dob,
    gender,
    address1,
    address2,
    city,
    province,
    country,
    postalCode,
    phoneNumber,
    regPersonContacts,
    updateExisting
  },
  successCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/registrant`,
      {
        firstName,
        middleName,
        lastName,
        dob,
        gender,
        address1,
        address2,
        city,
        province,
        country,
        postalCode,
        phoneNumber,
        regPersonContacts,
        updateExisting
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regRegistrants',
      payload: { value: response.data }
    });
    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regRegistrants', dispatch, onErrorCallback);
  }
};

const updateRegistrant = (dispatch) => async (
  pkRegPerson,
  {
    firstName,
    middleName,
    lastName,
    dob,
    gender,
    address1,
    address2,
    city,
    province,
    country,
    postalCode,
    phoneNumber,
    regPersonContacts,
    updateExisting
  },
  successCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/registrant/${pkRegPerson}`,
      {
        firstName,
        middleName,
        lastName,
        dob,
        gender,
        address1,
        address2,
        city,
        province,
        country,
        postalCode,
        phoneNumber,
        regPersonContacts,
        updateExisting
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regRegistrants',
      payload: { value: response.data }
    });
    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regRegistrants', dispatch, onErrorCallback);
  }
};

const deleteRegistrant = (dispatch) => async (
  pkRegPerson,
  successCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(`/api/registration/registrant/${pkRegPerson}`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'remove',
      stateName: 'regRegistrants',
      payload: (f) => f.pkRegPerson !== pkRegPerson
    });
    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regRegistrants', dispatch, onErrorCallback);
  }
};

const fetchWaitlistRegistrants = (dispatch) => async (
  pkRegAssociationDivision
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regWaitlistRegistrants'
    });

    let url = '/api/registration/waitlist';
    if (pkRegAssociationDivision) {
      url += `?pkRegAssociationDivision=${pkRegAssociationDivision}`;
    }
    const response = await sportsheadzApi.get(url, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      stateName: 'regWaitlistRegistrants',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regWaitlistRegistrants',
      payload: 'Oops something went wrong'
    });
  }
};

const removeWaitlistPerson = (dispatch) => async (
  pkRegWaitlistPerson,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    await sportsheadzApi.delete(
      `/api/registration/waitlist/${pkRegWaitlistPerson}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'remove',
      stateName: 'regWaitlistRegistrants',
      payload: (f) => f.pkRegWaitlistPerson !== pkRegWaitlistPerson
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regWaitlistRegistrants', dispatch, onErrorCallback);
  }
};

const fetchCart = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCart'
    });

    const response = await sportsheadzApi.get(`/api/registration/cart`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regCart',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchCartHistory = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartHistory'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/cart/history`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartHistory',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regCartHistory',
      payload: 'Oops something went wrong'
    });
  }
};

const fetchCartOrderHistory = (dispatch) => async (pkRegCart) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCartOrderHistory'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/cart/history/${pkRegCart}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCartOrderHistory',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regCartOrderHistory',
      payload: 'Oops something went wrong'
    });
  }
};

const addProductsToCart = (dispatch) => async (
  { regCartProducts },
  successCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCart'
    });

    const response = await sportsheadzApi.post(
      `/api/registration/cart/add-products`,
      { regCartProducts },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const updateProductInCart = (dispatch) => async (
  pkRegCartProduct,
  {
    count,
    regFormSubmissionAnswers,
    divisionRoles,
    financialAssistanceId,
    regFormSubmissionExternalIds,
    regFormSubmissionPersonContacts
  },
  successCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/cart/update-product/${pkRegCartProduct}`,
      {
        count,
        regFormSubmissionAnswers,
        divisionRoles,
        financialAssistanceId,
        regFormSubmissionExternalIds,
        regFormSubmissionPersonContacts
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });
    dispatch({
      type: 'success',
      stateName: 'regFormSubmission',
      payload: { value: null }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const redeemProductCoupon = (dispatch) => async (
  pkRegCartProduct,
  { code },
  successCallback,
  onErrorCallback
) => {
  try {
    const params = { code };
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        delete params[key];
      }
    });
    const searchParams = new URLSearchParams(params);
    const response = await sportsheadzApi.post(
      `/api/registration/cart/redeem-coupon/${pkRegCartProduct}?${searchParams}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });
    dispatch({
      type: 'success',
      stateName: 'regFormSubmission',
      payload: { value: null }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const removeProductFromCart = (dispatch) => async (pkRegCartProduct) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCart'
    });

    const response = await sportsheadzApi.delete(
      `/api/registration/cart/remove-product/${pkRegCartProduct}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regCart',
      payload: 'Oops something went wrong'
    });
  }
};

const checkoutCart = (dispatch) => async (
  { paymentType },
  successCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/cart/checkout`,
      { paymentType },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );

    if (successCallback) {
      successCallback(response.data?.sessionUrl);
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const fetchCheckoutResult = (dispatch) => async (
  { secureId },
  successCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/cart/checkout/result`,
      { secureId },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );

    if (successCallback) {
      successCallback(response.data?.sessionUrl);
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const clearCart = (dispatch) => async () => {
  dispatch({
    type: 'success',
    stateName: 'regCart',
    payload: { value: null }
  });
};

const fetchRegFormSubmission = (dispatch) => async (pkRegFormSubmission) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormSubmission'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/form/submission/${pkRegFormSubmission}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmission',
      payload: { value: response.data }
    });
  } catch {
    dispatch({
      type: 'error',
      stateName: 'regFormSubmission',
      payload: 'Oops something went wrong'
    });
  }
};

const updatePurchasedRegFormSubmission = (dispatch) => async (
  pkRegFormSubmission,
  {
    regFormSubmissionAnswers,
    regPerson,
    divisionRoles,
    financialAssistanceId,
    doNotChargeForChanges,
    regFormSubmissionExternalIds,
    regFormSubmissionPersonContacts
  },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.put(
      `/api/registration/admin/registrations/${pkRegFormSubmission}`,
      {
        regFormSubmissionAnswers,
        regPerson,
        divisionRoles,
        financialAssistanceId,
        doNotChargeForChanges,
        regFormSubmissionExternalIds,
        regFormSubmissionPersonContacts
      },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmission',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'regFormSubmission', dispatch, onErrorCallback);
  }
};

const fetchRegAssociationDivisionFormInvites = (dispatch) => async () => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationDivisionFormInvites'
    });
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationDivisionFormExternalInvites'
    });
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationEvaluatorInvites'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/league/divisions/invite`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisionFormInvites',
      payload: { value: response.data.invites }
    });
    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisionFormExternalInvites',
      payload: { value: response.data.externalInvites }
    });
    dispatch({
      type: 'success',
      stateName: 'regAssociationEvaluatorInvites',
      payload: { value: response.data.evaluatorInvites }
    });
  } catch (e) {
    handleError(e, 'regAssociationDivisionFormInvites', dispatch);
  }
};

const expireRegAssociationDivisionFormInvite = (dispatch) => async (
  pkRegAssociationDivisionFormInvite,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/registration/admin/league/divisions/invite/${pkRegAssociationDivisionFormInvite}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationDivisionFormInvites',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationDivisionFormInvite !==
          pkRegAssociationDivisionFormInvite
      }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regAssociationDivisionFormInvites',
      dispatch,
      onErrorCallback
    );
  }
};

const fetchRegAssociationDivisionFormExternalInvite = (dispatch) => async (
  inviteCode
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regAssociationDivisionFormExternalInvite'
    });
    const response = await sportsheadzApi.get(
      `/api/registration/admin/league/divisions/invite/external/${inviteCode}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisionFormExternalInvite',
      payload: { value: response.data }
    });
  } catch (e) {
    handleError(e, 'regAssociationDivisionFormExternalInvite', dispatch);
  }
};

const expireRegAssociationDivisionFormExternalInvite = (dispatch) => async (
  pkRegAssociationDivisionFormExternalInvite,
  { inviteCode },
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.delete(
      `/api/registration/admin/league/divisions/invite/external/${pkRegAssociationDivisionFormExternalInvite}?inviteCode=${inviteCode}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'replace',
      stateName: 'regAssociationDivisionFormExternalInvites',
      payload: {
        value: response.data,
        filter: (f) =>
          f.pkRegAssociationDivisionFormExternalInvite !==
          pkRegAssociationDivisionFormExternalInvite
      }
    });
    dispatch({
      type: 'success',
      stateName: 'regAssociationDivisionFormExternalInvite',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(
      e,
      'regAssociationDivisionFormExternalInvite',
      dispatch,
      onErrorCallback
    );
  }
};

const fetchRegFormSubmissionInvoices = (dispatch) => async (
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.get(`/api/registration/invoices`, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      withCredentials: true
    });
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionInvoices',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionInvoices', dispatch, onErrorCallback);
  }
};

const fetchRegFormSubmissionInvoice = (dispatch) => async (
  invoiceId,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.get(
      `/api/registration/invoices/${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionInvoice',
      payload: { value: response.data }
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionInvoices', dispatch, onErrorCallback);
  }
};

const checkoutInvoice = (dispatch) => async (
  pkRegFormSubmissionInvoice,
  onSuccessCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/invoice/checkout/${pkRegFormSubmissionInvoice}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    if (onSuccessCallback) {
      onSuccessCallback(response.data.sessionUrl);
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionInvoices', dispatch, onErrorCallback);
  }
};

const fetchCheckoutInvoiceResult = (dispatch) => async (
  { invoiceId, secureId },
  successCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/payment/registration/invoice/checkout/result`,
      { invoiceId, secureId },
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );

    if (successCallback) {
      successCallback(response.data?.sessionUrl);
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const fetchMerchPackages = (dispatch) => async (
  pkRegAssociations,
  successCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'merchPackages'
    });
    const query = pkRegAssociations
      ? `?pkRegAssociations=${pkRegAssociations.join(',')}`
      : '';
    const response = await sportsheadzApi.get(
      `/api/registration/merchandise/packages${query}`
    );
    dispatch({
      type: 'success',
      stateName: 'merchPackages',
      payload: { value: response.data }
    });
    if (successCallback) {
      successCallback(response.data);
    }
  } catch (e) {
    handleError(e, 'merchPackages', dispatch, onErrorCallback);
  }
};

const addMerchPackagesToCart = (dispatch) => async (
  { merchPackages },
  successCallback,
  onErrorCallback
) => {
  try {
    const response = await sportsheadzApi.post(
      `/api/registration/cart/merch`,
      merchPackages,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const updateMerchPackagesInCart = (dispatch) => async (
  { merchPackages, showLoadingState },
  successCallback,
  onErrorCallback
) => {
  try {
    if (showLoadingState) {
      dispatch({
        type: 'fetching',
        stateName: 'regCart'
      });
    }
    const response = await sportsheadzApi.put(
      `/api/registration/cart/merch`,
      merchPackages,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const removeMerchPackagesFromCart = (dispatch) => async (
  fkRegCartMerchPackages,
  successCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regCart'
    });

    const response = await sportsheadzApi.delete(
      `/api/registration/cart/merch?fkRegCartMerchPackages=${fkRegCartMerchPackages.join(
        ','
      )}`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regCart',
      payload: { value: response.data }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regCart', dispatch, onErrorCallback);
  }
};

const unsubscribeFromAssociationEmails = (dispatch) => async (
  {
    fkRegAssociation,
    fkRegFormSubmission,
    fkRegWaitlistPerson,
    fkRegAssociationDivisionFormExternalInvite,
    email,
    resubscribe
  },
  successCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regEmailUnsubscribe'
    });

    const response = await sportsheadzApi.post(
      `/api/registration/mailings/unsubscribe`,
      null,
      {
        params: {
          fkRegAssociation,
          fkRegFormSubmission,
          fkRegWaitlistPerson,
          fkRegAssociationDivisionFormExternalInvite,
          email,
          resubscribe
        }
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regEmailUnsubscribe',
      payload: { value: response.data }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regEmailUnsubscribe', dispatch, onErrorCallback);
  }
};

const fetchRegistrationEmails = (dispatch) => async (
  successCallback,
  onErrorCallback
) => {
  try {
    dispatch({
      type: 'fetching',
      stateName: 'regFormSubmissionEmails'
    });

    const response = await sportsheadzApi.get(
      `/api/registration/mailings/communications`,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        withCredentials: true
      }
    );
    dispatch({
      type: 'success',
      stateName: 'regFormSubmissionEmails',
      payload: { value: response.data }
    });

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    handleError(e, 'regFormSubmissionEmails', dispatch, onErrorCallback);
  }
};

export const { Provider, Context } = createDataProvider(
  registrationReducer,
  {
    addMerchPackagesToCart,
    addProductsToCart,
    checkoutCart,
    checkoutInvoice,
    clearCart,
    createRegistrant,
    deleteRegistrant,
    expireRegAssociationDivisionFormExternalInvite,
    expireRegAssociationDivisionFormInvite,
    fetchCheckoutInvoiceResult,
    fetchCheckoutResult,
    fetchMerchPackages,
    fetchRegAssociations,
    fetchRegAssociation,
    fetchRegAssociationDivisionFormExternalInvite,
    fetchRegAssociationDivisionFormInvites,
    fetchRegFormSubmissionInvoice,
    fetchRegFormSubmissionInvoices,
    fetchRegistrationEmails,
    fetchRegistrants,
    fetchWaitlistRegistrants,
    fetchCart,
    fetchCartHistory,
    fetchCartOrderHistory,
    fetchRegFormSubmission,
    unsubscribeFromAssociationEmails,
    updateMerchPackagesInCart,
    updateRegistrant,
    updateProductInCart,
    updatePurchasedRegFormSubmission,
    redeemProductCoupon,
    removeMerchPackagesFromCart,
    removeProductFromCart,
    removeWaitlistPerson
  },
  initialState
);
