import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  Loader,
  TextInput,
  Tooltip
} from '@mantine/core';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { Context as RegistrationAdminContext } from '../../../providers/RegistrationAdminProvider';
import { Context as RegistrationAdminDashboardContext } from '../../../providers/RegistrationAdminDashboardProvider';
import PaginationList from '../../common/PaginationList';
import AppFlexbox from '../../common/AppFlexbox';
import { formatUtcDate } from '../../../helpers/format';
import { REG_EXTERNAL_CONNECTION_TYPE_ENUM } from '../../../config/constants';

const HockeyCanadaConfigForm = ({ formState, setFormState }) => {
  const [configState, setConfigState] = useState({
    pageIndex: 1,
    search: ''
  });
  const { state, fetchAdminRegForms } = useContext(RegistrationAdminContext);
  const { state: dashboardState } = useContext(
    RegistrationAdminDashboardContext
  );

  const regAssociation = dashboardState.regAssociation.value;
  const associationForms = state.regForms.value.filter(
    (f) =>
      f.fkRegAssociation === regAssociation?.pkRegAssociation &&
      new Date(formatUtcDate(f.endDate)) > new Date()
  );

  const filteredForms = associationForms.filter(
    (f) =>
      !configState.search ||
      f.name.toLowerCase().includes(configState.search.toLowerCase())
  );
  const allFilteredFormsSelected = filteredForms.every(
    (f) =>
      !!formState.regFormExternalConnections?.find(
        (c) => c.fkRegForm === f.pkRegForm && c.isExcluded
      )
  );

  useEffect(() => {
    if (state.regForms.value.length === 0) {
      fetchAdminRegForms();
    }
  }, []);

  useEffect(() => {
    setFormState((c) => ({
      ...c,
      regFormExternalConnections: [
        ...associationForms.map((f) => {
          const existingConnection = f.regFormExternalConnections?.find(
            (ec) =>
              ec.regAssociationExternalConnection.fkRegExternalConnectionType.toString() ===
                REG_EXTERNAL_CONNECTION_TYPE_ENUM.HOCKEY_CANADA &&
              !ec.deleted &&
              ec.isExcluded
          );

          return existingConnection
            ? {
                fkRegForm: f.pkRegForm,
                isExcluded: true
              }
            : null;
        })
      ].filter((f) => f !== null)
    }));
  }, [state.regForms.value]);

  const onRegFormsChange = (pkRegforms = [], removeSelected = false) => {
    const regFormExternalConnectionsState =
      formState.regFormExternalConnections || [];
    setFormState({
      ...formState,
      regFormExternalConnections: [
        ...regFormExternalConnectionsState.filter(
          (c) => !pkRegforms.includes(c.fkRegForm)
        ),
        ...pkRegforms.map((pk) => ({
          fkRegForm: pk,
          isExcluded: !removeSelected
        }))
      ]
    });
  };

  return (
    <AppStack style={{ gap: 16 }}>
      <AppText style={{ fontSize: 14 }}>
        Configure your association with Hockey Canada to enable storing and
        reporting on Hockey Canada ID's.
      </AppText>

      <AppText style={{ fontSize: 14 }}>
        This will automatically add a Hockey Canada ID field to all forms. You
        can choose to exclude this field on individual forms.
      </AppText>

      {/* <Checkbox
        checked={formState.isRequired}
        description="Make Hockey Canada ID a required input on all forms. This can be overridden on individual forms."
        disabled={formState.loading}
        label="Make input required by default"
        onChange={() => {
          setFormState({
            ...formState,
            isRequired: !formState.isRequired
          });
        }}
        style={{ marginTop: 12 }}
        styles={{ label: { fontWeight: 500 } }}
      /> */}

      <Divider style={{ margin: '12px 0' }} />

      <Checkbox
        description="Choose which forms should not include the Hockey Canada ID field. You can update this later by editing the form."
        disabled={formState.loading}
        label="Select forms to exclude"
        onChange={() => {
          setFormState({
            ...formState,
            showFormExclusions: !formState.showFormExclusions
          });
        }}
        styles={{ label: { fontWeight: 500 } }}
        value={formState.showFormExclusions}
      />

      {formState.showFormExclusions && (
        <AppStack style={{ marginTop: 12 }}>
          <AppText
            style={{
              fontSize: 14,
              textAlign: 'center',
              color: '#666',
              fontStyle: 'italic'
            }}
          >
            Select the forms you want to exclude the Hockey Canada ID field
            from.
          </AppText>

          {state.regForms.loading ? (
            <AppStack
              style={{
                padding: 16,
                border: '1px solid #ccc',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Loader color="dark" size={40} />
            </AppStack>
          ) : associationForms.length === 0 ? (
            <AppStack
              style={{
                padding: 16,
                border: '1px solid #ccc',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AppText style={{ fontSize: 14 }}>
                No existing forms found.
              </AppText>
              <Button
                color="dark"
                component={Link}
                to="/admin/forms?modal=create"
                variant="outline"
              >
                Create a new form
              </Button>
            </AppStack>
          ) : (
            <AppStack style={{ gap: 8 }}>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <Tooltip
                  label="Only showing forms that are active and available for registration. (future end date)"
                  multiline
                  w={300}
                  withArrow
                >
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Available forms
                  </AppText>
                </Tooltip>

                <Checkbox
                  checked={allFilteredFormsSelected}
                  disabled={filteredForms.length === 0 || formState.loading}
                  label="Select all"
                  onChange={() => {
                    onRegFormsChange(
                      filteredForms.map((f) => f.pkRegForm),
                      allFilteredFormsSelected
                    );
                  }}
                  styles={{ label: { fontWeight: 500 } }}
                />
              </AppFlexbox>
              <TextInput
                disabled={formState.loading}
                onChange={(e) =>
                  setConfigState({
                    ...configState,
                    search: e.currentTarget.value,
                    pageIndex: 1
                  })
                }
                placeholder="Search forms by name"
                value={configState.search || ''}
              />

              {filteredForms.length === 0 ? (
                <AppStack
                  style={{
                    padding: 16,
                    border: '1px solid #ccc',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>
                    No forms found by search.
                  </AppText>
                  <Button
                    color="dark"
                    onClick={() =>
                      setConfigState({
                        ...configState,
                        search: '',
                        pageIndex: 1
                      })
                    }
                    variant="outline"
                  >
                    Clear search
                  </Button>
                </AppStack>
              ) : (
                <PaginationList
                  items={[
                    ...filteredForms.map((f) => {
                      const isSelected = !!formState.regFormExternalConnections?.find(
                        (c) => c.fkRegForm === f.pkRegForm && c.isExcluded
                      );

                      return (
                        <AppFlexbox
                          key={f.pkRegForm}
                          onClick={() => {
                            if (!formState.loading) {
                              onRegFormsChange([f.pkRegForm], isSelected);
                            }
                          }}
                          style={{
                            padding: 8,
                            alignItems: 'center',
                            cursor: formState.loading
                              ? 'not-allowed'
                              : 'pointer'
                          }}
                        >
                          <Checkbox
                            checked={isSelected}
                            disabled={formState.loading}
                            onChange={() => {}}
                          />
                          <AppText style={{ fontSize: 14 }}>{f.name}</AppText>
                        </AppFlexbox>
                      );
                    })
                  ]}
                  itemsPerPage={8}
                  onPageChange={(index) => {
                    setConfigState({ ...configState, pageIndex: index });
                  }}
                  pageIndex={configState.pageIndex}
                />
              )}
            </AppStack>
          )}
        </AppStack>
      )}
    </AppStack>
  );
};

HockeyCanadaConfigForm.propTypes = {
  formState: PropTypes.object,
  setFormState: PropTypes.func
};

export default HockeyCanadaConfigForm;
